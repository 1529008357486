import { AffiliateResponse } from "@types";

import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpandMoreRounded from "@material-ui/icons/ExpandMoreRounded";
import Typography from "@material-ui/core/Typography";

import { getPaymentName } from "utils/payments";
import formatMoney from "utils/formatMoney";
import EmptyData from "components/empty-data";
import { TooltipCard } from "components/cards";
import { getAffiliateState } from "modules/affiliate";

import Skeleton from "./Skeleton";
import { usePaymentInfoStyles } from "../../styles";

interface Props {
  handleEditPayment?: () => void;
}

const PaymentInformation: FC<Props> = ({ handleEditPayment }) => {
  const classes = usePaymentInfoStyles();

  const [expanded, setExpanded] = useState<boolean>(false);
  const { isLoading, error, data } = useSelector(getAffiliateState);

  if (isLoading) {
    return <Skeleton />;
  }

  if (error || !data) {
    return <EmptyData margin="16px" />;
  }

  const {
    allowPayments,
    paymentMinAmount,
    paymentMethod,
    paymentMethodDetails
  } = data as AffiliateResponse;

  return (
    <Box>
      <Box className={classes.header}>
        <Typography className={classes.title} variant="h3">
          Payment information
        </Typography>
        {handleEditPayment && <Button onClick={handleEditPayment}>Edit</Button>}
      </Box>
      <Box className={classes.accountInfo}>
        <TooltipCard label="Payment Method">
          {getPaymentName(paymentMethod)}
        </TooltipCard>
        {paymentMethodDetails && (
          <>
            {paymentMethodDetails.bankName && (
              <TooltipCard label="bank name">
                {paymentMethodDetails.bankName}
              </TooltipCard>
            )}
            {paymentMethodDetails.skrillAccount && (
              <TooltipCard label="skrill account">
                {paymentMethodDetails.skrillAccount}
              </TooltipCard>
            )}
            {paymentMethodDetails.casinoAccountEmail && (
              <TooltipCard label="casino account">
                {paymentMethodDetails.casinoAccountEmail}
              </TooltipCard>
            )}
            <TooltipCard label="Allow Payments">
              {allowPayments ? "Yes" : "No"}
            </TooltipCard>
            <TooltipCard label="Minimum Payment Amount">
              {formatMoney.from(paymentMinAmount, { decimals: 0 })}
            </TooltipCard>
            {expanded && (
              <>
                {paymentMethodDetails.bankAccountHolder && (
                  <TooltipCard label="account holder name">
                    {paymentMethodDetails.bankAccountHolder}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankIban && (
                  <TooltipCard label="Account Number/IBAN">
                    {paymentMethodDetails.bankIban}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankBic && (
                  <TooltipCard label="BIC/SWIFT">
                    {paymentMethodDetails.bankBic}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankClearingNumber && (
                  <TooltipCard label="VAT number">
                    {paymentMethodDetails.bankClearingNumber}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankAddress && (
                  <TooltipCard label="bank address">
                    {paymentMethodDetails.bankAddress}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankPostCode && (
                  <TooltipCard label="bank post code">
                    {paymentMethodDetails.bankPostCode}
                  </TooltipCard>
                )}
                {paymentMethodDetails.bankCountry && (
                  <TooltipCard label="bank country">
                    {paymentMethodDetails.bankCountry}
                  </TooltipCard>
                )}
              </>
            )}
            {(paymentMethodDetails.bankAccountHolder ||
              paymentMethodDetails.bankIban ||
              paymentMethodDetails.bankBic ||
              paymentMethodDetails.bankClearingNumber ||
              paymentMethodDetails.bankAddress ||
              paymentMethodDetails.bankPostCode ||
              paymentMethodDetails.bankCountry) && (
              <Typography
                variant="subtitle1"
                className={classes.expandLabel}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? "Show Less" : "Show more"}
                <ExpandMoreRounded
                  className={cn({ [classes.expanded]: expanded })}
                />
              </Typography>
            )}
          </>
        )}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default PaymentInformation;
