import { AffiliatePlayersRevenue } from "@types";

import { useCallback, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { BRANDS } from "constants/brands";

import {
  getAffiliatePlayersRevenueState,
  setAffiliatePlayersRevenueBrandFilter
} from "./duck";
import { useStyles } from "./styles";

export default function () {
  const dispatch = useDispatch();
  const classes = useStyles({});

  const { isLoading, error, brand, totals, total, data } = useSelector(
    getAffiliatePlayersRevenueState
  );

  const { query, setQuery, results } = useSearch<AffiliatePlayersRevenue>(
    ["playerId"],
    data
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleBrandFilter = useCallback(
    (brand: BRANDS | null) => {
      dispatch(setAffiliatePlayersRevenueBrandFilter(brand));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(setAffiliatePlayersRevenueBrandFilter(null));
    };
  }, [dispatch]);

  return {
    classes,
    error,
    isLoading,
    isEmpty: isEmpty(data),
    isEmptySearch: isEmpty(results),
    total,
    totals,
    brandFilterProps: {
      brand: brand,
      onChange: handleBrandFilter
    },
    showCpa: totals?.cpa !== 0,
    searchQuery: query,
    revenues: results,
    handleSearchChange
  };
}
