import { Plan } from "@types";

import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { useSearch } from "hooks";
import SelectableList from "components/selectable-list";
import { fetchPlans, getPlansState } from "modules/plans";

export interface ChangeDealProps {
  initialPlanId: number;
  handleSubmit: (planId: number) => void;
}

const ChangeDeal: FC<ChangeDealProps> = ({ initialPlanId, handleSubmit }) => {
  const dispatch = useDispatch();

  const { isLoading, data } = useSelector(getPlansState);
  const [selectedPlan, setPlan] = useState<number>(initialPlanId);
  const { query, setQuery, results } = useSearch<Plan>(["name"], data);

  const options = results.map(({ planId, name }) => ({
    label: name,
    value: planId
  }));

  const handleSearchReset = useCallback(() => setQuery(""), [setQuery]);
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
    [setQuery]
  );

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  return (
    <Box width="100%" height="100%" maxWidth={380} padding={2}>
      <Box className="drawer-header" marginBottom={2}>
        <Typography
          variant="h4"
          color="secondary"
          className="drawer-header_title"
        >
          change deal
        </Typography>
        <Button
          color="secondary"
          disabled={isEqual(initialPlanId, selectedPlan)}
          onClick={() => handleSubmit(selectedPlan)}
        >
          save changes
        </Button>
      </Box>
      <Input
        value={query}
        disabled={isLoading}
        placeholder="Search deal by name"
        onChange={handleSearch}
      />
      <Box
        margin={-2}
        marginTop={2}
        height="100%"
        maxHeight="calc(100% - 144px)"
      >
        <SelectableList
          isLoading={isLoading}
          options={options}
          selectedOption={selectedPlan}
          handleClear={handleSearchReset}
          handleChange={(id: number) => setPlan(id)}
        />
      </Box>
    </Box>
  );
};

export default ChangeDeal;
