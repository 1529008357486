import { Reducer } from "redux";

import { AppState } from "store";

enum ErrorActionTypes {
  SET_ERROR = "@error/set-error",
  RESET_ERROR = "@error/reset-error"
}

interface ErrorPayload {
  status: number;
  message?: string;
}

interface SetErrorStatusAction {
  type: ErrorActionTypes.SET_ERROR;
  payload: ErrorPayload;
}

interface ResetErrorAction {
  type: ErrorActionTypes.RESET_ERROR;
}

type ErrorsActions = SetErrorStatusAction | ResetErrorAction;

const setError = (payload: ErrorPayload): SetErrorStatusAction => ({
  type: ErrorActionTypes.SET_ERROR,
  payload
});

const resetError = (): ResetErrorAction => ({
  type: ErrorActionTypes.RESET_ERROR
});

interface ErrorState {
  status?: number;
  message?: string;
}

const initialState: ErrorState = {
  status: undefined,
  message: ""
};

const reducer: Reducer<ErrorState, ErrorsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ErrorActionTypes.SET_ERROR:
      return {
        status: action.payload.status,
        message: action.payload.message
      };
    case ErrorActionTypes.RESET_ERROR:
      return initialState;
    default:
      return state;
  }
};

const getErrorState = (state: AppState) => state.error;

export { reducer, setError, resetError, getErrorState };
