import { CreateLandingRequest } from "@types";
import { Reducer } from "redux";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";

import api from "api";
import { BRAND_NAMES } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";

enum AffiliateLandingsActionTypes {
  FETCH_AFFILIATE_LANDINGS_LOADING = "@affiliate/landings/fetch-landings-loading",
  FETCH_AFFILIATE_LANDINGS_SUCCESS = "@affiliate/landings/fetch-landings-success",
  FETCH_AFFILIATE_LANDINGS_FAILURE = "@affiliate/landings/fetch-landings-failure"
}

interface FetchAffiliateLandingsIsLoadingAction {
  type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_LOADING;
}

interface FetchAffiliateLandingsIsSuccessAction {
  type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_SUCCESS;
  payload: CreateLandingRequest[];
}

interface FetchAffiliateLandingsIsFailureAction {
  type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_FAILURE;
}

type AffiliateLandingsActions =
  | FetchAffiliateLandingsIsLoadingAction
  | FetchAffiliateLandingsIsSuccessAction
  | FetchAffiliateLandingsIsFailureAction;

const fetchAffiliateLandingsIsLoading =
  (): FetchAffiliateLandingsIsLoadingAction => ({
    type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_LOADING
  });

const fetchAffiliateLandingsIsSuccess = (
  landings: CreateLandingRequest[]
): FetchAffiliateLandingsIsSuccessAction => ({
  type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_SUCCESS,
  payload: landings
});

const fetchAffiliateLandingsIsFailure =
  (): FetchAffiliateLandingsIsFailureAction => ({
    type: AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_FAILURE
  });

const fetchAffiliateLandings =
  (): ThunkActionCreator<AffiliateLandingsActions> => async dispatch => {
    try {
      dispatch(fetchAffiliateLandingsIsLoading());
      const { landings } = await api.affiliate.landings.getLandings();
      dispatch(fetchAffiliateLandingsIsSuccess(landings));
    } catch (error) {
      dispatch(fetchAffiliateLandingsIsFailure());
    }
  };

interface AffiliateLandingsState {
  error: boolean;
  isLoading: boolean;
  data: CreateLandingRequest[];
}

const initialState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<AffiliateLandingsState, AffiliateLandingsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload
      };
    case AffiliateLandingsActionTypes.FETCH_AFFILIATE_LANDINGS_FAILURE:
      return {
        error: true,
        isLoading: false,
        data: []
      };

    default:
      return state;
  }
};

const getAffiliateLandingsState = (state: AppState) => state.affiliate.landings;
const getAffiliateLandingsBrands = (state: AppState) =>
  uniqBy(
    state.affiliate.landings.data.map(({ brandId }) => ({
      label: BRAND_NAMES[brandId],
      value: brandId
    })),
    "value"
  );
const getAffiliateLandingsSelectOptions = (state: AppState) =>
  groupBy(
    state.affiliate.landings.data.map(({ brandId, landingPage }) => ({
      brandId,
      label: landingPage,
      value: landingPage
    })),
    "brandId"
  );
const isCustomLanding = (landingPage?: string) => (state: AppState) =>
  isEmpty(
    state.affiliate.landings.data.filter(
      option => option.landingPage === landingPage
    )
  );

export {
  reducer,
  fetchAffiliateLandings,
  isCustomLanding,
  getAffiliateLandingsBrands,
  getAffiliateLandingsState,
  getAffiliateLandingsSelectOptions
};
