import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import formatMoney from "utils/formatMoney";
import { PAYMENT_STATUSES } from "constants/payments";
import { TooltipCard } from "components/cards";
import EmptyData from "components/empty-data";
import { DownloadCsvButton } from "components/button";
import Table, { Column, HTMLColumn, TableSkeleton } from "components/table";

import Skeleton from "./Skeleton";
import usePayment from "./usePayment";

const CSV_HEADERS = [
  { label: "Description", key: "description" },
  { label: "Tax", key: "tax" },
  { label: "Amount", key: "amount" }
];

const PaymentDetails: FC = () => {
  const {
    classes,
    error,
    tableError,
    isLoading,
    isTableLoading,
    affiliateId,
    month,
    roles,
    details,
    totals,
    payments,
    handleMarkPayment,
    handleConfirmPayment,
    handleDownloadDraft,
    handleDownloadInvoice
  } = usePayment();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error || !details) {
    return <EmptyData />;
  }

  const {
    invoiceId,
    invoiceNumber,
    name,
    contactName,
    allowPayments,
    countryId,
    taxRate,
    status,
    manager,
    canConfirm,
    canMarkAsPaid,
    openingBalance,
    creditedAmount,
    paidAmount,
    closingBalance,
    paymentMethod,
    paymentMethodDetails
  } = details;

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.affiliate}>
          <Typography variant="h2" className={classes.name}>
            {contactName}
          </Typography>
          <Typography className={classes.company}>{name}</Typography>
          <Box className={classes.cardsContainer}>
            <Box className={classes.infoRow}>
              <TooltipCard label="ID">{`#${affiliateId}`}</TooltipCard>
              <TooltipCard label="Country">{countryId}</TooltipCard>
              <TooltipCard label="Tax Rate">{`${taxRate}%`}</TooltipCard>
              {manager && <TooltipCard label="Manager">{manager}</TooltipCard>}
              <TooltipCard label="Allow Payments">
                {`${allowPayments ? "Yes" : "No"}`}
              </TooltipCard>
            </Box>
          </Box>
        </Box>
        <Box className={classes.paymentMethodContainer}>
          <Typography className={classes.paymentMethodTitle}>
            Beneficiary Bank Details
          </Typography>
          <Typography className={classes.paymentMethod}>
            {paymentMethod}
          </Typography>
          {paymentMethodDetails && (
            <Box className={classes.paymentMethodDetails}>
              {paymentMethodDetails.bankIban && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    IBAN:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankIban}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankBic && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    BIC:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankBic}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankAccountHolder && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Name:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankAccountHolder}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankName && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Bank:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankName}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankAddress && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Bank address:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankAddress}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankPostCode && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Bank postcode:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankPostCode}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.bankCountry && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Bank country:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.bankCountry}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.skrillAccount && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Skrill account:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.skrillAccount}
                  </Typography>
                </Box>
              )}
              {paymentMethodDetails.casinoAccountEmail && (
                <Box className={classes.paymentMethodProperty}>
                  <Typography className={classes.paymentMethodPropertyLabel}>
                    Casino account:
                  </Typography>
                  <Typography className={classes.paymentMethodPropertyText}>
                    {paymentMethodDetails.casinoAccountEmail}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.dateContainer}>
          <Typography variant="h3" className={classes.date}>
            {month}
          </Typography>
          {status === PAYMENT_STATUSES.unconfirmed ||
          status === PAYMENT_STATUSES.blocked ? (
            <Button
              disabled={!canConfirm}
              className={classes.confirmButton}
              onClick={handleConfirmPayment}
            >
              Confirm invoice
            </Button>
          ) : (
            <Button
              disabled={!canMarkAsPaid}
              className={classes.confirmButton}
              onClick={handleMarkPayment}
            >
              Mark as paid
            </Button>
          )}
        </Box>
        <Box className={classes.cardsContainer}>
          <Box className={classes.infoRow}>
            {invoiceId && invoiceNumber && (
              <>
                <TooltipCard label="Invoice ID">{invoiceId}</TooltipCard>
                <TooltipCard label="Invoice Number">
                  {invoiceNumber}
                </TooltipCard>
              </>
            )}
            <TooltipCard
              label="Invoice Status"
              className={cn({
                [classes.paid]: status === PAYMENT_STATUSES.paid,
                [classes.blocked]: status === PAYMENT_STATUSES.blocked,
                [classes.confirmed]: status === PAYMENT_STATUSES.confirmed,
                [classes.unconfirmed]: status === PAYMENT_STATUSES.unconfirmed
              })}
            >
              {status}
            </TooltipCard>
          </Box>
          <Box className={classes.infoRow}>
            <TooltipCard label="Opening balance">
              {formatMoney.from(openingBalance)}
            </TooltipCard>
            <TooltipCard label="Credited Amount">
              {formatMoney.from(creditedAmount)}
            </TooltipCard>
            <TooltipCard label="Paid Amount">
              {formatMoney.from(paidAmount)}
            </TooltipCard>
            <TooltipCard label="Closing balance">
              {formatMoney.from(closingBalance)}
            </TooltipCard>
          </Box>
        </Box>
        <Box className={classes.downloadButtons}>
          {!invoiceId ? (
            <Button
              onClick={() => handleDownloadDraft()}
              disabled={roles.includes("payer")}
            >
              Download draft
            </Button>
          ) : (
            <Button onClick={() => handleDownloadInvoice("invoice")}>
              Download invoice
            </Button>
          )}
          <Button
            onClick={() => handleDownloadInvoice("receipt")}
            disabled={!invoiceId}
          >
            Download receipt
          </Button>
          <DownloadCsvButton
            headers={CSV_HEADERS}
            data={payments}
            disabled={isEmpty(payments)}
          />
        </Box>
        <Box>
          {isTableLoading ? (
            <TableSkeleton showTotal rows={6} cells={3} cellWidth={128} />
          ) : !isEmpty(payments) && !tableError ? (
            <Box>
              <Table
                displayRows={4}
                initialData={payments}
                total={totals.total}
                totals={{ title: "Subtotal", totals }}
              >
                <HTMLColumn
                  label="Description"
                  name="description"
                  align="left"
                  className={classes.htmlColumn}
                />
                <Column label="Tax" name="tax" type="currency" align="right" />
                <Column
                  label="Amount"
                  name="amount"
                  type="currency"
                  align="right"
                />
              </Table>
            </Box>
          ) : (
            <EmptyData />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
