export enum CALLBACK_METHODS {
  GET = "GET",
  POST = "POST"
}

export const CALLBACK_METHODS_OPTIONS = [
  { label: CALLBACK_METHODS.GET, value: CALLBACK_METHODS.GET },
  { label: CALLBACK_METHODS.POST, value: CALLBACK_METHODS.POST }
];

export enum CALLBACK_TRIGGERS {
  NRC = "NRC",
  NDC = "NDC"
}

export const CALLBACK_TRIGGERS_OPTIONS = [
  { label: CALLBACK_TRIGGERS.NRC, value: CALLBACK_TRIGGERS.NRC },
  { label: CALLBACK_TRIGGERS.NDC, value: CALLBACK_TRIGGERS.NDC }
];
