import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  fetchAffiliateViewPlayers,
  getAffiliatePlayersState
} from "modules/affiliate-players";
import {
  ActivitiesTable,
  fetchAffiliateViewPlayerActivities
} from "modules/affiliate-player";
import { BackButton } from "components/button";
import { TableSkeleton } from "components/table";
import PlayerInfo, { PlayerInfoSkeleton } from "components/player-info";
import EmptyData from "components/empty-data";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: "relative",
      marginTop: 28
    },
    backButton: {
      position: "absolute",
      top: -52,
      left: 0,
      [theme.breakpoints.down("md")]: {
        top: -32
      }
    },
    divider: {
      margin: "16px 0px 32px 0px"
    }
  })
);

interface Params {
  playerId: string;
}

const AffiliateViewPlayerPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { playerId } = useParams<Params>();
  const { data, isLoading } = useSelector(getAffiliatePlayersState);

  const details = data.find(item => item.playerId === Number(playerId));

  const handleBack = useCallback(() => history.push("/players"), [history]);

  useEffect(() => {
    dispatch(fetchAffiliateViewPlayerActivities(Number(playerId)));
  }, [dispatch, playerId]);

  useEffect(() => {
    if (!details) {
      dispatch(fetchAffiliateViewPlayers(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box className={classes.container}>
      <BackButton className={classes.backButton} onClick={handleBack}>
        go back
      </BackButton>
      {details ? (
        <>
          <PlayerInfo {...details} />
          <Divider className={classes.divider} />
          <ActivitiesTable />
        </>
      ) : isLoading ? (
        <>
          <PlayerInfoSkeleton />
          <Divider className={classes.divider} />
          <TableSkeleton rows={12} cells={9} />
        </>
      ) : (
        <EmptyData />
      )}
    </Box>
  );
};

export default AffiliateViewPlayerPage;
