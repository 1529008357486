import { AffiliateLink } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { BRANDS } from "constants/brands";

enum AffiliateLinksActionTypes {
  FETCH_AFFILIATE_LINKS_LOADING = "@affiliate/links/fetch-links-loading",
  FETCH_AFFILIATE_LINKS_SUCCESS = "@affiliate/links/fetch-links-success",
  FETCH_AFFILIATE_LINKS_FAILURE = "@affiliate/links/fetch-links-failure",
  SET_AFFILIATE_LINKS_BRAND_FILTER = "@affiliate/links/set-brand-filet"
}

interface FetchAffiliateLinksIsLoadingAction {
  type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_LOADING;
}

interface FetchAffiliateLinksIsSuccessAction {
  type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_SUCCESS;
  payload: { links: AffiliateLink[] };
}

interface FetchAffiliateLinksIsFailureAction {
  type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_FAILURE;
}

interface SetAffiliateLinksBrandFilterAction {
  type: AffiliateLinksActionTypes.SET_AFFILIATE_LINKS_BRAND_FILTER;
  payload: BRANDS | null;
}

type AffiliateLinksActions =
  | FetchAffiliateLinksIsLoadingAction
  | FetchAffiliateLinksIsSuccessAction
  | FetchAffiliateLinksIsFailureAction
  | SetAffiliateLinksBrandFilterAction;

const fetchAffiliateLinksIsLoading =
  (): FetchAffiliateLinksIsLoadingAction => ({
    type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_LOADING
  });

const fetchAffiliateLinksIsSuccess = (
  links: AffiliateLink[]
): FetchAffiliateLinksIsSuccessAction => ({
  type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_SUCCESS,
  payload: { links }
});

const fetchAffiliateLinksIsFailure =
  (): FetchAffiliateLinksIsFailureAction => ({
    type: AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_FAILURE
  });

const fetchAffiliateLinks =
  (
    id: number,
    brandId: BRANDS | null
  ): ThunkActionCreator<AffiliateLinksActions> =>
  async dispatch => {
    dispatch(fetchAffiliateLinksIsLoading());
    try {
      const { links } = await api.admin.affiliate.links.getLinks(id, brandId);
      dispatch(fetchAffiliateLinksIsSuccess(links));
    } catch (err) {
      dispatch(fetchAffiliateLinksIsFailure());
    }
  };

const fetchAffiliateViewLinks =
  (brandId: BRANDS | null): ThunkActionCreator<AffiliateLinksActions> =>
  async dispatch => {
    dispatch(fetchAffiliateLinksIsLoading());
    try {
      const { links } = await api.affiliate.links.getLinks(brandId);
      dispatch(fetchAffiliateLinksIsSuccess(links));
    } catch (err) {
      dispatch(fetchAffiliateLinksIsFailure());
    }
  };

const setAffiliateLinksBrandFilter = (
  brand: BRANDS | null
): SetAffiliateLinksBrandFilterAction => ({
  type: AffiliateLinksActionTypes.SET_AFFILIATE_LINKS_BRAND_FILTER,
  payload: brand
});

interface AffiliateLinksState {
  error: boolean;
  isLoading: boolean;
  brand: BRANDS | null;
  data: AffiliateLink[];
}

const initialState = {
  error: false,
  isLoading: false,
  brand: null,
  data: []
};

const reducer: Reducer<AffiliateLinksState, AffiliateLinksActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_LOADING:
      return {
        ...state,
        isLoading: true,
        data: [],
        error: false
      };
    case AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.links,
        error: false
      };
    case AffiliateLinksActionTypes.SET_AFFILIATE_LINKS_BRAND_FILTER:
      return {
        ...state,
        brand: action.payload
      };
    case AffiliateLinksActionTypes.FETCH_AFFILIATE_LINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    default:
      return state;
  }
};

const getAffiliateLinksState = (state: AppState) => state.affiliate.links;
const getAffiliateLinksSelectOptions = (state: AppState) =>
  state.affiliate.links.data.map(({ name, linkId }) => ({
    label: name,
    value: linkId
  }));

export {
  reducer,
  fetchAffiliateLinks,
  fetchAffiliateViewLinks,
  getAffiliateLinksState,
  setAffiliateLinksBrandFilter,
  getAffiliateLinksSelectOptions
};
