import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormikHelpers, FormikValues } from "formik";

import api from "api";
import { openSnackbar } from "modules/snackbar";

import { useForgotValidationSchema } from "./helpers/useForgotSchema";
import { Steps, ForgotValues } from "./types";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState<Steps>(Steps.Email);

  const handleEmailFormSubmit = useCallback(
    async (values: FormikValues, actions: FormikHelpers<ForgotValues>) => {
      const { ok } = await api.affiliate.auth.forgotPassword(values.email);

      if (ok === true) {
        setStep(Steps.Code);
        actions.setTouched({});
      }
      actions.setSubmitting(false);
    },
    []
  );

  const handleCodeFormSubmit = useCallback(
    (_, actions: FormikHelpers<ForgotValues>) => {
      setStep(Steps.NewPassword);
      actions.setTouched({});
      actions.setSubmitting(false);
    },
    []
  );

  const handleNewPasswordSubmit = useCallback(
    async (values: FormikValues, actions: FormikHelpers<ForgotValues>) => {
      const { ok } = await api.affiliate.auth.updatePassword({
        email: values.email,
        pinCode: values.code,
        newPassword: values.password
      });

      if (ok === true) {
        actions.setTouched({});
        actions.setSubmitting(false);
        dispatch(
          openSnackbar({
            type: "success",
            message: "Password successfully changed!"
          })
        );
        history.push("/login");
      }
    },
    [dispatch, history]
  );

  const validationSchema = useForgotValidationSchema(step);

  const submitHandlers = {
    [Steps.Email]: handleEmailFormSubmit,
    [Steps.Code]: handleCodeFormSubmit,
    [Steps.NewPassword]: handleNewPasswordSubmit
  };

  return {
    step,
    validationSchema,
    handleStep: setStep,
    handleSubmit: submitHandlers[step]
  };
}
