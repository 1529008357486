import * as yup from "yup";
import { PAYMENT_METHODS } from "constants/payments";
import {
  bankAccountValidationSchema,
  skrillAccountValidationSchema,
  casinoAccountValidationSchema
} from "utils/payments";

const validationSchema = yup.object().shape({
  paymentMethod: yup.string().required("Payment method is required"),
  paymentMethodDetails: yup
    .object()
    .when("paymentMethod", {
      is: PAYMENT_METHODS.skrill,
      then: skrillAccountValidationSchema
    })
    .when("paymentMethod", {
      is: PAYMENT_METHODS.casino,
      then: casinoAccountValidationSchema
    })
    .when("paymentMethod", {
      is: PAYMENT_METHODS.bank,
      then: bankAccountValidationSchema
    }),
  paymentMinAmount: yup
    .number()
    .moreThan(0, "Min amount must be greater than 0")
    .required("Minimum payment amount is required")
});

export default validationSchema;
