import React, { FC } from "react";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { EMPTY_DATA_TEXT } from "constants/texts";

import { useEmptyDataStyles } from "./styles";

interface Props extends BoxProps {
  title?: string;
  text?: string;
}

const EmptyData: FC<Props> = ({ className, title, text, ...rest }) => {
  const classes = useEmptyDataStyles();

  return (
    <Box component="div" className={cn(classes.container, className)} {...rest}>
      <Typography className={classes.title}>
        {title ? title : "No data"}
      </Typography>
      <Typography className={classes.text}>
        {text ? text : EMPTY_DATA_TEXT}
      </Typography>
    </Box>
  );
};

export default EmptyData;
