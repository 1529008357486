import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";

import { useStyles } from "../styles";

interface Props {
  disabled?: boolean;
}

const EmailForm: FC<Props> = ({ disabled }) => {
  const classes = useStyles();

  return (
    <Box className={classes.form}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Forgot Password</Typography>
        <Link to="/login">
          <Button color="secondary">Sign In</Button>
        </Link>
      </Box>
      <Typography className={classes.notice}>
        Enter email address the mail you used when registering with Affmore. We
        will send you verification code.
      </Typography>
      <TextField label="Email" name="email" />
      <Box className={classes.buttons}>
        <Button
          type="submit"
          disabled={disabled}
          className={classes.submitButton}
        >
          Next step
        </Button>
      </Box>
    </Box>
  );
};

export default EmailForm;
