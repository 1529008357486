import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import DateRoute from "components/date-route";
import Layout from "pages/admin/Layout";
import Affiliates from "pages/admin/Affiliates";
import AffiliateLink from "pages/admin/AffiliateLink";
import AffiliateLinks from "pages/admin/AffiliateLinks";
import AffiliateLog from "pages/admin/AffiliateLog";
import AffiliateOverview from "pages/admin/AffiliateOverview";
import AffiliateRevenue from "pages/admin/AffiliateRevenue";
import AffiliateInvoices from "pages/admin/AffiliateInvoices";
import AffiliatePlayers from "pages/admin/AffiliatePlayers";
import AffiliateSubAffiliates from "pages/admin/AffiliateSubAffiliates";
import AffiliateActivities from "pages/admin/AffiliateActivities";
import AffiliateCallbacks from "pages/admin/AffiliateCallbacks";
import Overview from "pages/admin/Overview";
import Payments from "pages/admin/Payments";
import Landings from "pages/admin/Landings";
import Fees from "pages/admin/Fees";
import Plans from "pages/admin/Plans";
import NotFound from "pages/NotFound";
import ErrorPage from "modules/error";

import AffiliateLogin from "pages/affiliate-view/Login";
import ForgotPassword from "pages/affiliate-view/ForgotPassword";

const AdminRoutes: FC = () => (
  <Switch>
    <Route exact path="/login" component={AffiliateLogin} />
    <Route exact path="/forgot" component={ForgotPassword} />
    <Layout>
      <Switch>
        <Route exact path="/admin/affiliates" component={Affiliates} />
        <Route
          path="/admin/affiliates/:id/:tab"
          render={routeProps => (
            <Affiliates {...routeProps}>
              <Switch>
                <DateRoute
                  path="/admin/affiliates/:id/overview/:year/:month"
                  component={AffiliateOverview}
                />
                <DateRoute
                  path="/admin/affiliates/:id/revenue/:year/:month"
                  component={AffiliateRevenue}
                />
                <Route
                  exact
                  component={AffiliateLinks}
                  path="/admin/affiliates/:id/links"
                />
                <Route
                  path="/admin/affiliates/:id/links/:linkId"
                  component={AffiliateLink}
                />
                <Route
                  component={AffiliatePlayers}
                  path="/admin/affiliates/:id/players"
                />
                <Route
                  component={AffiliateInvoices}
                  path="/admin/affiliates/:id/invoices"
                />
                <DateRoute
                  exact
                  path="/admin/affiliates/:id/subs/:year/:month"
                  component={AffiliateSubAffiliates}
                />
                <Route
                  path="/admin/affiliates/:id/log"
                  component={AffiliateLog}
                />
                <Route
                  exact
                  path="/admin/affiliates/:id/activities"
                  component={AffiliateActivities}
                />
                <Route
                  exact
                  path="/admin/affiliates/:id/callbacks"
                  component={AffiliateCallbacks}
                />
                <Route path="*" render={() => <ErrorPage />} />
              </Switch>
            </Affiliates>
          )}
        />
        <DateRoute
          exact
          path="/admin/overview/:year/:month"
          component={Overview}
        />
        <DateRoute path="/admin/payments/:year/:month" component={Payments} />
        <Route path="/admin/plans" component={Plans} />
        <Route path="/admin/fees" component={Fees} />
        <Route exact path="/admin/landings" component={Landings} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </Layout>
  </Switch>
);

export default AdminRoutes;
