import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Divider as MaterialDivider } from "@material-ui/core";
import { DividerProps } from "@material-ui/core/Divider";

const Container = withStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: -16,
    overflow: "hidden"
  }
})((props: BoxProps) => <Box {...props} />);

const Divider = withStyles(() => ({
  root: {
    height: 1,
    margin: "32px 0"
  }
}))((props: DividerProps) => <MaterialDivider {...props} />);

export { Container, Divider };
