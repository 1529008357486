import { CreateAffiliateLinkValues, AffiliateLink } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers } from "formik";

import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";
import {
  createAffiliateLink,
  deleteAffiliateLink
} from "modules/affiliate-link";
import Links, {
  fetchAffiliateLinks,
  getAffiliateLinksState
} from "modules/affiliate-links";

interface Params {
  id: string;
}

const AffiliateLinksPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Params>();
  const { brand } = useSelector(getAffiliateLinksState);

  const handleLinkClick = ({ linkId }: AffiliateLink) => {
    history.push(`/admin/affiliates/${id}/links/${linkId}`);
  };

  const handleCreateLink = useCallback(
    (
      values: CreateAffiliateLinkValues,
      helpers: FormikHelpers<CreateAffiliateLinkValues>
    ) => {
      const data = {
        planId: values.planId,
        brandId: values.brandId,
        name: values.name,
        landingPage: values.landingPage
      };
      dispatch(createAffiliateLink(Number(id), brand, data, helpers));
    },
    [dispatch, id, brand]
  );

  const handleDeleteLink = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      ({ linkId, name }: AffiliateLink) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "delete-link",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              content: name,
              onConfirm: () =>
                dispatch(deleteAffiliateLink(Number(id), linkId, brand))
            }
          })
        );
      },
    [dispatch, id, brand]
  );

  const handleCreateDrawerOpen = useCallback(() => {
    dispatch(
      openDrawer("create-link", {
        title: "Create new link",
        onSubmit: handleCreateLink
      })
    );
  }, [dispatch, handleCreateLink]);

  useEffect(() => {
    dispatch(fetchAffiliateLinks(Number(id), brand));
  }, [dispatch, id, brand]);

  return (
    <Links
      handleLinkClick={handleLinkClick}
      handleDeleteLink={handleDeleteLink}
      handleCreateDrawerOpen={handleCreateDrawerOpen}
    />
  );
};

export default AffiliateLinksPage;
