import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import Datepicker from "modules/datepicker";

interface Props {
  affiliateId?: string;
  handleAddSubAffiliate: () => void;
}

const SearchButtons: FC<Props> = ({ affiliateId, handleAddSubAffiliate }) => (
  <>
    <Datepicker baseDateUrl={`/admin/affiliates/${affiliateId}/subs`} />
    <Button color="secondary" onClick={handleAddSubAffiliate}>
      add sub-affiliate
    </Button>
  </>
);

export default SearchButtons;
