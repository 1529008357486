import { CreateAffiliateLogRequest } from "@types";

import React, { FC } from "react";
import Dropzone from "react-dropzone";
import cn from "classnames";
import { Formik, FormikHelpers } from "formik";
import uniqBy from "lodash/uniqBy";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/SendRounded";
import LinearProgress from "@material-ui/core/LinearProgress";
import AttachIcon from "@material-ui/icons/AttachFileRounded";

import TextField from "components/text-field";
import AttachmentToUpload from "components/attachment-to-upload";

import { useStyles } from "../styles";

interface Props {
  uploadProgress: number;
  handleSubmit: (
    values: CreateAffiliateLogRequest,
    helpers: FormikHelpers<CreateAffiliateLogRequest>
  ) => void;
}

const initialValues: CreateAffiliateLogRequest = {
  note: "",
  attachments: []
};

const Form: FC<Props> = ({ uploadProgress, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        isSubmitting,
        values: { note, attachments },
        setFieldValue,
        handleSubmit
      }) => (
        <Dropzone
          noClick
          noDrag
          noKeyboard
          disabled={isSubmitting}
          onDrop={(files: File[]) => {
            const uniqFiles = uniqBy(
              [...attachments, ...files],
              ({ name }) => name
            );
            setFieldValue("attachments", uniqFiles);
          }}
        >
          {({ getInputProps, open }) => (
            <form onSubmit={handleSubmit}>
              <input {...getInputProps()} />
              <TextField
                name="note"
                disabled={isSubmitting}
                className={classes.textField}
                placeholder="Write a comment"
                InputProps={{
                  classes: { root: classes.noteInput },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={open}
                        className={cn(classes.attachmentIcon, {
                          [classes.attachmentIconActive]:
                            attachments.length !== 0
                        })}
                        disabled={isSubmitting}
                      >
                        <AttachIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        className={classes.submitIcon}
                        disabled={isSubmitting || !note}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box className={classes.uploads}>
                {isSubmitting && uploadProgress > 0 && uploadProgress < 100 && (
                  <Box className={classes.progressBar}>
                    <LinearProgress
                      variant="determinate"
                      className={classes.progressBarLoader}
                      value={uploadProgress > 0 ? uploadProgress : 65}
                    />
                    <Typography variant="subtitle1">{`${uploadProgress} %`}</Typography>
                  </Box>
                )}
                {!isSubmitting &&
                  attachments.map((file, index) => (
                    <AttachmentToUpload
                      key={index}
                      file={file}
                      onRemove={(name: string) => {
                        const files = attachments.filter(
                          file => file.name !== name
                        );
                        setFieldValue("attachments", files);
                      }}
                    />
                  ))}
              </Box>
            </form>
          )}
        </Dropzone>
      )}
    </Formik>
  );
};

export default Form;
