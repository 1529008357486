import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const ProfileSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Skeleton className={classes.title} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} height={85} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} height={156} />
        <Box className={classes.checkbox}>
          <Skeleton className={classes.checkInput} />
          <Skeleton className={classes.text} />
        </Box>
      </Box>
      <Box className={classes.payments}>
        <Skeleton className={classes.title} />
        <Skeleton className={classes.input} />
        <Skeleton className={classes.input} />
      </Box>
      <Skeleton className={classes.button} />
    </Box>
  );
};

export default ProfileSkeleton;
