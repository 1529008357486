import { useSelector } from "react-redux";

import { getAuthState } from "modules/authentication";
import { getViewModeState, isAdminViewMode } from "modules/view-mode";
import { getGoogleUser } from "modules/google-auth";

export default function useRoles() {
  const { loggedIn, isUserLoggedIn, isAdminLoggedIn, userId, isAdmin } =
    useSelector(getAuthState);
  const user = useSelector(getGoogleUser);
  const adminViewMode = useSelector(isAdminViewMode);
  const { affiliateId } = useSelector(getViewModeState);

  return {
    isAdmin,
    isLoggedin: loggedIn,
    isUserLoggedIn: isUserLoggedIn || adminViewMode,
    isAdminLoggedIn: (isAdminLoggedIn || !!user) && !adminViewMode,
    userId: affiliateId ? affiliateId : userId
  };
}
