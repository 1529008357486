import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontSize: 14,
      lineHeight: "24px",
      maxHeight: 42,
      backgroundColor: theme.colors.white,
      "& .MuiIconButton-root": {
        padding: 8
      }
    },
    adornedEnd: {
      paddingRight: 6
    }
  })
);

export { useStyles };
