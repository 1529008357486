import React, { FC, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { useUrlDateCheck } from "hooks";
import { fetchUsers } from "modules/users";
import { BrandDeal } from "modules/affiliate-deal";
import { BrandAffiliateFee } from "modules/affiliate-fee";
import Deals, { fetchAffiliateDeals } from "modules/affiliate-deals";
import Fees, { fetchAffiliateFees } from "modules/affiliate-fees";
import OverviewStatistics, {
  fetchAffiliateOverview
} from "modules/affiliate-overview";
import Drawer from "components/drawer";
import UserBalance from "components/user-balance";
import AffiliateDetails from "components/affiliate-details";

interface MatchParams {
  id: string;
  year: string;
  month: string;
}

type Props = RouteComponentProps<MatchParams>;

const AffiliateOverviewPage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { id, year, month } = match.params;

  useUrlDateCheck();

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  const handleDealClick = useCallback(
    (dealId: string) => {
      history.push(`${match.url}/deals/${dealId}`);
    },
    [history, match]
  );

  const handleAffiliateFeeClick = useCallback(
    (adminFeeId: string) => {
      history.push(`${match.url}/fees/${adminFeeId}`);
    },
    [history, match]
  );

  useEffect(() => {
    Promise.all([
      dispatch(fetchUsers()),
      dispatch(fetchAffiliateDeals(Number(id))),
      dispatch(fetchAffiliateFees(Number(id)))
    ]);
  }, [dispatch, id]);

  useEffect(() => {
    const options = {
      affiliateId: Number(id),
      year: Number(year),
      month: Number(month)
    };
    dispatch(fetchAffiliateOverview(options));
  }, [dispatch, id, year, month]);

  return (
    <Box>
      <AffiliateDetails />
      <OverviewStatistics />
      <Deals handleDealClick={handleDealClick} />
      <Fees handleAffiliateFeeClick={handleAffiliateFeeClick} />
      <UserBalance />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Route
          exact
          path={`${match.path}/deals/:brandId`}
          component={BrandDeal}
        />
        <Route
          exact
          path={`${match.path}/fees/:brandId`}
          component={BrandAffiliateFee}
        />
      </Drawer>
    </Box>
  );
};

export default AffiliateOverviewPage;
