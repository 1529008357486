import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    table: {
      height: "calc(100vh - 152px)"
    },
    idColumn: {
      maxWidth: 96,
      fontWeight: 500
    },
    nameColumn: {
      minWidth: 128
    },
    openingBalanceColumn: {
      minWidth: 64,
      maxWidth: 98,
      fontWeight: 500
    },
    creditedAmountColumn: {
      minWidth: 64,
      maxWidth: 98
    },
    paidAmountColumn: {
      minWidth: 64,
      maxWidth: 98
    },
    closingBalanceColumn: {
      minWidth: 64,
      maxWidth: 128,
      fontWeight: 500
    },
    taxRateColumn: {
      maxWidth: 128,
      paddingLeft: 32,
      paddingRight: 32
    },
    statusColumn: {
      display: "flex",
      alignItems: "center",
      maxWidth: 128,
      fontWeight: 500,
      [theme.breakpoints.down("xl")]: {
        maxWidth: 96
      }
    },
    managerColumn: {
      maxWidth: 256,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.colors.black61
    },
    paymentColumn: {
      minWidth: 104,
      maxWidth: 128
    },
    buttonColumn: {
      display: "flex",
      alignItems: "center",
      minWidth: 176,
      maxWidth: 176,
      "& button": {
        width: "90%"
      }
    }
  })
);

const skeletonStyles = {
  container: {
    marginTop: -16
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64
  },
  tableButton: {
    borderRadius: 17
  }
};

export { useStyles, skeletonStyles };
