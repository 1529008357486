import * as yup from "yup";

const validationSchema = yup.object().shape({
  subId: yup.string().required("Affiliate is required"),
  commissionShare: yup
    .number()
    .min(0, "Commission must be greater than 0")
    .max(100, "Commission must be lower than 100")
    .required("Commission is required")
});

export default validationSchema;
