import React, { FC, ChangeEvent, useCallback } from "react";

import Box, { BoxProps } from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import Input from "components/input";

import { useStyles } from "./styles";

interface InputProps {
  value: string;
  onChange: (query: string) => void;
}

type ClearButtonProps = InputProps;

interface Props extends InputProps, Omit<BoxProps, "onChange"> {
  placeholder?: string;
}

const ClearButton: FC<ClearButtonProps> = ({ value, onChange }) =>
  value ? (
    <IconButton onClick={() => onChange("")}>
      <CloseRoundedIcon fontSize="small" />
    </IconButton>
  ) : null;

const SearchInput: FC<Props> = ({ value, onChange, placeholder, ...rest }) => {
  const classes = useStyles();

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value),
    [onChange]
  );

  return (
    <Box {...rest}>
      <Input
        value={value}
        InputProps={{
          classes,
          endAdornment: <ClearButton value={value} onChange={onChange} />
        }}
        placeholder={placeholder || "Search"}
        onChange={handleSearch}
      />
    </Box>
  );
};

const SearchInputSkeleton: FC = () => (
  <Skeleton variant="rect" width="100%" height={42} />
);

export { SearchInputSkeleton };
export default SearchInput;
