/* eslint-disable no-undef */
import {
  AffiliatePlayers,
  AffiliatePlayersRevenue,
  AffiliatePlayersRevenueTotals,
  UpdateAffiliatePlayerRequest
} from "@types";

import { Reducer } from "redux";

import api from "api";
import { BRANDS } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { SnackbarActions, openSnackbar } from "modules/snackbar";

enum AffiliatePlayersActionTypes {
  CHECK_AFFILIATE_PLAYER = "@affiliate/players/check-player",
  UNCHECK_AFFILIATE_PLAYER = "@affiliate/players/uncheck-player",
  SET_AFFILIATE_CHECKED_PLAYERS = "@affiliate/players/set-checked-players",
  SET_AFFILIATE_PLAYERS_BRAND_FILTER = "@affiliate/players/set-brand-filter",
  FETCH_AFFILIATE_PLAYERS_LOADING = "@affiliate/players/fetch-players-loading",
  FETCH_AFFILIATE_PLAYERS_SUCCESS = "@affiliate/players/fetch-players-success",
  FETCH_AFFILIATE_VIEW_PLAYERS_SUCCESS = "@affiliate/players/fetch-affiliate-view-players-success",
  FETCH_AFFILIATE_PLAYERS_FAILURE = "@affiliate/players/fetch-players-failure",
  UPDATE_AFFILIATE_PLAYERS_SUCCESS = "@affiliate/players/update-players-success"
}

interface FetchAffiliatePlayersIsLoadingAction {
  type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_LOADING;
}

interface FetchAffiliatePlayersIsSuccessAction {
  type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_SUCCESS;
  payload: AffiliatePlayers;
}

interface FetchAffiliateViewPlayersIsSuccessAction {
  type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_VIEW_PLAYERS_SUCCESS;
  payload: AffiliatePlayers;
}

interface FetchAffiliatePlayersIsFailureAction {
  type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_FAILURE;
}

interface UpdateAffiliatePlayersIsSuccessAction {
  type: AffiliatePlayersActionTypes.UPDATE_AFFILIATE_PLAYERS_SUCCESS;
}

interface SetAffiliatePlayersBrandFilterAction {
  type: AffiliatePlayersActionTypes.SET_AFFILIATE_PLAYERS_BRAND_FILTER;
  payload: BRANDS | null;
}

interface CheckAffiliatePlayerAction {
  type: AffiliatePlayersActionTypes.CHECK_AFFILIATE_PLAYER;
  payload: number;
}

interface UncheckAffiliatePlayerAction {
  type: AffiliatePlayersActionTypes.UNCHECK_AFFILIATE_PLAYER;
  payload: number;
}

interface SetAffiliateCheckedPlayersAction {
  type: AffiliatePlayersActionTypes.SET_AFFILIATE_CHECKED_PLAYERS;
  payload: number[];
}

type AffiliatePlayersActions =
  | CheckAffiliatePlayerAction
  | UncheckAffiliatePlayerAction
  | SetAffiliateCheckedPlayersAction
  | FetchAffiliatePlayersIsLoadingAction
  | FetchAffiliatePlayersIsSuccessAction
  | FetchAffiliateViewPlayersIsSuccessAction
  | FetchAffiliatePlayersIsFailureAction
  | UpdateAffiliatePlayersIsSuccessAction
  | SetAffiliatePlayersBrandFilterAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliatePlayersActions | DrawersActions>
>;

const checkAffiliatePlayer = (id: number): CheckAffiliatePlayerAction => ({
  type: AffiliatePlayersActionTypes.CHECK_AFFILIATE_PLAYER,
  payload: id
});

const uncheckAffiliatePlayer = (id: number): UncheckAffiliatePlayerAction => ({
  type: AffiliatePlayersActionTypes.UNCHECK_AFFILIATE_PLAYER,
  payload: id
});

const setAffiliateCheckedPlayers = (
  ids: number[]
): SetAffiliateCheckedPlayersAction => ({
  type: AffiliatePlayersActionTypes.SET_AFFILIATE_CHECKED_PLAYERS,
  payload: ids
});

const setAffiliatePlayersBrandFilter = (
  brand: BRANDS | null
): SetAffiliatePlayersBrandFilterAction => ({
  type: AffiliatePlayersActionTypes.SET_AFFILIATE_PLAYERS_BRAND_FILTER,
  payload: brand
});

const fetchAffiliateplayersIsLoading =
  (): FetchAffiliatePlayersIsLoadingAction => ({
    type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_LOADING
  });

const fetchAffiliatePlayersIsSuccess = (
  players: AffiliatePlayers
): FetchAffiliatePlayersIsSuccessAction => ({
  type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_SUCCESS,
  payload: { ...players }
});

const fetchAffiliatePlayersIsFailure =
  (): FetchAffiliatePlayersIsFailureAction => ({
    type: AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_FAILURE
  });

const updateAffiliatePlayersIsSuccess =
  (): UpdateAffiliatePlayersIsSuccessAction => ({
    type: AffiliatePlayersActionTypes.UPDATE_AFFILIATE_PLAYERS_SUCCESS
  });

const fetchAffiliatePlayers =
  (
    id: number,
    brandId: BRANDS | null
  ): ThunkActionCreator<AffiliatePlayersActions> =>
  async dispatch => {
    dispatch(fetchAffiliateplayersIsLoading());
    try {
      const players = await api.admin.affiliate.players.getPlayers(id, brandId);
      dispatch(fetchAffiliatePlayersIsSuccess(players));
    } catch (err) {
      dispatch(fetchAffiliatePlayersIsFailure());
    }
  };

const fetchAffiliateViewPlayers =
  (brand: BRANDS | null): ThunkActionCreator<AffiliatePlayersActions> =>
  async dispatch => {
    dispatch(fetchAffiliateplayersIsLoading());
    try {
      const players = await api.affiliate.players.getPlayers(brand);
      dispatch(fetchAffiliatePlayersIsSuccess(players));
    } catch (err) {
      dispatch(fetchAffiliatePlayersIsFailure());
    }
  };

const updateAffiliatePlayers =
  (
    id: number,
    brandId: BRANDS | null,
    playersIds: number[],
    data: UpdateAffiliatePlayerRequest
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(fetchAffiliateplayersIsLoading());
    const promises = playersIds.map(playerId =>
      api.admin.affiliate.players.updatePlayer(id, playerId, data)
    );
    await Promise.all(promises);
    dispatch(updateAffiliatePlayersIsSuccess());
    dispatch(fetchAffiliatePlayers(id, brandId));
    dispatch(setAffiliateCheckedPlayers([]));
    dispatch(
      openSnackbar({
        type: "success",
        message: `${playersIds.length} ${
          playersIds.length > 1 ? "players" : "player"
        } updated!`
      })
    );
    dispatch(closeDrawer());
  };

interface AffiliatePlayersState {
  error: boolean;
  isLoading: boolean;
  brand: BRANDS | null;
  checkedIds: number[];
  total: number;
  totals?: AffiliatePlayersRevenueTotals;
  data: AffiliatePlayersRevenue[];
}

const initialState = {
  error: false,
  isLoading: false,
  brand: null,
  checkedIds: [],
  total: 0,
  totals: undefined,
  data: []
};

const reducer: Reducer<AffiliatePlayersState, AffiliatePlayersActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false
      };
    case AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_SUCCESS:
    case AffiliatePlayersActionTypes.FETCH_AFFILIATE_VIEW_PLAYERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.payload.revenues.items,
        totals: action.payload.revenues.totals,
        total: action.payload.revenues.total
      };
    case AffiliatePlayersActionTypes.SET_AFFILIATE_PLAYERS_BRAND_FILTER:
      return {
        ...state,
        brand: action.payload
      };
    case AffiliatePlayersActionTypes.CHECK_AFFILIATE_PLAYER:
      return {
        ...state,
        checkedIds: [...state.checkedIds, action.payload]
      };
    case AffiliatePlayersActionTypes.UNCHECK_AFFILIATE_PLAYER:
      return {
        ...state,
        checkedIds: state.checkedIds.filter(id => id !== action.payload)
      };
    case AffiliatePlayersActionTypes.SET_AFFILIATE_CHECKED_PLAYERS:
      return {
        ...state,
        checkedIds: action.payload
      };
    case AffiliatePlayersActionTypes.FETCH_AFFILIATE_PLAYERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    default:
      return state;
  }
};

const getAffiliatePlayersState = (state: AppState) => state.affiliate.players;
const getAffiliatePlayerStateById = (state: AppState) => (id: string) => ({
  error: state.affiliate.players.error,
  isLoading: state.affiliate.players.isLoading,
  player: state.affiliate.players.data.find(
    ({ playerId }) => playerId === Number(id)
  )
});

export {
  reducer,
  checkAffiliatePlayer,
  uncheckAffiliatePlayer,
  setAffiliateCheckedPlayers,
  fetchAffiliatePlayers,
  fetchAffiliateViewPlayers,
  updateAffiliatePlayers,
  setAffiliatePlayersBrandFilter,
  getAffiliatePlayersState,
  getAffiliatePlayerStateById
};
