import { AxiosInstance } from "axios";
import { BASE_API_URL } from "constants/variables";

import { API } from "./types";

export default (api: AxiosInstance): API => ({
  admin: {
    getProfile: () => api.get(`${BASE_API_URL}/admin/profile`),
    getCloseMonthStatus: () => api.get(`${BASE_API_URL}/admin/close-month`),
    closeMonth: () => api.put(`${BASE_API_URL}/admin/close-month`),
    auth: {
      signIn: data => api.post(`${BASE_API_URL}/auth/user/login`, data)
    },
    affiliate: {
      getAffiliate: affiliateId =>
        api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}`),
      updateAffiliate: (affiliateId, data) =>
        api.put(`${BASE_API_URL}/admin/affiliates/${affiliateId}`, data),
      getOverview: ({ affiliateId, year, month }) =>
        api.get(
          `${BASE_API_URL}/admin/affiliates/${affiliateId}/overview/${year}/${month}`
        ),
      getActivity: (affiliateId, from, to, brandId) =>
        api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/activities`, {
          params: { from, to, brandId }
        }),
      links: {
        getLinks: (affiliteId, brandId) =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliteId}/links`, {
            params: { brandId }
          }),
        getLink: (affiliateId, linkId, from, to) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/links/${linkId}`,
            { params: { from, to } }
          ),
        createLink: (affiliateId, data) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/links`,
            data
          ),
        updateLink: (affiliateId, linkId, data) =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/links/${linkId}`,
            data
          ),
        deleteLink: (affiliateId, linkId) =>
          api.delete(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/links/${linkId}`
          )
      },
      deals: {
        getDeals: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/deals`),
        updateDeal: data =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${data.affiliateId}/deals`,
            data
          ),
        deleteDeal: (affiliateId, brandId) =>
          api.delete(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/deals/${brandId}`
          )
      },
      fees: {
        getFees: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/fees`),
        updateFees: data =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${data.affiliateId}/fees`,
            data
          ),
        deleteFee: (affiliateId, brandId) =>
          api.delete(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/fees/${brandId}`
          )
      },
      players: {
        getPlayers: (affiliateId, brandId) =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/players`, {
            params: { brandId }
          }),
        getPlayersRevenue: ({ affiliateId, year, month, brandId }) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/revenues/${year}/${month}`,
            { params: { brandId } }
          ),
        getPlayerActivities: (affiliateId, playerId) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/players/${playerId}`
          ),
        getPlayerActivitiesByDate: ({ affiliateId, year, month, playerId }) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/players/${playerId}/${year}/${month}`
          ),
        updatePlayer: (affiliateId, playerId, data) =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/players/${playerId}`,
            data
          )
      },
      invoices: {
        getInvoice: (affiliateId, invoiceId) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoices/${invoiceId}`
          ),
        getDraftInvoice: affiliateId =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoice-draft`
          ),
        createInvoiceAttachment: (
          affiliateId,
          invoiceId,
          file,
          handleProgress
        ) => {
          const formData = new FormData();
          formData.append("file", file);

          return api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoice-attachment/${invoiceId}`,
            formData,
            {
              onUploadProgress: event => {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                handleProgress && handleProgress(percentCompleted);
              },
              headers: { "Content-Type": "multipart/form-data" }
            }
          );
        },
        getInvoices: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/invoices`),
        createPayment: (affiliateId, data) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoice-draft`,
            data
          ),
        confirmInvoice: affiliateId =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoices`,
            {}
          ),
        markInvoiceAsPaid: (affiliateId, invoiceId) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/invoices/${invoiceId}`
          )
      },
      callbacks: {
        getCallbacks: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/callbacks`),
        createCallback: (affiliateId, data) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/callbacks`,
            data
          ),
        updateCallback: (affiliateId, callbackId, data) =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/callbacks/${callbackId}`,
            data
          ),
        deleteCallback: (affiliateId, callbackId) =>
          api.delete(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/callbacks/${callbackId}`
          )
      },
      log: {
        getLog: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/logs`),
        createNote: (affiliateId, { note, attachments }, handleProgress) => {
          const formData = new FormData();
          formData.append("note", note);
          attachments.map(file => formData.append("file", file));

          return api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/logs`,
            formData,
            {
              onUploadProgress: event => {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                handleProgress && handleProgress(percentCompleted);
              },
              headers: { "Content-Type": "multipart/form-data" }
            }
          );
        }
      },
      subaffiliates: {
        getSubAffiliates: ({ affiliateId, year, month }) =>
          api.get(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/sub-affiliates/${year}/${month}`
          ),
        addSubAffiliate: (affiliateId, subAffiliateId, data) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/sub-affiliates/${subAffiliateId}`,
            data
          ),
        updateSubAffiliate: (affiliateId, subAffiliateId, data) =>
          api.put(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/sub-affiliates/${subAffiliateId}`,
            data
          ),
        deleteSubAffiliate: (affiliateId, subAffiliateId) =>
          api.delete(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/sub-affiliates/${subAffiliateId}`
          )
      },
      subaccounts: {
        getSubAccounts: affiliateId =>
          api.get(`${BASE_API_URL}/admin/affiliates/${affiliateId}/children`),
        createSubAccount: (affiliateId, data) =>
          api.post(
            `${BASE_API_URL}/admin/affiliates/${affiliateId}/children`,
            data
          )
      }
    },
    affiliates: {
      getAffiliates: () => api.get(`${BASE_API_URL}/admin/affiliates`)
    },
    users: {
      getUsers: () => api.get(`${BASE_API_URL}/admin/users`)
    },
    overview: {
      getOverview: ({ year, month, includeInternals, brandId }) =>
        api.get(`${BASE_API_URL}/admin/overview/${year}/${month}`, {
          params: { includeInternals, brandId }
        })
    },
    payments: {
      getPayments: (year, month) =>
        api.get(`${BASE_API_URL}/admin/payments`, { params: { year, month } }),
      getPayment: (affiliateId, year, month) =>
        api.get(`${BASE_API_URL}/admin/payments/${affiliateId}`, {
          params: { year, month }
        })
    },
    plans: {
      getPlans: () => api.get(`${BASE_API_URL}/admin/plans`),
      getPlan: planId => api.get(`${BASE_API_URL}/admin/plans/${planId}`),
      createPlan: data => api.post(`${BASE_API_URL}/admin/plans`, data),
      updatePlan: (planId, data) =>
        api.put(`${BASE_API_URL}/admin/plans/${planId}`, data),
      deletePlan: planId => api.delete(`${BASE_API_URL}/admin/plans/${planId}`)
    },
    fees: {
      getFees: () => api.get(`${BASE_API_URL}/admin/fees`),
      getFee: adminFeeId => api.get(`${BASE_API_URL}/admin/fees/${adminFeeId}`),
      createFee: data => api.post(`${BASE_API_URL}/admin/fees`, data),
      updateFee: (adminFeeId, data) =>
        api.put(`${BASE_API_URL}/admin/fees/${adminFeeId}`, data),
      deleteFee: adminFeeId =>
        api.delete(`${BASE_API_URL}/admin/fees/${adminFeeId}`)
    },
    landings: {
      getLandings: () => api.get(`${BASE_API_URL}/admin/landings`),
      createLanding: data => api.post(`${BASE_API_URL}/admin/landings`, data),
      updateLanding: (landingId, data) =>
        api.put(`${BASE_API_URL}/admin/landings/${landingId}`, data),
      deleteLanding: id => api.delete(`${BASE_API_URL}/admin/landings/${id}`)
    },
    attachments: {
      getAttachment: (url, handleProgress) =>
        api.get(`${BASE_API_URL}${url}`, {
          responseType: "blob",
          onDownloadProgress: event => {
            const percentCompleted = Math.round(
              (event.loaded * 100) / event.total
            );
            handleProgress && handleProgress(percentCompleted);
          }
        })
    }
  },
  affiliate: {
    getOverview: ({ year, month }) =>
      api.get(`${BASE_API_URL}/affiliate/overview/${year}/${month}`),
    getActivity: (from, to, brandId) =>
      api.get(`${BASE_API_URL}/affiliate/activities`, {
        params: { from, to, brandId }
      }),
    auth: {
      signIn: data => api.post(`${BASE_API_URL}/auth/affiliate/login`, data),
      signUp: (refId, data) =>
        api.post(`${BASE_API_URL}/auth/affiliate/register`, data, {
          params: { referral: refId }
        }),
      acceptTc: () => api.post(`${BASE_API_URL}/auth/affiliate/accept-tc`),
      forgotPassword: email =>
        api.post(`${BASE_API_URL}/auth/affiliate/password/forgot`, { email }),
      updatePassword: data =>
        api.post(`${BASE_API_URL}/auth/affiliate/password/update`, data),
      changePassword: data =>
        api.post(`${BASE_API_URL}/auth/affiliate/password/change`, data)
    },
    profile: {
      getProfile: () => api.get(`${BASE_API_URL}/affiliate`),
      getApiToken: () => api.get(`${BASE_API_URL}/affiliate/api-token`),
      refreshApiToken: () => api.post(`${BASE_API_URL}/affiliate/api-token`),
      updateProfile: data => api.put(`${BASE_API_URL}/affiliate`, data)
    },
    links: {
      getLinks: brandId =>
        api.get(`${BASE_API_URL}/affiliate/links`, {
          params: { brandId }
        }),
      getLink: (linkId, from, to) =>
        api.get(`${BASE_API_URL}/affiliate/links/${linkId}`, {
          params: { from, to }
        }),
      createLink: data => api.post(`${BASE_API_URL}/affiliate/links`, data),
      updateLink: (linkId, data) =>
        api.put(`${BASE_API_URL}/affiliate/links/${linkId}`, data),
      deleteLink: linkId =>
        api.delete(`${BASE_API_URL}/affiliate/links/${linkId}`)
    },
    deals: {
      getDeals: () => api.get(`${BASE_API_URL}/affiliate/deals`)
    },
    fees: {
      getFees: () => api.get(`${BASE_API_URL}/affiliate/fees`)
    },
    landings: {
      getLandings: () => api.get(`${BASE_API_URL}/affiliate/landings`)
    },
    payments: {
      getPayments: () => api.get(`${BASE_API_URL}/affiliate/payments`)
    },
    players: {
      getPlayersRevenue: ({ year, month, brandId }) =>
        api.get(`${BASE_API_URL}/affiliate/revenues/${year}/${month}`, {
          params: { brandId }
        }),
      getPlayers: brandId =>
        api.get(`${BASE_API_URL}/affiliate/players`, { params: { brandId } }),
      getPlayer: (playerId: number) =>
        api.get(`${BASE_API_URL}/affiliate/players/${playerId}`),
      getPlayerByDate: ({ year, month, playerId }) =>
        api.get(
          `${BASE_API_URL}/affiliate/players/${playerId}/${year}/${month}`
        )
    },
    subaffiliates: {
      getSubAffiliates: ({ year, month }) =>
        api.get(`${BASE_API_URL}/affiliate/sub-affiliates/${year}/${month}`)
    },
    subaccounts: {
      getSubAccounts: () => api.get(`${BASE_API_URL}/affiliate/children`),
      createSubAccount: data =>
        api.post(`${BASE_API_URL}/affiliate/children`, data)
    }
  }
});
