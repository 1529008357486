import format from "date-fns/format";
import {
  now,
  month as defaultMonth,
  year as defaultYear
} from "constants/dates";

export default function (initYear: string, initMonth: string) {
  const year = Number(initYear);
  const month = Number(initMonth);

  if (isNaN(year) || year > Number(format(now, "yyyy")) || year < 2001) {
    return false;
  } else if (
    isNaN(month) ||
    month > 12 ||
    month <= 0 ||
    (month > Number(defaultMonth) && year === Number(defaultYear))
  ) {
    return false;
  }

  return true;
}
