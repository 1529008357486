import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

import Table, { Column, DropdownColumn } from "components/table";
import Search from "components/search";
import EmptyData from "components/empty-data";

import SearchButtons from "./components/SearchButtons";
import Skeleton from "./Skeleton";
import usePlans from "./usePlans";

const CSV_HEADERS = [
  { label: "Name", key: "name" },
  { label: "Global NRS", key: "nrs" },
  { label: "Global CPA", key: "cpa" },
  { label: "Additional Rules", key: "rules" },
  { label: "Usages", key: "usages" }
];

const Component: FC = () => {
  const {
    classes,
    error,
    isLoading,
    isEmpty,
    isEmptySearch,
    plans,
    searchQuery,
    handleSearchQuery,
    handleRowClick,
    handleDeletePlan,
    handleArchivePlan,
    handleCreateDrawer
  } = usePlans();

  return (
    <>
      <Search
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search plan by Name"
        disabled={isEmpty}
        buttons={
          <SearchButtons
            csvProps={{
              headers: CSV_HEADERS,
              data: plans,
              disabled: isEmptySearch
            }}
            handleCreatePlanOpen={handleCreateDrawer}
          />
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <Skeleton />
        ) : isEmpty || error ? (
          <EmptyData />
        ) : (
          <Table
            initialData={plans}
            estimatedItemSize={49}
            handleRowClick={handleRowClick}
          >
            <Column
              label="Name"
              name="name"
              align="left"
              variant="h6"
              type="text"
              className={classes.nameColumn}
            />
            <Column label="Global NRS" name="nrs" type="percent" />
            <Column label="Global CPA" name="cpa" type="currency" />
            <Column label="Additional Rules" name="rules" type="text" />
            <Column label="Usages" name="usages" type="text" />
            <DropdownColumn name="dropdown" className={classes.iconColumn}>
              <MenuItem onClick={handleDeletePlan}>Remove</MenuItem>
              <MenuItem onClick={handleArchivePlan}>Archive</MenuItem>
            </DropdownColumn>
          </Table>
        )}
      </Box>
    </>
  );
};

export default Component;
