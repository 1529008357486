import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Search from "components/search";
import EmptyData from "components/empty-data";
import { PaymentInfo } from "modules/affiliate-invoices";
import Table, { Column, HTMLColumn } from "components/table";

import useAffiliatePayments from "./useAffiliatePayments";
import SearchButtons from "./components/SearchButtons";
import Skeleton from "./Skeleton";

const CSV_HEADERS = [
  { label: "ID", key: "paymentId" },
  { label: "Date", key: "transactionDate" },
  { label: "Type", key: "type" },
  { label: "Description", key: "description" },
  { label: "Amount", key: "amount" }
];

interface Props {
  handleCreateDrawerOpen?: () => void;
}

const Component: FC<Props> = ({ handleCreateDrawerOpen }) => {
  const {
    classes,
    isEmpty,
    isLoading,
    disabled,
    account,
    totals,
    payments,
    searchValue,
    handleSearch
  } = useAffiliatePayments();

  if (isLoading || !account) {
    return <Skeleton />;
  }

  return (
    <Box className={classes.container}>
      {!account.masterId && <PaymentInfo />}
      <Typography className={classes.commPayments} variant="h3">
        Commission Payments
      </Typography>
      <Search
        value={searchValue}
        onChange={handleSearch}
        placeholder="Search payment by Description"
        buttons={
          <SearchButtons
            csvProps={{
              headers: CSV_HEADERS,
              data: payments,
              disabled: disabled
            }}
            onCreateDrawerOpen={handleCreateDrawerOpen}
          />
        }
      />
      {isEmpty ? (
        <EmptyData className={classes.divider} />
      ) : (
        <Table
          initialData={payments}
          displayRows={8}
          totals={{ title: "Subtotal", totals }}
        >
          <Column
            label="ID"
            name="paymentId"
            align="left"
            type="text"
            className={classes.idColumn}
          />
          <Column
            label="Date"
            name="transactionDate"
            align="left"
            type="text"
            className={classes.dateColumn}
          />
          {handleCreateDrawerOpen && (
            <Column
              label="Created By"
              name="createdBy"
              align="left"
              type="text"
              className={classes.createdByColumn}
            />
          )}
          <Column
            label="Type"
            name="type"
            align="left"
            type="text"
            className={classes.typeColumn}
          />
          <HTMLColumn
            label="Description"
            name="description"
            align="left"
            className={classes.descriptionColumn}
          />
          <Column label="Amount" name="amount" type="currency" />
        </Table>
      )}
    </Box>
  );
};

export default Component;
