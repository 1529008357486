import React, { FC } from "react";
import Box from "@material-ui/core/Box";

import TextField from "components/text-field";
import SelectField from "components/select-field";
import CheckboxField from "components/checkbox-field";
import { countriesOptions } from "constants/countries";

interface Props {
  countryId?: string | null;
}

const EditProfile: FC<Props> = ({ countryId }) => {
  const getCountryValue = (value?: string | null) =>
    value ? countriesOptions.find(country => country.value === value) : null;

  return (
    <Box marginTop={2} marginBottom={2}>
      <TextField label="Company" name="name" />
      <TextField label="VAT Number" name="vatNumber" />
      <TextField label="Name" name="contactName" />
      <TextField multiline rows={2} label="Address" name="address" />
      <SelectField
        name="countryId"
        label="Country"
        placeholder="Select country"
        options={countriesOptions}
        value={getCountryValue(countryId)}
      />
      <TextField label="Email" name="email" />
      <TextField label="Skype" name="skype" />
      <TextField label="Phone" name="phone" />
      <TextField label="Info" name="info" rows={5} multiline={Boolean(5)} />
      <CheckboxField
        name="allowEmails"
        label="Subscribe to our Email newsletter"
      />
    </Box>
  );
};

export default EditProfile;
