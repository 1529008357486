import { AffiliateLog } from "@types";

import React, { FC, useRef } from "react";
import { VariableSizeList } from "react-window";
import Box from "@material-ui/core/Box";
import AutoSizer from "react-virtualized-auto-sizer";

import ListItem from "./ListItem";

const DEFAULT_SIZE = 92;

interface Props {
  items: AffiliateLog[];
}

const Content: FC<Props> = ({ items }) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<{ [key: number]: number }>({});

  const getRowHeight = (index: number) =>
    rowHeights.current[index] || DEFAULT_SIZE;

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  return (
    <Box width="100%" height="100%">
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            ref={listRef}
            height={height}
            width={width}
            itemData={items}
            itemSize={getRowHeight}
            itemCount={items.length}
            overscanCount={4}
          >
            {props => <ListItem {...props} handleRowHeight={setRowHeight} />}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default Content;
