import React, { FC, ReactElement } from "react";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { useCardStyles, useSkeletonStyles } from "../styles";

interface Props {
  label: string;
  className?: string;
  labelClassName?: string;
  children: ReactElement | string;
  onClick: () => void;
}

interface SkeletonProps extends BoxProps {
  width?: number;
  height?: number;
  children?: ReactElement | string;
}

const ActionCardSkeleton: FC<SkeletonProps> = ({
  width = 128,
  height = 16,
  children,
  ...boxProps
}) => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container} {...boxProps}>
      <Skeleton className={classes.label} width={width} height={height} />
      {children}
    </Box>
  );
};

const ActionCard: FC<Props> = ({
  label,
  className,
  labelClassName,
  children,
  onClick
}) => {
  const classes = useCardStyles();

  return (
    <Card className={cn(classes.card, classes.hoverEffect, classes.mobileCard)}>
      <Box className={classes.divider} />
      <CardActionArea
        disableRipple
        onClick={onClick}
        classes={{
          focusVisible: classes.cardAreaVisible
        }}
      >
        <CardContent>
          <Typography
            component="span"
            variant="subtitle1"
            className={cn(labelClassName)}
          >
            {label}
          </Typography>
          <Typography component="div" className={cn(classes.text, className)}>
            {children}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { ActionCardSkeleton };
export default ActionCard;
