import { AffiliatePlayersRevenue } from "@types";

import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Search from "components/search";
import EmptyData from "components/empty-data";
import Table, { Column, CheckboxColumn, TableSkeleton } from "components/table";

import SearchButtons from "./components/SearchButtons";
import useAffiliatePlayers from "./useAffiliatePlayers";

const CSV_HEADERS = [
  { label: "Player ID", key: "playerId" },
  { label: "Deposits", key: "deposits" },
  { label: "Net Revenue", key: "netRevenue" },
  { label: "Commission", key: "commission" },
  { label: "Brand", key: "brandId" },
  { label: "Deal", key: "deal" },
  { label: "Country", key: "countryId" },
  { label: "Registration Date", key: "registrationDate" }
];

interface Props {
  handleRowClick: (player: AffiliatePlayersRevenue) => void;
}

const Component: FC<Props> = ({ handleRowClick }) => {
  const {
    error,
    totals,
    isAdmin,
    classes,
    isEmpty,
    players,
    isLoading,
    searchQuery,
    isEmptySearch,
    isEmptyChecked,
    brandFilterProps,
    checkedTableProps,
    handleSearchQuery,
    handleChangeDealDrawer,
    handleMovePlayersDrawer
  } = useAffiliatePlayers();

  return (
    <Box>
      <Typography variant="h3" className={classes.title}>
        Player Lifetime Values
      </Typography>
      <Search
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search by Player ID"
        disabled={isEmpty}
        buttons={
          <SearchButtons
            disabled={isEmptyChecked}
            csvProps={{
              headers: CSV_HEADERS,
              data: players,
              disabled: isEmptySearch
            }}
            filterProps={brandFilterProps}
            handleChangeDeal={isAdmin && handleChangeDealDrawer}
            handleMovePlayers={isAdmin && handleMovePlayersDrawer}
          />
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <TableSkeleton rows={14} cells={8} />
        ) : isEmpty || error ? (
          <EmptyData />
        ) : (
          <Table
            initialData={players}
            totals={{ title: "Total", totals }}
            handleRowClick={handleRowClick}
            {...checkedTableProps}
          >
            {isAdmin && <CheckboxColumn name="checkbox" />}
            <Column
              className={classes.playerColumn}
              label="Player ID"
              name="playerId"
              align="left"
              type="text"
            />
            <Column
              className={classes.numberColumn}
              label="Deposits"
              name="deposits"
              align="left"
              type="currency"
            />
            <Column
              className={classes.numberColumn}
              label="Net."
              name="netRevenue"
              align="left"
              type="currency"
            />
            <Column
              className={classes.numberColumn}
              label="Comm."
              name="commission"
              align="left"
              type="currency"
            />
            <Column
              className={classes.brandColumn}
              label="Brand"
              name="brandId"
              align="left"
              type="brand"
            />
            <Column
              className={classes.dealColumn}
              label="Deal"
              name="deal"
              align="left"
              type="text"
            />
            <Column
              className={classes.countryColumn}
              label="Country"
              name="countryId"
              align="left"
              type="text"
            />
            <Column
              label="Reg."
              name="registrationDate"
              type="date"
              align="right"
              className={classes.dateColumn}
            />
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default Component;
