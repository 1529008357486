import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Search from "components/search";
import EmptyData from "components/empty-data";
import Table, { Column, LinkColumn, TableSkeleton } from "components/table";

import useAffiliateActivites from "./useAffiliateActivities";
import SearchButtons from "./components/SearchButtons";

const CSV_HEADERS = [
  { label: "Link", key: "link" },
  { label: "Segment", key: "segment" },
  { label: "Brand", key: "brandId" },
  { label: "Clicks", key: "clicks" },
  { label: "NRC", key: "nrc" },
  { label: "NDC", key: "ndc" },
  { label: "Deposits", key: "deposits" },
  { label: "Net Revenue", key: "netRevenue" },
  { label: "Commission", key: "commission" },
  { label: "CPA", key: "cpa" }
];

const Component: FC = () => {
  const {
    classes,
    isLoading,
    isEmpty,
    isEmptySearch,
    error,
    totals,
    linkHref,
    activities,
    searchValue,
    brand,
    handleBrand,
    handleSearch
  } = useAffiliateActivites();

  if (error) {
    return <EmptyData />;
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h3" className={classes.title}>
        Activity Report
      </Typography>
      <Search
        value={searchValue}
        onChange={handleSearch}
        placeholder="Search by link / segment"
        buttons={
          <SearchButtons
            disabled={isEmptySearch}
            csvProps={{
              headers: CSV_HEADERS,
              data: activities
            }}
            filterProps={{ brand, onChange: handleBrand }}
          />
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <TableSkeleton rows={12} cells={11} />
        ) : isEmpty ? (
          <EmptyData />
        ) : (
          <Table
            estimatedItemSize={49}
            initialData={activities}
            totals={{ title: "Subtotal", totals }}
          >
            <LinkColumn
              label="Link"
              name="link"
              linkName="linkId"
              align="left"
              href={linkHref}
              className={classes.linkColumn}
            />
            <Column
              label="Segment"
              name="segment"
              align="left"
              type="text"
              className={classes.segmentColumn}
            />
            <Column
              label="Brand"
              name="brandId"
              align="left"
              type="brand"
              className={classes.brandColumn}
            />
            <Column
              label="Clicks"
              name="clicks"
              align="right"
              type="text"
              className={classes.clicksColumn}
            />
            <Column
              label="NRC"
              name="nrc"
              align="right"
              type="text"
              className={classes.regColumn}
            />
            <Column
              label="NDC"
              name="ndc"
              align="right"
              type="text"
              className={classes.ndcColumn}
            />
            <Column
              label="Deposits"
              name="deposits"
              align="right"
              type="currency"
              className={classes.depositsColumn}
            />
            <Column
              label="Turnover"
              name="turnover"
              align="right"
              type="currency"
              className={classes.turnoverColumn}
            />
            <Column
              label="Net."
              name="netRevenue"
              align="right"
              type="currency"
              className={classes.netColumn}
            />
            <Column
              label="Comm."
              name="commission"
              align="right"
              type="currency"
              className={classes.commColumn}
            />
            <Column
              label="CPA"
              name="cpa"
              align="right"
              type="currency"
              className={classes.cpaColumn}
            />
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default Component;
