import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { getDatePickerState } from "modules/datepicker";

import { useMenuStyles } from "../styles";

interface Props {
  isViewMode: boolean;
  showSubs: boolean;
  handleMenuItem?: (event: React.MouseEvent) => void;
  handleLogoutSubmit: () => void;
}

const Menu: FC<Props> = ({
  isViewMode,
  showSubs,
  handleMenuItem,
  handleLogoutSubmit
}) => {
  const classes = useMenuStyles();
  const [logoutClicked, setLogoutClicked] = useState<boolean>(false);
  const { year, month } = useSelector(getDatePickerState);

  useEffect(() => {
    if (logoutClicked) {
      const logoutTimeout = setTimeout(() => setLogoutClicked(false), 5000);

      return () => {
        clearTimeout(logoutTimeout);
      };
    }
  }, [logoutClicked]);

  const handleLogout = () => {
    setLogoutClicked(true);

    if (logoutClicked) {
      handleLogoutSubmit();
    }
  };

  return (
    <Box className={classes.menu}>
      <NavLink
        to={`/overview/${year}/${month}`}
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Overview
      </NavLink>
      <NavLink
        to="/links"
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Links
      </NavLink>
      <NavLink
        to="/payments"
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Payments
      </NavLink>
      <NavLink
        to={`/revenue/${year}/${month}`}
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Revenue
      </NavLink>
      <NavLink
        to="/activities"
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Activity
      </NavLink>
      <NavLink
        to="/players"
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Players
      </NavLink>
      <NavLink
        hidden={!showSubs}
        to={`/subs/${year}/${month}`}
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Sub-Affiliates
      </NavLink>
      <NavLink
        to="/profile"
        className={classes.link}
        activeClassName={classes.activeLink}
        onClick={handleMenuItem}
      >
        Profile
      </NavLink>
      {logoutClicked ? (
        <Typography onClick={handleLogout} className={classes.logoutLinkSubmit}>
          Are you sure?
        </Typography>
      ) : (
        <Typography
          onClick={handleLogout}
          className={cn(classes.logoutLink, {
            [classes.logoutLinkDisabled]: isViewMode
          })}
        >
          Logout
        </Typography>
      )}
    </Box>
  );
};

export default Menu;
