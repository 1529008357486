import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core";
import MuiSnackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";

import { closeSnackbar, getSnackbarState } from "./duck";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      "& .MuiButtonBase-root:hover": {
        color: `${theme.colors.white} !important`
      },
      [theme.breakpoints.down("sm")]: {
        left: 24,
        right: 24,
        transform: "unset",
        "& .MuiAlert-icon": {
          alignItems: "center"
        }
      }
    }
  })
);

const Snackbar: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, type, message } = useSelector(getSnackbarState);

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={6000}
      className={classes.container}
      onClose={handleClose}
    >
      <Slide in={isOpen} direction="up" mountOnEnter unmountOnExit>
        <Alert
          translate="no"
          variant="filled"
          severity={type}
          onClose={handleClose}
        >
          {message}
        </Alert>
      </Slide>
    </MuiSnackbar>
  );
};

export default Snackbar;
