/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Ref, forwardRef } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Button from "@material-ui/core/Button";

import { CircleIconButton } from "components/button";

import useInfoDialog from "./useInfoDialog";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component: FC = () => {
  const { classes, isOpen, fullScreen, handleClose } = useInfoDialog();

  return (
    <Dialog
      keepMounted
      fullScreen={fullScreen}
      scroll="paper"
      open={isOpen}
      classes={{ root: classes.root }}
      PaperProps={{ className: classes.paper }}
      TransitionComponent={Transition}
      onClose={(_event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        handleClose();
      }}
    >
      <DialogTitle className={classes.header} disableTypography>
        <Typography variant="h3" className={classes.title}>
          Essential Information: Please Review
        </Typography>
        <CircleIconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CircleIconButton>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.content}>
        <Typography className={classes.text}>
          <Typography variant="h5" paragraph>
            Dear Affiliate:
          </Typography>
          <Typography variant="body2" paragraph>
            This letter will serve as notice to you that effective immediately,
            all revenue sharing rates or commission rates under the Affmore
            Affiliate Program Terms and Conditions have been changed to zero (0)
            percent. Any other commissions, revenue sharing arrangements, or
            fees related to player acquisition, player deposits or player wagers
            have also been eliminated with immediate effect. Accordingly, as of
            the date on this correspondence, Affiliates will no longer earn
            commissions or any other payments as a result of player registration
            or any other player activity on any websites operated by Esports
            Entertainment (Malta) Limited (“EEM”).
          </Typography>
          <Typography variant="body2" paragraph>
            These changes apply to existingplayers already referred by an
            Affiliate and any new players that may be referred on or after the
            date of this correspondence. These changes are enacted with
            immediate effect pursuant to paragraph 4.1.1.5 and paragraph 20 of
            the Affmore Affiliate Program Terms and Conditions. These changes
            will remain in force until further notice. EEM hereby reserves all
            rights and remedies available to it at law and under the Affmore
            Affiliate Program Terms & Conditions. Nothing in this correspondence
            constitutes a waiver by EEM of any rights or remedies.
          </Typography>
          <Typography variant="body2" paragraph>
            If you have questions about the changes set forth herein, you may
            direct them via email to{" "}
            <a href="mailto:Legal@eeg.bet" className={classes.link}>
              Legal@eeg.bet
            </a>
            . Your attention to this matter is appreciated.
          </Typography>
          <Typography
            variant="subtitle1"
            paragraph
            className={classes.subtitle}
          >
            effective from 08.03.2024
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>go to overview</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
