import React, { FC } from "react";
import { SubAffiliate } from "@types";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { TooltipCard } from "components/cards";

import { useStyles } from "./styles";

export interface AffiliateDetailsProps {
  affiliate: SubAffiliate;
  handleEditCommission?: (affiliate: SubAffiliate) => void;
}

const AffiliateDetails: FC<AffiliateDetailsProps> = ({
  affiliate,
  handleEditCommission
}) => {
  const classes = useStyles();
  const { affiliateId, affiliateName, commissionShare, registrationDate } =
    affiliate;

  return (
    <Box className="drawer-container">
      <Box className="drawer-header">
        <Typography variant="h4" className="drawer-header_title">
          Affiliate Details
        </Typography>
        {handleEditCommission && (
          <Button
            color="secondary"
            onClick={() => handleEditCommission(affiliate)}
          >
            edit commission
          </Button>
        )}
      </Box>
      <Box>
        <Box className={classes.idContainer}>
          <TooltipCard className={classes.id} label="Affiliate ID">
            {String(affiliateId)}
          </TooltipCard>
        </Box>
        <Box className={classes.info}>
          <TooltipCard label="Affiliate Name">{affiliateName}</TooltipCard>
          {registrationDate && (
            <TooltipCard label="Registration Date">
              {registrationDate}
            </TooltipCard>
          )}
          <TooltipCard label="Commission Share">{`${commissionShare}%`}</TooltipCard>
        </Box>
      </Box>
    </Box>
  );
};

export default AffiliateDetails;
