import { AffiliatePlayersRevenue } from "@types";

import React, { FC, ReactElement } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Search from "components/search";
import Table, { Column, TableSkeleton } from "components/table";
import EmptyData from "components/empty-data";

import SearchButtons from "./components/SearchButtons";
import useAffiliatePlayersRevenue from "./useAffiliatePlayersRevenue";

const CSV_HEADERS = [
  { label: "Player ID", key: "playerId" },
  { label: "Country", key: "countryId" },
  { label: "Brand", key: "brandId" },
  { label: "Deposits", key: "deposits" },
  { label: "Turnover", key: "turnover" },
  { label: "Gross Revenue", key: "grossRevenue" },
  { label: "Bonuses", key: "bonuses" },
  { label: "Adjustments", key: "adjustments" },
  { label: "Fees", key: "fees" },
  { label: "Net Revenue", key: "netRevenue" },
  { label: "Commission", key: "commission" },
  { label: "CPA", key: "cpa" }
];

interface Props {
  datepicker?: ReactElement;
  handleRowClick: (item: AffiliatePlayersRevenue) => void;
}

const Component: FC<Props> = ({ datepicker, handleRowClick }) => {
  const {
    classes,
    error,
    isLoading,
    isEmpty,
    isEmptySearch,
    total,
    totals,
    showCpa,
    searchQuery,
    brandFilterProps,
    revenues,
    handleSearchChange
  } = useAffiliatePlayersRevenue();

  return (
    <Box>
      <Typography variant="h3" className={classes.title}>
        Monthly Revenue Report
      </Typography>
      <Search
        value={searchQuery}
        placeholder="Search by Player ID"
        disabled={isEmpty}
        onChange={handleSearchChange}
        buttons={
          <SearchButtons
            disabled={isEmptySearch}
            csvProps={{
              headers: CSV_HEADERS,
              data: revenues
            }}
            filterProps={brandFilterProps}
            datepicker={datepicker}
          />
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <TableSkeleton showTotal rows={13} cells={12} />
        ) : isEmpty || error ? (
          <EmptyData />
        ) : (
          <Table
            total={total}
            initialData={revenues}
            totals={{ title: "Subtotal", totals }}
            handleRowClick={handleRowClick}
          >
            <Column
              label="Player ID"
              name="playerId"
              align="left"
              type="text"
              className={classes.playerColumn}
            />
            <Column
              label="Country"
              name="countryId"
              align="left"
              variant="h6"
              type="text"
              className={classes.countryColumn}
            />
            <Column
              label="Brand"
              name="brandId"
              align="left"
              type="brand"
              className={classes.brandColumn}
            />
            <Column label="Deposits" name="deposits" type="currency" />
            <Column label="Turnover" name="turnover" type="currency" />
            <Column label="Gross" name="grossRevenue" type="currency" />
            <Column label="Bonuses" name="bonuses" type="currency" />
            <Column label="Adjustm." name="adjustments" type="currency" />
            <Column label="Fees" name="fees" type="currency" />
            <Column label="Tax" name="tax" type="currency" />
            <Column
              label="Net"
              name="netRevenue"
              type="currency"
              className={classes.netRevenueColumn}
            />
            <Column label="Comm." name="commission" type="currency" />
            {showCpa && <Column label="CPA" name="cpa" type="currency" />}
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default Component;
