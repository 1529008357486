import * as yup from "yup";

import { Steps } from "../types";

const useForgotValidationSchema = (step: Steps) => {
  const emailValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required")
  });

  const codeValidationSchema = yup.object().shape({
    code: yup
      .string()
      .min(6, "Code must contain 6 numbers")
      .max(6, "Code must contain 6 numbers")
      .required("Code is required")
  });

  const newPasswordValidationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password can't be lower than 8 characters")
      .required("Password is required"),
    passwordConfirm: yup
      .string()
      .min(8, "Password can't be lower than 8 characters")
      .oneOf([yup.ref("password")], "Passwords doesn't match")
      .required("Confirm password is required")
  });

  switch (step) {
    case Steps.Email:
      return emailValidationSchema;
    case Steps.Code:
      return codeValidationSchema;
    case Steps.NewPassword:
      return newPasswordValidationSchema;
  }
};

export { useForgotValidationSchema };
