import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const BRAND_COUNT = Array.from(Array(5));

const LandingsSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      {BRAND_COUNT.map((_, index) => (
        <Box key={index} className={classes.brand}>
          <Skeleton className={classes.name} />
          <Skeleton variant="circle" className={classes.button} />
        </Box>
      ))}
    </Box>
  );
};

export default LandingsSkeleton;
