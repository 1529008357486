import { AffiliatePlayersRevenue as IPlayerRevenue } from "@types";

import React, { FC, useCallback, useEffect } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  generatePath
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Drawer from "components/drawer";
import { useUrlDateCheck } from "hooks";
import { PlayerRevenue } from "modules/affiliate-player";
import AffiliatePlayersRevenue, {
  fetchAffiliatePlayersRevenue,
  getAffiliatePlayersRevenueState
} from "modules/affiliate-players-revenue";
import { PlayerDeal, updateAffiliatePlayerDeal } from "modules/affiliate-deal";

interface MatchParams {
  id: string;
  year: string;
  month: string;
}

type Props = RouteComponentProps<MatchParams>;

const AffiliateRevenuePage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { id, year, month } = match.params;

  const { brand } = useSelector(getAffiliatePlayersRevenueState);

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  const handleRevenueClick = useCallback(
    ({ playerId }: IPlayerRevenue) => {
      history.push(`${match.url}/players/${playerId}`);
    },
    [history, match]
  );

  const handleChangeDealSubmit = useCallback(
    async (planId: number, playerId: number, pathname: string) => {
      const path = generatePath(pathname, {
        id,
        year,
        month,
        playerId,
        dealId: planId
      });

      await dispatch(updateAffiliatePlayerDeal(Number(id), playerId, planId));
      dispatch(
        fetchAffiliatePlayersRevenue({
          affiliateId: Number(id),
          year: Number(year),
          month: Number(month),
          brandId: brand
        })
      );
      history.replace(path);
    },
    [dispatch, history, brand, id, year, month]
  );

  useUrlDateCheck();

  useEffect(() => {
    const options = {
      affiliateId: Number(id),
      year: Number(year),
      month: Number(month),
      brandId: brand
    };

    dispatch(fetchAffiliatePlayersRevenue(options));
  }, [dispatch, id, year, month, brand]);

  return (
    <>
      <AffiliatePlayersRevenue handleRowClick={handleRevenueClick} />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Switch>
          <Route
            exact
            path={`${match.path}/players/:playerId`}
            component={PlayerRevenue}
          />
          <Route
            exact
            path={`${match.path}/players/:playerId/deals/:dealId`}
            render={props => (
              <PlayerDeal onSubmit={handleChangeDealSubmit} {...props} />
            )}
          />
        </Switch>
      </Drawer>
    </>
  );
};

export default AffiliateRevenuePage;
