import React, { FC } from "react";

import DatePicker from "modules/datepicker";
import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";
import BrandFilter, { BrandFilterProps } from "components/brand-filter";

interface Props {
  disabled: boolean;
  filterProps: BrandFilterProps;
  csvProps: DownloadCsvButtonProps;
}

const SearchButtons: FC<Props> = ({ disabled, filterProps, csvProps }) => (
  <>
    <BrandFilter {...filterProps} />
    <DownloadCsvButton disabled={disabled} {...csvProps} />
    <DatePicker baseDateUrl="/admin/overview" />
  </>
);

export default SearchButtons;
