import React, { FC, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { useCardStyles, useSkeletonStyles } from "../styles";

const TOOLTIP_TEXT = "Click to copy";

interface Props {
  label: string;
  className?: string;
  children: string | number;
}

const TooltipCardSkeleton: FC<BoxProps> = props => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container} {...props}>
      <Skeleton className={classes.label} width={64} height={16} />
      <Skeleton className={classes.text} width={100} height={22} />
    </Box>
  );
};

const TooltipCard: FC<Props> = ({ label, className, children }) => {
  const classes = useCardStyles();
  const [tooltip, setTooltip] = useState<string>(TOOLTIP_TEXT);

  return (
    <CopyToClipboard
      text={String(children)}
      onCopy={() => setTooltip("Copied")}
    >
      <Tooltip
        title={tooltip}
        placement="bottom-start"
        onClose={() => setTooltip(TOOLTIP_TEXT)}
      >
        <Card
          className={cn(classes.card, classes.hoverEffect, classes.mobileCard)}
        >
          <Box className={classes.divider} />
          <CardActionArea
            classes={{
              focusVisible: classes.cardAreaVisible
            }}
          >
            <CardContent>
              <Typography variant="subtitle1" component="span">
                {label}
              </Typography>
              <Typography
                component="div"
                className={cn(classes.text, className)}
              >
                {children}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    </CopyToClipboard>
  );
};

export { TooltipCardSkeleton };
export default TooltipCard;
