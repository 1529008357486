import { AffiliateDeal } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum AffiliateDealsActionTypes {
  FETCH_AFFILIATE_DEALS_LOADING = "@affiliate/deals/fetch-deals-loading",
  FETCH_AFFILIATE_DEALS_SUCCESS = "@affiliate/deals/fetch-deals-success",
  FETCH_AFFILIATE_DEALS_FAILURE = "@affiliate/deals/fetch-deals-failure"
}

interface FetchAffiliateDealsIsLoadingAction {
  type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_LOADING;
}

interface FetchAffiliateDealsIsSuccessAction {
  type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_SUCCESS;
  payload: { deals: AffiliateDeal[] };
}

interface FetchAffiliateDealsIsFailureAction {
  type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_FAILURE;
}

type AffiliateDealsActions =
  | FetchAffiliateDealsIsLoadingAction
  | FetchAffiliateDealsIsSuccessAction
  | FetchAffiliateDealsIsFailureAction;

const fetchAffiliateDealsIsLoading =
  (): FetchAffiliateDealsIsLoadingAction => ({
    type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_LOADING
  });

const fetchAffiliateDealsIsSuccess = (
  deals: AffiliateDeal[]
): FetchAffiliateDealsIsSuccessAction => ({
  type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_SUCCESS,
  payload: { deals }
});

const fetchAffiliateDealsIsFailure =
  (): FetchAffiliateDealsIsFailureAction => ({
    type: AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_FAILURE
  });

const fetchAffiliateDeals =
  (id: number): ThunkActionCreator<AffiliateDealsActions> =>
  async dispatch => {
    dispatch(fetchAffiliateDealsIsLoading());
    try {
      const { deals } = await api.admin.affiliate.deals.getDeals(id);
      dispatch(fetchAffiliateDealsIsSuccess(deals));
    } catch (err) {
      dispatch(fetchAffiliateDealsIsFailure());
    }
  };

const fetchAffiliateViewDeals =
  (): ThunkActionCreator<AffiliateDealsActions> => async dispatch => {
    dispatch(fetchAffiliateDealsIsLoading());
    try {
      const { deals } = await api.affiliate.deals.getDeals();
      dispatch(fetchAffiliateDealsIsSuccess(deals));
    } catch (err) {
      dispatch(fetchAffiliateDealsIsFailure());
    }
  };

interface AffiliateDealsState {
  error: boolean;
  isLoading: boolean;
  data: AffiliateDeal[];
}

const initialState: AffiliateDealsState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<AffiliateDealsState, AffiliateDealsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.deals
      };
    case AffiliateDealsActionTypes.FETCH_AFFILIATE_DEALS_FAILURE:
      return {
        error: true,
        isLoading: false,
        data: []
      };
    default:
      return state;
  }
};

const getAffiliateDealsState = (state: AppState) => state.affiliate.deals;
const getAffiliateDealStateById = (state: AppState) => (id: string) => ({
  error: state.affiliate.deals.error,
  isLoading: state.affiliate.deals.isLoading,
  deal: state.affiliate.deals.data.find(({ brandId }) => brandId === id)
});

export {
  reducer,
  fetchAffiliateDeals,
  fetchAffiliateViewDeals,
  getAffiliateDealsState,
  getAffiliateDealStateById
};
