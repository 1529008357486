import React, { FC } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface Props {
  label?: string | React.ReactElement;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  name: string;
  className?: string;
}

const CheckboxField: FC<Props> = ({ label, ...props }) => {
  const [field] = useField(props);

  return (
    <FormControlLabel
      {...props}
      label={
        <Box fontSize="14px" lineHeight="36px">
          {label}
        </Box>
      }
      control={<Checkbox {...field} checked={!!field.value} />}
    />
  );
};

export default CheckboxField;
