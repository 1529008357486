import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      height: "calc(100vh - 152px)"
    },
    idColumn: {
      maxWidth: 96
    },
    nameColumn: {
      fontWeight: 500,
      minWidth: "40vw"
    },
    boldColumn: {
      fontWeight: 500
    },
    iconColumn: {
      maxWidth: 64
    }
  })
);

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%"
    },
    tableRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 48,
      padding: "0 16px"
    },
    row: {
      display: "flex"
    },
    rowData: {
      width: "35vw",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    tableButton: {
      marginLeft: 16,
      borderRadius: "50%"
    }
  })
);

export { useStyles, useSkeletonStyles };
