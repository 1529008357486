import { LoginRequest } from "@types";

import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormikHelpers } from "formik";

import NonLoggedin from "components/nonloggedin";
import Login, { signIn } from "modules/authentication";

const AffiliateViewLoginPage: FC = () => {
  const dispatch = useDispatch();

  const handleLoginSubmit = useCallback(
    (values: LoginRequest, helpers: FormikHelpers<LoginRequest>) => {
      dispatch(signIn(values, helpers));
    },
    [dispatch]
  );

  return (
    <NonLoggedin>
      <Login handleSubmit={handleLoginSubmit} />
    </NonLoggedin>
  );
};
export default AffiliateViewLoginPage;
