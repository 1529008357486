import { Overrides } from "@material-ui/core/styles/overrides";
import { colors } from "./colors";

const datepickerOverrides: Overrides = {
  MuiPickersModal: {
    dialogAction: {
      color: colors.blue
    }
  },
  MuiPickersToolbar: {
    toolbar: {
      padding: 16,
      height: "auto",
      backgroundColor: colors.blue
    }
  },
  MuiPickersToolbarText: {
    toolbarTxt: {
      "&::after": {
        content: '""',
        position: "absolute",
        top: "47%",
        width: 0,
        height: 0,
        marginLeft: 4,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderTop: "4px solid",
        borderTopColor: "inherit"
      }
    }
  },
  MuiPickersToolbarButton: {
    toolbarBtn: {
      border: "none",
      padding: "6px 20px 6px 8px",
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
  MuiPickersBasePicker: {
    container: {
      width: 256
    },
    pickerView: {
      minWidth: "auto",
      flexDirection: "column",
      justifyContent: "flex-start",
      minHeight: 268,
      maxHeight: 268
    }
  },
  MuiPickersYearSelection: {
    container: {
      width: "100%",
      height: "auto"
    }
  },
  MuiPickersMonthSelection: {
    container: {
      width: "100%",
      alignContent: "flex-start"
    }
  },
  MuiPickersMonth: {
    root: {
      height: 64
    },
    monthDisabled: {
      color: colors.blackbd
    }
  },
  MuiPickersDay: {
    day: {
      width: 32,
      height: 32
    }
  },
  MuiPickersCalendarHeader: {
    dayLabel: {
      color: colors.black61
    },
    switchHeader: {
      margin: 4,
      "& .MuiPickersCalendarHeader-transitionContainer": {
        cursor: "pointer",
        "&:hover": {
          color: colors.blue
        }
      }
    },
    iconButton: {
      borderRadius: "8%",
      "&:hover": {
        color: colors.blue
      }
    }
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: 8,
      minHeight: "100%"
    }
  }
};

export { datepickerOverrides };
