import React, { FC } from "react";

import { colors } from "theme";
import EmptyData from "components/empty-data";
import SearchInput from "components/search-input";

import Skeleton from "./Skeleton";
import List from "./components/List";
import useAffiliatesSidebar from "./useAffiliatesSidebar";
import { SidebarContainer } from "./styles";

const Component: FC = () => {
  const {
    isLoading,
    error,
    affiliate,
    affiliates,
    lastUsageIds,
    searchQuery,
    handleSearch,
    handleChangeAffiliate,
    handleRemoveAffiliate,
    handleClearAffiliates
  } = useAffiliatesSidebar();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <EmptyData />;
  }

  return (
    <SidebarContainer>
      <SearchInput
        display="flex"
        padding={2}
        borderBottom={`1px solid ${colors.blacke0}`}
        value={searchQuery}
        placeholder="Search by id, name, email"
        onChange={handleSearch}
      />
      <List
        affiliates={affiliates}
        lastUsageIds={lastUsageIds}
        activeAffiliate={affiliate}
        onClearSearch={() => handleSearch("")}
        onChange={handleChangeAffiliate}
        onRemove={handleRemoveAffiliate}
        onClear={handleClearAffiliates}
      />
    </SidebarContainer>
  );
};

export default Component;
