import { CreateAffiliateLogResponse, PaymentInvoiceResponse } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { DrawersActions } from "drawers";
import { FormikHelpers } from "formik";

enum AffiliateInvoiceActionTypes {
  FETCH_AFFILIATE_INVOICE_LOADING = "@affiliate/invoice/fetch-invoice-loading",
  FETCH_AFFILIATE_INVOICE_SUCCESS = "@affiliate/invoice/fetch-invoice-success",
  FETCH_AFFILIATE_INVOICE_FAILURE = "@affiliate/invoice/fetch-invoice-failure",
  CREATE_AFFILIATE_INVOICE_ATTACHMENT = "@affiliate/invoice/create-attachment-success"
}

interface FetchAffiliateInvoiceIsLoadingAction {
  type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_LOADING;
}

interface FetchAffiliateInvoiceIsSuccessAction {
  type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_SUCCESS;
  payload: PaymentInvoiceResponse;
}

interface FetchAffiliateInvoiceIsFailureAction {
  type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_FAILURE;
}

interface CreateAffiliateInvoiceAttachmentIsSuccessAction {
  type: AffiliateInvoiceActionTypes.CREATE_AFFILIATE_INVOICE_ATTACHMENT;
  payload: CreateAffiliateLogResponse;
}

type AffiliateInvoiceActions =
  | FetchAffiliateInvoiceIsLoadingAction
  | FetchAffiliateInvoiceIsSuccessAction
  | FetchAffiliateInvoiceIsFailureAction
  | CreateAffiliateInvoiceAttachmentIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateInvoiceActions | DrawersActions>
>;

const fetchAffiliateInvoiceIsLoading =
  (): FetchAffiliateInvoiceIsLoadingAction => ({
    type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_LOADING
  });

const fetchAffiliateInvoiceIsSuccess = (
  data: PaymentInvoiceResponse
): FetchAffiliateInvoiceIsSuccessAction => ({
  type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_SUCCESS,
  payload: data
});

const fetchAffiliateInvoiceIsFailure =
  (): FetchAffiliateInvoiceIsFailureAction => ({
    type: AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_FAILURE
  });

const createAffiliateInvoiceAttachmentIsSuccess = (
  response: CreateAffiliateLogResponse
): CreateAffiliateInvoiceAttachmentIsSuccessAction => ({
  type: AffiliateInvoiceActionTypes.CREATE_AFFILIATE_INVOICE_ATTACHMENT,
  payload: response
});

const fetchAffiliateInvoice =
  (id: number, invoiceId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(fetchAffiliateInvoiceIsLoading());
    try {
      const response = await api.admin.affiliate.invoices.getInvoice(
        id,
        invoiceId
      );
      dispatch(fetchAffiliateInvoiceIsSuccess(response));
    } catch (err) {
      dispatch(fetchAffiliateInvoiceIsFailure());
    }
  };

const createAffiliateInvoiceAttachment =
  (
    affiliateId: number,
    invoiceId: number,
    file: File,
    handleProgress: (progress: number) => void,
    helpers: FormikHelpers<{ file?: File }>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const response =
        await api.admin.affiliate.invoices.createInvoiceAttachment(
          affiliateId,
          invoiceId,
          file,
          handleProgress
        );
      helpers.resetForm({});
      dispatch(createAffiliateInvoiceAttachmentIsSuccess(response));
      dispatch(openSnackbar({ type: "success", message: "Attachment added!" }));
    } finally {
      helpers.setSubmitting(false);
    }
  };

interface AffiliateInvoiceState {
  error: boolean;
  isLoading: boolean;
  data: PaymentInvoiceResponse | null;
}

const initialState = {
  error: false,
  isLoading: false,
  data: null
};

const reducer: Reducer<AffiliateInvoiceState, AffiliateInvoiceActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_LOADING: {
      return {
        ...state,
        error: false,
        isLoading: true
      };
    }
    case AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload
      };
    case AffiliateInvoiceActionTypes.FETCH_AFFILIATE_INVOICE_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case AffiliateInvoiceActionTypes.CREATE_AFFILIATE_INVOICE_ATTACHMENT:
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              attachments: action.payload.log.attachments
            }
          : null
      };
    default:
      return state;
  }
};

const getAffiliateInvoiceState = (state: AppState) => state.affiliate.invoice;

export {
  reducer,
  fetchAffiliateInvoice,
  createAffiliateInvoiceAttachment,
  getAffiliateInvoiceState
};
