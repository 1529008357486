import React, {
  FC,
  ReactElement,
  useState,
  useCallback,
  useEffect,
  useRef,
  cloneElement,
  MouseEvent
} from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import minDate from "date-fns/min";
import maxDate from "date-fns/max";
import { useMediaQuery, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";

import { useAnchor } from "hooks";
import { now, minDate as defaultMinDate } from "constants/dates";
import { CircleIconButton } from "components/button";

import { getDatePickerState, setDateRange } from "../duck";
import { useStyles } from "../styles";

const formatDateRange = (date: [Date, Date]) =>
  `${format(date[0], "LLL dd yyyy")} - ${format(date[1], "LLL dd yyyy")}`;

const getCalendarTitle = () =>
  document.getElementsByClassName(
    "MuiPickersCalendarHeader-transitionContainer"
  )[0];

const RangeDatePicker: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { dateRange } = useSelector(getDatePickerState);
  const [month, _setMonth] = useState<MaterialUiPickersDate>(null);
  const [year, _setYear] = useState<MaterialUiPickersDate>(null);
  const monthRef = useRef(month);
  const setMonth = (date: MaterialUiPickersDate) => {
    monthRef.current = date;
    _setMonth(date);
  };
  const setYear = (date: MaterialUiPickersDate) => {
    setMonth(date);
    _setYear(date);
  };
  const [begin, setBegin] = useState<MaterialUiPickersDate>(dateRange[0]);
  const [end, setEnd] = useState<MaterialUiPickersDate>(dateRange[1]);
  const [hover, setHover] = useState<MaterialUiPickersDate>(null);

  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();

  const min = minDate([begin as Date, (end || hover) as Date]);
  const max = maxDate([begin as Date, (end || hover) as Date]);

  const dispatchDateRange = useCallback(
    (range: [MaterialUiPickersDate, MaterialUiPickersDate]) => {
      dispatch(setDateRange(range as [Date, Date]));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    if (!end && dateRange) {
      setBegin(dateRange[0]);
      setEnd(dateRange[1]);
    }
    handleClosePopup();
  }, [end, dateRange, handleClosePopup]);

  const handleSelectMonth = () => {
    const currentDate = monthRef.current ? monthRef.current : dateRange[0];

    setBegin(startOfMonth(currentDate));
    setEnd(endOfMonth(currentDate));
    dispatchDateRange([startOfMonth(currentDate), endOfMonth(currentDate)]);
    handleClosePopup();
  };

  const initMonthTitleEvent = () => {
    const monthTitle = getCalendarTitle();
    monthTitle && monthTitle.addEventListener("click", handleSelectMonth);
  };

  const removeMonthTitleEvent = () => {
    const monthTitle = getCalendarTitle();
    monthTitle && monthTitle.removeEventListener("click", handleSelectMonth);
  };

  const handleDateRange = useCallback(
    (day: MaterialUiPickersDate) => {
      if (!begin) {
        setBegin(day);
      } else if (!end) {
        setEnd(day);
        dispatchDateRange(day && day < begin ? [day, begin] : [begin, day]);
        handleClosePopup();
      } else {
        setBegin(day);
        setEnd(null);
      }
    },
    [begin, end, handleClosePopup, dispatchDateRange]
  );

  const renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      _selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
      dayComponent: ReactElement
    ) => {
      return cloneElement(dayComponent, {
        onClick: (event: MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleDateRange(day);
        },
        onMouseEnter: () => setHover(day),
        className:
          day &&
          cn(classes.day, {
            [classes.beginCap]: isSameDay(day, min),
            [classes.endCap]: isSameDay(day, max),
            [classes.currentDay]: isSameDay(day, now),
            [classes.isSelected]: day >= min && day <= max,
            [classes.isDisabled]: dayComponent.props.disabled,
            [classes.notCurrentMonth]: !dayInCurrentMonth
          })
      });
    },
    [classes, min, max, handleDateRange]
  );

  useEffect(() => {
    const monthTitle = getCalendarTitle();
    if (monthTitle) {
      initMonthTitleEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <>
      {isMobile ? (
        <CircleIconButton onClick={handleOpenPopup}>
          <CalendarIcon className={classes.icon} />
        </CircleIconButton>
      ) : (
        <Button
          className={classes.button}
          onClick={handleOpenPopup}
          endIcon={<CalendarIcon fontSize="small" className={classes.icon} />}
        >
          {formatDateRange(dateRange)}
        </Button>
      )}
      <DatePicker
        disableFuture
        variant="inline"
        orientation="portrait"
        open={!!anchorEl}
        value={begin}
        minDate={defaultMinDate}
        maxDate={now}
        PopoverProps={{
          anchorEl,
          TransitionProps: {
            onEntered: initMonthTitleEvent,
            onExited: removeMonthTitleEvent
          },
          transformOrigin: {
            vertical: -8,
            horizontal: "center"
          }
        }}
        TextFieldComponent={() => null}
        renderDay={renderDay}
        onClose={handleClose}
        onChange={() => null}
        onYearChange={setYear}
        onMonthChange={setMonth}
      />
    </>
  );
};

export default RangeDatePicker;
