import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  name: yup.string().required("Company name is required"),
  info: yup.string().notRequired().nullable()
});

export default validationSchema;
