import React, { FC, ReactElement, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";

import Sidebar from "modules/affiliates-sidebar";
import Affiliate, {
  fetchAffiliate,
  fetchAffiliateSubAccounts
} from "modules/affiliate";
import EmptyAffiliate from "components/empty-affiliate";
import AffiliatesTabs from "components/affiliates-tabs";

interface RouteParams {
  id: string;
  tab: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  children: ReactElement;
}

const AffiliatesPage: FC<Props> = ({
  children,
  match: {
    params: { id, tab }
  }
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    id &&
      Promise.all([
        dispatch(fetchAffiliateSubAccounts(Number(id))),
        dispatch(fetchAffiliate(Number(id)))
      ]);
  }, [dispatch, id]);

  return (
    <Box display="flex" height="100%" padding="0">
      <Sidebar />
      <Box
        display="block"
        width="100%"
        overflow="auto"
        padding="24px 32px 32px 32px"
      >
        {id && tab ? (
          <>
            <Affiliate />
            <AffiliatesTabs />
            <Box>{children}</Box>
          </>
        ) : (
          <EmptyAffiliate />
        )}
      </Box>
    </Box>
  );
};
export default AffiliatesPage;
