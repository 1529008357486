import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      zIndex: "1301 !important"
    },
    paper: {
      maxWidth: 512,
      padding: 32,
      borderRadius: 8
    },
    title: {
      color: theme.colors.black21,
      fontWeight: 500
    },
    subtitle: {
      marginTop: 8,
      textTransform: "none"
    },
    text: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500,
      wordWrap: "break-word",
      color: theme.colors.black21
    },
    content: {
      marginTop: 16,
      color: theme.colors.black21
    },
    actions: {
      padding: 0,
      marginTop: 32,
      justifyContent: "flex-start"
    },
    deleteBtn: {
      color: theme.colors.white,
      background: theme.colors.red,
      borderColor: theme.colors.red,
      "&:hover": {
        color: theme.colors.white,
        background: theme.colors.red
      }
    },
    submitBtn: {
      color: theme.colors.white,
      background: theme.colors.blue,
      "&:hover": {
        color: theme.colors.white,
        background: theme.colors.blue,
        borderColor: theme.colors.blue
      }
    }
  })
);

export { useStyles };
