import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import Button, { ButtonProps } from "@material-ui/core/Button";
import flatten from "flat";

const SubmitButton: FC<
  Omit<ButtonProps, "disabled" | "type" | "color">
> = props => {
  const { dirty, isSubmitting, errors, setFieldTouched, isValidating } =
    useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      for (const path of Object.keys(flatten(errors))) {
        setFieldTouched(path, true, false);
      }
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);

  return (
    <Button
      type="submit"
      color="secondary"
      disabled={!dirty || isSubmitting}
      {...props}
    />
  );
};

export default SubmitButton;
