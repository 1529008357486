import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import format from "date-fns/format";
import getTime from "date-fns/getTime";
import { DatePicker as MaterialDatePicker } from "@material-ui/pickers";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";

import { now, minDate } from "constants/dates";
import { useAnchor } from "hooks";
import { CircleIconButton } from "components/button";

import { getDatePickerState, getFormattedDate, setDate } from "../duck";
import { useStyles } from "../styles";

interface Props {
  baseDateUrl: string;
}

const DatePicker: FC<Props> = ({ baseDateUrl }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { fullDate } = useSelector(getDatePickerState);
  const displayDate = useSelector(getFormattedDate);

  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const nextTime = getTime((date as Date).setDate(1));
      const limitTime = getTime(now);

      if (nextTime < limitTime || nextTime === limitTime) {
        const year = format(date as Date, "yyyy");
        const month = format(date as Date, "MM");
        const replaceUrl = `${baseDateUrl}/${year}/${month}`;

        dispatch(setDate(date as Date));
        history.replace(replaceUrl);
      }
    },
    [dispatch, history, baseDateUrl]
  );

  return (
    <>
      <Hidden mdUp implementation="js">
        <CircleIconButton onClick={handleOpenPopup}>
          <CalendarIcon className={classes.icon} />
        </CircleIconButton>
      </Hidden>
      <Hidden smDown implementation="js">
        <Button
          className={classes.button}
          endIcon={<CalendarIcon fontSize="small" className={classes.icon} />}
          onClick={handleOpenPopup}
        >
          {displayDate}
        </Button>
      </Hidden>
      <div className={classes.picker}>
        <MaterialDatePicker
          autoOk
          disableFuture
          openTo="month"
          orientation="portrait"
          views={["year", "month"]}
          variant="inline"
          open={!!anchorEl}
          value={fullDate}
          minDate={minDate}
          maxDate={now}
          PopoverProps={{
            anchorEl,
            transformOrigin: {
              vertical: -8,
              horizontal: "center"
            }
          }}
          onClose={handleClosePopup}
          onChange={handleDateChange}
        />
      </div>
    </>
  );
};

export default DatePicker;
