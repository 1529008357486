import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

import { useSkeletonStyles } from "./styles";

const CELL_COUNT = Array.from(Array(4));
const ROWS_COUNT = Array.from(Array(15));

const PlansSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  const TableRow: FC = () => (
    <>
      <Box className={classes.tableRow}>
        <Skeleton width={256} height={18} />
        <Box className={classes.row}>
          <Box className={classes.rowData}>
            {CELL_COUNT.map((_, index) => (
              <Skeleton key={index} width={64} height={18} />
            ))}
          </Box>
          <Skeleton className={classes.tableButton} width={24} height={24} />
        </Box>
      </Box>
      <Divider />
    </>
  );

  return (
    <Box className={classes.container}>
      <TableRow />
      <Divider />
      {ROWS_COUNT.map((_, index) => (
        <TableRow key={index} />
      ))}
    </Box>
  );
};

export default PlansSkeleton;
