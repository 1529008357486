import React, { FC } from "react";
import { isWithinInterval } from "date-fns";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoRounded";

import EmptyData from "components/empty-data";
import { ActionCard } from "components/cards";
import { DEFAULT_FEE } from "constants/fee";
import { BRANDS, BRAND_SELECT_OPTIONS } from "constants/brands";
import getBrandColorClassName from "utils/getBrandColorClassName";

import Skeleton from "./Skeleton";
import useAffiliateFees from "./useAffiliateFees";

interface Props {
  handleAffiliateFeeClick: (brandId: BRANDS) => void;
}

const Component: FC<Props> = ({ handleAffiliateFeeClick }) => {
  const { classes, isLoading, error, fees } = useAffiliateFees();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <EmptyData marginBottom={4} />;
  }

  return (
    <>
      <Typography component="div" className={classes.title}>
        Fees
      </Typography>
      <Box className={classes.row}>
        {BRAND_SELECT_OPTIONS.filter(({ value }) => value !== "SB").map(
          ({ label: brandName, value: brandId }) => {
            const fee = !isEmpty(fees)
              ? fees.find(
                  ({ brandId: feeBrandId, periodFrom, periodTo }) =>
                    feeBrandId == brandId &&
                    isWithinInterval(new Date(), {
                      start: periodFrom,
                      end: periodTo
                    })
                )
              : null;
            return (
              <ActionCard
                key={brandId}
                label={brandName}
                labelClassName={getBrandColorClassName(fee?.brandId || brandId)}
                onClick={() => handleAffiliateFeeClick(fee?.brandId || brandId)}
              >
                <Box className={classes.flexCenter}>
                  <Typography
                    noWrap
                    component="span"
                    className={classes.brandText}
                  >
                    {`${fee?.percent || DEFAULT_FEE}%`}
                  </Typography>
                  <InfoIcon className={classes.icon} />
                </Box>
              </ActionCard>
            );
          }
        )}
      </Box>
      <Divider className={classes.divider} />
    </>
  );
};

export default Component;
