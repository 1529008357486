import React, { FC, ReactElement } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import formatMoney from "utils/formatMoney";

import { useTotalStyles } from "../styles";

interface Props {
  summary?: ReactElement;
  total?: number;
}

const Total: FC<Props> = ({ summary, total }) => {
  const classes = useTotalStyles();

  return (
    <>
      {summary}
      {total ? (
        <Box className={classes.container}>
          <Typography variant="h4">Total:</Typography>
          <Typography variant="h4" className={classes.value}>
            {formatMoney.from(total)}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default Total;
