import {
  AffiliateResponse,
  AffiliateViewProfileResponse,
  UpdateAffiliateProfile,
  UpdateAffiliateViewProfileRequest,
  UpdateAffiliatePaymentInformation,
  AffiliateApiTokenResponse,
  CreateSubAccountRequest,
  Affiliates,
  ListAffiliate
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import omit from "lodash/omit";
import pick from "lodash/pick";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { fetchAffiliates } from "modules/affiliates-sidebar";
import { SnackbarActions, openSnackbar } from "modules/snackbar";

enum AffiliateActionTypes {
  AFFILIATE_LOADING = "@affiliate/affiliate-loading",
  FETCH_AFFILIATE_API_TOKEN = "@affiliate/fetch-affiliate-api-token",
  REFRESH_AFFILIATE_API_TOKEN = "@affiliate/refresh-affiliate-api-token",
  FETCH_AFFILIATE_SUCCESS = "@affiliate/fetch-affiliate-success",
  FETCH_AFFILIATE_FAILURE = "@affiliate/fetch-affiliate-failure",
  UPDATE_AFFILIATE_SUCCESS = "@affiliate/update-affiliate-success",
  UPDATE_AFFILIATE_FAILURE = "@affiliate/update-affiliate-failure",
  FETCH_SUBACCOUNTS_SUCCESS = "@affiliate/fetch-subaccounts-success",
  FETCH_SUBACCOUNTS_FAILURE = "@affiliate/fetch-subaccounts-failure",
  CREATE_SUBACCOUNT_SUCCESS = "@affiliate/create-subaccount-success",
  SET_INFORMATION_DIALOG_STATUS = "@authentication/set-information-dialog-status"
}

interface AffiliateIsLoadingAction {
  type: AffiliateActionTypes.AFFILIATE_LOADING;
}

interface RefreshAffiliateApiTokenIsSuccessAction {
  type: AffiliateActionTypes.REFRESH_AFFILIATE_API_TOKEN;
  payload: AffiliateApiTokenResponse;
}

interface FetchAffiliateApiTokenIsSuccessAction {
  type: AffiliateActionTypes.FETCH_AFFILIATE_API_TOKEN;
  payload: AffiliateApiTokenResponse;
}

interface FetchAffiliateIsSuccessAction {
  type: AffiliateActionTypes.FETCH_AFFILIATE_SUCCESS;
  payload: AffiliateResponse | AffiliateViewProfileResponse;
}

interface FetchAffiliateIsFailureAction {
  type: AffiliateActionTypes.FETCH_AFFILIATE_FAILURE;
}

interface UpdateAffiliateIsSuccessAction {
  type: AffiliateActionTypes.UPDATE_AFFILIATE_SUCCESS;
  payload: AffiliateResponse;
}

interface UpdateUserAffiliateIsSuccessAction {
  type: AffiliateActionTypes.UPDATE_AFFILIATE_SUCCESS;
  payload: AffiliateViewProfileResponse;
}

interface UpdateAffiliateIsFailureAction {
  type: AffiliateActionTypes.UPDATE_AFFILIATE_FAILURE;
}

interface FetchSubAccountsIsSuccessAction {
  type: AffiliateActionTypes.FETCH_SUBACCOUNTS_SUCCESS;
  payload: Affiliates;
}

interface FetchSubAccountsIsFailureAction {
  type: AffiliateActionTypes.FETCH_SUBACCOUNTS_FAILURE;
}

interface CreateSubAccountIsSuccessAction {
  type: AffiliateActionTypes.CREATE_SUBACCOUNT_SUCCESS;
}

interface SetInformationDialogStatusAction {
  type: AffiliateActionTypes.SET_INFORMATION_DIALOG_STATUS;
  payload: boolean;
}

type AffiliateActions =
  | AffiliateIsLoadingAction
  | RefreshAffiliateApiTokenIsSuccessAction
  | FetchAffiliateApiTokenIsSuccessAction
  | FetchAffiliateIsSuccessAction
  | FetchAffiliateIsFailureAction
  | UpdateAffiliateIsSuccessAction
  | UpdateUserAffiliateIsSuccessAction
  | UpdateAffiliateIsFailureAction
  | FetchSubAccountsIsSuccessAction
  | FetchSubAccountsIsFailureAction
  | CreateSubAccountIsSuccessAction
  | SetInformationDialogStatusAction;

type AffiliateActionsWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateActions | DrawersActions>
>;

const affiliateIsLoading = (): AffiliateIsLoadingAction => ({
  type: AffiliateActionTypes.AFFILIATE_LOADING
});

const fetchAffiliateIsSuccess = (
  affiliate: AffiliateResponse | AffiliateViewProfileResponse
): FetchAffiliateIsSuccessAction => ({
  type: AffiliateActionTypes.FETCH_AFFILIATE_SUCCESS,
  payload: affiliate
});

const refreshAffiliateApiTokenIsSuccess = (
  data: AffiliateApiTokenResponse
): RefreshAffiliateApiTokenIsSuccessAction => ({
  type: AffiliateActionTypes.REFRESH_AFFILIATE_API_TOKEN,
  payload: data
});

const fetchAffiliateApiTokenIsSuccess = (
  data: AffiliateApiTokenResponse
): FetchAffiliateApiTokenIsSuccessAction => ({
  type: AffiliateActionTypes.FETCH_AFFILIATE_API_TOKEN,
  payload: data
});

const fetchAffiliateIsFailure = (): FetchAffiliateIsFailureAction => ({
  type: AffiliateActionTypes.FETCH_AFFILIATE_FAILURE
});

const updateAffiliateIsSuccess = (
  affiliate: AffiliateResponse
): UpdateAffiliateIsSuccessAction => ({
  type: AffiliateActionTypes.UPDATE_AFFILIATE_SUCCESS,
  payload: affiliate
});

const updateUserAffiliateIsSuccess = (
  affiliate: AffiliateViewProfileResponse
): UpdateUserAffiliateIsSuccessAction => ({
  type: AffiliateActionTypes.UPDATE_AFFILIATE_SUCCESS,
  payload: affiliate
});

const updateAffiliateIsFailure = (): UpdateAffiliateIsFailureAction => ({
  type: AffiliateActionTypes.UPDATE_AFFILIATE_FAILURE
});

const fetchSubAccountsIsSuccess = (
  affiliates: Affiliates
): FetchSubAccountsIsSuccessAction => ({
  type: AffiliateActionTypes.FETCH_SUBACCOUNTS_SUCCESS,
  payload: affiliates
});

const fetchSubAccountsIsFailure = (): FetchSubAccountsIsFailureAction => ({
  type: AffiliateActionTypes.FETCH_SUBACCOUNTS_FAILURE
});

const createSubAccountIsSuccess = (): CreateSubAccountIsSuccessAction => ({
  type: AffiliateActionTypes.CREATE_SUBACCOUNT_SUCCESS
});

const setInformationDialogStatus = (
  status: boolean
): SetInformationDialogStatusAction => ({
  type: AffiliateActionTypes.SET_INFORMATION_DIALOG_STATUS,
  payload: status
});

const fetchAffiliateApiToken =
  (): AffiliateActionsWithSnackbar => async dispatch => {
    const response = await api.affiliate.profile.getApiToken();
    dispatch(fetchAffiliateApiTokenIsSuccess(response));
  };

const refreshAffiliateApiToken =
  (): AffiliateActionsWithSnackbar => async dispatch => {
    const response = await api.affiliate.profile.refreshApiToken();
    dispatch(refreshAffiliateApiTokenIsSuccess(response));
    dispatch(
      openSnackbar({
        type: "success",
        message: "API Token refreshed!"
      })
    );
  };

const fetchAffiliate =
  (id: number): ThunkActionCreator<AffiliateActions> =>
  async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.admin.affiliate.getAffiliate(id);
      dispatch(fetchAffiliateIsSuccess(affiliate));
    } catch (err) {
      dispatch(fetchAffiliateIsFailure());
    }
  };

const fetchAffiliateViewProfile =
  (): ThunkActionCreator<AffiliateActions> => async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.affiliate.profile.getProfile();
      dispatch(fetchAffiliateIsSuccess(affiliate));
    } catch (err) {
      dispatch(fetchAffiliateIsFailure());
    }
  };

const updateAffiliateProfile =
  (
    id: number,
    data: UpdateAffiliateProfile,
    helpers?: FormikHelpers<UpdateAffiliateProfile>
  ): AffiliateActionsWithSnackbar =>
  async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.admin.affiliate.updateAffiliate(id, data);
      dispatch(
        openSnackbar({
          type: "success",
          message: `Affiliate(${affiliate.affiliateId}) profile updated!`
        })
      );
      dispatch(updateAffiliateIsSuccess(affiliate));
      dispatch(closeDrawer());
    } catch (error) {
      dispatch(updateAffiliateIsFailure());
    } finally {
      helpers?.setSubmitting(false);
    }
  };

const updateAffiliatePaymentInformation =
  (
    id: number,
    data: UpdateAffiliatePaymentInformation,
    helpers?: FormikHelpers<UpdateAffiliatePaymentInformation>
  ): AffiliateActionsWithSnackbar =>
  async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.admin.affiliate.updateAffiliate(id, data);
      dispatch(
        openSnackbar({
          type: "success",
          message: `Affiliate(${affiliate.affiliateId}) payment information updated!`
        })
      );
      dispatch(updateAffiliateIsSuccess(affiliate));
      dispatch(closeDrawer());
    } catch (error) {
      dispatch(updateAffiliateIsFailure());
    } finally {
      helpers?.setSubmitting(false);
    }
  };

const closeAffiliateAccount =
  (id: number): AffiliateActionsWithSnackbar =>
  async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.admin.affiliate.updateAffiliate(id, {
        isClosed: true
      });
      dispatch(
        openSnackbar({
          type: "success",
          message: `Affiliate(${affiliate.affiliateId}) account closed!`
        })
      );
      dispatch(updateAffiliateIsSuccess(affiliate));
    } catch (error) {
      dispatch(updateAffiliateIsFailure());
    }
  };

const updateAffiliateViewProfile =
  (
    data: UpdateAffiliateViewProfileRequest,
    helpers: FormikHelpers<UpdateAffiliateViewProfileRequest>
  ): AffiliateActionsWithSnackbar =>
  async dispatch => {
    dispatch(affiliateIsLoading());
    try {
      const affiliate = await api.affiliate.profile.updateProfile(data);
      dispatch(openSnackbar({ type: "success", message: "Profile updated!" }));
      dispatch(updateUserAffiliateIsSuccess(affiliate));
    } catch (error) {
      dispatch(updateAffiliateIsFailure());
    } finally {
      helpers.setSubmitting(false);
    }
  };

const fetchAffiliateViewSubAccounts =
  (): ThunkActionCreator<AffiliateActions> => async dispatch => {
    try {
      const response = await api.affiliate.subaccounts.getSubAccounts();
      dispatch(fetchSubAccountsIsSuccess(response));
    } catch (error) {
      dispatch(fetchSubAccountsIsFailure());
    }
  };

const fetchAffiliateSubAccounts =
  (id: number): ThunkActionCreator<AffiliateActions> =>
  async dispatch => {
    try {
      const response = await api.admin.affiliate.subaccounts.getSubAccounts(id);
      dispatch(fetchSubAccountsIsSuccess(response));
    } catch (error) {
      dispatch(fetchSubAccountsIsFailure());
    }
  };

const createAffiliateViewSubAccount =
  (
    data: CreateSubAccountRequest,
    helpers: FormikHelpers<CreateSubAccountRequest>
  ): AffiliateActionsWithSnackbar =>
  async dispatch => {
    try {
      await api.affiliate.subaccounts.createSubAccount(data);
      dispatch(
        openSnackbar({ type: "success", message: "Sub account created!" })
      );
      helpers.resetForm();
    } finally {
      helpers.setSubmitting(false);
    }
  };

const createAffiliateSubAccount =
  (
    id: number,
    data: CreateSubAccountRequest,
    helpers: FormikHelpers<CreateSubAccountRequest>
  ): AffiliateActionsWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.subaccounts.createSubAccount(id, data);
      dispatch(
        openSnackbar({ type: "success", message: "Sub account created!" })
      );
      Promise.all([
        dispatch(createSubAccountIsSuccess()),
        dispatch(fetchAffiliateSubAccounts(id)),
        dispatch(fetchAffiliates())
      ]);
      helpers.resetForm();
    } finally {
      helpers.setSubmitting(false);
    }
  };

interface AffiliateState {
  error: boolean;
  isLoading: boolean;
  apiToken?: string;
  data?: AffiliateResponse | AffiliateViewProfileResponse;
  subaccounts: ListAffiliate[];
  infoDialogShown: boolean;
}

const initialState: AffiliateState = {
  error: false,
  isLoading: false,
  apiToken: undefined,
  data: undefined,
  infoDialogShown: false,
  subaccounts: []
};

const reducer: Reducer<AffiliateState, AffiliateActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateActionTypes.AFFILIATE_LOADING:
      return {
        ...initialState,
        ...state,
        isLoading: true
      };
    case AffiliateActionTypes.FETCH_AFFILIATE_SUCCESS:
    case AffiliateActionTypes.UPDATE_AFFILIATE_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        data: {
          ...action.payload,
          lastLogin: action.payload.lastLoginDate
            ? `Last login on ${format(
                parseISO(action.payload.lastLoginDate),
                "dd LLL yyyy HH:mm"
              )}`
            : "Never logged in yet"
        }
      };
    case AffiliateActionTypes.REFRESH_AFFILIATE_API_TOKEN:
    case AffiliateActionTypes.FETCH_AFFILIATE_API_TOKEN:
      return {
        ...state,
        apiToken: action.payload.apiToken
      };
    case AffiliateActionTypes.FETCH_SUBACCOUNTS_SUCCESS:
      return {
        ...state,
        subaccounts: action.payload.affiliates
      };
    case AffiliateActionTypes.UPDATE_AFFILIATE_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case AffiliateActionTypes.FETCH_AFFILIATE_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case AffiliateActionTypes.SET_INFORMATION_DIALOG_STATUS:
      return {
        ...state,
        infoDialogShown: action.payload
      };
    default:
      return state;
  }
};

const getAffiliateState = (state: AppState) => state.affiliate.profile;
const getInfoDialogStatus = (state: AppState) =>
  state.affiliate.profile.infoDialogShown;
const getAffiliateProfileState = (state: AppState) => ({
  masterId: state.affiliate.profile.data?.masterId,
  profile: {
    name: state.affiliate.profile.data?.affiliateName,
    ...omit(state.affiliate.profile.data, [
      "affiliateId",
      "affiliateName",
      "lastLogin",
      "lastLoginDate",
      "paymentMethod",
      "paymentMethodDetails",
      "paymentMinAmount",
      "accountBalance",
      "masterId",
      "createdAt",
      "updatedAt",
      "isClosed"
    ])
  } as UpdateAffiliateProfile
});
const getAffiliatePaymentInformationState = (state: AppState) => ({
  ...pick(state.affiliate.profile.data, [
    "paymentMinAmount",
    "paymentMethod",
    "paymentMethodDetails"
  ])
});
const getAffiliateViewProfileState = (state: AppState) =>
  state.affiliate.profile.data
    ? {
        name: state.affiliate.profile.data.affiliateName,
        ...omit(state.affiliate.profile.data, [
          "affiliateId",
          "affiliateName",
          "lastLogin",
          "lastLoginDate",
          "accountBalance",
          "masterId",
          "createdAt",
          "updatedAt"
        ])
      }
    : null;

export {
  reducer,
  fetchAffiliate,
  fetchAffiliateApiToken,
  refreshAffiliateApiToken,
  updateAffiliatePaymentInformation,
  fetchAffiliateViewProfile,
  fetchAffiliateSubAccounts,
  fetchAffiliateViewSubAccounts,
  closeAffiliateAccount,
  updateAffiliateProfile,
  updateAffiliateViewProfile,
  createAffiliateSubAccount,
  createAffiliateViewSubAccount,
  getAffiliateState,
  getAffiliateProfileState,
  getAffiliateViewProfileState,
  getAffiliatePaymentInformationState,
  getInfoDialogStatus,
  setInformationDialogStatus
};
