import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import EmptyData from "components/empty-data";
import { DownloadCsvButton } from "components/button";
import PlayerInfo, { PlayerInfoSkeleton } from "components/player-info";
import { getAffiliatePlayerStateById } from "modules/affiliate-players";

import CSV_HEADERS from "./csvHeaders";
import ActivitiesTable from "./ActivitiesTable";
import {
  fetchAffiliatePlayerActivities,
  getAffiliatePlayerActivitiesState
} from "./duck";

interface MatchParams {
  id: string;
  playerId: string;
}

type Props = RouteComponentProps<MatchParams>;

const PlayerDetails: FC<Props> = ({ match }) => {
  const dispatch = useDispatch();
  const { id, playerId } = match.params;
  const { activities } = useSelector(getAffiliatePlayerActivitiesState);
  const { error, isLoading, player } = useSelector(getAffiliatePlayerStateById)(
    playerId
  );

  useEffect(() => {
    dispatch(fetchAffiliatePlayerActivities(Number(id), Number(playerId)));
  }, [dispatch, id, playerId]);

  return (
    <Box padding={2} width="896px">
      <Box>
        <Box className="drawer-header">
          <Typography variant="h4" className="drawer-header_title">
            Player Details
          </Typography>
          <Box display="flex" alignItems="center">
            <Button
              color="secondary"
              component={Link}
              style={{ marginRight: "8px" }}
              to={`${match.url}/deals/${player?.planId}`}
            >
              deal details
            </Button>
            <DownloadCsvButton
              headers={CSV_HEADERS}
              data={activities}
              disabled={isEmpty(activities)}
            />
          </Box>
        </Box>
        {isLoading ? (
          <PlayerInfoSkeleton />
        ) : error || !player ? (
          <EmptyData />
        ) : (
          <PlayerInfo {...player} />
        )}
        <Divider style={{ margin: "16px 0 32px 0" }} />
        <ActivitiesTable />
      </Box>
    </Box>
  );
};

export default PlayerDetails;
