export enum BRANDS {
  LD = "LD",
  KK = "KK",
  OS = "OS",
  CJ = "CJ",
  FK = "FK",
  SN = "SN",
  VB = "VB",
  SB = "SB"
}

export const BRAND_NAMES = {
  LD: "LuckyDino",
  KK: "JustWOW",
  OS: "OlaSpill",
  CJ: "CasinoJEFE",
  FK: "HipSpin",
  SN: "FreshSpins",
  VB: "VieBet",
  SB: "Sportnation.bet"
};

export const BRAND_SELECT_OPTIONS = [
  { label: BRAND_NAMES.LD, value: BRANDS.LD },
  { label: BRAND_NAMES.KK, value: BRANDS.KK },
  { label: BRAND_NAMES.OS, value: BRANDS.OS },
  { label: BRAND_NAMES.CJ, value: BRANDS.CJ },
  { label: BRAND_NAMES.FK, value: BRANDS.FK },
  { label: BRAND_NAMES.SN, value: BRANDS.SN },
  { label: BRAND_NAMES.VB, value: BRANDS.VB },
  { label: BRAND_NAMES.SB, value: BRANDS.SB }
];
