/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateSubAffiliateValues } from "@types";

import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";
import { getAffiliateSubAffiliatesSelectOptions } from "modules/affiliate-sub-afiiliates";

import validationSchema from "./validationSchema";

export interface AddSubAffiliateProps {
  affiliateId: string;
  onSubmit: (
    value: CreateSubAffiliateValues,
    helpers: FormikHelpers<CreateSubAffiliateValues>
  ) => void;
}

const initialValues: CreateSubAffiliateValues = {
  commissionShare: ""
};

const AddSubAffiliate: FC<AddSubAffiliateProps> = ({
  affiliateId,
  onSubmit
}) => {
  const affiliates = useSelector(getAffiliateSubAffiliatesSelectOptions).filter(
    ({ value }) => value !== affiliateId
  );

  const getSubAffiliateValue = (value?: number) => {
    const affiliate = affiliates.find(
      affiliate => affiliate.value === String(value)
    );
    return affiliate ? affiliate : null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form className="drawer-container" onSubmit={handleSubmit}>
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              Add New Sub-Affiliate
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <SelectField
              name="subId"
              placeholder="Select an affilite"
              label="Sub-Affiliate"
              options={affiliates}
              value={getSubAffiliateValue(values.subId)}
            />
            <TextField
              numeric
              label="Commission Share (%)"
              name="commissionShare"
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default AddSubAffiliate;
