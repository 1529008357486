import { PlansDescription } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum PlansActionTypes {
  FETCH_PLANS_LOADING = "@plans/fetch-plans-loading",
  FETCH_PLANS_SUCCESS = "@plans/fetch-plans-success",
  FETCH_PLANS_FAILURE = "@plans/fetch-plans-failure"
}

interface FetchPlansIsLoadingAction {
  type: PlansActionTypes.FETCH_PLANS_LOADING;
}

interface FetchPlansIsSuccessAction {
  type: PlansActionTypes.FETCH_PLANS_SUCCESS;
  payload: { plans: PlansDescription[] };
}

interface FetchPlansIsFailureAction {
  type: PlansActionTypes.FETCH_PLANS_FAILURE;
}

type PlansActions =
  | FetchPlansIsLoadingAction
  | FetchPlansIsSuccessAction
  | FetchPlansIsFailureAction;

const fetchPlansIsLoading = (): FetchPlansIsLoadingAction => ({
  type: PlansActionTypes.FETCH_PLANS_LOADING
});

const fetchPlansIsSuccess = (
  plans: PlansDescription[]
): FetchPlansIsSuccessAction => ({
  type: PlansActionTypes.FETCH_PLANS_SUCCESS,
  payload: { plans }
});

const fetchPlansIsFailure = (): FetchPlansIsFailureAction => ({
  type: PlansActionTypes.FETCH_PLANS_FAILURE
});

const fetchPlans = (): ThunkActionCreator<PlansActions> => async dispatch => {
  dispatch(fetchPlansIsLoading());
  try {
    const { plans } = await api.admin.plans.getPlans();
    dispatch(fetchPlansIsSuccess(plans));
  } catch (err) {
    dispatch(fetchPlansIsFailure());
  }
};

interface PlansState {
  error: boolean;
  isLoading: boolean;
  data: PlansDescription[];
}

const initialState: PlansState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<PlansState, PlansActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PlansActionTypes.FETCH_PLANS_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case PlansActionTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        data: action.payload.plans
      };
    case PlansActionTypes.FETCH_PLANS_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };

    default:
      return state;
  }
};

const getPlansState = (state: AppState) => state.plans;
const getPlansSelect = (state: AppState) =>
  state.plans.data.map(({ planId, name }) => ({
    label: name,
    value: String(planId)
  }));

export { reducer, fetchPlans, getPlansSelect, getPlansState };
