import { TableFilterValues } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {
  PAYMENT_STATUSES_OPTIONS,
  PAYMENT_METHODS_OPTIONS
} from "constants/payments";
import SelectField from "components/select-field";

export interface TableFilterProps {
  initialState: TableFilterValues;
  handleSubmit: (
    values: TableFilterValues,
    helpers: FormikHelpers<TableFilterValues>
  ) => void;
}

const allOption = { label: "All", value: "all" };
const STATUSES = [allOption, ...PAYMENT_STATUSES_OPTIONS];
const PAYMENTS = [allOption, ...PAYMENT_METHODS_OPTIONS];
const TAX_RATES = [
  allOption,
  { label: "0%", value: 0 },
  { label: "18%", value: 18 }
];

const TableFilter: FC<TableFilterProps> = ({ initialState, handleSubmit }) => {
  const getStatusValue = (value: string) =>
    STATUSES.find(option => option.value === value);

  const getPaymentValue = (value: string) =>
    PAYMENTS.find(option => option.value === value);

  const getTaxRateValue = (value: string) =>
    TAX_RATES.find(option => option.value === value);

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit}>
      {({ values, handleSubmit }) => (
        <form className="drawer-container" onSubmit={handleSubmit}>
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              Table Filter
            </Typography>
            <Button type="submit" color="secondary">
              save changes
            </Button>
          </Box>
          <SelectField
            name="status"
            label="Status"
            isSearchable={false}
            placeholder="Select status"
            options={STATUSES}
            value={getStatusValue(values.status)}
          />
          <SelectField
            name="paymentMethod"
            label="Payment Method"
            isSearchable={false}
            placeholder="Select payment method"
            options={PAYMENTS}
            value={getPaymentValue(values.paymentMethod)}
          />
          <SelectField
            name="taxRate"
            label="Tax Rate"
            isSearchable={false}
            placeholder="Select tax rate"
            options={TAX_RATES}
            value={getTaxRateValue(values.taxRate)}
          />
        </form>
      )}
    </Formik>
  );
};

export default TableFilter;
