import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100%"
    },
    leftSide: {
      display: "flex",
      flexDirection: "column",
      width: "calc(70% - 32px)",
      height: "calc(100vh - 296px)"
    },
    rightSide: {
      width: "calc(30% - 32px)",
      height: "calc(100vh - 296px)"
    },
    vertDivider: {
      margin: "0 32px",
      height: "auto",
      width: 1,
      background: theme.colors.blacke0
    },
    empty: {
      padding: "0 16px 16px 16px"
    },
    textField: {
      marginTop: 0
    },
    noteInput: {
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiInputAdornment-root .Mui-disabled svg": {
        color: `${theme.colors.blackbd} !important`
      }
    },
    attachmentIcon: {
      color: theme.colors.black61,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    attachmentIconActive: {
      color: theme.colors.blue
    },
    submitIcon: {
      color: theme.colors.blue
    },
    sharedFilesContainer: {
      height: "calc(100% - 72px)"
    },
    sharedFilesList: {
      height: "100%",
      overflow: "auto",
      "& > *": {
        marginTop: 8
      }
    },
    list: {
      height: "100%"
    },
    log: {
      padding: "24px 16px",
      borderTop: `1px solid ${theme.colors.blacke0}`
    },
    logHeader: {
      display: "flex"
    },
    logUsername: {
      color: theme.colors.black21,
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500
    },
    systemUser: {
      color: theme.colors.blue
    },
    systemMessage: {
      background: "none"
    },
    logLastVisit: {
      color: theme.colors.black9e,
      fontSize: 12,
      lineHeight: "16px",
      paddingLeft: 8
    },
    logTextContainer: {
      paddingTop: 8
    },
    logText: {
      fontSize: 14,
      lineHeight: "20px",
      color: theme.colors.black21,
      whiteSpace: "pre-wrap"
    },
    logAttachments: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 8,
      "& > *:not(:first-child)": {
        marginLeft: 8
      }
    },
    title: {
      fontWeight: 500,
      color: theme.colors.black21
    },
    tip: {
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black9e
    },
    divider: {
      margin: "32px 0"
    },
    noFilesContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 16
    },
    noFilesIcon: {
      width: 32,
      color: theme.colors.blackbd
    },
    noFilesText: {
      fontSize: 14,
      marinTop: 16,
      lineHeight: "24px",
      color: theme.colors.black9e
    },
    uploads: {
      padding: "0 16px 16px 16px"
    },
    progressBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    progressBarLoader: {
      width: "90%"
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: 16,
      width: "100%",
      borderTop: `1px solid ${theme.colors.blacke0}`
    },
    title: {
      display: "flex"
    },
    subtitle: {
      marginLeft: 8
    }
  })
);

export { useStyles, useSkeletonStyles };
