import { AffiliateActivity } from "@types";

import { ChangeEvent, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import format from "date-fns/format";

import { useRoles, useSearch } from "hooks";
import { BRANDS } from "constants/brands";
import { getDatePickerState } from "modules/datepicker";

import { useStyles } from "./styles";
import {
  fetchAffiliateActivities,
  fetchAffiliateViewActivities,
  setAffiliateActivitiesBrandFilter,
  getAffiliateActivitiesState
} from "./duck";

interface Params {
  id: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const { dateRange } = useSelector(getDatePickerState);
  const {
    error,
    isLoading,
    brand,
    total,
    totals,
    data: activities
  } = useSelector(getAffiliateActivitiesState);

  const { isAdminLoggedIn } = useRoles();
  const { query, setQuery, results } = useSearch<AffiliateActivity>(
    ["link", "segment"],
    activities
  );

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleBrandFilter = useCallback(
    (brand: BRANDS | null) => {
      dispatch(setAffiliateActivitiesBrandFilter(brand));
    },
    [dispatch]
  );

  useEffect(() => {
    const from = format(dateRange[0], "yyyy-MM-dd");
    const to = format(dateRange[1], "yyyy-MM-dd");

    if (id) {
      dispatch(fetchAffiliateActivities(Number(id), from, to, brand));
    } else {
      dispatch(fetchAffiliateViewActivities(from, to, brand));
    }
  }, [dateRange, dispatch, id, brand]);

  useEffect(() => {
    return () => {
      dispatch(setAffiliateActivitiesBrandFilter(null));
    };
  }, [dispatch]);

  return {
    classes,
    isLoading,
    isEmpty: isEmpty(activities),
    isEmptySearch: isEmpty(results),
    linkHref: isAdminLoggedIn
      ? `/admin/affiliates/${id}/links/{linkId}`
      : `/links/{linkId}`,
    error,
    total,
    totals,
    searchValue: query,
    brand,
    activities: results,
    handleSearch: handleSearchQuery,
    handleBrand: handleBrandFilter
  };
}
