import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    table: {
      width: "100%",
      height: "calc(100vh - 196px)"
    },
    playerColumn: {
      fontWeight: 500,
      minWidth: 68,
      maxWidth: 96
    },
    numberColumn: {
      minWidth: 88,
      maxWidth: 96,
      textAlign: "left"
    },
    countryColumn: {
      maxWidth: 52
    },
    dealColumn: {
      fontWeight: 500,
      minWidth: 256,
      paddingRight: 8
    },
    brandColumn: {
      minWidth: 96,
      maxWidth: 128
    },
    dateColumn: {
      maxWidth: 128,
      whiteSpace: "nowrap"
    }
  })
);

export { useStyles };
