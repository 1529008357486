import {
  Plan,
  PlanAffiliate,
  PlanResponse,
  PlanRule,
  CreatePlanRequest,
  CreatePlanValues
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { fetchPlans } from "modules/plans";

interface AffiliatePlan extends Plan {
  brand?: string;
}

enum PlanActionTypes {
  CLEAR_STATE = "@plan/clear-state",
  PLAN_LOADING = "@plan/plan-loading",
  FETCH_PLAN_SUCCESS = "@plan/fetch-plan-success",
  FETCH_PLAN_FAILURE = "@plan/fetch-plan-failure",
  UPDATE_PLAN_SUCCESS = "@plan/update-plan-success",
  CREATE_PLAN_SUCCESS = "@plan/create-plan-success",
  DELETE_PLAN_SUCCESS = "@plan/delete-plan-success",
  ARCHIVE_PLAN_SUCCESS = "@plan/archive-plan-success"
}

interface PlanIsLoadingAction {
  type: PlanActionTypes.PLAN_LOADING;
}

interface FetchPlanIsSuccessAction {
  type: PlanActionTypes.FETCH_PLAN_SUCCESS;
  payload: PlanResponse;
}

interface FetchPlanIsFailureAction {
  type: PlanActionTypes.FETCH_PLAN_FAILURE;
}

interface UpdatePlanIsSuccessAction {
  type: PlanActionTypes.UPDATE_PLAN_SUCCESS;
  payload: PlanResponse;
}

interface CreatePlanIsSuccessAction {
  type: PlanActionTypes.CREATE_PLAN_SUCCESS;
}

interface DeletePlanIsSuccessAction {
  type: PlanActionTypes.DELETE_PLAN_SUCCESS;
}

interface ArchivePlanIsSuccessAction {
  type: PlanActionTypes.ARCHIVE_PLAN_SUCCESS;
}

interface ClearStateAction {
  type: PlanActionTypes.CLEAR_STATE;
}

type PlanActions =
  | ClearStateAction
  | PlanIsLoadingAction
  | FetchPlanIsSuccessAction
  | FetchPlanIsFailureAction
  | UpdatePlanIsSuccessAction
  | CreatePlanIsSuccessAction
  | DeletePlanIsSuccessAction
  | ArchivePlanIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<PlanActions | DrawersActions>
>;

const planIsIsLoading = (): PlanIsLoadingAction => ({
  type: PlanActionTypes.PLAN_LOADING
});

const fetchPlanIsSuccess = (plan: PlanResponse): FetchPlanIsSuccessAction => ({
  type: PlanActionTypes.FETCH_PLAN_SUCCESS,
  payload: { ...plan }
});

const fetchPlanIsFailure = (): FetchPlanIsFailureAction => ({
  type: PlanActionTypes.FETCH_PLAN_FAILURE
});

const updatePlanIsSuccess = (
  plan: PlanResponse
): UpdatePlanIsSuccessAction => ({
  type: PlanActionTypes.UPDATE_PLAN_SUCCESS,
  payload: { ...plan }
});

const createPlanIsSuccess = (): CreatePlanIsSuccessAction => ({
  type: PlanActionTypes.CREATE_PLAN_SUCCESS
});

const deletePlanIsSuccess = (): DeletePlanIsSuccessAction => ({
  type: PlanActionTypes.DELETE_PLAN_SUCCESS
});

const archivePlanIsSuccess = (): ArchivePlanIsSuccessAction => ({
  type: PlanActionTypes.ARCHIVE_PLAN_SUCCESS
});

const clearPlanState = (): ClearStateAction => ({
  type: PlanActionTypes.CLEAR_STATE
});

const fetchPlan =
  (id: number): ThunkActionCreator<PlanActions> =>
  async dispatch => {
    dispatch(planIsIsLoading());
    try {
      const plan = await api.admin.plans.getPlan(id);
      dispatch(fetchPlanIsSuccess(plan));
    } catch (err) {
      dispatch(fetchPlanIsFailure());
    }
  };

const updatePlan =
  (
    planId: number,
    data: CreatePlanRequest,
    formikHelpers: FormikHelpers<CreatePlanValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const plan = await api.admin.plans.updatePlan(planId, data);
      dispatch(openSnackbar({ type: "success", message: "Plan updated!" }));
      dispatch(updatePlanIsSuccess(plan));
      dispatch(fetchPlans());
      dispatch(fetchPlan(planId));
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const createPlan =
  (
    data: CreatePlanRequest,
    formikHelpers: FormikHelpers<CreatePlanValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.plans.createPlan(data);
      dispatch(openSnackbar({ type: "success", message: "Plan created!" }));
      dispatch(createPlanIsSuccess());
      dispatch(fetchPlans());
      formikHelpers.resetForm();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const deletePlan =
  (id: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.plans.deletePlan(id);
    dispatch(openSnackbar({ type: "success", message: "Plan removed!" }));
    dispatch(deletePlanIsSuccess());
    dispatch(fetchPlans());
  };

const archivePlan =
  (id: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    const { plan, rules } = await api.admin.plans.getPlan(id);
    await api.admin.plans.updatePlan(id, {
      plan: { ...plan, archived: true },
      rules
    });
    dispatch(openSnackbar({ type: "success", message: "Plan archived!" }));
    dispatch(archivePlanIsSuccess());
    dispatch(fetchPlans());
  };

interface PlanState {
  error: boolean;
  isLoading: boolean;
  details?: AffiliatePlan;
  rules: PlanRule[];
  affiliates: PlanAffiliate[];
}

const initialState: PlanState = {
  error: false,
  isLoading: false,
  details: undefined,
  rules: [],
  affiliates: []
};

const reducer: Reducer<PlanState, PlanActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PlanActionTypes.PLAN_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case PlanActionTypes.FETCH_PLAN_SUCCESS:
    case PlanActionTypes.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        details: action.payload.plan,
        rules: action.payload.rules,
        affiliates: action.payload.affiliates
          ? action.payload.affiliates
          : state.affiliates
      };
    case PlanActionTypes.FETCH_PLAN_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case PlanActionTypes.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

const getPlanState = (state: AppState) => ({
  ...state.plan,
  details: state.plan.details! as Plan,
  rules: state.plan.rules as PlanRule[]
});

export {
  reducer,
  fetchPlan,
  updatePlan,
  createPlan,
  deletePlan,
  archivePlan,
  getPlanState,
  clearPlanState
};
