import { IsMulti, SelectOption } from "@types";

import React, { FC } from "react";
import Select, { GroupBase, Props } from "react-select";
import Creatable, { CreatableProps } from "react-select/creatable";

import {
  Control,
  CreateLabel,
  DropdownIndicator,
  Menu,
  Option,
  MultiValueLabel,
  NoOptionsMessage
} from "./Components";
import { selectStyles, useSelectStyles } from "./styles";

const baseComponents = {
  Control,
  Menu,
  Option,
  DropdownIndicator,
  NoOptionsMessage,
  MultiValueLabel
};

type SelectInputProps = Props<SelectOption, IsMulti> & {
  error?: boolean;
  startAdornment?: JSX.Element;
  helperText?: string;
};

type CreatableSelectProps = CreatableProps<
  SelectOption,
  IsMulti,
  GroupBase<SelectOption>
> & {
  error?: boolean;
  startAdornment?: JSX.Element;
  helperText?: string;
  createLabel?: string;
};

const SelectInput: FC<SelectInputProps> = props => {
  const classes = useSelectStyles();

  return (
    <Select
      {...props}
      menuPlacement="auto"
      classes={classes}
      styles={selectStyles}
      components={baseComponents}
    />
  );
};

const CreatableSelect: FC<CreatableSelectProps> = ({
  createLabel,
  ...rest
}) => {
  const classes = useSelectStyles();

  return (
    <Creatable
      {...rest}
      classes={classes}
      components={baseComponents}
      styles={selectStyles}
      formatCreateLabel={value => (
        <CreateLabel value={value} label={createLabel} />
      )}
    />
  );
};

export { CreatableSelect };
export default SelectInput;
