import React, { FC } from "react";
import { useField } from "formik";
import { OutlinedInputProps } from "@material-ui/core";
import Input from "@material-ui/core/TextField";

import {
  isNegativeNumber,
  isFloatingNumber,
  isNumber,
  isPhone,
  isNegativeFloatingNumber
} from "utils/regexes";

interface Props {
  label?: string;
  name: string;
  type?: string;
  numeric?: boolean;
  negative?: boolean;
  disabled?: boolean;
  className?: string;
  rows?: number;
  rowsMax?: number;
  multiline?: boolean;
  placeholder?: string;
  InputProps?: Partial<OutlinedInputProps>;
  style?: React.CSSProperties;
}

const TextField: FC<Props> = ({ numeric, negative, type, ...props }) => {
  const [field, meta] = useField(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numeric && negative) {
      return isNegativeNumber.test(event.target.value) && field.onChange(event);
    }
    if (numeric) {
      return isNumber.test(event.target.value) && field.onChange(event);
    }
    if (type === "amount" && negative && event.target.value) {
      return (
        isNegativeFloatingNumber.test(event.target.value) &&
        field.onChange(event)
      );
    }
    if (type === "amount" && event.target.value) {
      return isFloatingNumber.test(event.target.value) && field.onChange(event);
    }
    if (type === "tel" && event.target.value) {
      return isPhone.test(event.target.value) && field.onChange(event);
    }
    return field.onChange(event);
  };

  return (
    <Input
      {...field}
      {...props}
      margin="normal"
      type={type}
      value={field.value ? field.value : ""}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      onChange={handleChange}
    />
  );
};

export default TextField;
