import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

const PDFIcon: FC<SvgIconProps> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.00065 1.6665C4.08877 1.6665 3.33398 2.42129 3.33398 3.33317V16.6665C3.33398 17.5784 4.08877 18.3332 5.00065 18.3332H15.0007C15.9125 18.3332 16.6673 17.5784 16.6673 16.6665V6.6665L11.6673 1.6665H5.00065ZM5.00065 3.33317H10.834V7.49984H15.0007V16.6665H5.00065V3.33317ZM9.86068 7.49984C9.40234 7.49984 9.05904 7.81104 8.99154 8.3527C8.92487 8.89354 9.19298 9.83928 9.66048 10.7176C9.45965 11.3259 9.25904 11.8662 8.99154 12.4071C8.12237 12.6771 7.32049 13.082 6.98633 13.4878C6.45216 14.0953 6.71953 14.5011 6.85286 14.7036C6.98703 14.9061 7.18758 14.9998 7.45508 14.9998C7.58924 14.9998 7.72293 14.9729 7.8571 14.9054C8.3921 14.7029 8.92529 13.8916 9.46029 12.9458C9.92779 12.8108 10.3958 12.6764 10.8633 12.6089C11.3308 13.1489 11.8003 13.4879 12.2012 13.6229C12.6687 13.7579 13.0697 13.5542 13.2705 13.0809C13.4038 12.6751 13.3361 12.3386 13.002 12.1353C12.6003 11.8653 11.9319 11.8643 11.1302 11.9318C10.8627 11.5268 10.597 11.1218 10.3962 10.716C10.7303 9.70265 10.8632 8.82518 10.7298 8.28434C10.5957 7.81101 10.319 7.49984 9.86068 7.49984Z" />
  </svg>
);

export default PDFIcon;
