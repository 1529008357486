import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDialogState, closeDialog, DialogPayload } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialog, payload } = useSelector(getDialogState);
  const [localContent, setLocalContent] = useState<DialogPayload>();

  useEffect(() => {
    if (dialog !== undefined) {
      setLocalContent(payload);
    }
  }, [dialog, payload]);

  const isDelete = localContent?.type === "delete";
  const isSubmit = localContent?.type === "submit";

  const handleClose = useCallback(() => {
    localContent?.onCancel && localContent.onCancel();
    dispatch(closeDialog());
  }, [dispatch, localContent]);

  const handleConfirm = useCallback(() => {
    localContent?.onConfirm();
    handleClose();
  }, [localContent, handleClose]);

  return {
    classes,
    isDelete,
    isSubmit,
    dialog,
    state: localContent,
    handleClose,
    handleConfirm
  };
}
