import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "redux";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CssBaseline from "@material-ui/core/CssBaseline";

import { AppState } from "store";
import theme from "theme";
import Routes from "routes";
import Drawers from "drawers";
import Dialog from "modules/dialog";
import Snackbar from "modules/snackbar";
import { GoogleAuthProvider } from "modules/google-auth";
import { ROUTE_BASENAME } from "constants/variables";
interface Props {
  store: Store<AppState>;
}

const App: FC<Props> = ({ store }) => (
  <GoogleAuthProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <BrowserRouter basename={ROUTE_BASENAME}>
            <Drawers />
            <Snackbar />
            <Dialog />
            <Routes />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </GoogleAuthProvider>
);

export default App;
