import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { getDatePickerState } from "modules/datepicker";

interface Params {
  id: string;
  tab: string;
  year: string;
  month: string;
}

export default function (affiliateId?: number): string {
  const { id, tab, year: paramsYear, month: paramsMonth } = useParams<Params>();
  const { year, month } = useSelector(getDatePickerState);

  if (!affiliateId && paramsYear && paramsMonth) {
    return `/admin/affiliates/${id}/${tab}/${year}/${month}`;
  } else if (!affiliateId && !paramsYear && !paramsMonth) {
    return `/admin/affiliates/${id}/${tab}`;
  } else if (
    affiliateId &&
    (tab === "overview" || tab === "subs" || tab === "revenue")
  ) {
    return `/admin/affiliates/${affiliateId}/${tab}/${year}/${month}`;
  } else if (affiliateId && !tab) {
    return `/admin/affiliates/${affiliateId}/overview/${year}/${month}`;
  } else if (affiliateId) {
    return `/admin/affiliates/${affiliateId}/${tab}`;
  }

  return `/admin/affiliates/${id}/${tab}`;
}
