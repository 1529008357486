import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import omit from "lodash/omit";

import { openDrawer, closeDrawer, getDrawersState } from "drawers";
import { BRANDS } from "constants/brands";
import {
  getAffiliateFeesStateById,
  updateAffiliateFees
} from "modules/affiliate-fees";
import { CreateAffiliateFeesValues } from "@types";
import { FormikHelpers } from "formik";
import formatToRequest from "./helpers/formatToRequest";

interface MatchParams {
  id: string;
  year: string;
  month: string;
  brandId: BRANDS;
}

export default function () {
  const dispatch = useDispatch();
  const { id, brandId } = useParams<MatchParams>();

  const { isLoading, error, affiliateFees } = useSelector(
    getAffiliateFeesStateById
  )(brandId);
  const { isOpen } = useSelector(getDrawersState);

  useEffect(() => {
    return () => {
      isOpen && dispatch(closeDrawer());
    };
  }, [dispatch, isOpen]);

  const handleChangeAffiliateFeesSubmit = useCallback(
    (
      values: CreateAffiliateFeesValues,
      helpers: FormikHelpers<CreateAffiliateFeesValues>
    ) => {
      const data = formatToRequest(values);
      dispatch(updateAffiliateFees(data, helpers));
    },
    [dispatch]
  );

  const handleChangeAffiliateFeeOpen = useCallback(() => {
    dispatch(
      openDrawer("change-affiliate-fee", {
        initialState: {
          brandId,
          affiliateId: Number(id),
          fees: affiliateFees
        },
        handleSubmit: handleChangeAffiliateFeesSubmit
      })
    );
  }, [affiliateFees, brandId, dispatch, handleChangeAffiliateFeesSubmit, id]);

  const affiliateFee = affiliateFees.find(({ isRunning }) => isRunning);
  return {
    error,
    affiliateFee: omit(affiliateFee, ["rules", "brandId"]),
    rules: affiliateFee ? affiliateFee.rules : [],
    isLoading,
    brand: brandId as BRANDS,
    handleChangeAffiliateFeeOpen
  };
}
