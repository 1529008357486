import {
  CreateSubAffiliateValues,
  AffiliateResponse,
  CreateSubAccountRequest
} from "@types";

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";

import { useAnchor } from "hooks";
import { openDrawer, closeDrawer } from "drawers";
import { openDialog } from "modules/dialog";
import { getDatePickerState } from "modules/datepicker";
import { startAdminViewMode } from "modules/view-mode";
import { addAffiliateSubAffiliate } from "modules/affiliate-sub-afiiliates";

import {
  createAffiliateSubAccount,
  closeAffiliateAccount,
  getAffiliateState
} from "./duck";
import { useStyles } from "./styles";

interface Params {
  id: string;
  tab: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, tab } = useParams<Params>();
  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();
  const {
    isLoading,
    error,
    data: affiliate,
    subaccounts
  } = useSelector(getAffiliateState);
  const { year, month } = useSelector(getDatePickerState);

  const isCalendarTab = tab === "overview" || tab === "revenue";
  const datePickerUrl = `/admin/affiliates/${id}/${tab}`;

  const handleEditDrawer = useCallback(() => {
    handleClosePopup();

    dispatch(openDrawer("edit-affiliate", { affiliateId: id }));
  }, [handleClosePopup, dispatch, id]);

  const handleAddSubAffiliate = useCallback(
    (
      { subId, commissionShare }: CreateSubAffiliateValues,
      helpers: FormikHelpers<CreateSubAffiliateValues>
    ) => {
      dispatch(
        addAffiliateSubAffiliate(
          Number(subId),
          { commissionShare: Number(commissionShare) },
          { affiliateId: Number(id), year: Number(year), month: Number(month) },
          helpers
        )
      );
    },
    [dispatch, id, year, month]
  );

  const handleSubAffiliateDrawer = useCallback(() => {
    handleClosePopup();
    dispatch(
      openDrawer("add-subaffiliate", {
        affiliateId: id,
        onSubmit: handleAddSubAffiliate
      })
    );
  }, [dispatch, id, handleClosePopup, handleAddSubAffiliate]);

  const handleViewMode = useCallback(() => {
    const backURL = history.location.pathname;
    handleClosePopup();
    dispatch(startAdminViewMode(Number(id), backURL));
    history.push(`/overview/${year}/${month}`);
  }, [dispatch, handleClosePopup, history, id, month, year]);

  const handleCreateSubAccount = useCallback(
    (
      values: CreateSubAccountRequest,
      helpers: FormikHelpers<CreateSubAccountRequest>
    ) => {
      dispatch(
        createAffiliateSubAccount(
          Number(affiliate?.affiliateId),
          values,
          helpers
        )
      );
    },
    [dispatch, affiliate]
  );

  const handleCreateSubAccountDrawer = useCallback(() => {
    dispatch(
      openDrawer("create-subaccount", {
        handleSubmit: handleCreateSubAccount
      })
    );
    handleClosePopup();
  }, [dispatch, handleClosePopup, handleCreateSubAccount]);

  const handleSubaccountsChange = useCallback(
    (id: number) => {
      history.push(`/admin/affiliates/${id}/overview/${year}/${month}`);
      dispatch(closeDrawer());
    },
    [dispatch, history, year, month]
  );

  const handleSubaccountsDrawer = useCallback(() => {
    dispatch(
      openDrawer("subaccounts", {
        handleListChange: handleSubaccountsChange
      })
    );
  }, [dispatch, handleSubaccountsChange]);

  const handleCloseAccount = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "close-account",
        payload: {
          type: "submit",
          title: "Are you sure you want to close affiliate account?",
          subtitle: "This operation cannot be undone",
          onConfirm: () => dispatch(closeAffiliateAccount(Number(id)))
        }
      })
    );
    handleClosePopup();
  }, [id, dispatch, handleClosePopup]);

  return {
    classes,
    isLoading,
    isCalendarTab,
    affiliate: affiliate as AffiliateResponse,
    error,
    datePickerUrl,
    menuAnchor: anchorEl,
    handleViewMode,
    handleEditDrawer,
    handleCloseAccount,
    handleSubAffiliateDrawer,
    handleCreateSubAccountDrawer,
    handleMenuOpen: handleOpenPopup,
    handleMenuClose: handleClosePopup,
    handleSubaccountsDrawer: !isEmpty(subaccounts)
      ? handleSubaccountsDrawer
      : undefined
  };
}
