import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: "100%"
    },
    titleContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    },
    masterProfile: {
      display: "flex",
      flexDirection: "column",
      margin: -16,
      alignItems: "flex-start",
      borderRadius: 8,
      padding: 16,
      transition: "background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        background: theme.colors.blackf5
      }
    },
    label: {
      fontSize: 14,
      lineHeight: "24px",
      textTransform: "none"
    },
    subtitle: {
      textTransform: "none",
      marginTop: 2
    },
    buttons: {
      display: "flex",
      alignSelf: "flex-end"
    },
    actionButton: {
      margin: "0 0 0 8px"
    },
    emptyData: {
      marginBottom: 0
    }
  })
);

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 56,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden"
    },
    loginDate: {
      margin: 0
    },
    name: {
      margin: 0,
      marginTop: 2
    },
    buttons: {
      display: "flex",
      alignSelf: "flex-end"
    },
    button: {
      margin: 0,
      borderRadius: 17
    }
  })
);

export { useStyles, useSkeletonStyles };
