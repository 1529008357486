import { UpdateAffiliatePaymentInformation } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbolRounded";

import { getPaymentName } from "utils/payments";
import { PAYMENT_METHODS_OPTIONS, PAYMENT_METHODS } from "constants/payments";
import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";
import { BankForm, SkrillForm, CasinoForm } from "components/account-forms";

import validationSchema from "./validationSchema";

export interface EditPaymentProps {
  initialState: UpdateAffiliatePaymentInformation;
  onSubmit: (
    values: UpdateAffiliatePaymentInformation,
    helpers: FormikHelpers<UpdateAffiliatePaymentInformation>
  ) => void;
}

const EditPayment: FC<EditPaymentProps> = ({ initialState, onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    initialValues={initialState}
  >
    {({ values: { paymentMethod }, handleSubmit }) => (
      <form className="drawer-container" onSubmit={handleSubmit}>
        <Box className="drawer-header">
          <Typography variant="h4" className="drawer-header_title">
            Edit Payment Information
          </Typography>
          <SubmitButton>save changes</SubmitButton>
        </Box>
        <SelectField
          label="Payment Method"
          name="paymentMethod"
          isSearchable={false}
          placeholder="Select Payment Method"
          value={{
            label: getPaymentName(paymentMethod),
            value: paymentMethod
          }}
          options={PAYMENT_METHODS_OPTIONS}
        />
        {paymentMethod === PAYMENT_METHODS.bank && <BankForm />}
        {paymentMethod === PAYMENT_METHODS.skrill && <SkrillForm />}
        {paymentMethod === PAYMENT_METHODS.casino && <CasinoForm />}
        <TextField
          numeric
          name="paymentMinAmount"
          label="Minimum Payment Amount"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EuroSymbolIcon />
              </InputAdornment>
            )
          }}
        />
      </form>
    )}
  </Formik>
);

export default EditPayment;
