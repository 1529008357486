import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      "& .drawer-container": {
        width: "100%",
        padding: 16,
        maxWidth: 380
      },
      "& .drawer-header": {
        padding: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.colors.blue,
        [theme.breakpoints.down("sm")]: {
          padding: 0,
          paddingLeft: 42,
          minHeight: 32
        }
      },
      "& .drawer-header_title": {
        maxWidth: "50%",
        color: theme.colors.blue,
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
          lineHeight: "24px"
        }
      },
      "& .drawer-header_subtitle": {
        color: theme.colors.black75,
        fontSize: 12,
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
          lineHeight: "18px"
        }
      },
      "& .no-data": {
        padding: 32
      }
    },
    root: {},
    paper: {
      maxHeight: "calc(100vh - 8px)",
      background: theme.colors.white,
      margin: 4,
      minWidth: 380,
      maxWidth: 896,
      borderRadius: 8,
      [theme.breakpoints.down("sm")]: {
        minWidth: 312,
        maxWidth: 312
      }
    },
    closeButton: {
      position: "absolute",
      top: 4,
      left: 4,
      width: 48,
      height: 48,
      margin: 2,
      color: theme.colors.black9e,
      "&:hover": {
        color: theme.colors.blue
      }
    }
  })
);

export { useStyles };
