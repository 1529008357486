import { ListAffiliate, CreateFeeValues } from "@types";

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import { isWithinInterval } from "date-fns";

import { useSearch } from "hooks";
import getGroupedFeeRules from "utils/getGroupedFeeRules";
import { getDatePickerState } from "modules/datepicker";
import { openDrawer, closeDrawer, getDrawersState } from "drawers";

import {
  getFeeState,
  fetchFee,
  clearFeeState,
  updateFee,
  setFeeRunningAffiliateFilter
} from "./duck";
import formatFeeToRequest from "./helpers/formatToRequest";
import { useStyles } from "./styles";

interface MatchParams {
  adminFeeId: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { adminFeeId } = useParams<MatchParams>();
  const history = useHistory();

  const { isOpen } = useSelector(getDrawersState);
  const {
    error,
    isLoading,
    details,
    rules,
    affiliates,
    filterRunningAffiliates
  } = useSelector(getFeeState);

  const { year, month } = useSelector(getDatePickerState);

  const displayedAffiliates =
    filterRunningAffiliates || details?.draftRemovedAt
      ? // TODO 456 can probably avoid the interval check here and use isRunning from API
        affiliates.filter(({ brands }) => {
          return brands.some(({ periodFrom, periodTo }) => {
            return isWithinInterval(new Date(), {
              start: new Date(periodFrom),
              end: new Date(periodTo)
            });
          });
        })
      : affiliates;

  const { query, setQuery, results } = useSearch<ListAffiliate>(
    ["affiliateName", "affiliateId", "affiliateEmail"],
    displayedAffiliates
  );

  const handleResetSearch = useCallback(() => setQuery(""), [setQuery]);

  const handleListChange = useCallback(
    (id: number) =>
      history.push(`/admin/affiliates/${id}/overview/${year}/${month}`),
    [history, year, month]
  );

  const handleEditFee = useCallback(
    (values: CreateFeeValues, helpers: FormikHelpers<CreateFeeValues>) => {
      const data = formatFeeToRequest(values);
      dispatch(updateFee(Number(adminFeeId), data, helpers));
    },
    [dispatch, adminFeeId]
  );

  const handleChangeButton = useCallback(() => {
    dispatch(
      openDrawer("edit-fee", {
        title: "Edit Fee",
        initialState: {
          fee: {
            name: details.name,
            percent: details.nextMonthPercent || details.percent,
            active: details.active,
            isRunning: details.isRunning
          },
          rules: getGroupedFeeRules(
            rules
              .filter(({ nextMonthPercent }) => nextMonthPercent !== null)
              .map(({ nextMonthPercent, ...r }) => ({
                ...r,
                percent: nextMonthPercent || r.percent
              }))
          )
        },
        handleSubmit: handleEditFee
      })
    );
  }, [details, dispatch, handleEditFee, rules]);

  const handleFilterRunningAffiliates = useCallback(
    (filterRunningAffiliates: boolean) => {
      dispatch(setFeeRunningAffiliateFilter(filterRunningAffiliates));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchFee(Number(adminFeeId)));

    return () => {
      dispatch(clearFeeState());
    };
  }, [dispatch, adminFeeId]);

  useEffect(() => {
    return () => {
      if (isOpen) dispatch(closeDrawer());
    };
  }, [dispatch, isOpen]);

  return {
    classes,
    error,
    isLoading,
    fee: details,
    rules,
    filterRunningAffiliates,
    searchQuery: query,
    affiliates: results,
    handleSearch: setQuery,
    handleFilterRunningAffiliates,
    handleResetSearch,
    handleListChange,
    handleChangeButton
  };
}
