import React, { FC, CSSProperties } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import RemoveIcon from "@material-ui/icons/ClearRounded";
import Skeleton from "@material-ui/lab/Skeleton";

import { useListItemStyles, useListItemSkeletonStyles } from "../styles";

interface Props {
  id: number;
  url: string;
  style: CSSProperties;
  title: string;
  selectedAffiliate?: number;
  onRemove?: (id: number) => void;
  onChange: (id: number) => void;
}

const ListItemComponent: FC<Props> = ({
  id,
  url,
  title,
  style,
  selectedAffiliate,
  onRemove,
  onChange
}) => {
  const classes = useListItemStyles();
  const selected = selectedAffiliate === id;

  return (
    <Box style={style} className={classes.container}>
      <ListItem
        button
        disableRipple
        selected={selected}
        classes={{
          root: classes.root
        }}
        component={Link}
        to={url}
        onClick={() => onChange(id)}
      >
        {selected && <Box className={classes.indicator} />}
        <ListItemText
          primary={<Typography className={classes.title}>{title}</Typography>}
          secondary={
            <Typography className={classes.subtitle}>{`id: ${id}`}</Typography>
          }
        />
      </ListItem>
      {onRemove && (
        <ListItemSecondaryAction
          className={cn(classes.iconButton, {
            [classes.iconButtonVisible]: selected
          })}
        >
          <IconButton onClick={() => onRemove(id)}>
            <RemoveIcon className={classes.removeIcon} />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </Box>
  );
};

const ListItemSkeleton: FC = () => {
  const classes = useListItemSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Skeleton className={classes.title} width={256} />
      <Skeleton className={classes.subtitle} width={128} />
    </Box>
  );
};

export { ListItemSkeleton };
export default ListItemComponent;
