import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/SwipeableDrawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MenuRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";

import Logo from "assets/icons/Logo";
import UserBalance from "components/user-balance";
import { getDatePickerState } from "modules/datepicker";

import Menu from "./Menu";
import Profile from "./Profile";
import { useMobileStyles } from "../styles";

interface Props {
  isViewMode: boolean;
  showSubs: boolean;
  handleLogout: () => void;
  handleStopAffiliateViewMode?: () => void;
}

const MobileSidebar: FC<Props> = ({
  isViewMode,
  showSubs,
  handleLogout,
  handleStopAffiliateViewMode
}) => {
  const classes = useMobileStyles();

  const { year, month } = useSelector(getDatePickerState);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleMobileOpen = () => setMobileOpen(!mobileOpen);
  const handleMenuItem = () => handleMobileOpen();

  return (
    <Hidden lgUp implementation="css">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.iconButton}
            onClick={handleMobileOpen}
          >
            <MenuIcon />
          </IconButton>
          <Link to={`/overview/${year}/${month}`} className={classes.logo}>
            <Logo />
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleMobileOpen}
        onOpen={handleMobileOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <Box className={classes.drawerContent}>
          <Box className={classes.drawerContentHeader}>
            <IconButton
              className={classes.iconButton}
              onClick={handleMobileOpen}
            >
              <CloseIcon />
            </IconButton>
            <Link to={`/overview/${year}/${month}`} className={classes.logo}>
              <Logo />
            </Link>
          </Box>
          <Profile />
          <Menu
            isViewMode={isViewMode}
            showSubs={showSubs}
            handleMenuItem={handleMenuItem}
            handleLogoutSubmit={handleLogout}
          />
        </Box>
        <Box className={classes.drawerContentFooter}>
          {handleStopAffiliateViewMode && (
            <Button
              onClick={handleStopAffiliateViewMode}
              className={classes.stopViewModeBtn}
            >
              Back to master account
            </Button>
          )}
          <UserBalance className={classes.userBalance} />
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default MobileSidebar;
