import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex"
    },
    link: {
      padding: "2px 3px 2px 4px",
      borderRadius: 2,
      background: theme.colors.teal50,
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.teal
    },
    tooltip: {
      display: "flex",
      overflow: "hidden"
    },
    iconColumn: {
      maxWidth: 46
    }
  })
);

export { useStyles };
