import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { TextCardSkeleton } from "components/cards";

import { useSkeletonStyles } from "./styles";

const CARDS_COUNT = Array.from(Array(4));

const LinkInfoSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <>
      <Box className={classes.linkTitle}>
        <Skeleton width={150} height={32} />
        <Skeleton width={88} height={30} className={classes.button} />
      </Box>
      <Box className={classes.linkInfo}>
        {CARDS_COUNT.map((_, index) => (
          <TextCardSkeleton key={index} width={64} className={classes.textCard}>
            <Skeleton className={classes.text} />
          </TextCardSkeleton>
        ))}
      </Box>
    </>
  );
};

export default LinkInfoSkeleton;
