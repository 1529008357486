import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import {
  fetchAffiliateViewProfile,
  fetchAffiliateViewSubAccounts
} from "modules/affiliate";
import { getAuthState, logOut } from "modules/authentication";
import { getDatePickerState } from "modules/datepicker";
import {
  getViewModeState,
  stopAdminViewMode,
  stopAffiliateViewMode
} from "modules/view-mode";
import { getAffiliateState } from "modules/affiliate";
import {
  getAffiliateSubAffiliatesState,
  fetchAffiliateViewSubAffiliates
} from "modules/affiliate-sub-afiiliates";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();

  const { year, month } = useSelector(getDatePickerState);
  const { userId } = useSelector(getAuthState);
  const { accountId, affiliateId, backUrl } = useSelector(getViewModeState);
  const { data } = useSelector(getAffiliateSubAffiliatesState);
  const { data: affiliateProfile } = useSelector(getAffiliateState);

  useEffect(() => {
    dispatch(fetchAffiliateViewProfile());
    dispatch(fetchAffiliateViewSubAccounts());
  }, [dispatch, userId]);

  useEffect(() => {
    if (!history.location.pathname.includes("subs")) {
      dispatch(fetchAffiliateViewSubAffiliates(Number(year), Number(month)));
    }
  }, [dispatch, history.location.pathname, year, month]);

  const handleStopAdminViewMode = useCallback(() => {
    dispatch(stopAdminViewMode());
    history.push(backUrl);
  }, [backUrl, dispatch, history]);

  const handleStopAffiliateViewMode = useCallback(() => {
    dispatch(stopAffiliateViewMode());
    window.location.reload();
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    history.push("/login");
  }, [dispatch, history]);

  return {
    isEmpty: isEmpty(data),
    isViewMode: !!affiliateId,
    accountBalance: affiliateProfile?.accountBalance,
    paymentMinAmount: affiliateProfile?.paymentMinAmount,
    handleLogout,
    handleStopAdminViewMode: affiliateId ? handleStopAdminViewMode : undefined,
    handleStopAffiliateViewMode: accountId
      ? handleStopAffiliateViewMode
      : undefined
  };
}
