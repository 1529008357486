import { makeStyles, createStyles } from "@material-ui/core/styles";

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column"
    },
    brand: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    name: {
      margin: "16px 8px",
      width: 180,
      height: 32
    },
    button: {
      width: 20,
      height: 20
    }
  })
);

export { useSkeletonStyles };
