import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "theme";

const text = {
  fontSize: 12,
  fontWeight: 400,
  fontFamily: "Rubik"
};

const styles = StyleSheet.create({
  body: {
    position: "relative",
    paddingTop: 32,
    paddingBottom: 64,
    paddingHorizontal: 64,
    fontFamily: "Rubik"
  },
  watermark: {
    position: "absolute",
    top: "25%",
    left: "4%",
    width: 557
  },
  logoWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 32
  },
  logo: {
    width: 96
  },
  title: {
    ...text,
    fontSize: 18,
    fontWeight: 500,
    textAlign: "left"
  },
  info: {
    display: "flex",
    flexDirection: "column"
  },
  infoBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  affiliateName: {
    ...text,
    fontWeight: 500,
    margin: "2px 0"
  },
  affiliateAddress: {
    ...text,
    margin: "2px 0"
  },
  affiliateVatNumber: {
    ...text,
    display: "flex",
    flexDirection: "row",
    margin: "2px 0 0 0"
  },
  affiliateVatNumberLabel: {},
  affiliateVatNumberValue: {
    marginLeft: 4,
    fontWeight: 500
  },
  companyName: {
    ...text,
    margin: "2px 0"
  },
  companyAddress1: {
    ...text,
    margin: "2px 0"
  },
  companyAddress2: {
    ...text,
    margin: "2px 0"
  },
  companyVatNumber: {
    ...text,
    display: "flex",
    flexDirection: "row",
    margin: "2px 0 0 0"
  },
  companyVatNumberLabel: {},
  companyVatNumberValue: {
    marginLeft: 4,
    fontWeight: 500
  },
  invoice: {
    width: "100%",
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  invoiceDate: {
    ...text,
    display: "flex",
    flexDirection: "row",
    margin: "2px 0"
  },
  invoiceDateLabel: {},
  invoiceDateValue: {
    marginLeft: 4,
    fontWeight: 500
  },
  invoiceNumber: {
    ...text,
    display: "flex",
    flexDirection: "row",
    margin: "2px 0"
  },
  invoiceNumberLabel: {},
  invoiceNumberValue: {
    marginLeft: 4,
    fontWeight: 500
  },
  table: {
    ...text,
    marginTop: 32,
    width: "100%",
    borderRadius: 4,
    border: `1pt solid ${colors.black21}`
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1pt solid ${colors.black21}`
  },
  tableContent: {
    width: "100%"
  },
  row: {
    display: "flex",
    flexDirection: "column"
  },
  firstSubRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  secondSubRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1pt solid ${colors.black21}`
  },
  descriptionColumn: {
    width: "80%",
    padding: 4,
    display: "flex",
    textAlign: "left",
    borderRight: `1pt solid ${colors.black21}`
  },
  amountColumn: {
    width: "20%",
    padding: 4,
    display: "flex",
    textAlign: "right"
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  paymentMethod: {
    ...text,
    marginTop: 32
  },
  paymentMethodTitle: {
    textAlign: "left"
  },
  paymentMethodDetails: {
    marginTop: 4,
    marginLeft: 32,
    display: "flex",
    flexDirection: "column"
  },
  paymentMethodDetailsText: {
    ...text,
    display: "flex",
    flexDirection: "row"
  },
  paymentMethodDetailsLabel: {},
  paymentMethodDetailsValue: {
    marginLeft: 4,
    fontWeight: 500
  },
  note: {
    marginTop: 32
  },
  noteText: {
    ...text
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: colors.black9e
  }
});

export default styles;
