import {
  Overview,
  OverviewAffiliate,
  OverviewAffiliateTotals,
  OverviewRequest
} from "@types";

import { Reducer } from "redux";

import api from "api";
import { BRANDS } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";

enum OverviewActionTypes {
  FETCH_OVERVIEW_LOADING = "@overview/fetch-overview-loading",
  FETCH_OVERVIEW_SUCCESS = "@overview/fetch-overview-success",
  FETCH_OVERVIEW_FAILURE = "@overview/fetch-overview-failure",
  SET_OVERVIEW_BRAND_FILTER = "@overview/set-brand-filter",
  SET_INCLUDE_INTERNALS = "@overview/set-include-internals"
}

interface FetchOverviewIsLoadingAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_LOADING;
}

interface FetchOverviewIsSuccessAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_SUCCESS;
  payload: Overview;
}

interface FetchOverviewIsFailureAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_FAILURE;
}

interface SetOverviewBrandFilterAction {
  type: OverviewActionTypes.SET_OVERVIEW_BRAND_FILTER;
  payload: BRANDS | null;
}

interface SetIncludeInternalsAction {
  type: OverviewActionTypes.SET_INCLUDE_INTERNALS;
}

type OverviewActions =
  | FetchOverviewIsLoadingAction
  | FetchOverviewIsSuccessAction
  | FetchOverviewIsFailureAction
  | SetOverviewBrandFilterAction
  | SetIncludeInternalsAction;

const fetchOverviewIsLoading = (): FetchOverviewIsLoadingAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_LOADING
});

const fetchOverviewIsSuccess = (
  data: Overview
): FetchOverviewIsSuccessAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_SUCCESS,
  payload: data
});

const fetchOverviewIsFailure = (): FetchOverviewIsFailureAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_FAILURE
});

const fetchOverview =
  (options: OverviewRequest): ThunkActionCreator<OverviewActions> =>
  async dispatch => {
    dispatch(fetchOverviewIsLoading());
    try {
      const response = await api.admin.overview.getOverview(options);
      dispatch(fetchOverviewIsSuccess(response));
    } catch (err) {
      dispatch(fetchOverviewIsFailure());
    }
  };

const setOverviewBrandFilter = (
  brand: BRANDS | null
): SetOverviewBrandFilterAction => ({
  type: OverviewActionTypes.SET_OVERVIEW_BRAND_FILTER,
  payload: brand
});

const setIncludeInternals = (): SetIncludeInternalsAction => ({
  type: OverviewActionTypes.SET_INCLUDE_INTERNALS
});

interface OverviewState {
  error: boolean;
  isLoading: boolean;
  brand: BRANDS | null;
  total: number;
  includeInternals: boolean;
  totals?: OverviewAffiliateTotals;
  data: OverviewAffiliate[];
}

const initialState: OverviewState = {
  error: false,
  isLoading: false,
  brand: null,
  total: 0,
  includeInternals: false,
  data: []
};

const reducer: Reducer<OverviewState, OverviewActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OverviewActionTypes.FETCH_OVERVIEW_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case OverviewActionTypes.FETCH_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.total,
        totals: action.payload.totals,
        data: action.payload.affiliates
      };
    case OverviewActionTypes.FETCH_OVERVIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case OverviewActionTypes.SET_OVERVIEW_BRAND_FILTER:
      return {
        ...state,
        brand: action.payload
      };
    case OverviewActionTypes.SET_INCLUDE_INTERNALS:
      return {
        ...state,
        includeInternals: !state.includeInternals
      };
    default:
      return state;
  }
};

const getOverviewState = (state: AppState) => state.overview;

export {
  reducer,
  fetchOverview,
  setIncludeInternals,
  setOverviewBrandFilter,
  getOverviewState
};
