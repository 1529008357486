import React, { FC } from "react";

import EmptyData from "components/empty-data";

import AffiliateFeeDetails from "./AffiliateFeeDetails";
import Skeleton from "../Skeleton";
import useAffiliateFee from "../useAffiliateFee";

const BrandFee: FC = () => {
  const {
    error,
    isLoading,
    brand,
    affiliateFee,
    rules,
    handleChangeAffiliateFeeOpen
  } = useAffiliateFee();

  if (isLoading) {
    return <Skeleton />;
  }

  if (!affiliateFee || error) {
    return <EmptyData className="no-data" margin={2} />;
  }

  return (
    <AffiliateFeeDetails
      isAdmin
      brand={brand}
      affiliateFee={affiliateFee}
      rules={rules || []}
      handleChange={handleChangeAffiliateFeeOpen}
    />
  );
};

export default BrandFee;
