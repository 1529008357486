import React, { FC, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteComponentProps } from "react-router-dom";

import { useUrlDateCheck } from "hooks";
import Drawer from "components/drawer";
import PageContainer from "components/page-container";
import Payment from "modules/payment";
import Payments, { fetchPayments } from "modules/payments";
import { fetchCloseMonthStatus } from "modules/authentication";

interface MatchParams {
  year: string;
  month: string;
}

type Props = RouteComponentProps<MatchParams>;

const PaymentsPage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { year, month } = match.params;

  useUrlDateCheck();

  useEffect(() => {
    Promise.all([
      dispatch(fetchPayments(year, month)),
      dispatch(fetchCloseMonthStatus())
    ]);
  }, [dispatch, year, month]);

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  return (
    <PageContainer>
      <Payments />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Route exact path={`${match.path}/:affiliateId`} component={Payment} />
      </Drawer>
    </PageContainer>
  );
};

export default PaymentsPage;
