import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    idColumn: {
      maxWidth: 96,
      fontWeight: 500
    },
    dateColumn: {
      maxWidth: 96
    },
    createdByColumn: {
      maxWidth: 256,
      paddingRight: 4,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    typeColumn: {
      maxWidth: 128
    },
    paidColumn: {
      paddingLeft: 24,
      fontWeight: 500
    },
    statusColumn: {
      maxWidth: 64
    },
    descriptionColumn: {
      minWidth: 256
    },
    amountColumn: {
      fontWeight: 500
    }
  })
);

const usePaymentInfoStyles = makeStyles(theme =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 16
    },
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    paymentMethod: {
      marginLeft: -16,
      marginBottom: -16
    },
    accountInfo: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      marginLeft: -16
    },
    expandLabel: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      color: theme.colors.black9e,
      transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        color: theme.colors.black61
      },
      "& .MuiSvgIcon-root": {
        width: 16,
        height: 16,
        transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      }
    },
    expanded: {
      transform: "rotate(180deg)"
    },
    divider: {
      margin: "32px 0"
    }
  })
);

const usePaymentInfoSkeletonStyles = makeStyles(theme =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px"
    },
    buttons: {
      display: "flex"
    },
    button: {
      borderRadius: 17,
      marginLeft: 8,
      width: 130,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        height: 32,
        width: 32
      }
    },
    details: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "-16px"
    },
    divider: {
      margin: "32px 0"
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    search: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "16px 0"
    },
    buttons: {
      display: "flex"
    },
    button: {
      borderRadius: 17,
      marginLeft: 8,
      width: 130,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        height: 32,
        width: 32
      }
    }
  })
);

export {
  useStyles,
  useSkeletonStyles,
  usePaymentInfoStyles,
  usePaymentInfoSkeletonStyles
};
