import { AffiliateCallback, CreateAffiliateCallbackRequest } from "@types";

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";

import {
  createAffiliateCallback,
  fetchAffiliateCallbacks,
  getAffiliateCallbacksState,
  removeAffiliateCallback,
  updateAffiliateCallback
} from "./duck";
import formatToRequest from "./helpers/formatToRequest";
import { useStyles } from "./styles";

interface Params {
  id: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: affiliateId } = useParams<Params>();
  const { error, isLoading, data } = useSelector(getAffiliateCallbacksState);
  const { query, setQuery, results } = useSearch<AffiliateCallback>(
    ["name"],
    data
  );

  useEffect(() => {
    dispatch(fetchAffiliateCallbacks(Number(affiliateId)));
  }, [dispatch, affiliateId]);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleCreateCallback = useCallback(
    (
      values: CreateAffiliateCallbackRequest,
      helpers: FormikHelpers<CreateAffiliateCallbackRequest>
    ) => {
      dispatch(createAffiliateCallback(Number(affiliateId), values, helpers));
    },
    [dispatch, affiliateId]
  );

  const handleUpdateCallback = useCallback(
    (callbackId: number) =>
      (
        values: CreateAffiliateCallbackRequest,
        helpers: FormikHelpers<CreateAffiliateCallbackRequest>
      ) => {
        dispatch(
          updateAffiliateCallback(
            Number(affiliateId),
            callbackId,
            values,
            helpers
          )
        );
      },
    [dispatch, affiliateId]
  );

  const handleRemoveCallback = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      ({ callbackId }: AffiliateCallback) => {
        event.stopPropagation();
        dispatch(
          openDialog({
            dialog: "remove-callback",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              onConfirm: () =>
                dispatch(
                  removeAffiliateCallback(Number(affiliateId), callbackId)
                )
            }
          })
        );
      },
    [dispatch, affiliateId]
  );

  const handleRowClick = useCallback(
    (callback: AffiliateCallback) => {
      dispatch(
        openDrawer("edit-callback", {
          title: "edit callback",
          affiliateId,
          initialState: formatToRequest(callback),
          handleSubmit: handleUpdateCallback(callback.callbackId)
        })
      );
    },
    [dispatch, affiliateId, handleUpdateCallback]
  );

  const handleCreateCallbackDrawer = useCallback(() => {
    dispatch(
      openDrawer("create-callback", {
        title: "create callback",
        affiliateId,
        handleSubmit: handleCreateCallback
      })
    );
  }, [dispatch, affiliateId, handleCreateCallback]);

  return {
    error,
    classes,
    isLoading,
    isEmpty: isEmpty(data),
    isEmptySearch: isEmpty(results),
    data: results,
    searchQuery: query,
    handleSearch,
    handleRowClick,
    handleRemoveCallback,
    handleCreateCallbackDrawer
  };
}
