import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      minWidth: 700,
      background: theme.colors.blackf5
    },
    header: {
      display: "flex",
      padding: 32,
      borderBottom: `1px solid ${theme.colors.blacke0}`
    },
    affiliate: {
      maxWidth: 312
    },
    name: {},
    company: {
      fontSize: 14,
      lineHeight: "24px",
      textTransform: "uppercase"
    },
    infoRow: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden"
    },
    cardsContainer: {
      marginLeft: "-16px"
    },
    paymentMethodContainer: {
      maxWidth: 312,
      marginLeft: 12
    },
    paymentMethodTitle: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 500
    },
    paymentMethod: {
      fontSize: 12,
      lineHeight: "16px",
      margin: "8px 0"
    },
    paymentMethodDetails: {
      display: "flex",
      flexDirection: "column"
    },
    paymentMethodProperty: {
      display: "flex",
      color: theme.colors.black21
    },
    paymentMethodPropertyLabel: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500
    },
    paymentMethodPropertyText: {
      fontSize: 12,
      marginLeft: 2,
      lineHeight: "16px"
    },
    content: {
      padding: 32,
      background: theme.colors.white
    },
    dateContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    date: {
      fontWeight: 500
    },
    confirmButton: {
      color: theme.colors.blue,
      borderColor: theme.colors.blue50
    },
    downloadButtons: {
      margin: "16px 0",
      "& > *:not(:first-child)": {
        marginLeft: 8
      }
    },
    downloadReceiptButton: {
      marginLeft: 8
    },
    htmlColumn: {
      display: "flex",
      alignItems: "center",
      minWidth: 312
    },
    paid: {
      color: theme.colors.teal
    },
    confirmed: {
      color: theme.colors.orange
    },
    unconfirmed: {
      color: theme.colors.red
    },
    blocked: {
      color: theme.colors.black61
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      minWidth: 700,
      background: theme.colors.blackf5
    },
    header: {
      display: "flex",
      padding: 32,
      borderBottom: `1px solid ${theme.colors.blacke0}`
    },
    content: {
      padding: 32,
      background: theme.colors.white
    },
    bankDetails: {
      marginTop: 24
    },
    dateRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 16
    },
    buttonsRow: {
      display: "flex",
      marginTop: "8px",
      marginBottom: "16px",
      "& :last-child": {
        marginLeft: "8px"
      }
    },
    button: {
      borderRadius: 17
    }
  })
);

export { useStyles, useSkeletonStyles };
