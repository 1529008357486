import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "32px 0"
    },
    name: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px",
        marginRight: 8
      }
    },
    info: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      margin: -16
    },
    link: {
      padding: "2px 3px 2px 4px",
      borderRadius: 2,
      background: theme.colors.teal50,
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.teal,
      width: "fit-content",
      whiteSpace: "normal"
    }
  })
);

const useReportStyles = makeStyles((theme: Theme) =>
  createStyles({
    trafficReport: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: theme.colors.black9e,
      marginTop: 64,
      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        marginTop: 32
      }
    },
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    emptyScreen: {
      marginTop: 32,
      [theme.breakpoints.down("md")]: {
        marginTop: 16
      }
    },
    button: {
      marginLeft: 8,
      background: theme.colors.blackfa,
      "&:hover $icon": {
        color: "inherit"
      }
    },
    icon: {
      color: theme.colors.black61,
      width: 16,
      height: 16
    },
    dateColumn: {
      minWidth: 96,
      whiteSpace: "nowrap"
    },
    segmentColumn: {
      minWidth: 128,
      fontWeight: 500
    }
  })
);

const useSkeletonStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 32,
      margin: "32px 0"
    },
    button: {
      borderRadius: 17,
      marginLeft: 8
    },
    linkInfo: {
      display: "flex",
      flexWrap: "wrap",
      margin: -16,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    textCard: {
      padding: 16
    },
    text: {
      marginTop: 2,
      marginBottom: 0,
      width: 230,
      height: 22,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  })
);

export { useDetailsStyles, useReportStyles, useSkeletonStyles };
