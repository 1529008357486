import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import EmptyData from "components/empty-data";

import Skeleton from "./Skeleton";
import InvoiceInformation from "./components/InvoiceInformation";
import PaymentsTable from "./components/PaymentsTable";
import InvoiceActions from "./components/InvoiceActions";
import useAffiliateInvoice from "./useAffiliateInvoice";

const InvoiceDetails: FC = () => {
  const {
    isLoading,
    error,
    invoice,
    handleMarkInvoice,
    handleDownloadInvoice,
    handleCreateAttachment
  } = useAffiliateInvoice();

  return (
    <Box className="drawer-container" minWidth="700px">
      <Box className="drawer-header">
        <Typography variant="h4" className="drawer-header_title">
          Invoice Details
        </Typography>
        <Button
          color="secondary"
          disabled={!invoice?.canMarkAsPaid}
          onClick={handleMarkInvoice}
        >
          Mark as paid
        </Button>
      </Box>
      <Divider style={{ margin: "16px 0" }} />
      {isLoading ? (
        <Skeleton />
      ) : error || !invoice ? (
        <EmptyData padding="16px" />
      ) : (
        <InvoiceInformation {...invoice} />
      )}
      <Divider style={{ margin: "16px 0" }} />
      <InvoiceActions
        handleDownloadInvoice={handleDownloadInvoice}
        handleCreateAttachment={handleCreateAttachment}
      />
      <Divider style={{ margin: "16px 0" }} />
      <PaymentsTable />
    </Box>
  );
};

export default InvoiceDetails;
