import { CreateAffiliateLinkValues } from "@types";

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { BRANDS, BRAND_SELECT_OPTIONS } from "constants/brands";
import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";
import {
  isCustomLanding,
  fetchAffiliateLandings,
  getAffiliateLandingsSelectOptions
} from "modules/affiliate-landings";

import validationSchema from "./validationSchema";

export interface CreateUserLinkProps {
  title: string;
  initialState?: CreateAffiliateLinkValues;
  onSubmit: (values: CreateAffiliateLinkValues) => void;
}

const initialValues: CreateAffiliateLinkValues = {
  name: "",
  brandId: null,
  landingPage: ""
};

const CreateUserLink: FC<CreateUserLinkProps> = ({
  title,
  initialState,
  onSubmit
}) => {
  const dispatch = useDispatch();

  const isCustom = useSelector(isCustomLanding(initialState?.landingPage));
  const landings = useSelector(getAffiliateLandingsSelectOptions);

  const getBrandValue = (value: BRANDS | null) =>
    value ? BRAND_SELECT_OPTIONS.find(brand => brand.value === value) : null;

  const getLandingValue = (value?: string) =>
    value ? { value, label: value } : null;

  useEffect(() => {
    dispatch(fetchAffiliateLandings());
  }, [dispatch]);

  return (
    <Formik
      initialValues={initialState ? initialState : initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="drawer-container">
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              {title}
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <TextField name="name" label="name" />
            {(!initialState || !isCustom) && (
              <SelectField
                name="brandId"
                label="Brand"
                placeholder="Select brand"
                isSearchable={false}
                isDisabled={initialState ? !!initialState.brandId : false}
                options={BRAND_SELECT_OPTIONS}
                value={getBrandValue(values.brandId)}
              />
            )}
            <SelectField
              name="landingPage"
              label="Landing"
              isDisabled={initialState && isCustom}
              placeholder="Select landing page"
              options={values.brandId ? landings[values.brandId] : []}
              value={getLandingValue(values.landingPage)}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateUserLink;
