import { TransactionType, CreatePaymentRequest } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbolRounded";

import {
  TRANSACTION_METHODS,
  TRANSACTION_METHODS_OPTIONS
} from "constants/payments";
import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";

import validationSchema from "./validationSchema";

export interface CreatePaymentProps {
  onSubmit: (
    values: CreatePaymentRequest,
    helpers: FormikHelpers<CreatePaymentRequest>
  ) => void;
}

const initialValues: CreatePaymentRequest = {
  type: TRANSACTION_METHODS.commission,
  amount: 0,
  description: ""
};

const CreatePayment: FC<CreatePaymentProps> = ({ onSubmit }) => {
  const getSelectTypeValue = (value: TransactionType | null) =>
    value
      ? TRANSACTION_METHODS_OPTIONS.find(type => type.value === value)
      : null;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="drawer-container">
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              Add new payment
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <SelectField
              isSearchable={false}
              name="type"
              label="Type"
              placeholder="Select Type"
              value={getSelectTypeValue(values.type)}
              options={TRANSACTION_METHODS_OPTIONS}
            />
            <TextField
              type="amount"
              name="amount"
              label="amount"
              negative={values.type === TRANSACTION_METHODS.manual}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroSymbolIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
            />
            <TextField multiline label="description" name="description" />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreatePayment;
