import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    idContainer: { display: "flex", overflow: "hidden" },
    id: {
      fontSize: 24,
      lineHeight: "32px"
    },
    info: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden"
    }
  })
);

export { useStyles };
