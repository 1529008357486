import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useEmptyDataStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    title: {
      fontSize: 20,
      lineHeight: "28px",
      color: theme.colors.black75,
      textTransform: "capitalize"
    },
    text: {
      marginTop: 8,
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black75
    }
  })
);

export { useEmptyDataStyles };
