import React, { FC } from "react";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { EMPTY_SEARCH_TEXT } from "constants/texts";

import { useEmptySearchStyles } from "./styles";

interface Props extends BoxProps {
  className?: string;
  onClear?: () => void;
}

const EmptySearch: FC<Props> = ({ className, onClear, ...rest }) => {
  const classes = useEmptySearchStyles();

  return (
    <Box className={cn(classes.container, className)} {...rest}>
      <Typography component="div" variant="h4" className={classes.title}>
        Not found
      </Typography>
      <Typography component="div" className={classes.text}>
        {EMPTY_SEARCH_TEXT}
      </Typography>
      {onClear && (
        <Button className={classes.button} onClick={onClear}>
          Clear filter
        </Button>
      )}
    </Box>
  );
};

export default EmptySearch;
