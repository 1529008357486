/* eslint-disable @typescript-eslint/ban-types */
import React, { FC } from "react";
import { useSelector } from "react-redux";

import EmptyData from "components/empty-data";
import {
  AffiliateFeeDetails,
  getAffiliateFeeState
} from "modules/affiliate-fee";

export type UserAffiliateFeeDetailsProps = {};

const UserAffiliateFeeDetails: FC<UserAffiliateFeeDetailsProps> = () => {
  const { error, data } = useSelector(getAffiliateFeeState);

  if (!data || error) {
    return <EmptyData className="no-data" margin={4} />;
  }

  return (
    <AffiliateFeeDetails
      brand={data.brandId}
      affiliateFee={data}
      rules={data.rules}
    />
  );
};

export default UserAffiliateFeeDetails;
