import React, { FC, useCallback } from "react";
import { DatePicker as MaterialDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { maxDate } from "constants/dates";
import { useField } from "formik";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      "&:hover:not($disabled) $icon": {
        fill: theme.colors.blue
      },
      "& input": {
        cursor: "pointer"
      }
    },
    disabled: {},
    icon: {
      width: 16,
      height: 16,
      cursor: "pointer",
      fill: theme.colors.blackbd
    }
  })
);

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
}

const MonthPickerField: FC<Props> = props => {
  const [field, meta, helpers] = useField(props);
  const classes = useStyles();

  const handleChange = useCallback(
    (date: MaterialUiPickersDate) => {
      helpers.setValue(date);
    },
    [helpers]
  );

  return (
    <MaterialDatePicker
      {...field}
      autoOk
      inputVariant="outlined"
      openTo="month"
      orientation="portrait"
      views={["year", "month"]}
      variant="inline"
      format="LLL yyyy"
      value={field.value}
      minDate={props.minDate || new Date()}
      maxDate={maxDate}
      label={props.label}
      onChange={handleChange}
      disabled={props.disabled}
      InputProps={{
        classes: {
          root: classes.root,
          disabled: classes.disabled
        },
        endAdornment: <CalendarIcon className={classes.icon} />
      }}
      error={meta.value && !!meta.error}
      helperText={meta.value && meta.error}
    />
  );
};

export default MonthPickerField;
