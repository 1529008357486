import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useSmallInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.colors.black21,
      minWidth: 82,
      "& .MuiOutlinedInput-root": {
        height: 32,
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
        background: theme.colors.blackee,
        "& .MuiSelect-iconOutlined": {
          marginRight: 0
        },
        "& .MuiSelect-select": {
          padding: 0,
          margin: 0,
          marginBottom: 4
        },
        "& .MuiOutlinedInput-input": {
          padding: "8px 0px 7px 0"
        },
        "& .MuiInputAdornment-positionStart": {
          height: "100%",
          color: "inherit",
          "& .MuiSvgIcon-root": {
            width: 16,
            height: 16
          }
        }
      },
      "& .Mui-focused": {
        background: theme.colors.blue50
      },
      "& .Mui-error .MuiOutlinedInput-root": {
        background: theme.colors.red10
      },
      "& .Mui-error .MuiInputAdornment-positionStart": {
        color: theme.colors.red
      }
    }
  })
);

export { useSmallInputStyles };
