import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { BRANDS } from "constants/brands";
import Search from "components/search";
import EmptyData from "components/empty-data";
import EmptySearch from "components/empty-search";

import Skeleton from "./Skeleton";
import ExpandedTable from "./components/ExpandedTable";
import useLandings from "./useLandings";

const Component: FC = () => {
  const {
    isEmpty,
    isEmptyLandings,
    isLoading,
    isExpanded,
    error,
    landings,
    searchQuery,
    handleSearch,
    handleTableExpand,
    handleRemoveLanding,
    handleEditDrawerOpen,
    handleCreateDrawerOpen
  } = useLandings();

  return (
    <Box>
      <Search
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Search by Name"
        disabled={isEmpty}
        buttons={
          <Button color="secondary" onClick={handleCreateDrawerOpen}>
            create new landing
          </Button>
        }
      />
      {isLoading ? (
        <Skeleton />
      ) : isEmpty || error ? (
        <EmptyData />
      ) : (
        <Box maxHeight="calc(100vh - 152px)" overflow="auto">
          {!isEmptyLandings ? (
            Object.keys(landings).map(brandId => (
              <ExpandedTable
                key={brandId}
                brandId={brandId as BRANDS}
                landings={landings[brandId]}
                isExpanded={isExpanded[brandId as BRANDS]}
                handleExpand={handleTableExpand}
                handleRowClick={handleEditDrawerOpen}
                handleRemoveLanding={handleRemoveLanding}
              />
            ))
          ) : (
            <EmptySearch />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Component;
