import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useAnchor } from "hooks";
import { BRAND_SELECT_OPTIONS, BRANDS, BRAND_NAMES } from "constants/brands";

export interface BrandFilterProps {
  brand: BRANDS | null;
  onChange: (brand: BRANDS | null) => void;
}

const BrandFilter: FC<BrandFilterProps> = ({ brand, onChange }) => {
  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();

  const onFilter = (brandId: BRANDS | null) => {
    onChange(brandId);
    handleClosePopup();
  };

  return (
    <>
      <Button
        onClick={handleOpenPopup}
        color={brand ? "secondary" : "default"}
        endIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {brand ? BRAND_NAMES[brand] : "all"}
      </Button>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClosePopup}>
        {BRAND_SELECT_OPTIONS.filter(({ value }) => brand !== value).map(
          ({ label, value }) => (
            <MenuItem key={value} onClick={() => onFilter(value)}>
              {label}
            </MenuItem>
          )
        )}
        {brand && (
          <MenuItem onClick={() => onFilter(null)}>All Brands</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default BrandFilter;
