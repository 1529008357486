import { Reducer } from "redux";

import { AppState } from "store";

enum ViewModeTypes {
  START_ADMIN_VIEW_MODE = "@view-mode/start-admin-view-mode",
  STOP_ADMIN_VIEW_MODE = "@view-mode/stop-admin-view-mode",
  START_AFFILIATE_VIEW_MODE = "@view-mode/start-affiliate-view-mode",
  STOP_AFFILIATE_VIEW_MODE = "@view-modee/stop-affiliate-view-mode"
}

interface ViewModePayload {
  id: number;
  backUrl: string;
}

interface StartAdminViewModeAction {
  type: ViewModeTypes.START_ADMIN_VIEW_MODE;
  payload: ViewModePayload;
}

interface StopAdminViewModeAction {
  type: ViewModeTypes.STOP_ADMIN_VIEW_MODE;
}

interface StartAffiliateViewModeAction {
  type: ViewModeTypes.START_AFFILIATE_VIEW_MODE;
  payload: {
    id: number;
  };
}

interface StopAffiliateViewModeAction {
  type: ViewModeTypes.STOP_AFFILIATE_VIEW_MODE;
}

type ViewModeActions =
  | StartAdminViewModeAction
  | StopAdminViewModeAction
  | StartAffiliateViewModeAction
  | StopAffiliateViewModeAction;

const startAdminViewMode = (
  id: number,
  backUrl: string
): StartAdminViewModeAction => ({
  type: ViewModeTypes.START_ADMIN_VIEW_MODE,
  payload: { id, backUrl }
});

const stopAdminViewMode = (): StopAdminViewModeAction => ({
  type: ViewModeTypes.STOP_ADMIN_VIEW_MODE
});

const startAffiliateViewMode = (id: number): StartAffiliateViewModeAction => ({
  type: ViewModeTypes.START_AFFILIATE_VIEW_MODE,
  payload: { id }
});

const stopAffiliateViewMode = (): StopAffiliateViewModeAction => ({
  type: ViewModeTypes.STOP_AFFILIATE_VIEW_MODE
});

interface ViewModeState {
  backUrl: string;
  accountId?: number;
  affiliateId?: number;
}

const initialState: ViewModeState = {
  backUrl: ""
};

const reducer: Reducer<ViewModeState, ViewModeActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ViewModeTypes.START_ADMIN_VIEW_MODE:
      return {
        ...state,
        affiliateId: action.payload.id,
        backUrl: action.payload.backUrl
      };
    case ViewModeTypes.STOP_ADMIN_VIEW_MODE:
      return initialState;
    case ViewModeTypes.START_AFFILIATE_VIEW_MODE:
      return {
        ...state,
        accountId: action.payload.id
      };
    case ViewModeTypes.STOP_AFFILIATE_VIEW_MODE:
      return {
        ...state,
        accountId: undefined
      };
    default:
      return state;
  }
};

const getViewModeState = (state: AppState) => state.viewMode;
const isAdminViewMode = (state: AppState): boolean =>
  !!state.viewMode.affiliateId;
const isAffiliateViewMode = (state: AppState): boolean =>
  !!state.viewMode.accountId;

export {
  reducer,
  startAdminViewMode,
  stopAdminViewMode,
  startAffiliateViewMode,
  stopAffiliateViewMode,
  isAdminViewMode,
  isAffiliateViewMode,
  getViewModeState
};
