import { LoginRequest } from "@types";

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";

import { useStyles } from "./styles";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Please enter email"),
  password: yup
    .string()
    .min(8, "Password can't be lower than 8 characters")
    .required("Password is required")
});

interface Props {
  isAdmin?: boolean;
  handleSubmit: (
    values: LoginRequest,
    actions: FormikHelpers<LoginRequest>
  ) => void;
}

const initialValues: LoginRequest = {
  email: "",
  password: ""
};

const Login: FC<Props> = ({ isAdmin, handleSubmit }) => {
  const classes = useStyles({ isAdmin });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Box className={classes.row}>
            <Typography variant="h3">Welcome!</Typography>
          </Box>
          <TextField
            label="email"
            name="email"
            disabled={formik.isSubmitting}
          />
          <TextField
            label="password"
            name="password"
            type="password"
            disabled={formik.isSubmitting}
          />
          <Box className={classes.row}>
            {!isAdmin && (
              <Link to="/forgot" className={classes.forgotLink}>
                Forgot password?
              </Link>
            )}
            <Button
              type="submit"
              className={classes.submitButton}
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            >
              sign in
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
