import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";

interface Props {
  csvProps: DownloadCsvButtonProps;
  onCreateDrawerOpen?: () => void;
}

const SearchButtons: FC<Props> = ({ csvProps, onCreateDrawerOpen }) => (
  <>
    <DownloadCsvButton {...csvProps} />
    {onCreateDrawerOpen && (
      <Button onClick={onCreateDrawerOpen} color="secondary">
        add new transaction
      </Button>
    )}
  </>
);

export default SearchButtons;
