import React, { FC } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { SearchInputSkeleton } from "components/search-input";

import { getLastUsageAffiliatesIds } from "./duck";
import { ListItemSkeleton } from "./components/ListItem";
import { SidebarContainer, useSidebarSkeletonStyles } from "./styles";

const ARRAY_LIST_COUNT = Array.from(Array(16));

const SidebarSkeleton: FC = () => {
  const classes = useSidebarSkeletonStyles();
  const lastUsageIds = useSelector(getLastUsageAffiliatesIds);

  return (
    <SidebarContainer>
      <Box className={classes.search}>
        <SearchInputSkeleton />
      </Box>
      <Box className={classes.listContainer}>
        {lastUsageIds.length !== 0 && (
          <Skeleton className={classes.label} width={192} height={28} />
        )}
        {lastUsageIds.map(id => (
          <ListItemSkeleton key={id} />
        ))}
        <Skeleton className={classes.label} width={192} height={28} />
        {ARRAY_LIST_COUNT.map((_, index) => (
          <ListItemSkeleton key={index} />
        ))}
      </Box>
    </SidebarContainer>
  );
};

export default SidebarSkeleton;
