import { AffiliateLog, CreateAffiliateLogRequest } from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";

enum AffiliateLogActionTypes {
  FETCH_AFFILIATE_LOG_LOADING = "@affiliate/log/fetch-log-loading",
  FETCH_AFFILIATE_LOG_SUCCESS = "@affiliate/log/fetch-log-success",
  FETCH_AFFILIATE_LOG_FAILURE = "@affiliate/log/fetch-log-failure",
  CREATE_AFFILIATE_LOG_NOTE_SUCCESS = "@affiliate/log/create-note-success"
}

interface FetchAffiliateLogIsLoadingAction {
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_LOADING;
}

interface FetchAffiliateLogIsSuccessAction {
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_SUCCESS;
  payload: { logs: AffiliateLog[] };
}

interface FetchAffiliateLogIsFailureAction {
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_FAILURE;
}

interface CreateAffiliateLogNoteIsSuccessAction {
  type: AffiliateLogActionTypes.CREATE_AFFILIATE_LOG_NOTE_SUCCESS;
}

type AffiliateLogActions =
  | FetchAffiliateLogIsLoadingAction
  | FetchAffiliateLogIsSuccessAction
  | FetchAffiliateLogIsFailureAction
  | CreateAffiliateLogNoteIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateLogActions>
>;

const fetchAffiliateLogIsLoading = (): FetchAffiliateLogIsLoadingAction => ({
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_LOADING
});

const fetchAffiliateLogIsSuccess = (
  logs: AffiliateLog[]
): FetchAffiliateLogIsSuccessAction => ({
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_SUCCESS,
  payload: { logs }
});

const fetchAffiliateLogsIsFailure = (): FetchAffiliateLogIsFailureAction => ({
  type: AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_FAILURE
});

const createAffiliateLogNoteIsSuccess =
  (): CreateAffiliateLogNoteIsSuccessAction => ({
    type: AffiliateLogActionTypes.CREATE_AFFILIATE_LOG_NOTE_SUCCESS
  });

const fetchAffiliateLog =
  (id: number): ThunkActionCreator<AffiliateLogActions> =>
  async dispatch => {
    dispatch(fetchAffiliateLogIsLoading());
    try {
      const { logs } = await api.admin.affiliate.log.getLog(id);
      dispatch(fetchAffiliateLogIsSuccess(logs));
    } catch (err) {
      dispatch(fetchAffiliateLogsIsFailure());
    }
  };

const createAffiliateLogNote =
  (
    id: number,
    data: CreateAffiliateLogRequest,
    formikHelpers: FormikHelpers<CreateAffiliateLogRequest>,
    handleProgress?: (progress: number) => void
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.log.createNote(id, data, handleProgress);
      dispatch(
        openSnackbar({ type: "success", message: "AffiliateLog created!" })
      );
      dispatch(createAffiliateLogNoteIsSuccess());
      formikHelpers.resetForm({});
      dispatch(fetchAffiliateLog(id));
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

interface AffiliateLogState {
  error: boolean;
  isLoading: boolean;
  data: AffiliateLog[];
}

const initialState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<AffiliateLogState, AffiliateLogActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_LOADING:
      return {
        ...state,
        isLoading: true,
        data: [],
        error: false
      };
    case AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.logs,
        error: false
      };
    case AffiliateLogActionTypes.FETCH_AFFILIATE_LOG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    default:
      return state;
  }
};

const getAffiliateLogState = (state: AppState) => state.affiliate.log;

export {
  reducer,
  fetchAffiliateLog,
  createAffiliateLogNote,
  getAffiliateLogState
};
