import { ListAffiliate } from "@types";

import { Reducer } from "redux";
import uniq from "lodash/uniq";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum AffiliatesActionTypes {
  FETCH_AFFILIATES_LOADING = "@sidebar/fetch-affiliates-loading",
  FETCH_AFFILIATES_SUCCESS = "@sidebar/fetch-affiliates-success",
  FETCH_AFFILIATES_FAILURE = "@sidebar/fetch-affiliates-failure",
  ADD_TO_LAST_USAGE_AFFILIATES = "@sidebar/add-to-last-usage",
  REMOVE_FROM_LAST_USAGE_AFFILIATES = "@sidebar/remove-from-last-usage",
  CLEAR_LAST_USAGE_AFFILIATES = "@sidebar/clear-last-usage"
}

interface FetchAffiliatesIsLoadingAction {
  type: AffiliatesActionTypes.FETCH_AFFILIATES_LOADING;
}

interface FetchAffiliatesIsSuccessAction {
  type: AffiliatesActionTypes.FETCH_AFFILIATES_SUCCESS;
  payload: {
    affiliates: ListAffiliate[];
  };
}

interface FetchAffiliatesIsFailureAction {
  type: AffiliatesActionTypes.FETCH_AFFILIATES_FAILURE;
}

interface AddToLastUsageAffiliatesAction {
  type: AffiliatesActionTypes.ADD_TO_LAST_USAGE_AFFILIATES;
  payload: { id: number };
}

interface RemoveFromLastUsageAffiliatesAction {
  type: AffiliatesActionTypes.REMOVE_FROM_LAST_USAGE_AFFILIATES;
  payload: { id: number };
}

interface ClearLastUsageAffiliatesAction {
  type: AffiliatesActionTypes.CLEAR_LAST_USAGE_AFFILIATES;
}

type AffiliatesActions =
  | FetchAffiliatesIsLoadingAction
  | FetchAffiliatesIsSuccessAction
  | FetchAffiliatesIsFailureAction
  | AddToLastUsageAffiliatesAction
  | RemoveFromLastUsageAffiliatesAction
  | ClearLastUsageAffiliatesAction;

const fetchAffiliatesIsLoading = (): FetchAffiliatesIsLoadingAction => ({
  type: AffiliatesActionTypes.FETCH_AFFILIATES_LOADING
});

const fetchAffiliatesIsSuccess = (
  affiliates: ListAffiliate[]
): FetchAffiliatesIsSuccessAction => ({
  type: AffiliatesActionTypes.FETCH_AFFILIATES_SUCCESS,
  payload: { affiliates }
});

const fetchAffiliatesIsFailure = (): FetchAffiliatesIsFailureAction => ({
  type: AffiliatesActionTypes.FETCH_AFFILIATES_FAILURE
});

const addToLastUsageAffiliates = (
  id: number
): AddToLastUsageAffiliatesAction => ({
  type: AffiliatesActionTypes.ADD_TO_LAST_USAGE_AFFILIATES,
  payload: { id }
});

const removeFromLastUsageAffiliates = (
  id: number
): RemoveFromLastUsageAffiliatesAction => ({
  type: AffiliatesActionTypes.REMOVE_FROM_LAST_USAGE_AFFILIATES,
  payload: { id }
});

const clearLastUsageAffiliates = () => ({
  type: AffiliatesActionTypes.CLEAR_LAST_USAGE_AFFILIATES
});

const fetchAffiliates =
  (): ThunkActionCreator<AffiliatesActions> => async dispatch => {
    dispatch(fetchAffiliatesIsLoading());
    try {
      const { affiliates } = await api.admin.affiliates.getAffiliates();
      dispatch(fetchAffiliatesIsSuccess(affiliates));
    } catch (err) {
      dispatch(fetchAffiliatesIsFailure());
    }
  };

interface AffiliatesState {
  error: boolean;
  isLoading: boolean;
  list: ListAffiliate[];
  lastUsageIds: number[];
}

const initialState = {
  error: false,
  isLoading: false,
  list: [],
  lastUsageIds: []
};

const reducer: Reducer<AffiliatesState, AffiliatesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliatesActionTypes.FETCH_AFFILIATES_LOADING:
      return {
        ...state,
        isLoading: true,
        list: [],
        error: false
      };
    case AffiliatesActionTypes.FETCH_AFFILIATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.affiliates,
        error: false
      };
    case AffiliatesActionTypes.FETCH_AFFILIATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: true
      };
    case AffiliatesActionTypes.ADD_TO_LAST_USAGE_AFFILIATES:
      return {
        ...state,
        lastUsageIds: uniq([action.payload.id, ...state.lastUsageIds])
      };
    case AffiliatesActionTypes.REMOVE_FROM_LAST_USAGE_AFFILIATES:
      return {
        ...state,
        lastUsageIds: state.lastUsageIds.filter(id => id !== action.payload.id)
      };

    case AffiliatesActionTypes.CLEAR_LAST_USAGE_AFFILIATES:
      return {
        ...state,
        lastUsageIds: []
      };
    default:
      return state;
  }
};

const getAffiliatesState = (state: AppState) => state.affiliates;

const getLastUsageAffiliatesIds = (state: AppState) =>
  state.affiliates.lastUsageIds;

export {
  reducer,
  fetchAffiliates,
  addToLastUsageAffiliates,
  removeFromLastUsageAffiliates,
  clearLastUsageAffiliates,
  getAffiliatesState,
  getLastUsageAffiliatesIds
};
