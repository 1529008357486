import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import AffiliateProfile from "modules/affiliate-profile";
import { fetchAffiliateApiToken } from "modules/affiliate";

const AffiliateViewProfilePage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAffiliateApiToken());
  }, [dispatch]);

  return <AffiliateProfile />;
};

export default AffiliateViewProfilePage;
