import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import isEmpty from "lodash/isEmpty";

import EmptyData from "components/empty-data";

import SharedFiles from "./components/SharedFiles";
import List from "./components/List";
import Form from "./components/Form";
import Skeleton from "./Skeleton";
import useAffiliateLog from "./useAffiliateLog";

const Component: FC = () => {
  const {
    classes,
    error,
    isLoading,
    logs,
    files,
    uploadProgress,
    handleCreateLog
  } = useAffiliateLog();

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSide}>
        <Form uploadProgress={uploadProgress} handleSubmit={handleCreateLog} />
        <Box className={classes.list}>
          {isLoading ? (
            <Skeleton />
          ) : error || isEmpty(logs) ? (
            <EmptyData className={classes.empty} />
          ) : (
            <List items={logs} />
          )}
        </Box>
      </Box>
      <Divider className={classes.vertDivider} orientation="vertical" />
      <Box className={classes.rightSide}>
        <SharedFiles isLoading={isLoading} sharedFiles={files} />
      </Box>
    </Box>
  );
};

export default Component;
