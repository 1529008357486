import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

import { skeletonStyles } from "./styles";

const ROWS_COUNT = Array.from(Array(7));

const TableHeader: FC = () => (
  <>
    <Box style={{ ...skeletonStyles.row, height: 48 }}>
      <Skeleton style={skeletonStyles.smallCell} />
      <Skeleton style={skeletonStyles.smallCell} />
      <Box style={skeletonStyles.headerLink}>
        <Skeleton style={skeletonStyles.smallCell} />
      </Box>
      <Box style={skeletonStyles.headerLink}>
        <Skeleton style={skeletonStyles.smallCell} />
      </Box>
      <Skeleton style={skeletonStyles.mediumCell} />
      <Box style={skeletonStyles.button} />
    </Box>
    <Divider />
  </>
);

const TableRow: FC = () => (
  <>
    <Box style={skeletonStyles.row}>
      <Skeleton style={skeletonStyles.smallCell} />
      <Skeleton style={skeletonStyles.smallCell} />
      <Box>
        <Skeleton style={skeletonStyles.smallCell} />
        <Skeleton style={skeletonStyles.largeCell} />
      </Box>
      <Box>
        <Skeleton style={skeletonStyles.smallCell} />
        <Skeleton style={skeletonStyles.largeCell} />
      </Box>

      <Skeleton style={skeletonStyles.mediumCell} />
      <Skeleton style={skeletonStyles.button} />
    </Box>
    <Divider />
  </>
);

const CallbacksSkeleton: FC = () => (
  <Box style={skeletonStyles.container}>
    <TableHeader />
    <Divider />
    {ROWS_COUNT.map((_, index) => (
      <TableRow key={index} />
    ))}
  </Box>
);

export default CallbacksSkeleton;
