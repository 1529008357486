import { CreateAffiliateFeesValues } from "@types";

import React, { FC, useEffect } from "react";
import { Form, Formik, FormikProps, FieldArray, FormikHelpers } from "formik";
import { max, addMonths, subMinutes, startOfMonth, endOfMonth } from "date-fns";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CreateCardIcon from "@material-ui/icons/PlaylistAddOutlined";

import SubmitButton from "components/submit-button";

import { fetchFees } from "modules/fees";
import { useStyles } from "./styles";
import validationSchema from "./validationSchema";
import Card from "./components/Card";

export interface ChangeAffiliateFeeProps {
  initialState: CreateAffiliateFeesValues;
  handleSubmit: (
    values: CreateAffiliateFeesValues,
    helpers: FormikHelpers<CreateAffiliateFeesValues>
  ) => void;
}

const ChangeAffiliateFee: FC<ChangeAffiliateFeeProps> = ({
  initialState,
  handleSubmit
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFees());
  }, [dispatch]);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue
      }: FormikProps<CreateAffiliateFeesValues>) => (
        <Form className="drawer-container">
          <Box className="drawer-header" marginBottom={2}>
            <Typography
              variant="h4"
              color="secondary"
              className="drawer-header_title"
            >
              Edit Fees
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Divider className={classes.divider} />
          <FieldArray
            name="fees"
            render={arrayHelpers => (
              <Box>
                <Box className={classes.createCardContainer}>
                  <ButtonBase
                    className={classes.createCard}
                    onClick={() => {
                      // TODO 456: i'm still not sure how this works, but it does
                      const maxPeriodTo = values.fees.length
                        ? max(values.fees.map(({ periodTo }) => periodTo))
                        : new Date();
                      const pF = startOfMonth(addMonths(maxPeriodTo, 1));
                      arrayHelpers.push({
                        adminFeeId: null,
                        periodFrom: subMinutes(pF, pF.getTimezoneOffset()),
                        periodTo: endOfMonth(addMonths(maxPeriodTo, 1))
                      });
                    }}
                  >
                    <Typography className={classes.createCardText}>
                      Click to add new fee
                    </Typography>
                    <CreateCardIcon className={classes.createCardIcon} />
                  </ButtonBase>
                </Box>
                <Box>
                  {values.fees.map((fee, index) => (
                    <Card
                      id={index}
                      key={index}
                      fee={fee}
                      errors={errors}
                      touched={touched}
                      handleFieldValue={setFieldValue}
                      handleRemoveCard={() => {
                        if (!fee.isRunning) {
                          arrayHelpers.remove(index);
                        } else {
                          arrayHelpers.replace(index, {
                            ...fee,
                            periodTo: endOfMonth(fee.periodFrom)
                          });
                        }
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ChangeAffiliateFee;
