import * as yup from "yup";
import { isURL } from "utils/regexes";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  brand: yup.string().nullable().notRequired(),
  landingPage: yup
    .string()
    .nullable()
    .matches(isURL, "Landing must be a valid URL")
    .required("Landing is required"),
  planId: yup.number().nullable().notRequired()
});

export default validationSchema;
