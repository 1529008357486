import { CreatePlanValues, SelectOption } from "@types";

import React, { FC, useState } from "react";
import { Form, Formik, FormikProps, FieldArray, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbolRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CreateCardIcon from "@material-ui/icons/PlaylistAddOutlined";
import IconButton from "@material-ui/core/IconButton";

import { useAnchor } from "hooks";
import { isPercent } from "utils/regexes";
import Input from "components/input";
import SubmitButton from "components/submit-button";

import { nrsOptions, getInitialCountries } from "./helpers";
import validationSchema from "./validationSchema";
import CardField from "./components/CardField";
import Card from "./components/Card";
import { useStyles } from "./styles";

export interface CreatePlanProps {
  initialState?: CreatePlanValues;
  handleSubmit: (
    values: CreatePlanValues,
    helpers: FormikHelpers<CreatePlanValues>
  ) => void;
}

const initialValues: CreatePlanValues = {
  plan: {
    name: "",
    nrs: null,
    cpa: ""
  },
  rules: []
};

const CreatePlan: FC<CreatePlanProps> = ({ initialState, handleSubmit }) => {
  const classes = useStyles();

  const [countries, setCountries] = useState<SelectOption[]>(
    getInitialCountries(initialState ? initialState.rules : initialValues.rules)
  );
  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();

  return (
    <Formik
      initialValues={initialState ? initialState : initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue
      }: FormikProps<CreatePlanValues>) => (
        <Form className="drawer-container">
          <Box className="drawer-header">
            <Typography variant="h4" color="secondary">
              {initialState ? "Edit Plan" : "Create New Plan"}
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Input
            label="name"
            name="plan.name"
            className={classes.dealInput}
            error={touched.plan?.name && !!errors.plan?.name}
            helperText={touched.plan?.name && errors.plan?.name}
            value={values.plan.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box className={classes.global}>
            <Typography variant="h5">Global</Typography>
            <Box className={classes.nrs}>
              <Typography className={classes.title}>
                Net Revenue Share:
              </Typography>
              <CardField
                name="plan.nrs"
                placeholder="Ladder"
                pattern={isPercent}
                className={classes.select}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className={classes.startAdornment}>%</span>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.endAdornment}
                    >
                      <IconButton
                        className={classes.nrsDropdown}
                        onClick={event => handleOpenPopup(event)}
                      >
                        <ExpandMoreRoundedIcon
                          className={classes.dropdownIcon}
                        />
                      </IconButton>
                      <Menu
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={handleClosePopup}
                      >
                        {nrsOptions.map((option, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setFieldValue("plan.nrs", option.value);
                              handleClosePopup();
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className={classes.cpa}>
              <Typography className={classes.title}>
                Cost per Acquisition:
              </Typography>
              <CardField
                name="plan.cpa"
                className={`${classes.smallInput} ${classes.cpaInput}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EuroSymbolIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <FieldArray
            name="rules"
            render={arrayHelpers => (
              <Box>
                <Box className={classes.createCardContainer}>
                  <ButtonBase
                    className={classes.createCard}
                    onClick={() => {
                      arrayHelpers.insert(0, {});
                    }}
                  >
                    <Typography className={classes.createCardText}>
                      Click to add country restrictions
                    </Typography>
                    <CreateCardIcon className={classes.createCardIcon} />
                  </ButtonBase>
                </Box>
                <Box>
                  {values.rules.map((rule, index) => (
                    <Card
                      id={index}
                      key={index}
                      rule={rule}
                      errors={errors}
                      touched={touched}
                      countries={countries}
                      handleFieldValue={setFieldValue}
                      handleCountries={setCountries}
                      handleRemoveCard={() => arrayHelpers.remove(index)}
                    />
                  ))}
                </Box>
              </Box>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreatePlan;
