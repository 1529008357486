import React from "react";
import Button from "@material-ui/core/Button";

import { ButtonColumnProps } from "../../types";

const ButtonColumn: React.FC<ButtonColumnProps> = ({
  children,
  handleMarkPayment: _handleMarkPayment,
  handleConfirmPayment: _handleConfirmPayment,
  ...rest
}: ButtonColumnProps) => <Button {...rest}>{children}</Button>;

export default ButtonColumn;
