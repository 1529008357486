import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowIcon from "@material-ui/icons/KeyboardBackspaceRounded";

import TexField from "components/text-field";

import { useStyles } from "../styles";

interface Props {
  email: string;
  disabled?: boolean;
  handleBack: (event: React.SyntheticEvent<HTMLElement>) => void;
}

const SecondStep: FC<Props> = ({ disabled, email, handleBack }) => {
  const classes = useStyles();

  return (
    <Box className={classes.form}>
      <Typography className={classes.title}>Verification Code</Typography>
      <Typography className={classes.notice}>
        Copy-paste the verification code that we sent you on mail&nbsp;
      </Typography>
      <Typography className={classes.userEmail}>{email}</Typography>
      <TexField numeric label="Code" name="code" />
      <Box className={classes.buttons}>
        <IconButton className={classes.backButton} onClick={handleBack}>
          <ArrowIcon />
        </IconButton>
        <Button
          type="submit"
          disabled={disabled}
          className={classes.submitButton}
        >
          next step
        </Button>
      </Box>
    </Box>
  );
};

export default SecondStep;
