import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Hints: FC = () => (
  <Box padding={2} marginTop={1} borderRadius={4} bgcolor="text.hint">
    <Typography variant="h5">Parameters for URL</Typography>
    <Box marginTop={1}>
      <Typography>{`{uid} - User ID`}</Typography>
      <Typography>{`{rid} — Registration ID passed as rid parameter`}</Typography>
      <Typography>{`{segment} — Segment`}</Typography>
      <Typography>{`{linkid} — Link ID`}</Typography>
    </Box>
  </Box>
);
export default Hints;
