import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  isAdmin?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme =>
  createStyles({
    form: {
      maxWidth: 348
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:first-child": {
        marginBottom: 16
      },
      "&:last-child": {
        marginTop: 16
      }
    },
    forgotLink: {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black75,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    submitButton: ({ isAdmin }) => ({
      height: 42,
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue}`,
      borderRadius: 21,
      width: isAdmin ? "100%" : "auto",
      "&:hover": {
        color: theme.colors.white,
        background: theme.colors.blue,
        borderColor: theme.colors.blue
      }
    }),
    error: {
      marginTop: 16
    },
    formHelperText: {
      position: "absolute",
      bottom: -16,
      left: 8,
      margin: 0
    }
  })
);

export { useStyles };
