import { IsMulti, SelectOption } from "@types";

import { StylesConfig } from "react-select";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { colors } from "../../theme";

const useSelectStyles = makeStyles(() =>
  createStyles({
    input: {
      display: "flex",
      height: "100%",
      minHeight: "53.63px",
      padding: 0
    },
    noOptionsMessage: {
      padding: 16
    },
    dropdown: {
      margin: "0 7px",
      marginRight: 11,
      color: colors.blackbd
    },
    paper: {
      maxHeight: 300,
      borderRadius: 8,
      position: "absolute",
      zIndex: 999,
      marginTop: -8,
      left: 0,
      right: 0
    },
    optionText: {
      whiteSpace: "normal"
    }
  })
);

const selectStyles: StylesConfig<SelectOption, IsMulti> = {
  placeholder: base => ({
    ...base,
    color: colors.black75,
    marginLeft: 6
  }),
  singleValue: base => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 6,
    paddingRight: 8,
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
    textOverflow: "unset",
    bottom: "-47px",
    top: "calc(50% + 17px)"
  }),
  input: base => ({
    ...base,
    color: colors.black21,
    marginLeft: 6,
    "& input": {
      lineHeight: "19px",
      fontFamily: '"Rubik", sans-serif'
    }
  }),
  clearIndicator: base => ({
    ...base,
    cursor: "pointer",
    color: colors.blackbd,
    "&:hover": {
      color: colors.blue
    }
  }),
  indicatorSeparator: base => ({
    ...base,
    height: 24,
    alignSelf: "center"
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: colors.blue50,
    minWidth: "auto"
  }),
  multiValueRemove: base => ({
    ...base,
    "&:hover": {
      cursor: "pointer",
      background: "none",
      color: colors.blue
    }
  })
};

export { selectStyles, useSelectStyles };
