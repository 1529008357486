/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreateAffiliateFeeValues,
  CreateAffiliateFeesValues,
  Fee
} from "@types";

import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormikErrors, FormikTouched } from "formik";
import { addMonths, startOfMonth, endOfMonth } from "date-fns";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/CloseOutlined";
import StopIcon from "@material-ui/icons/StopRounded";
import TrashedIcon from "@material-ui/icons/RemoveRounded";
import WarningRoundedIcon from "@material-ui/icons/ErrorOutline";
import OkRoundedIcon from "@material-ui/icons/CheckCircleOutline";

import Select from "components/select";
import { getFeesState } from "modules/fees";

import MonthPickerField from "./MonthPicker";
import { useStyles } from "../styles";

interface Props {
  id: number;
  fee: CreateAffiliateFeeValues;
  errors: FormikErrors<CreateAffiliateFeesValues>;
  touched: FormikTouched<CreateAffiliateFeesValues>;
  handleFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
  handleRemoveCard: () => void;
}

const Card: FC<Props> = ({ id, fee, handleFieldValue, handleRemoveCard }) => {
  const classes = useStyles();

  const [minPeriodTo, setMinPeriodTo] = useState(
    startOfMonth(addMonths(new Date(), 1))
  );

  useEffect(() => {
    if (fee.periodFrom !== minPeriodTo) setMinPeriodTo(fee.periodFrom);
  }, [fee.periodFrom, handleFieldValue, id, minPeriodTo]);

  useEffect(() => {
    if (fee.periodTo < fee.periodFrom)
      handleFieldValue(`fees.${id}.periodTo`, endOfMonth(fee.periodFrom));
  }, [fee.periodFrom, fee.periodTo, handleFieldValue, id]);

  const { isLoading, data } = useSelector(getFeesState);
  const options = data
    .filter(({ draftRemovedAt }) => !draftRemovedAt)
    .map(({ adminFeeId, name, active, nextMonthActive }) => ({
      label: `${name} ${!active ? "*" : !nextMonthActive ? "**" : ""}`,
      value: adminFeeId
    }));

  const handleSubtext = useMemo(
    () => (data: Fee[], feeId: number | undefined) => {
      const feeData = data.find(({ adminFeeId }) => adminFeeId === feeId);
      if (!feeData?.active) {
        if (feeData?.nextMonthActive)
          return "OK:Selected fee will be active next month";
        return "WARN:Selected fee is inactive";
      }
      if (!feeData?.nextMonthActive)
        return "WARN:Selected fee will be inactive next month";
      return "";
    },
    []
  );

  const handleDefaultSelectedFee = useMemo(
    () =>
      (
        data: Fee[],
        options: { label: string; value: number }[],
        feeId: number | undefined
      ) => {
        const feeData = options.find(({ value }) => value === feeId);
        if (feeData) return feeData;
        const firstActive = options.find(({ value }) => {
          return !!data.some(
            ({ adminFeeId, active, nextMonthActive }) =>
              adminFeeId === value && active && nextMonthActive
          );
        });
        if (firstActive) return firstActive;
        return options[0];
      },
    []
  );

  const [selectedFee, setSelectedFee] = useState(
    handleDefaultSelectedFee(data, options, fee?.adminFeeId)
  );
  const [infoSubtext, setInfoSubtext] = useState(
    handleSubtext(data, fee?.adminFeeId)
  );

  useEffect(() => {
    if (!selectedFee && options.length > 0)
      setSelectedFee(handleDefaultSelectedFee(data, options, fee?.adminFeeId));
  }, [
    selectedFee,
    options,
    fee?.adminFeeId,
    id,
    data,
    handleDefaultSelectedFee
  ]);

  useEffect(() => {
    if (selectedFee) setInfoSubtext(handleSubtext(data, selectedFee.value));
  }, [selectedFee, data, id, handleSubtext]);

  useEffect(() => {
    if (selectedFee)
      handleFieldValue(`fees.${id}.adminFeeId`, selectedFee.value);
  }, [handleFieldValue, id, selectedFee]);

  const handleChange = useCallback(
    (option: any) => {
      setSelectedFee(option);
      handleFieldValue(`fees.${id}.adminFeeId`, option.value);
    },
    [handleFieldValue, id]
  );

  return (
    <Paper className={classes.planCard}>
      <Box className={classes.planCardHeader}>
        <Box className={classes.planCardHeaderTitle}>
          {fee.draftRemovedAt ? (
            <Typography className={classes.iconButton}>{fee.name}</Typography>
          ) : (
            <Select
              name={`fees.${id}.adminFeeId`}
              className={classes.countrySelector}
              placeholder="Select fee"
              value={selectedFee}
              options={options}
              onChange={handleChange}
              isDisabled={isLoading || fee.isRunning}
            />
          )}
          <IconButton
            className={classes.iconButton}
            onClick={handleRemoveCard}
            disabled={!!fee.draftRemovedAt}
          >
            {fee.draftRemovedAt ? (
              <TrashedIcon />
            ) : fee.isRunning ? (
              <StopIcon />
            ) : (
              <RemoveIcon />
            )}
          </IconButton>
        </Box>
        {!fee.draftRemovedAt && infoSubtext && (
          <Box>
            <Typography
              className={classes.subtext}
              style={{ fontStyle: "italic" }}
            >
              {infoSubtext.startsWith("WARN") ? (
                <WarningRoundedIcon style={{ fontSize: 14 }} />
              ) : (
                <OkRoundedIcon style={{ fontSize: 14 }} />
              )}
              {infoSubtext.split(":").pop()}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.rules}>
        <Box className={classes.dateRow}>
          <MonthPickerField
            label="From"
            name={`fees.${id}.periodFrom`}
            disabled={fee.isRunning}
            minDate={startOfMonth(addMonths(new Date(), 1))}
          />
          <MonthPickerField
            label="To"
            name={`fees.${id}.periodTo`}
            minDate={minPeriodTo}
            disabled={!!fee.draftRemovedAt}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default Card;
