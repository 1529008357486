import { CreatePlanRuleValue } from "@types";

import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import {
  allCountriesOption,
  countriesOptions,
  isAllCountriesSelected
} from "constants/countries";

const ruleNrsOptions = [
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "30", value: "30" },
  { label: "40", value: "40" },
  { label: "50", value: "50" }
];

const nrsOptions = [{ label: "Ladder", value: null }, ...ruleNrsOptions];

const initialCountries = [allCountriesOption, ...countriesOptions];

const getInitialCountries = (rules: CreatePlanRuleValue[]) => {
  if (!isEmpty(rules)) {
    if (!isAllCountriesSelected(rules[0].countryIds)) {
      const initialIds = flatten(rules.map(rule => rule.countryIds));
      const filteredCountries = initialCountries.filter(
        ({ value }) => !initialIds.some(id => id === value)
      );

      return filteredCountries;
    }
    return [];
  }

  return initialCountries;
};

export { nrsOptions, ruleNrsOptions, initialCountries, getInitialCountries };
