/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  ReactElement,
  Children,
  cloneElement,
  memo,
  MouseEvent,
  useRef,
  useEffect
} from "react";
import { ListChildComponentProps } from "react-window";
import cn from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { getPaymentName } from "utils/payments";
import { PAYMENT_STATUSES } from "constants/payments";

import { GeneratedRowProps } from "../types";
import { useTableStyles } from "../styles";

interface Props extends ListChildComponentProps {
  checkedIds?: number[];
  columns: ReactElement<GeneratedRowProps>[];
  handleRowHeight?: (index: number, size: number) => void;
  handleRowClick?: (item: any) => void;
  handleRowCheck?: (itemId: number) => void;
}

const getColumnProps = (
  item: any,
  props: GeneratedRowProps
): { [key: string]: any } => {
  const {
    children,
    name,
    comparedName,
    secondName,
    urlName,
    linkName,
    onClick,
    handleMarkPayment,
    handleConfirmPayment
  } = props;

  const basicProps = {
    value: item[name],
    children: children,
    className: comparedName
      ? cn({
          more: item[name] > item[comparedName],
          less: item[name] < item[comparedName],
          equal: item[name] === item[comparedName]
        })
      : undefined
  };

  if (name === "link") {
    return {
      ...basicProps,
      linkValue: linkName && item[linkName]
    };
  }

  if (secondName || urlName) {
    return {
      ...basicProps,
      urlValue: urlName && item[urlName],
      secondValue: secondName && item[secondName]
    };
  }

  if (name === "paymentMethod") {
    return {
      ...basicProps,
      value: getPaymentName(item[name])
    };
  }

  if (name === "payment-button") {
    const isConfirm =
      item.status === PAYMENT_STATUSES.unconfirmed ||
      item.status === PAYMENT_STATUSES.blocked;

    return {
      ...basicProps,
      className: cn("payment-button", {
        "Mui-disabled": isConfirm ? !item.canConfirm : !item.canMarkAsPaid
      }),
      children: isConfirm ? "Confirm invoice" : "Mark as paid",
      onClick: (event: MouseEvent) => {
        event.stopPropagation();

        if (isConfirm && handleConfirmPayment) {
          handleConfirmPayment(item);
        }
        if (!isConfirm && handleMarkPayment) {
          handleMarkPayment(item);
        }
        onClick && onClick(item);
      }
    };
  }

  if (name === "dropdown") {
    return {
      ...basicProps,
      children: Children.map(children, (child: any) =>
        cloneElement(child, {
          onClick: event => {
            child.props.onClick(event)(item);
          }
        })
      )
    };
  }

  return basicProps;
};

const Row: FC<Props> = ({
  index,
  style,
  data,
  columns,
  checkedIds,
  handleRowHeight,
  handleRowCheck,
  handleRowClick
}) => {
  const classes = useTableStyles();
  const item = data[index];
  const rowRef = useRef<any>({});

  useEffect(() => {
    if (rowRef.current && handleRowHeight) {
      handleRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  return (
    <div style={style}>
      <TableRow
        key={index}
        ref={handleRowHeight && rowRef}
        component="div"
        className={cn({
          [classes.withAction]: !!handleRowClick,
          [classes.actionDisabled]: item.adminFeeId && item.draftRemovedAt
        })}
        onClick={() => handleRowClick && handleRowClick(item)}
      >
        {Children.map(columns, column => {
          const { className, name, align, style } = column.props;

          return (
            <TableCell
              key={index}
              style={style}
              component="div"
              align={align || "right"}
              className={cn([className], {
                [classes.checkbox]: name === "checkbox"
              })}
            >
              {name === "checkbox" ? (
                <Checkbox
                  checked={checkedIds && checkedIds.includes(item.playerId)}
                  onClick={event => {
                    event.stopPropagation();
                    handleRowCheck && handleRowCheck(item.playerId);
                  }}
                />
              ) : name === "dropdown" &&
                item.adminFeeId &&
                item.draftRemovedAt ? (
                <span />
              ) : (
                cloneElement(column, getColumnProps(item, column.props))
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </div>
  );
};

export default memo(Row);
