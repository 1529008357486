import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    flexCenter: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        justifyContent: "space-between"
      }
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      margin: -16
    },
    brandText: {
      maxWidth: "256px",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      [theme.breakpoints.between("xs", "sm")]: {
        maxWidth: "100%"
      }
    },
    divider: {
      height: 1,
      backgroundColor: theme.colors.blacke0,
      margin: "32px 0"
    },
    icon: {
      fontSize: 16,
      marginLeft: 8,
      color: theme.colors.blacke0
    },
    title: {
      marginBottom: 16,
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500,
      color: theme.colors.black21
    },
    positive: {
      background: theme.colors.teal50,
      color: theme.colors.green
    },
    negative: {
      background: theme.colors.red10,
      color: theme.colors.red
    },
    neutral: {
      background: theme.colors.orange10,
      color: theme.colors.orange
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: "100%",
      width: "100%"
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      margin: -16
    },
    secondRow: {
      marginTop: 24
    },
    item: {
      padding: 16
    },
    text: {
      width: 140,
      height: 22,
      margin: 0,
      marginTop: 2,
      [theme.breakpoints.between("xs", "sm")]: {
        height: 24
      }
    },
    icon: {
      alignSelf: "center",
      marginLeft: 8,
      width: 16,
      heigh: 16
    },
    divider: {
      height: "1px",
      backgroundColor: theme.colors.blacke0,
      margin: "32px 0"
    },
    actionCard: {
      padding: 16,
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%"
      }
    },
    dealsRow: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.between("xs", "sm")]: {
        justifyContent: "space-between"
      }
    },
    dealsLabel: {
      marginBottom: 16,
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500,
      color: theme.colors.black21
    }
  })
);

export { useStyles, useSkeletonStyles };
