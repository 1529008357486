import React from "react";
import { Link } from "react-router-dom";

import { LoginButton } from "./LoginButton";

import "./styles/login-page.style.css";
import { getGoogleAccountFromCookie } from "../utils";

interface Props {
  logo?: React.ReactNode;
  redirectPath?: string;
}

const GoogleLoginPage: React.FC<Props> = ({ logo, redirectPath }) => {
  const user = getGoogleAccountFromCookie();

  return (
    <div className="login-page">
      <div className="login-page__box">
        <div className="login-page__box-content">
          {logo && logo}
          <LoginButton redirectPath={redirectPath} />
        </div>
        {user && (
          <div className="login-page__box-text">
            You are already logged in!
            <Link
              to={redirectPath || "/"}
              className="login-page__box-text-link"
            >
              Go to Main Page
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export { GoogleLoginPage };
