/* eslint-disable @typescript-eslint/no-explicit-any */
const REDUX_STATE = "redux-state";

const getPersistedState = () => {
  const state = localStorage.getItem(REDUX_STATE);
  if (!state) {
    return {};
  }

  try {
    return JSON.parse(state);
  } catch (e) {
    return {};
  }
};

const setPersistedState = (state: any) => {
  localStorage.setItem(REDUX_STATE, JSON.stringify(state));
};

const clearLocalStorage = () => localStorage.clear();

export { REDUX_STATE, getPersistedState, setPersistedState, clearLocalStorage };
