import {
  SubAffiliate,
  RequestOptionsByDate,
  SubAffiliateTotal,
  CreateSubAffiliateRequest,
  CreateSubAffiliateValues
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { SnackbarActions, openSnackbar } from "modules/snackbar";

enum AffiliateSubAffiliatesActionTypes {
  FETCH_AFFILIATE_SUB_AFFILIATES_LOADING = "@affiliate/subaffiliates/fetch-subaffiliates-loading",
  FETCH_AFFILIATE_SUB_AFFILIATES_SUCCESS = "@affiliate/subaffiliates/fetch-subaffiliates-success",
  FETCH_AFFILIATE_SUB_AFFILIATES_FAILURE = "@affiliate/subaffiliates/fetch-subaffiliates-failure",
  ADD_AFFILIATE_SUB_AFFILIATE_SUCCESS = "@affiliate/subaffiliates/add-subaffiliate-success",
  UPDATE_AFFILIATE_SUB_AFFILIATE_SUCCESS = "@affiliate/subaffiliates/update-subaffiliate-success",
  DELETE_AFFILIATE_SUB_AFFILIATE_SUCCESS = "@affiliate/subaffiliates/delete-subaffiliate-success"
}

interface FetchAffiliateSubAffiliatesIsLoadingAction {
  type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_LOADING;
}

interface FetchAffiliateSubAffiliatesIsSuccessAction {
  type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_SUCCESS;
  payload: {
    total: number;
    totals: SubAffiliateTotal;
    items: SubAffiliate[];
  };
}

interface FetchAffiliateSubAffiliatesIsFailureAction {
  type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_FAILURE;
}

interface AddAffiliateSubAffiliateIsSuccessAction {
  type: AffiliateSubAffiliatesActionTypes.ADD_AFFILIATE_SUB_AFFILIATE_SUCCESS;
}

interface UpdateAffiliateSubAffiliateIsSuccessAction {
  type: AffiliateSubAffiliatesActionTypes.UPDATE_AFFILIATE_SUB_AFFILIATE_SUCCESS;
}

interface DeleteAffiliateSubAffiliateIsSuccessAction {
  type: AffiliateSubAffiliatesActionTypes.DELETE_AFFILIATE_SUB_AFFILIATE_SUCCESS;
}

type AffiliateSubAffiliatesActions =
  | FetchAffiliateSubAffiliatesIsLoadingAction
  | FetchAffiliateSubAffiliatesIsSuccessAction
  | FetchAffiliateSubAffiliatesIsFailureAction
  | AddAffiliateSubAffiliateIsSuccessAction
  | UpdateAffiliateSubAffiliateIsSuccessAction
  | DeleteAffiliateSubAffiliateIsSuccessAction;

type ActionsType = ThunkActionCreator<AffiliateSubAffiliatesActions>;
type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateSubAffiliatesActions | DrawersActions>
>;

const fetchAffiliateSubAffiliatesIsLoading =
  (): FetchAffiliateSubAffiliatesIsLoadingAction => ({
    type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_LOADING
  });

const fetchAffiliateSubAffiliatesIsSuccess = (affiliates: {
  total: number;
  totals: SubAffiliateTotal;
  items: SubAffiliate[];
}): FetchAffiliateSubAffiliatesIsSuccessAction => ({
  type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_SUCCESS,
  payload: { ...affiliates }
});

const fetchAffiliateSubAffiliatesIsFailure =
  (): FetchAffiliateSubAffiliatesIsFailureAction => ({
    type: AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_FAILURE
  });

const addAffiliateSubAffiliateIsSuccess =
  (): AddAffiliateSubAffiliateIsSuccessAction => ({
    type: AffiliateSubAffiliatesActionTypes.ADD_AFFILIATE_SUB_AFFILIATE_SUCCESS
  });

const updateAffiliateSubAffiliateIsSuccess =
  (): UpdateAffiliateSubAffiliateIsSuccessAction => ({
    type: AffiliateSubAffiliatesActionTypes.UPDATE_AFFILIATE_SUB_AFFILIATE_SUCCESS
  });

const deleteAffiliateSubAffiliateIsSuccess =
  (): DeleteAffiliateSubAffiliateIsSuccessAction => ({
    type: AffiliateSubAffiliatesActionTypes.DELETE_AFFILIATE_SUB_AFFILIATE_SUCCESS
  });

const fetchAffiliateSubAffiliates =
  (options: RequestOptionsByDate): ActionsType =>
  async dispatch => {
    dispatch(fetchAffiliateSubAffiliatesIsLoading());
    try {
      const { affiliates } =
        await api.admin.affiliate.subaffiliates.getSubAffiliates(options);
      dispatch(fetchAffiliateSubAffiliatesIsSuccess(affiliates));
    } catch (err) {
      dispatch(fetchAffiliateSubAffiliatesIsFailure());
    }
  };

const fetchAffiliateViewSubAffiliates =
  (year: number, month: number): ActionsType =>
  async dispatch => {
    dispatch(fetchAffiliateSubAffiliatesIsLoading());
    try {
      const { affiliates } = await api.affiliate.subaffiliates.getSubAffiliates(
        {
          year,
          month
        }
      );
      dispatch(fetchAffiliateSubAffiliatesIsSuccess(affiliates));
    } catch (err) {
      dispatch(fetchAffiliateSubAffiliatesIsFailure());
    }
  };

const addAffiliateSubAffiliate =
  (
    subId: number,
    data: CreateSubAffiliateRequest,
    options: RequestOptionsByDate,
    helpers: FormikHelpers<CreateSubAffiliateValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.subaffiliates.addSubAffiliate(
        options.affiliateId,
        subId,
        data
      );
      dispatch(addAffiliateSubAffiliateIsSuccess());
      dispatch(
        openSnackbar({
          type: "success",
          message: "Sub affiliate added successfully!"
        })
      );
      dispatch(fetchAffiliateSubAffiliates(options));
      helpers.resetForm();
    } finally {
      helpers.setSubmitting(false);
    }
  };

const updateAffiliateSubAffiliate =
  (
    subId: number,
    data: CreateSubAffiliateRequest,
    options: RequestOptionsByDate,
    helpers: FormikHelpers<CreateSubAffiliateValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.subaffiliates.updateSubAffiliate(
        options.affiliateId,
        subId,
        data
      );
      dispatch(updateAffiliateSubAffiliateIsSuccess());
      dispatch(
        openSnackbar({
          type: "success",
          message: "Commission share updated!"
        })
      );
      dispatch(fetchAffiliateSubAffiliates(options));
      dispatch(closeDrawer());
    } finally {
      helpers.setSubmitting(false);
    }
  };

const deleteAffiliateSubAffiliate =
  (subId: number, options: RequestOptionsByDate): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.affiliate.subaffiliates.deleteSubAffiliate(
      options.affiliateId,
      subId
    );
    dispatch(deleteAffiliateSubAffiliateIsSuccess());
    dispatch(
      openSnackbar({
        type: "success",
        message: "Sub affiliate removed!"
      })
    );
    dispatch(fetchAffiliateSubAffiliates(options));
  };

interface AffiliateSubAffiliatesState {
  error: boolean;
  isLoading: boolean;
  total: number;
  totals?: SubAffiliateTotal;
  data: SubAffiliate[];
}

const initialState = {
  error: false,
  isLoading: false,
  total: 0,
  totals: undefined,
  data: []
};

const reducer: Reducer<
  AffiliateSubAffiliatesState,
  AffiliateSubAffiliatesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.total,
        totals: action.payload.totals,
        data: action.payload.items
      };
    case AffiliateSubAffiliatesActionTypes.FETCH_AFFILIATE_SUB_AFFILIATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliateSubAffiliatesState = (state: AppState) =>
  state.affiliate.subaffiliates;

const getAffiliateSubAffiliatesSelectOptions = (state: AppState) => {
  const subAffiliatesIds = state.affiliate.subaffiliates.data.map(
    ({ affiliateId }) => String(affiliateId)
  );

  const affiliates = state.affiliates.list
    .map(({ affiliateId, affiliateName }) => ({
      label: affiliateName,
      value: String(affiliateId)
    }))
    .filter(({ value }) => !subAffiliatesIds.includes(value));

  return affiliates;
};

export {
  reducer,
  fetchAffiliateSubAffiliates,
  addAffiliateSubAffiliate,
  updateAffiliateSubAffiliate,
  deleteAffiliateSubAffiliate,
  fetchAffiliateViewSubAffiliates,
  getAffiliateSubAffiliatesState,
  getAffiliateSubAffiliatesSelectOptions
};
