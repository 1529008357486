import { CreateAffiliateLinkValues, AffiliateLink } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";

import AffiliateLinks, {
  fetchAffiliateViewLinks,
  getAffiliateLinksState
} from "modules/affiliate-links";
import {
  createAffiliateViewLink,
  deleteAffiliateViewLink
} from "modules/affiliate-link";
import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";

const AffiliateViewLinksPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { brand } = useSelector(getAffiliateLinksState);

  const handleLinkClick = useCallback(
    ({ linkId }: AffiliateLink) => {
      history.push(`/links/${linkId}`);
    },
    [history]
  );

  const handleCreateLink = useCallback(
    (
      values: CreateAffiliateLinkValues,
      helpers: FormikHelpers<CreateAffiliateLinkValues>
    ) => {
      const data = {
        name: values.name,
        brandId: values.brandId,
        landingPage: values.landingPage
      };
      dispatch(createAffiliateViewLink(brand, data, helpers));
    },
    [dispatch, brand]
  );

  const handleDeleteLink = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      ({ linkId, name }: AffiliateLink) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "delete-link",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              content: name,
              onConfirm: () => dispatch(deleteAffiliateViewLink(linkId, brand))
            }
          })
        );
      },
    [dispatch, brand]
  );

  const handleCreateDrawerOpen = useCallback(() => {
    dispatch(
      openDrawer("create-user-link", {
        title: "Add new link",
        onSubmit: handleCreateLink
      })
    );
  }, [dispatch, handleCreateLink]);

  useEffect(() => {
    dispatch(fetchAffiliateViewLinks(brand));
  }, [dispatch, brand]);

  return (
    <AffiliateLinks
      handleDeleteLink={handleDeleteLink}
      handleLinkClick={handleLinkClick}
      handleCreateDrawerOpen={handleCreateDrawerOpen}
    />
  );
};

export default AffiliateViewLinksPage;
