import { useState, useCallback, MouseEvent } from "react";

export default function () {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenPopup = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget),
    []
  );
  const handleClosePopup = useCallback(() => setAnchorEl(null), []);

  return { anchorEl, handleOpenPopup, handleClosePopup };
}
