import { useSelector } from "react-redux";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { getAffiliateOverviewState } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { isLoading, error, data } = useSelector(getAffiliateOverviewState);

  return {
    classes,
    isMobile,
    isLoading,
    error,
    statistics: data
  };
}
