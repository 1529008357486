import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box, { BoxProps } from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import NotFoundIcon from "assets/icons/NotFound";
import ServerIcon from "assets/icons/Server";

import useError from "./useError";

const getPageProps = (code: number) => {
  switch (true) {
    case code === 401: {
      return {
        text: "Access Denied.",
        tip: "You will be redirecting to login page.",
        icon: <ServerIcon />
      };
    }
    case code >= 500: {
      return {
        text: "Server Error.",
        tip: "Please try again in a few moments.",
        icon: <ServerIcon />
      };
    }
    case code >= 400 && code < 500:
    default: {
      return {
        text: "Oops! The page you were looking for doesn’t exist.",
        tip: "You have misstyped the address or the page may have moved.",
        icon: <NotFoundIcon />
      };
    }
  }
};

const Component: FC<BoxProps> = props => {
  const { classes, status, linkUrl } = useError();
  const { text, tip, icon } = getPageProps(status!);

  return (
    <Box className={classes.container} {...props}>
      <Box className={classes.leftContainer}>
        <Box className={classes.wrapper}>
          <Typography className={classes.oops}>{text}</Typography>
          <Typography className={classes.tip}>{tip}</Typography>
          {status && (status === 401 || status >= 500) ? null : (
            <Link className={classes.homeLink} to={linkUrl}>
              <Button className={classes.homeButton} color="secondary">
                go to homepage
              </Button>
            </Link>
          )}
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.rightContainer}>
        <Box>{icon}</Box>
      </Box>
    </Box>
  );
};

export default Component;
