import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { HTMLColumnProps } from "../../types";

const HTMLColumn = withStyles(() =>
  createStyles({
    root: {
      fontSize: 12,
      lineHeight: "16px"
    }
  })
)(({ value, ...rest }: HTMLColumnProps) => (
  <Box {...rest}>
    <div
      dangerouslySetInnerHTML={{
        __html: String(value)
      }}
    />
  </Box>
));

export default HTMLColumn;
