import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

const ImageFileIcon: FC<SvgIconProps> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.00065 1.66699C4.08877 1.66699 3.33398 2.42178 3.33398 3.33366V16.667C3.33398 17.5789 4.08877 18.3337 5.00065 18.3337H15.0007C15.9125 18.3337 16.6673 17.5789 16.6673 16.667V6.66699L11.6673 1.66699H5.00065ZM5.00065 3.33366H10.834V7.50033H15.0007V16.667H5.00065V3.33366ZM11.2507 11.112L9.16732 13.8903L7.91732 12.2236L5.83398 15.0003H14.1673L11.2507 11.112Z" />
  </svg>
);

export default ImageFileIcon;
