import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FileIcon from "@material-ui/icons/DescriptionRounded";
import Skeleton from "@material-ui/lab/Skeleton";
import isEmpty from "lodash/isEmpty";

import Attachment from "components/attachment";

import { useStyles } from "../styles";

interface Props {
  isLoading: boolean;
  sharedFiles: string[];
}

const items = Array.from(Array(10));
const generateSkeletonFiles = () =>
  items.map((_, idx) => <Skeleton key={idx} width="100%" height={36} />);

const SharedFiles: FC<Props> = ({ isLoading, sharedFiles }) => {
  const classes = useStyles();

  return (
    <Box className={classes.sharedFilesContainer}>
      <Typography className={classes.title}>Shared Files</Typography>
      <Typography className={classes.tip}>
        All files from these affiliate logs. Add any file into the message pane
        and the file will be shown here.
      </Typography>
      <Box className={classes.sharedFilesList}>
        {isLoading ? (
          generateSkeletonFiles()
        ) : !isEmpty(sharedFiles) ? (
          sharedFiles.map((fileName, idx) => (
            <Attachment
              key={idx}
              tooltipPlacement="left-end"
              fileName={fileName}
            />
          ))
        ) : (
          <Box className={classes.noFilesContainer}>
            <Typography className={classes.noFilesText}>
              There are no attachments
            </Typography>
            <FileIcon className={classes.noFilesIcon} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SharedFiles;
