import { Plan as BasePlan, PlanRule, UpdateAffiliateDealRequest } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { BRANDS } from "constants/brands";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { fetchAffiliateDeals } from "modules/affiliate-deals";

interface AffiliateDeal extends BasePlan {
  brandId?: BRANDS;
  rules: PlanRule[];
}

enum AffiliateDealActionTypes {
  AFFILIATE_DEAL_LOADING = "@affiliate/deal/loading",
  UPDATE_AFFILIATE_DEAL_SUCCESS = "@affiliate/deal/update-deal-success",
  UPDATE_AFFILIATE_PLAYER_DEAL_SUCCESS = "@affiliate/deal/update-player-deal-success",
  UPDATE_AFFILIATE_DEAL_FAILURE = "@affiliate/deal/update-deal-failure",
  RECEIVE_AFFILIATE_DEAL = "@affiliate/deal/receive-deal"
}

interface UpdateAffiliateDealIsLoadingAction {
  type: AffiliateDealActionTypes.AFFILIATE_DEAL_LOADING;
}

interface UpdateAffiliateDealIsSuccessAction {
  type: AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_SUCCESS;
  payload: { deal: AffiliateDeal };
}

interface UpdateAffiliatePlayerDealIsSuccessAction {
  type: AffiliateDealActionTypes.UPDATE_AFFILIATE_PLAYER_DEAL_SUCCESS;
}

interface UpdateAffiliateDealIsFailureAction {
  type: AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_FAILURE;
}

interface ReceiveAffiliateDealAction {
  type: AffiliateDealActionTypes.RECEIVE_AFFILIATE_DEAL;
  payload: { deal: AffiliateDeal };
}

type AffiliateDealActions =
  | UpdateAffiliateDealIsLoadingAction
  | UpdateAffiliateDealIsSuccessAction
  | UpdateAffiliatePlayerDealIsSuccessAction
  | UpdateAffiliateDealIsFailureAction
  | ReceiveAffiliateDealAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateDealActions | DrawersActions>
>;

const updateAffiliateDealIsLoading =
  (): UpdateAffiliateDealIsLoadingAction => ({
    type: AffiliateDealActionTypes.AFFILIATE_DEAL_LOADING
  });

const updateAffiliateDealIsSuccess = (
  deal: AffiliateDeal
): UpdateAffiliateDealIsSuccessAction => ({
  type: AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_SUCCESS,
  payload: { deal }
});

const updateAffiliatePlayerDealIsSuccess =
  (): UpdateAffiliatePlayerDealIsSuccessAction => ({
    type: AffiliateDealActionTypes.UPDATE_AFFILIATE_PLAYER_DEAL_SUCCESS
  });

const updateAffiliateDealIsFailure =
  (): UpdateAffiliateDealIsFailureAction => ({
    type: AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_FAILURE
  });

const updateAffiliateDeal =
  (data: UpdateAffiliateDealRequest): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(updateAffiliateDealIsLoading());
    try {
      const { deal } = await api.admin.affiliate.deals.updateDeal(data);

      dispatch(
        openSnackbar({
          type: "success",
          message: `Deal successfully changed to: ${deal.name}`
        })
      );
      dispatch(fetchAffiliateDeals(data.affiliateId));
      dispatch(updateAffiliateDealIsSuccess(deal));
      dispatch(closeDrawer());
    } catch (err) {
      dispatch(updateAffiliateDealIsFailure());
    }
  };

const updateAffiliatePlayerDeal =
  (
    affiliateId: number,
    playerId: number,
    planId: number
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(updateAffiliateDealIsLoading());
    try {
      await api.admin.affiliate.players.updatePlayer(affiliateId, playerId, {
        planId
      });
      dispatch(
        openSnackbar({ type: "success", message: "Player deal updated!" })
      );
      dispatch(updateAffiliatePlayerDealIsSuccess());
      dispatch(closeDrawer());
    } catch (err) {
      dispatch(updateAffiliateDealIsFailure());
    }
  };

const receiveAffiliateDeal = (
  deal: AffiliateDeal
): ReceiveAffiliateDealAction => ({
  type: AffiliateDealActionTypes.RECEIVE_AFFILIATE_DEAL,
  payload: { deal }
});

interface AffiliateDealState {
  error: boolean;
  isLoading: boolean;
  data?: AffiliateDeal;
}

const initialState: AffiliateDealState = {
  error: false,
  isLoading: false,
  data: undefined
};

const reducer: Reducer<AffiliateDealState, AffiliateDealActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateDealActionTypes.AFFILIATE_DEAL_LOADING:
      return {
        error: false,
        isLoading: true,
        data: undefined
      };
    case AffiliateDealActionTypes.RECEIVE_AFFILIATE_DEAL:
      return {
        error: false,
        isLoading: false,
        data: action.payload.deal
      };
    case AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.deal
      };
    case AffiliateDealActionTypes.UPDATE_AFFILIATE_PLAYER_DEAL_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false
      };
    case AffiliateDealActionTypes.UPDATE_AFFILIATE_DEAL_FAILURE:
      return {
        ...initialState,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliateDealState = (state: AppState) => state.affiliate.deal;

export {
  reducer,
  updateAffiliateDeal,
  updateAffiliatePlayerDeal,
  receiveAffiliateDeal,
  getAffiliateDealState
};
