import {
  AffiliateFee,
  CreateAffiliateFeesValues,
  UpdateAffiliateFeesRequest
} from "@types";

import { Reducer } from "redux";
import { parseISO, subDays, addDays, startOfMonth, endOfMonth } from "date-fns";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { closeDrawer, DrawersActions } from "drawers";
import { FormikHelpers } from "formik";

enum AffiliateFeesActionTypes {
  FETCH_AFFILIATE_FEES_LOADING = "@affiliate/fees/fetch-fees-loading",
  FETCH_AFFILIATE_FEES_SUCCESS = "@affiliate/fees/fetch-fees-success",
  FETCH_AFFILIATE_FEES_FAILURE = "@affiliate/fees/fetch-fees-failure",
  UPDATE_AFFILIATE_FEES_LOADING = "@affiliate/fee/update-fees-loading",
  UPDATE_AFFILIATE_FEES_SUCCESS = "@affiliate/fee/update-fees-success",
  UPDATE_AFFILIATE_FEES_FAILURE = "@affiliate/fee/update-fees-failure"
}

interface FetchAffiliateFeesIsLoadingAction {
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_LOADING;
}

interface FetchAffiliateFeesIsSuccessAction {
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_SUCCESS;
  payload: { fees: AffiliateFee[] };
}

interface FetchAffiliateFeesIsFailureAction {
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_FAILURE;
}

interface UpdateAffiliateFeesIsLoadingAction {
  type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_LOADING;
}

interface UpdateAffiliateFeesIsSuccessAction {
  type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_SUCCESS;
  payload: { fees: AffiliateFee[] };
}

interface UpdateAffiliateFeesIsFailureAction {
  type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_FAILURE;
}

type AffiliateFeesActions =
  | FetchAffiliateFeesIsLoadingAction
  | FetchAffiliateFeesIsSuccessAction
  | FetchAffiliateFeesIsFailureAction
  | UpdateAffiliateFeesIsLoadingAction
  | UpdateAffiliateFeesIsSuccessAction
  | UpdateAffiliateFeesIsFailureAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateFeesActions | DrawersActions>
>;

const fetchAffiliateFeesIsLoading = (): FetchAffiliateFeesIsLoadingAction => ({
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_LOADING
});

const fetchAffiliateFeesIsSuccess = (
  fees: AffiliateFee[]
): FetchAffiliateFeesIsSuccessAction => ({
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_SUCCESS,
  payload: { fees }
});

const fetchAffiliateFeesIsFailure = (): FetchAffiliateFeesIsFailureAction => ({
  type: AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_FAILURE
});

const fetchAffiliateFees =
  (id: number): ThunkActionCreator<AffiliateFeesActions> =>
  async dispatch => {
    dispatch(fetchAffiliateFeesIsLoading());
    try {
      const { fees } = await api.admin.affiliate.fees.getFees(id);
      dispatch(
        fetchAffiliateFeesIsSuccess(
          fees.map(({ periodFrom, periodTo, ...rest }) => {
            return {
              ...rest,
              periodFrom: startOfMonth(addDays(parseISO(periodFrom), 1)),
              periodTo: endOfMonth(subDays(parseISO(periodTo), 1))
            };
          })
        )
      );
    } catch (err) {
      dispatch(fetchAffiliateFeesIsFailure());
    }
  };

const fetchAffiliateViewFees =
  (): ThunkActionCreator<AffiliateFeesActions> => async dispatch => {
    dispatch(fetchAffiliateFeesIsLoading());
    try {
      const { fees } = await api.affiliate.fees.getFees();
      dispatch(
        fetchAffiliateFeesIsSuccess(
          // TODO 456 might not need transfor for when fetching.
          fees.map(({ periodFrom, periodTo, ...rest }) => {
            return {
              ...rest,
              periodFrom: startOfMonth(addDays(parseISO(periodFrom), 1)),
              periodTo: endOfMonth(subDays(parseISO(periodTo), 1))
            };
          })
        )
      );
    } catch (err) {
      dispatch(fetchAffiliateFeesIsFailure());
    }
  };

const updateAffiliateFeesIsLoading =
  (): UpdateAffiliateFeesIsLoadingAction => ({
    type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_LOADING
  });

const updateAffiliateFeesIsSuccess = (
  fees: AffiliateFee[]
): UpdateAffiliateFeesIsSuccessAction => ({
  type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_SUCCESS,
  payload: { fees }
});

const updateAffiliateFeesIsFailure =
  (): UpdateAffiliateFeesIsFailureAction => ({
    type: AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_FAILURE
  });

const updateAffiliateFees =
  (
    data: UpdateAffiliateFeesRequest,
    formikHelpers: FormikHelpers<CreateAffiliateFeesValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(updateAffiliateFeesIsLoading());
    try {
      const { fees } = await api.admin.affiliate.fees.updateFees(data);
      dispatch(
        openSnackbar({
          type: "success",
          message: `Affiliates fees updated successfully`
        })
      );
      dispatch(fetchAffiliateFees(data.affiliateId));
      dispatch(
        updateAffiliateFeesIsSuccess(
          fees.map(({ periodFrom, periodTo, ...rest }) => {
            return {
              ...rest,
              // these are important, cause timezones suck.
              periodFrom: startOfMonth(addDays(parseISO(periodFrom), 1)),
              periodTo: endOfMonth(subDays(parseISO(periodTo), 1))
            };
          })
        )
      );
      dispatch(closeDrawer());
    } catch (err) {
      dispatch(updateAffiliateFeesIsFailure());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

interface AffiliateFeesState {
  error: boolean;
  isLoading: boolean;
  data: AffiliateFee[];
}

const initialState: AffiliateFeesState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<AffiliateFeesState, AffiliateFeesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.fees
      };
    case AffiliateFeesActionTypes.FETCH_AFFILIATE_FEES_FAILURE:
      return {
        error: true,
        isLoading: false,
        data: []
      };
    case AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.fees
      };
    case AffiliateFeesActionTypes.UPDATE_AFFILIATE_FEES_FAILURE:
      return {
        ...initialState,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliateFeesState = (state: AppState) => state.affiliate.fees;
const getAffiliateFeeStateById = (state: AppState) => (id: string) => ({
  error: state.affiliate.fees.error,
  isLoading: state.affiliate.fees.isLoading,
  affiliateFee: state.affiliate.fees.data.find(({ brandId }) => brandId === id)
});
const getAffiliateFeesStateById = (state: AppState) => (id: string) => ({
  error: state.affiliate.fees.error,
  isLoading: state.affiliate.fees.isLoading,
  affiliateFees: state.affiliate.fees.data.filter(
    ({ brandId }) => brandId === id
  )
});

export {
  reducer,
  fetchAffiliateFees,
  fetchAffiliateViewFees,
  updateAffiliateFees,
  getAffiliateFeesState,
  getAffiliateFeesStateById,
  getAffiliateFeeStateById
};
