import { Reducer } from "redux";

import { AppState } from "store";

import { AddSubAffiliateProps } from "./add-subaffiliate";
import { EditCommissionProps } from "./edit-commission";
import { AffiliateDetailsProps } from "./affiliate-details";
import { ChangeDealProps } from "./change-deal";
import { MovePlayersProps } from "./move-players";
import { CreateLandingProps } from "./create-landing";
import { CreateLinkProps } from "./create-link";
import { CreatePlanProps } from "./create-plan";
import { CreatePaymentProps } from "./create-payment";
import { CreateUserLinkProps } from "./create-user-link";
import { EditAffiliateProps } from "./edit-affiliate";
import { EditPaymentProps } from "./edit-payment";
import { UserDealDetailsProps } from "./user-deal-details";
import { CreateSubAccountProps } from "./create-subaccount";
import { SubaccountsProps } from "./subaccounts";
import { TableFilterProps } from "./table-filter";
import { CreateCallbackProps } from "./create-callback";
import { CreateFeeProps } from "./create-fee";

type Drawer =
  | "edit-affiliate"
  | "change-deal"
  | "edit-link"
  | "edit-user-link"
  | "create-link"
  | "create-user-link"
  | "user-deal-details"
  | "create-payment"
  | "create-landing"
  | "edit-landing"
  | "create-plan"
  | "edit-plan"
  | "edit-payment-information"
  | "affiliate-details"
  | "add-subaffiliate"
  | "edit-commission"
  | "move-players"
  | "create-subaccount"
  | "subaccounts"
  | "table-filter"
  | "create-callback"
  | "edit-callback"
  | "create-fee"
  | "edit-fee"
  | "change-affiliate-fee"
  | "user-affiliate-fee-details";

type DrawerPayload = (
  | AddSubAffiliateProps
  | EditCommissionProps
  | AffiliateDetailsProps
  | ChangeDealProps
  | MovePlayersProps
  | CreateLandingProps
  | CreateLinkProps
  | CreatePlanProps
  | CreatePaymentProps
  | EditPaymentProps
  | CreateUserLinkProps
  | EditAffiliateProps
  | UserDealDetailsProps
  | CreateSubAccountProps
  | SubaccountsProps
  | TableFilterProps
  | CreateCallbackProps
  | CreateFeeProps
) & {
  backURL?: string;
};

enum DrawersActionTypes {
  OPEN_DRAWER = "@drawers/open-drawer",
  CLOSE_DRAWER = "@drawers/close-drawer"
}

interface OpenDrawerAction<T = DrawerPayload> {
  type: DrawersActionTypes.OPEN_DRAWER;
  payload: {
    drawer: Drawer;
    payload?: T;
  };
}

interface CloseDrawerAction {
  type: DrawersActionTypes.CLOSE_DRAWER;
}

export type DrawersActions = OpenDrawerAction | CloseDrawerAction;

const openDrawer = <T = DrawerPayload>(
  drawer: Drawer,
  payload?: T
): OpenDrawerAction<T> => ({
  type: DrawersActionTypes.OPEN_DRAWER,
  payload: { drawer, payload }
});

const closeDrawer = (): CloseDrawerAction => ({
  type: DrawersActionTypes.CLOSE_DRAWER
});

interface DrawersState {
  isOpen: boolean;
  drawer?: Drawer;
  payload?: DrawerPayload;
}

const initialState: DrawersState = {
  isOpen: false,
  drawer: undefined,
  payload: undefined
};

const reducer: Reducer<DrawersState, DrawersActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DrawersActionTypes.OPEN_DRAWER:
      return {
        isOpen: true,
        drawer: action.payload.drawer,
        payload: action.payload.payload
      };
    case DrawersActionTypes.CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};

const getDrawersState = (state: AppState) => state.drawers;

export { reducer, openDrawer, closeDrawer, getDrawersState };
