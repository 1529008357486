import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

const EmptyAffiliate: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        Oh, you’ve selected nothing
      </Typography>
      <Typography className={classes.text}>
        Choose affiliate from the list
      </Typography>
    </Box>
  );
};

export default EmptyAffiliate;
