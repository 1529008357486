import React from "react";
import cn from "classnames";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { PAYMENT_STATUSES } from "constants/payments";
import { BRANDS, BRAND_NAMES } from "constants/brands";
import formatMoney from "utils/formatMoney";
import getBrandColorClassName from "utils/getBrandColorClassName";

import { ColumnProps } from "../../types";

const columnStyles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      lineHeight: "16px",
      overflow: "inherit",
      textOverflow: "inherit",
      whiteSpace: "inherit",
      fontWeight: "inherit",
      "&.status-text": {
        fontWeight: 500
      },
      "&.active-status-text": {
        fontWeight: 500
      },
      "&.paid": {
        color: theme.colors.teal
      },
      "&.more": {
        color: theme.colors.orange
      },
      "&.confirmed": {
        color: theme.colors.orange
      },
      "&.less": {
        color: theme.colors.red
      },
      "&.unconfirmed": {
        color: theme.colors.red
      },
      "&.blocked": {
        color: theme.colors.black61
      },
      "&.equal": {
        color: theme.colors.black9e
      },
      "&.brand-column": {
        fontSize: 12,
        lineHeight: "16px",
        whiteSpace: "nowrap"
      }
    }
  });

const Column = withStyles(columnStyles)(
  ({ value, type, comparedName: _comparedName, ...rest }: ColumnProps) => {
    switch (type) {
      case "text":
        return <Typography {...rest}>{value}</Typography>;
      case "boolean":
        return (
          <Typography
            {...rest}
            className={cn("active-status-text", {
              paid: value,
              unconfirmed: !value
            })}
          >
            {value ? "Yes" : "No"}
          </Typography>
        );
      case "status":
        return (
          <Typography
            {...rest}
            className={cn("status-text", {
              paid: String(value) === PAYMENT_STATUSES.paid,
              blocked: String(value) === PAYMENT_STATUSES.blocked,
              confirmed: String(value) === PAYMENT_STATUSES.confirmed,
              unconfirmed: String(value) === PAYMENT_STATUSES.unconfirmed
            })}
          >
            {value}
          </Typography>
        );
      case "date":
        return (
          <Typography {...rest}>
            {format(parseISO(String(value)), "dd MMM yyyy")}
          </Typography>
        );
      case "currency":
        return (
          <Typography color={value! < 0 ? "error" : "initial"} {...rest}>
            {formatMoney.from(Number(value))}
          </Typography>
        );
      case "percent":
        return (
          <Typography color={value! < 0 ? "error" : "initial"} {...rest}>
            {value !== null ? `${value}%` : "Ladder"}
          </Typography>
        );
      case "feePercent":
        return (
          <Typography color={value! < 0 ? "error" : "initial"} {...rest}>
            {value !== null ? `${value}%` : "-"}
          </Typography>
        );
      case "brand":
        return (
          <Typography
            {...rest}
            className={`brand-column ${getBrandColorClassName(
              value as BRANDS
            )}`}
          >
            {BRAND_NAMES[String(value) as BRANDS]}
          </Typography>
        );
    }
  }
);

export default Column;
