import { AffiliateLink } from "@types";

import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { useRoles } from "hooks";
import { BRANDS } from "constants/brands";
import { LINK_URL } from "constants/variables";

import { getAffiliateLinksState, setAffiliateLinksBrandFilter } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const dispatch = useDispatch();
  const { isAdminLoggedIn } = useRoles();
  const classes = useStyles({ isAdmin: isAdminLoggedIn });
  const { isLoading, error, brand, data } = useSelector(getAffiliateLinksState);

  const [searchQuery, setQuery] = useState<string>("");
  const filteredLinks = data.filter(item =>
    ["name", "code", "landingPage"].some(key =>
      `${key === "code" ? LINK_URL : ""}${String(
        item[key as keyof AffiliateLink]
      )}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleBrandFilter = useCallback(
    (brand: BRANDS | null) => {
      dispatch(setAffiliateLinksBrandFilter(brand));
    },
    [dispatch]
  );

  return {
    classes,
    error,
    isLoading,
    isEmpty: isEmpty(data),
    links: filteredLinks,
    brandFilterProps: {
      brand: brand,
      onChange: handleBrandFilter
    },
    searchQuery,
    handleSearch
  };
}
