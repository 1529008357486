import React, { FC, ReactElement } from "react";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";
import BrandFilter, { BrandFilterProps } from "components/brand-filter";

interface Props {
  disabled?: boolean;
  filterProps: BrandFilterProps;
  csvProps: DownloadCsvButtonProps;
  datepicker?: ReactElement;
}

const SearchButtons: FC<Props> = ({
  disabled,
  csvProps,
  filterProps,
  datepicker
}) => {
  return (
    <>
      <BrandFilter {...filterProps} />
      {datepicker}
      <DownloadCsvButton disabled={disabled} {...csvProps} />
    </>
  );
};

export default SearchButtons;
