import {
  CreatePaymentRequest,
  AffiliateInvoicesResponse,
  AffiliateDraftInvoice,
  AffiliateInvoices
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import { PAYMENT_STATUSES } from "constants/payments";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { DrawersActions, closeDrawer } from "drawers";

enum AffiliateInvoicesActionTypes {
  FETCH_AFFILIATE_INVOICES_LOADING = "@affiliate/invoices/fetch-invoices-loading",
  FETCH_AFFILIATE_INVOICES_SUCCESS = "@affiliate/invoices/fetch-invoices-success",
  FETCH_AFFILIATE_INVOICES_FAILURE = "@affiliate/invoices/fetch-invoices-failure",
  CREATE_AFFILIATE_PAYMENT_SUCCESS = "@affiliate/invoices/create-payment-success",
  CONFIRM_AFFILIATE_DRAFT_PAYMENTS_SUCCESS = "@affiliate/invoices/confirm-draft-payments-success",
  MARK_AS_PAID_AFFILIATE_PAYMENTS_SUCCESS = "@affiliate/invoices/mark-as-paid-payments-success"
}

interface FetchAffiliateInvoicesIsLoadingAction {
  type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_LOADING;
}

interface FetchAffiliateInvoicesIsSuccessAction {
  type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_SUCCESS;
  payload: AffiliateInvoicesResponse;
}

interface FetchAffiliateInvoicesIsFailureAction {
  type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_FAILURE;
}

interface CreateAffiliatePaymentIsSuccessAction {
  type: AffiliateInvoicesActionTypes.CREATE_AFFILIATE_PAYMENT_SUCCESS;
}

interface ConfirmAffiliateDraftPaymentsIsSuccessAction {
  type: AffiliateInvoicesActionTypes.CONFIRM_AFFILIATE_DRAFT_PAYMENTS_SUCCESS;
}

interface MarkAsPaidAffiliatePaymentsIsSuccessAction {
  type: AffiliateInvoicesActionTypes.MARK_AS_PAID_AFFILIATE_PAYMENTS_SUCCESS;
  payload: { invoiceId: number };
}

type AffiliateInvoicesActions =
  | FetchAffiliateInvoicesIsLoadingAction
  | FetchAffiliateInvoicesIsSuccessAction
  | FetchAffiliateInvoicesIsFailureAction
  | CreateAffiliatePaymentIsSuccessAction
  | ConfirmAffiliateDraftPaymentsIsSuccessAction
  | MarkAsPaidAffiliatePaymentsIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateInvoicesActions | DrawersActions>
>;

const fetchAffiliateInvoicesIsLoading =
  (): FetchAffiliateInvoicesIsLoadingAction => ({
    type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_LOADING
  });

const fetchAffiliateInvoicesIsSuccess = (
  data: AffiliateInvoicesResponse
): FetchAffiliateInvoicesIsSuccessAction => ({
  type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_SUCCESS,
  payload: data
});

const fetchAffiliateInvoicesIsFailure =
  (): FetchAffiliateInvoicesIsFailureAction => ({
    type: AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_FAILURE
  });

const createAffiliatePaymentIsSuccess =
  (): CreateAffiliatePaymentIsSuccessAction => ({
    type: AffiliateInvoicesActionTypes.CREATE_AFFILIATE_PAYMENT_SUCCESS
  });

const confirmAffiliateDraftPaymentsIsSuccess =
  (): ConfirmAffiliateDraftPaymentsIsSuccessAction => ({
    type: AffiliateInvoicesActionTypes.CONFIRM_AFFILIATE_DRAFT_PAYMENTS_SUCCESS
  });

const markAsPaidAffiliatePaymentsIsSuccess = (
  invoiceId: number
): MarkAsPaidAffiliatePaymentsIsSuccessAction => ({
  type: AffiliateInvoicesActionTypes.MARK_AS_PAID_AFFILIATE_PAYMENTS_SUCCESS,
  payload: { invoiceId }
});

const fetchAffiliateInvoices =
  (id: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    dispatch(fetchAffiliateInvoicesIsLoading());
    try {
      const response = await api.admin.affiliate.invoices.getInvoices(id);
      dispatch(fetchAffiliateInvoicesIsSuccess(response));
    } catch (err) {
      dispatch(fetchAffiliateInvoicesIsFailure());
    }
  };

const createAffiliatePayment =
  (
    id: number,
    data: CreatePaymentRequest,
    helpers: FormikHelpers<CreatePaymentRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.invoices.createPayment(id, data);
      dispatch(createAffiliatePaymentIsSuccess());
      dispatch(openSnackbar({ type: "success", message: "Payment created!" }));
      dispatch(fetchAffiliateInvoices(id));
      dispatch(closeDrawer());
      helpers.resetForm();
    } finally {
      helpers.setSubmitting(false);
    }
  };

const confirmAffiliateDraftPayments =
  (affiliateId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    const response = await api.admin.affiliate.invoices.confirmInvoice(
      affiliateId
    );

    if (response) {
      dispatch(confirmAffiliateDraftPaymentsIsSuccess());
      dispatch(
        openSnackbar({
          type: "success",
          message: "Payment successful confirmed"
        })
      );
      dispatch(fetchAffiliateInvoices(affiliateId));
    }
  };

const markAsPaidAffiliatePayments =
  (affiliateId: number, invoiceId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    const response = await api.admin.affiliate.invoices.markInvoiceAsPaid(
      affiliateId,
      invoiceId
    );
    if (response.ok) {
      dispatch(markAsPaidAffiliatePaymentsIsSuccess(invoiceId));
      dispatch(
        openSnackbar({
          type: "success",
          message: "Payments successfully marked"
        })
      );
    }
  };

interface AffiliateInvoicesState {
  error: boolean;
  isLoading: boolean;
  draft: AffiliateDraftInvoice;
  invoices: AffiliateInvoices;
}

const initialState = {
  error: false,
  isLoading: false,
  draft: {
    canConfirm: false,
    payments: {
      total: 0,
      items: [],
      totals: {
        amount: 0
      }
    }
  },
  invoices: {
    items: [],
    total: 0,
    totals: {
      creditedAmount: 0,
      paidAmount: 0
    }
  }
};

const reducer: Reducer<AffiliateInvoicesState, AffiliateInvoicesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        draft: action.payload.draft,
        invoices: action.payload.invoices
          ? action.payload.invoices
          : state.invoices
      };
    case AffiliateInvoicesActionTypes.FETCH_AFFILIATE_INVOICES_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case AffiliateInvoicesActionTypes.MARK_AS_PAID_AFFILIATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          items: state.invoices.items.map(
            ({
              invoiceId,
              canMarkAsPaid,
              creditedAmount,
              paidAmount,
              status,
              ...rest
            }) => {
              const isActive = invoiceId === action.payload.invoiceId;
              return {
                ...rest,
                invoiceId,
                creditedAmount,
                paidAmount: isActive ? creditedAmount : paidAmount,
                canMarkAsPaid: isActive ? false : canMarkAsPaid,
                status: isActive ? PAYMENT_STATUSES.paid : status
              };
            }
          )
        }
      };
    default:
      return state;
  }
};

const getAffiliateInvoicesState = (state: AppState) => state.affiliate.invoices;
const getAffiliateInvoicesDraftState = (state: AppState) => ({
  error: state.affiliate.invoices.error,
  isLoading: state.affiliate.invoices.isLoading,
  canConfirm: state.affiliate.invoices.draft.canConfirm,
  totals: state.affiliate.invoices.draft.payments.totals,
  payments: state.affiliate.invoices.draft.payments.items
});
const getAffiliateInvoiceStateById = (state: AppState) => (id: string) => ({
  error: state.affiliate.invoices.error,
  isLoading: state.affiliate.invoices.isLoading,
  invoice: state.affiliate.invoices.invoices.items.find(
    ({ invoiceId }) => invoiceId === Number(id)
  )
});

export {
  reducer,
  fetchAffiliateInvoices,
  createAffiliatePayment,
  confirmAffiliateDraftPayments,
  markAsPaidAffiliatePayments,
  getAffiliateInvoicesState,
  getAffiliateInvoiceStateById,
  getAffiliateInvoicesDraftState
};
