import { Fee as BaseFee, FeeRule } from "@types";

import { Reducer } from "redux";

import { AppState } from "store";
import { BRANDS } from "constants/brands";

interface AffiliateFee extends BaseFee {
  brandId?: BRANDS;
  rules: FeeRule[];
}

enum AffiliateFeeActionTypes {
  AFFILIATE_FEE_LOADING = "@affiliate/fee/loading",
  RECEIVE_AFFILIATE_FEE = "@affiliate/fee/receive-fee"
}

interface AffiliateFeeIsLoadingAction {
  type: AffiliateFeeActionTypes.AFFILIATE_FEE_LOADING;
}

interface ReceiveAffiliateFeeAction {
  type: AffiliateFeeActionTypes.RECEIVE_AFFILIATE_FEE;
  payload: { fee: AffiliateFee };
}

type AffiliateFeeActions =
  | AffiliateFeeIsLoadingAction
  | ReceiveAffiliateFeeAction;

const receiveAffiliateFee = (fee: AffiliateFee): ReceiveAffiliateFeeAction => ({
  type: AffiliateFeeActionTypes.RECEIVE_AFFILIATE_FEE,
  payload: { fee }
});

interface AffiliateFeeState {
  error: boolean;
  isLoading: boolean;
  data?: AffiliateFee;
}

const initialState: AffiliateFeeState = {
  error: false,
  isLoading: false,
  data: undefined
};

const reducer: Reducer<AffiliateFeeState, AffiliateFeeActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateFeeActionTypes.AFFILIATE_FEE_LOADING:
      return {
        error: false,
        isLoading: true,
        data: undefined
      };
    case AffiliateFeeActionTypes.RECEIVE_AFFILIATE_FEE:
      return {
        error: false,
        isLoading: false,
        data: action.payload.fee
      };
    default:
      return state;
  }
};

const getAffiliateFeeState = (state: AppState) => state.affiliate.fee;

export { reducer, receiveAffiliateFee, getAffiliateFeeState };
