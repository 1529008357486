import { CreateSubAccountRequest } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import SubmitButton from "components/submit-button";
import TextField from "components/text-field";

import validationSchema from "./validationSchema";
export interface CreateSubAccountProps {
  handleSubmit: (
    values: CreateSubAccountRequest,
    helpers: FormikHelpers<CreateSubAccountRequest>
  ) => void;
}

const initialValues: CreateSubAccountRequest = {
  email: "",
  name: "",
  info: ""
};

const CreateSubAccount: FC<CreateSubAccountProps> = ({ handleSubmit }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit} className="drawer-container">
        <Box className="drawer-header">
          <Typography variant="h4" className="drawer-header_title">
            Create sub account
          </Typography>
          <SubmitButton>save changes</SubmitButton>
        </Box>
        <Box>
          <TextField label="Email" name="email" />
          <TextField label="Company" name="name" />
          <TextField multiline rows={3} label="Info" name="info" />
        </Box>
      </form>
    )}
  </Formik>
);

export default CreateSubAccount;
