import {
  AffiliateLink,
  AffiliateLinkClick,
  AffiliateLinkClicks,
  AffiliateLinkClicksTotal,
  CreateAffiliateLinkRequest,
  UpdateAffiliateLinkRequest
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import api from "api";
import { BRANDS } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { SnackbarActions, openSnackbar } from "modules/snackbar";
import {
  fetchAffiliateLinks,
  fetchAffiliateViewLinks
} from "modules/affiliate-links";

enum AffiliateLinkActionTypes {
  FETCH_AFFILIATE_LINK_TRAFFICS_LOADING = "@affiliate/link/fetch-link-traffics-loading",
  FETCH_AFFILIATE_LINK_TRAFFICS_SUCCESS = "@affiliate/link/fetch-link-traffics-success",
  FETCH_AFFILIATE_LINK_TRAFFICS_FAILURE = "@affiliate/link/fetch-link-traffics-failure",
  RECEIVE_AFFILIATE_LINK_DETAILS = "@affiliate/link/receive-link-details",
  CREATE_AFFILIATE_LINK_SUCCESS = "@affiliate/link/create-link-success",
  UPDATE_AFFILIATE_LINK_SUCCESS = "@affiliate/link/update-link-success",
  DELETE_AFFILIATE_LINK_SUCCESS = "@affiliate/link/delete-link-success"
}

interface FetchAffiliateLinkTrafficsIsLoadingAction {
  type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_LOADING;
}

interface FetchAffiliateLinkTrafficsIsSuccessAction {
  type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_SUCCESS;
  payload: {
    clicks: {
      items: AffiliateLinkClick[];
      totals: AffiliateLinkClicksTotal;
      total: number;
    };
  };
}

interface FetchAffiliateLinkTrafficsIsFailureAction {
  type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_FAILURE;
}

interface ReceiveAffiliateLinkDetailsAction {
  type: AffiliateLinkActionTypes.RECEIVE_AFFILIATE_LINK_DETAILS;
  payload: AffiliateLink;
}

interface CreateAffiliateLinkIsSuccessAction {
  type: AffiliateLinkActionTypes.CREATE_AFFILIATE_LINK_SUCCESS;
}

interface UpdateAffiliateLinkIsSuccessAction {
  type: AffiliateLinkActionTypes.UPDATE_AFFILIATE_LINK_SUCCESS;
}

interface DeleteAffiliateLinkIsSuccessAction {
  type: AffiliateLinkActionTypes.DELETE_AFFILIATE_LINK_SUCCESS;
}

type AffiliateLinkActions =
  | FetchAffiliateLinkTrafficsIsLoadingAction
  | FetchAffiliateLinkTrafficsIsSuccessAction
  | FetchAffiliateLinkTrafficsIsFailureAction
  | ReceiveAffiliateLinkDetailsAction
  | CreateAffiliateLinkIsSuccessAction
  | UpdateAffiliateLinkIsSuccessAction
  | DeleteAffiliateLinkIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateLinkActions | DrawersActions>
>;

const fetchAffiliateLinkTrafficReportIsLoading =
  (): FetchAffiliateLinkTrafficsIsLoadingAction => ({
    type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_LOADING
  });

const fetchAffiliateLinkTrafficReportIsSuccess = (
  clicks: AffiliateLinkClicks
): FetchAffiliateLinkTrafficsIsSuccessAction => ({
  type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_SUCCESS,
  payload: { ...clicks }
});

const fetchAffiliateLinkTrafficReportIsFailure =
  (): FetchAffiliateLinkTrafficsIsFailureAction => ({
    type: AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_FAILURE
  });

const createAffiliateLinkIsSuccess =
  (): CreateAffiliateLinkIsSuccessAction => ({
    type: AffiliateLinkActionTypes.CREATE_AFFILIATE_LINK_SUCCESS
  });

const updateAffiliateLinkIsSuccess =
  (): UpdateAffiliateLinkIsSuccessAction => ({
    type: AffiliateLinkActionTypes.UPDATE_AFFILIATE_LINK_SUCCESS
  });

const deleteAffiliateLinkIsSuccess =
  (): DeleteAffiliateLinkIsSuccessAction => ({
    type: AffiliateLinkActionTypes.DELETE_AFFILIATE_LINK_SUCCESS
  });

const receiveAffiliateLinkDetails = (
  details: AffiliateLink
): ReceiveAffiliateLinkDetailsAction => ({
  type: AffiliateLinkActionTypes.RECEIVE_AFFILIATE_LINK_DETAILS,
  payload: details
});

const fetchAffiliateLink =
  (
    id: number,
    linkId: number,
    from: string,
    to: string
  ): ThunkActionCreator<AffiliateLinkActions> =>
  async dispatch => {
    dispatch(fetchAffiliateLinkTrafficReportIsLoading());
    try {
      const clicks = await api.admin.affiliate.links.getLink(
        id,
        linkId,
        from,
        to
      );
      dispatch(fetchAffiliateLinkTrafficReportIsSuccess(clicks));
    } catch (err) {
      dispatch(fetchAffiliateLinkTrafficReportIsFailure());
    }
  };

const createAffiliateLink =
  (
    affiliateId: number,
    brandId: BRANDS | null,
    data: CreateAffiliateLinkRequest,
    formikHelpers: FormikHelpers<CreateAffiliateLinkRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.affiliate.links.createLink(affiliateId, data);
      formikHelpers.resetForm();
      dispatch(openSnackbar({ type: "success", message: "Link created!" }));
      dispatch(createAffiliateLinkIsSuccess());
      dispatch(fetchAffiliateLinks(affiliateId, brandId));
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const deleteAffiliateLink =
  (
    affiliateId: number,
    linkId: number,
    brandId: BRANDS | null
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.affiliate.links.deleteLink(affiliateId, linkId);
    dispatch(openSnackbar({ type: "success", message: "Link removed!" }));
    dispatch(deleteAffiliateLinkIsSuccess());
    dispatch(fetchAffiliateLinks(affiliateId, brandId));
  };

const updateAffiliateLink =
  (
    affiliateId: number,
    linkId: number,
    data: CreateAffiliateLinkRequest,
    formikHelpers: FormikHelpers<CreateAffiliateLinkRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const { link } = await api.admin.affiliate.links.updateLink(
        affiliateId,
        linkId,
        data
      );
      dispatch(
        openSnackbar({
          type: "success",
          message: `${link.name} - successfully updated!`
        })
      );
      dispatch(receiveAffiliateLinkDetails(link));
      dispatch(updateAffiliateLinkIsSuccess());
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const fetchAffiliateViewLink =
  (
    linkId: number,
    from: string,
    to: string
  ): ThunkActionCreator<AffiliateLinkActions> =>
  async dispatch => {
    dispatch(fetchAffiliateLinkTrafficReportIsLoading());
    try {
      const clicks = await api.affiliate.links.getLink(linkId, from, to);
      dispatch(fetchAffiliateLinkTrafficReportIsSuccess(clicks));
    } catch (err) {
      dispatch(fetchAffiliateLinkTrafficReportIsFailure());
    }
  };

const createAffiliateViewLink =
  (
    brandId: BRANDS | null,
    data: CreateAffiliateLinkRequest,
    formikHelpers: FormikHelpers<CreateAffiliateLinkRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.affiliate.links.createLink(data);
      dispatch(openSnackbar({ type: "success", message: "Link created!" }));
      dispatch(createAffiliateLinkIsSuccess());
      dispatch(fetchAffiliateViewLinks(brandId));
      formikHelpers.resetForm();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const updateAffiliateViewLink =
  (
    linkId: number,
    data: UpdateAffiliateLinkRequest,
    formikHelpers: FormikHelpers<CreateAffiliateLinkRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const { link } = await api.affiliate.links.updateLink(linkId, data);
      dispatch(
        openSnackbar({
          type: "success",
          message: `${link.name} - successfully updated!`
        })
      );
      dispatch(receiveAffiliateLinkDetails(link));
      dispatch(updateAffiliateLinkIsSuccess());
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const deleteAffiliateViewLink =
  (linkId: number, brandId: BRANDS | null): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.affiliate.links.deleteLink(linkId);
    dispatch(openSnackbar({ type: "success", message: "Link removed!" }));
    dispatch(deleteAffiliateLinkIsSuccess());
    dispatch(fetchAffiliateViewLinks(brandId));
  };

interface AffiliateLinksState {
  error: boolean;
  isLoading: boolean;
  details?: AffiliateLink;
  total: number;
  totals?: AffiliateLinkClicksTotal;
  data: AffiliateLinkClick[];
}

const initialState = {
  error: false,
  isLoading: false,
  total: 0,
  totals: undefined,
  details: undefined,
  data: []
};

const reducer: Reducer<AffiliateLinksState, AffiliateLinkActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false
      };
    case AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.clicks.total,
        totals: {
          ...action.payload.clicks.totals,
          total: action.payload.clicks.total
        },
        data: action.payload.clicks.items.map(({ date, ...rest }) => ({
          date: format(parseISO(date), "dd MMM yyyy"),
          ...rest
        })),
        error: false
      };
    case AffiliateLinkActionTypes.FETCH_AFFILIATE_LINK_TRAFFICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case AffiliateLinkActionTypes.RECEIVE_AFFILIATE_LINK_DETAILS:
      return {
        ...state,
        details: action.payload
      };
    default:
      return state;
  }
};

const getAffiliateLinkState = (state: AppState) => state.affiliate.link;

export {
  reducer,
  fetchAffiliateLink,
  createAffiliateLink,
  deleteAffiliateLink,
  updateAffiliateLink,
  createAffiliateViewLink,
  updateAffiliateViewLink,
  deleteAffiliateViewLink,
  fetchAffiliateViewLink,
  receiveAffiliateLinkDetails,
  getAffiliateLinkState
};
