import { ListAffiliate } from "@types";
import some from "lodash/some";

const getLastUsageAffiliates = (
  list: ListAffiliate[],
  lastUsageIds: number[]
) =>
  list
    .filter(({ affiliateId }) => some(lastUsageIds, id => id === affiliateId))
    .sort((a, b) => {
      const A = a["affiliateId"];
      const B = b["affiliateId"];

      if (lastUsageIds.indexOf(A) > lastUsageIds.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

export default getLastUsageAffiliates;
