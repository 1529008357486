import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useUrlDateCheck } from "hooks";
import { openDrawer } from "drawers";
import { receiveAffiliateDeal } from "modules/affiliate-deal";
import { receiveAffiliateFee } from "modules/affiliate-fee";
import DatePicker, { getDatePickerState } from "modules/datepicker";
import Deals, {
  fetchAffiliateViewDeals,
  getAffiliateDealsState
} from "modules/affiliate-deals";
import Fees, {
  fetchAffiliateViewFees,
  getAffiliateFeesState
} from "modules/affiliate-fees";
import OverviewStatistics, {
  fetchUserOverview
} from "modules/affiliate-overview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    picker: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
        "& .MuiTypography-h3": {
          fontSize: 16,
          lineHeight: "24px"
        }
      }
    }
  })
);

const AffiliateViewOverviewPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { year, month } = useSelector(getDatePickerState);
  const { data: deals } = useSelector(getAffiliateDealsState);
  const { data: fees } = useSelector(getAffiliateFeesState);

  useUrlDateCheck();

  useEffect(() => {
    const options = {
      year: Number(year),
      month: Number(month)
    };

    Promise.all([
      dispatch(fetchUserOverview(options)),
      dispatch(fetchAffiliateViewDeals()),
      dispatch(fetchAffiliateViewFees())
    ]);
  }, [dispatch, year, month]);

  const handleDealClick = useCallback(
    (id: string) => {
      const deal = deals.find(({ brandId }) => brandId === id);
      if (deal) {
        dispatch(receiveAffiliateDeal(deal));
        dispatch(openDrawer("user-deal-details"));
      }
    },
    [dispatch, deals]
  );

  const handleAffiliateFeeClick = useCallback(
    (id: string) => {
      const fee = fees.find(({ brandId }) => brandId === id);

      if (fee) {
        dispatch(receiveAffiliateFee(fee));
        dispatch(openDrawer("user-affiliate-fee-details"));
      }
    },
    [dispatch, fees]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.picker}>
        <Typography variant="h3">Overview</Typography>
        <DatePicker baseDateUrl="/overview" />
      </Box>
      <OverviewStatistics />
      <Deals handleDealClick={handleDealClick} />
      <Fees handleAffiliateFeeClick={handleAffiliateFeeClick} />
    </Box>
  );
};

export default AffiliateViewOverviewPage;
