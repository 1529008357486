import { AffiliatePlayersRevenue } from "@types";

import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";

import AffiliatePlayers from "modules/affiliate-players";

const AffiliateViewPlayersPage: FC = () => {
  const history = useHistory();

  const handlePlayerClick = useCallback(
    ({ playerId }: AffiliatePlayersRevenue) => {
      history.push(`/players/${playerId}`);
    },
    [history]
  );

  return <AffiliatePlayers handleRowClick={handlePlayerClick} />;
};

export default AffiliateViewPlayersPage;
