import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import api from "api";
import { openDialog } from "modules/dialog";
import Invoice from "components/pdf-invoice";
import {
  createAffiliateInvoiceAttachment,
  fetchAffiliateInvoice
} from "modules/affiliate-invoice";
import {
  markAsPaidAffiliatePayments,
  getAffiliateInvoiceStateById
} from "modules/affiliate-invoices";
import { FormikHelpers } from "formik";

interface MatchParams {
  id: string;
  invoiceId: string;
}

export default function () {
  const dispatch = useDispatch();
  const { id, invoiceId } = useParams<MatchParams>();

  const { isLoading, error, invoice } = useSelector(
    getAffiliateInvoiceStateById
  )(invoiceId);

  useEffect(() => {
    dispatch(fetchAffiliateInvoice(Number(id), Number(invoiceId)));
  }, [dispatch, id, invoiceId]);

  const handleDownloadInvoice = useCallback(
    async (template: "invoice" | "receipt") => {
      const invoice = await api.admin.affiliate.invoices.getInvoice(
        Number(id),
        Number(invoiceId)
      );
      const blob = await pdf(
        <Invoice template={template} invoice={invoice} />
      ).toBlob();
      saveAs(blob, `${invoice.invoiceNumber}.pdf`);
    },
    [id, invoiceId]
  );

  const handleMarkInvoice = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "mark-payment",
        payload: {
          type: "submit",
          title: "Are you sure you want to mark invoice as paid?",
          subtitle: "This operation cannot be undone",
          onConfirm: () =>
            dispatch(markAsPaidAffiliatePayments(Number(id), Number(invoiceId)))
        }
      })
    );
  }, [dispatch, id, invoiceId]);

  const handleCreateAttachment = useCallback(
    (
      file: File,
      handleProgress: (progress: number) => void,
      helpers: FormikHelpers<{ file?: File }>
    ) => {
      dispatch(
        createAffiliateInvoiceAttachment(
          Number(id),
          Number(invoiceId),
          file,
          handleProgress,
          helpers
        )
      );
    },
    [dispatch, id, invoiceId]
  );

  return {
    isLoading,
    error,
    invoice,
    handleMarkInvoice,
    handleDownloadInvoice,
    handleCreateAttachment
  };
}
