import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

import DocumentFileIcon from "assets/icons/DocumentFile";
import ImageFileIcon from "assets/icons/ImageFile";
import PDFIcon from "assets/icons/PDF";

interface Props extends SvgIconProps {
  type: "document" | "image" | "pdf";
}

const FileIcon: FC<Props> = ({ type, ...rest }) => {
  switch (type) {
    case "image":
      return <ImageFileIcon {...rest} />;
    case "pdf":
      return <PDFIcon {...rest} />;
    case "document":
    default:
      return <DocumentFileIcon {...rest} />;
  }
};

export default FileIcon;
