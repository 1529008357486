import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: "100%",
      width: "100%"
    },
    title: {
      display: "flex",
      flexWrap: "wrap"
    },
    search: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 64
    },
    buttons: {
      display: "flex",
      alignContent: "center"
    },
    button: {
      borderRadius: 17,
      marginRight: 8
    },
    text: {
      margin: 0
    },
    tableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 50,
      borderBottom: `2px solid ${theme.colors.blacke0}`
    },
    tableRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 49
    },
    rowData: {
      width: "40vw",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    tableButton: {
      borderRadius: "50%"
    }
  })
);

export const useStyles = makeStyles(theme =>
  createStyles({
    dealInput: {
      marginTop: 16
    },
    global: {
      marginTop: 32,
      paddingLeft: 16,
      paddingRight: 16
    },
    divider: {
      height: "1px",
      margin: "32px -16px 16px -16px"
    },
    createCardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "0 0 16px"
    },
    createCard: {
      display: "flex",
      padding: 4,
      borderRadius: 8,
      cursor: "pointer",
      color: theme.colors.black75,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    createCardText: {
      fontSize: 14,
      lineHeight: "24px",
      padding: "0 8px"
    },
    createCardIcon: {
      color: "inherit"
    },
    title: {
      color: theme.colors.black21,
      fontSize: 14,
      lineHeight: "24px"
    },
    nrs: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8
    },
    input: {
      minWidth: 128,
      "& .Mui-error": {
        background: theme.colors.red10
      }
    },
    smallInput: {
      width: 82,
      fontWeight: 500,
      "& .Mui-error": {
        background: theme.colors.red10
      }
    },
    cpa: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8
    },
    cpaInput: {
      width: 108
    },
    select: {
      width: 128,
      fontWeight: 500,
      "& .Mui-error": {
        background: theme.colors.red10
      },
      "& input": {
        color: theme.colors.black21,
        "&::placeholder": {
          opacity: 1,
          color: `${theme.colors.black21} !important`
        }
      }
    },
    selectDisabled: {
      width: 128,
      fontWeight: 500,
      "& .Mui-error": {
        background: theme.colors.red10
      },
      "& input": {
        color: "currentColor",
        "&::placeholder": {
          opacity: 1,
          color: "currentColor !important"
        }
      }
    },
    selectButton: {
      height: 30
    },
    countrySelector: {
      width: "100%",
      marginBottom: -16,
      "& .MuiOutlinedInput-root": {
        marginBottom: 16
      }
    },
    addTitle: {
      color: theme.colors.blackbd,
      fontSize: 14
    },
    iconButton: {
      padding: 8,
      marginLeft: 8,
      color: theme.colors.blackbd,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    planCard: {
      marginBottom: 12,
      borderRadius: 6
    },
    planCardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 56,
      padding: 8,
      borderBottom: `1px solid ${theme.colors.blacke0}`
    },
    rules: {
      padding: "6px 16px 16px 16px"
    },
    startAdornment: {
      fontSize: 16,
      fontWeight: 500
    },
    endAdornment: {
      margin: 0
    },
    nrsDropdown: {
      width: 24,
      height: 24,
      color: theme.colors.black21,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    dropdownIcon: {
      color: "inherit !important",
      width: "22px !important",
      height: "22px !important"
    },
    rule: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:not(first-child)": {
        marginTop: 10
      }
    },
    ruleTitle: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black21,
      "&:last-child": {
        marginLeft: 8
      }
    },
    deposit: {
      display: "flex",
      alignItems: "center"
    },
    cardError: {},
    cardErrorText: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.red,
      "&:first-child": {
        marginTop: 16
      }
    }
  })
);
