import { UpdateAffiliateProfile } from "@types";

import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { countriesOptions } from "constants/countries";
import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";
import CheckboxField from "components/checkbox-field";
import { fetchUsers, getUsersForSelect } from "modules/users";
import {
  getAffiliateProfileState,
  updateAffiliateProfile
} from "modules/affiliate";

import validationSchema from "./validationSchema";

export interface EditAffiliateProps {
  affiliateId: number;
}

const EditAffiliate: FC<EditAffiliateProps> = ({ affiliateId }) => {
  const dispatch = useDispatch();

  const users = useSelector(getUsersForSelect);
  const { masterId, profile } = useSelector(getAffiliateProfileState);

  const handleSubmit = useCallback(
    (
      values: UpdateAffiliateProfile,
      helpers: FormikHelpers<UpdateAffiliateProfile>
    ) => {
      dispatch(updateAffiliateProfile(affiliateId, values, helpers));
    },
    [dispatch, affiliateId]
  );

  const getUserValue = (value?: number | null) =>
    value ? users.find(user => user.value === value) : null;

  const getCountryValue = (value?: string) =>
    value ? countriesOptions.find(country => country.value === value) : null;

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Formik
      initialValues={profile}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="drawer-container">
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              Edit Profile Information
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <SelectField
              isClearable
              name="userId"
              label="Account Manager"
              placeholder="Select Account Manager"
              options={users}
              value={getUserValue(values.userId)}
            />
            <Box display="flex" flexDirection="column">
              <CheckboxField
                name="allowEmails"
                label="Subscribe to our Email newsletter"
              />
              <CheckboxField name="isInternal" label="Internal account" />
              <CheckboxField
                name="allowNegativeFee"
                label="Allow negative fee"
              />
              <CheckboxField
                name="floorBrandCommission"
                label="Per brand commissions"
              />
              <CheckboxField name="allowPayments" label="Allow payments" />
            </Box>
            <TextField label="Company" name="name" />
            {!masterId && <TextField label="VAT Number" name="vatNumber" />}
            <TextField label="Name" name="contactName" />
            {!masterId && (
              <>
                <TextField multiline rows={2} label="Address" name="address" />
                <SelectField
                  name="countryId"
                  label="Country"
                  placeholder="Select country"
                  options={countriesOptions}
                  value={getCountryValue(values.countryId)}
                />
              </>
            )}
            <TextField label="Email" name="email" />
            <TextField label="Skype" name="skype" />
            <TextField label="Phone" name="phone" />
            <TextField multiline rows={3} label="Info" name="info" />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditAffiliate;
