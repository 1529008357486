import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        justifyContent: "flex-start",
        marginBottom: 32
      }
    },
    box: {
      maxWidth: 360,
      width: "calc(50% - 32px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%"
      }
    },
    secondBox: {
      marginTop: 64,
      [theme.breakpoints.down("sm")]: {
        marginTop: 32
      }
    },
    button: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: 32
      }
    },
    adornment: {
      width: 15,
      marginRight: "0 !important"
    },
    text: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black21,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    refreshButton: {
      margin: 16
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: 360,
      width: "calc(50% - 32px)",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "100%"
      }
    },
    title: {
      width: 120,
      height: 32
    },
    payments: {
      marginTop: 64,
      [theme.breakpoints.down("sm")]: {
        marginTop: 32
      }
    },
    checkbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "32px 0"
    },
    input: {
      marginTop: 30,
      height: 56,
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },
    button: {
      width: 124,
      height: 30,
      marginTop: 30,
      borderRadius: 17,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 32
      }
    },
    checkInput: {
      width: 32,
      height: 32,
      marginRight: 16
    },
    text: {
      width: "100%",
      height: 24
    }
  })
);

export { useStyles, useSkeletonStyles };
