import React, { FC } from "react";

import EmptyData from "components/empty-data";

import DealDetails from "./DealDetails";
import Skeleton from "./../Skeleton";
import useAffiliateDeal from "./../useAffiliateDeal";

const BrandDeal: FC = () => {
  const { error, isLoading, brand, deal, rules, handleChangeDealOpen } =
    useAffiliateDeal();

  if (isLoading) {
    return <Skeleton />;
  }

  if (!deal || error) {
    return <EmptyData className="no-data" />;
  }

  return (
    <DealDetails
      isAdmin
      brand={brand}
      deal={deal}
      rules={rules}
      handleChange={handleChangeDealOpen}
    />
  );
};

export default BrandDeal;
