import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDropzone } from "react-dropzone";
import { FormikHelpers, useFormik } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/SendRounded";
import AttachIcon from "@material-ui/icons/AttachFileRounded";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import EmptyData from "components/empty-data";
import Attachment from "components/attachment";
import AttachmentToUpload from "components/attachment-to-upload";
import { DownloadCsvButton } from "components/button";

import { getAffiliateInvoiceState } from "../duck";

const CSV_HEADERS = [
  { label: "ID", key: "paymentId" },
  { label: "Date", key: "transactionDate" },
  { label: "CreatedBy", key: "createdBy" },
  { label: "Type", key: "type" },
  { label: "Description", key: "description" },
  { label: "Amount", key: "amount" }
];

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: "0 16px"
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      "& > *:not(:first-child)": {
        marginLeft: 8
      }
    },
    skeletonButton: {
      borderRadius: 17
    },
    attached: {
      display: "flex",
      flexWrap: "wrap",
      margin: "16px 0"
    },
    attachments: {
      display: "flex",
      flexDirection: "column",
      padding: "16px 16px 0px 16px"
    },
    attachment: {
      padding: 7,
      color: theme.colors.black61,
      border: `1px solid ${theme.colors.blackc4}`,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    active: {
      color: theme.colors.blue
    },
    submitButton: {
      display: "flex",
      alignSelf: "flex-end",
      marginTop: 8,
      color: theme.colors.black75,
      transition: " all 0.4s ease-out",
      cursor: "pointer",
      "&:hover": {
        color: theme.colors.blue
      }
    },
    submitText: {
      fontWeight: 500,
      color: "inherit",
      textTransform: "uppercase"
    },
    submitIcon: {
      fontSize: 16,
      marginLeft: 4
    },
    uploads: {
      padding: "0 16px 16px 16px"
    },
    progressBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    progressBarLoader: {
      width: "90%"
    }
  })
);

interface Values {
  file?: File;
}

interface Props {
  handleCreateAttachment: (
    file: File,
    handleProgress: (progress: number) => void,
    helpers: FormikHelpers<Values>
  ) => void;
  handleDownloadInvoice: (template: "invoice" | "receipt") => void;
}

const initialValues: Values = {
  file: undefined
};

const InvoiceActions: FC<Props> = ({
  handleDownloadInvoice,
  handleCreateAttachment
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(0);
  const { isLoading, error, data } = useSelector(getAffiliateInvoiceState);

  const handleAttachment = useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      handleCreateAttachment(values.file!, setProgress, helpers);
    },
    [handleCreateAttachment]
  );

  const {
    isSubmitting,
    values: { file },
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues,
    onSubmit: handleAttachment
  });

  const { open, getInputProps } = useDropzone({
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    multiple: false,
    maxFiles: 1,
    onDrop: (files: File[]) => {
      const acceptedFile = files[0];
      setFieldValue("file", acceptedFile);
    }
  });

  if (isLoading) {
    return (
      <Box className={classes.container}>
        <Box className={classes.buttonsContainer}>
          <Box className={classes.buttons}>
            <Skeleton
              width={149}
              height={32}
              className={classes.skeletonButton}
            />
            <Skeleton
              width={151}
              height={32}
              className={classes.skeletonButton}
            />
            <Skeleton
              width={125}
              height={32}
              className={classes.skeletonButton}
            />
          </Box>
          <Skeleton width={32} height={32} variant="circle" />
        </Box>
        <Skeleton height={36} className={classes.attached} />
      </Box>
    );
  }

  if (error || !data) {
    return <EmptyData style={{ margin: 16 }} />;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttons}>
          <Button onClick={() => handleDownloadInvoice("invoice")}>
            Download invoice
          </Button>
          <Button onClick={() => handleDownloadInvoice("receipt")}>
            Download receipt
          </Button>
          <DownloadCsvButton
            headers={CSV_HEADERS}
            data={data.payments}
            disabled={isEmpty(data.payments)}
          />
        </Box>
        <input {...getInputProps()} />
        <IconButton
          onClick={open}
          className={cn(classes.attachment, {
            [classes.active]: !!file
          })}
        >
          <AttachIcon fontSize="small" />
        </IconButton>
      </Box>
      {data.attachments && (
        <Box className={classes.attached}>
          <Attachment fileName={data.attachments[0]} />
        </Box>
      )}
      {file && (
        <Box className={classes.attachments}>
          <AttachmentToUpload
            file={file}
            onRemove={(_name: string) => {
              setFieldValue("file", null);
            }}
          />
          {isSubmitting && progress > 0 && progress < 100 && (
            <Box className={classes.progressBar}>
              <LinearProgress
                variant="determinate"
                className={classes.progressBarLoader}
                value={progress > 0 ? progress : 65}
              />
              <Typography variant="subtitle1">{`${progress} %`}</Typography>
            </Box>
          )}
          <Box className={classes.submitButton} onClick={() => handleSubmit()}>
            <Typography variant="subtitle1" className={classes.submitText}>
              Add attachment
            </Typography>
            <SendIcon className={classes.submitIcon} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InvoiceActions;
