import { ListAffiliate } from "@types";

import React, { FC, useCallback, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useSearch } from "hooks";
import Input from "components/input";
import SelectableList from "components/selectable-list";
import { getAffiliateState } from "modules/affiliate";
import { getViewModeState } from "modules/view-mode";

export interface SubaccountsProps {
  handleListChange: (id: number) => void;
  handleCreateDrawer?: () => void;
}

const SubAccounts: FC<SubaccountsProps> = ({
  handleListChange,
  handleCreateDrawer
}) => {
  const { subaccounts } = useSelector(getAffiliateState);
  const { accountId } = useSelector(getViewModeState);
  const { query, setQuery, results } = useSearch<ListAffiliate>(
    ["affiliateName", "affiliateId", "affiliateEmail"],
    subaccounts
  );

  const options = results.map(result => ({
    label: result.affiliateName,
    value: result.affiliateId,
    description: result.affiliateId
  }));

  const handleSearchReset = useCallback(() => setQuery(""), [setQuery]);
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
    [setQuery]
  );

  return (
    <Box width="100%" height="100%" maxWidth={380} padding={2}>
      <Box className="drawer-header" marginBottom={2}>
        {handleCreateDrawer ? (
          <>
            <Typography variant="h4" color="secondary">
              change account
            </Typography>
            <Button color="secondary" onClick={handleCreateDrawer}>
              Add account
            </Button>
          </>
        ) : (
          <Typography variant="h4" color="secondary">
            {`subaccounts (${subaccounts.length})`}
          </Typography>
        )}
      </Box>
      <Input
        value={query}
        placeholder="Search by id, name, email"
        onChange={handleSearch}
      />
      <Box
        margin={-2}
        marginTop={2}
        height="100%"
        maxHeight="calc(100% - 144px)"
      >
        <SelectableList
          options={options}
          selectedOption={accountId}
          handleClear={handleSearchReset}
          handleChange={handleListChange}
        />
      </Box>
    </Box>
  );
};

export default SubAccounts;
