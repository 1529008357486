import { Plan, PlansDescription, CreatePlanValues } from "@types";

import { useCallback, ChangeEvent, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";
import {
  deletePlan,
  archivePlan,
  createPlan,
  formatPlanToRequest
} from "modules/plan";
import { getAuthState } from "modules/authentication";

import { getPlansState } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { roles } = useSelector(getAuthState);
  const { isLoading, error, data } = useSelector(getPlansState);

  const { query, setQuery, results } = useSearch<Plan>(["name"], data);

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleRowClick = useCallback(
    ({ planId }: PlansDescription) => {
      history.push(`/admin/plans/${planId}`);
    },
    [history]
  );

  const handleCreatePlan = useCallback(
    (values: CreatePlanValues, helpers: FormikHelpers<CreatePlanValues>) => {
      const data = formatPlanToRequest(values);
      dispatch(createPlan(data, helpers));
    },
    [dispatch]
  );

  const handleDeletePlan = useCallback(
    (event: MouseEvent<HTMLElement>) =>
      ({ name, planId }: PlansDescription) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "delete-plan",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              content: name,
              onConfirm: () => dispatch(deletePlan(planId))
            }
          })
        );
      },
    [dispatch]
  );

  const handleArchivePlan = useCallback(
    (event: MouseEvent<HTMLElement>) =>
      ({ name, planId }: PlansDescription) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "archive-plan",
            payload: {
              type: "submit",
              title: "Are you sure you want to archive?",
              content: name,
              onConfirm: () => dispatch(archivePlan(planId))
            }
          })
        );
      },
    [dispatch]
  );

  const handleCreateDrawer = useCallback(() => {
    dispatch(
      openDrawer("create-plan", {
        handleSubmit: handleCreatePlan
      })
    );
  }, [dispatch, handleCreatePlan]);

  return {
    classes,
    error,
    isLoading,
    isEmpty: isEmpty(data),
    isEmptySearch: isEmpty(results),
    plans: results,
    searchQuery: query,
    handleSearchQuery,
    handleRowClick,
    handleDeletePlan,
    handleArchivePlan,
    handleCreateDrawer: roles.includes("admin") ? handleCreateDrawer : undefined
  };
}
