import {
  RegistartionRequest,
  RegistrationValues,
  LoginRequest,
  ChangePasswordRequest,
  ChangePasswordValues,
  ProfileResponse,
  RoleTypes
} from "@types";

import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { DialogActionsType } from "modules/dialog";
import { SnackbarActions, openSnackbar } from "modules/snackbar";

enum AuthenticationActionTypes {
  SIGN_IN_LOADING = "@authentication/sign-in-loading",
  SIGN_IN_SUCCESS = "@authentication/sign-in-success",
  SIGN_IN_FAILURE = "@authentication/sign-in-failure",
  ADMIN_SIGN_IN_SUCCESS = "@authentication/admin-sign-in-success",
  SIGN_UP_LOADING = "@authentication/sign-up-loading",
  SIGN_UP_SUCCESS = "@authentication/sign-up-success",
  SIGN_UP_FAILURE = "@authentication/sign-up-failure",
  SET_AUTH_TOKEN = "@authentication/set-auth-token",
  SET_ADMIN_PROFILE = "@authentication/set-admin-profile",
  CHANGE_PASSWORD_SUCCESS = "@authentication/change-password-success",
  SET_CLOSE_MONTH_STATUS = "@authentication/set-close-month-status",
  CLOSE_MONTH = "@authentication/close-month",
  LOGOUT = "@authentication/logout"
}

interface SignInLoadingAction {
  type: AuthenticationActionTypes.SIGN_IN_LOADING;
}

interface SignInSuccessAction {
  type: AuthenticationActionTypes.SIGN_IN_SUCCESS;
  payload: { userId: number; tcAccepted: boolean };
}

interface SignInFailureAction {
  type: AuthenticationActionTypes.SIGN_IN_FAILURE;
}

interface AdminSignInSuccessAction {
  type: AuthenticationActionTypes.ADMIN_SIGN_IN_SUCCESS;
  payload: { email: string; roles: RoleTypes[] };
}

interface SignUpLoadingAction {
  type: AuthenticationActionTypes.SIGN_UP_LOADING;
}

interface SignUpSuccessAction {
  type: AuthenticationActionTypes.SIGN_UP_SUCCESS;
  payload: { userId: number };
}

interface SignUpFailureAction {
  type: AuthenticationActionTypes.SIGN_UP_FAILURE;
}

interface SetAuthenticationTokenAction {
  type: AuthenticationActionTypes.SET_AUTH_TOKEN;
  payload: { token: string };
}

interface SetAdminProfileAction {
  type: AuthenticationActionTypes.SET_ADMIN_PROFILE;
  payload: ProfileResponse;
}

interface ChangePasswordIsSuccessAction {
  type: AuthenticationActionTypes.CHANGE_PASSWORD_SUCCESS;
}

interface SetCloseMonthStatusAction {
  type: AuthenticationActionTypes.SET_CLOSE_MONTH_STATUS;
  payload: boolean;
}

interface CloseMonthAction {
  type: AuthenticationActionTypes.CLOSE_MONTH;
}

interface LogoutAction {
  type: AuthenticationActionTypes.LOGOUT;
}

type AuthenticationActions =
  | SignInLoadingAction
  | SignInSuccessAction
  | SignInFailureAction
  | AdminSignInSuccessAction
  | SignUpLoadingAction
  | SignUpSuccessAction
  | SignUpFailureAction
  | CloseMonthAction
  | SetAuthenticationTokenAction
  | SetAdminProfileAction
  | ChangePasswordIsSuccessAction
  | SetCloseMonthStatusAction
  | LogoutAction;

type ActionType = ThunkActionCreator<AuthenticationActions | DialogActionsType>;
type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AuthenticationActions>
>;

const singInLoading = (): SignInLoadingAction => ({
  type: AuthenticationActionTypes.SIGN_IN_LOADING
});

const signInSuccess = (
  userId: number,
  tcAccepted: boolean
): SignInSuccessAction => ({
  type: AuthenticationActionTypes.SIGN_IN_SUCCESS,
  payload: { userId, tcAccepted }
});

const signInFailure = (): SignInFailureAction => ({
  type: AuthenticationActionTypes.SIGN_IN_FAILURE
});

const adminSignInSuccess = (
  email: string,
  roles: RoleTypes[]
): AdminSignInSuccessAction => ({
  type: AuthenticationActionTypes.ADMIN_SIGN_IN_SUCCESS,
  payload: { email, roles }
});

const signUpLoading = (): SignUpLoadingAction => ({
  type: AuthenticationActionTypes.SIGN_UP_LOADING
});

const signUpSuccess = (userId: number): SignUpSuccessAction => ({
  type: AuthenticationActionTypes.SIGN_UP_SUCCESS,
  payload: { userId }
});

const signUpFailure = (): SignUpFailureAction => ({
  type: AuthenticationActionTypes.SIGN_UP_FAILURE
});

const changePasswordIsSuccess = (): ChangePasswordIsSuccessAction => ({
  type: AuthenticationActionTypes.CHANGE_PASSWORD_SUCCESS
});

const setCloseMonthStatus = (status: boolean): SetCloseMonthStatusAction => ({
  type: AuthenticationActionTypes.SET_CLOSE_MONTH_STATUS,
  payload: status
});

const setAuthToken = (token: string): SetAuthenticationTokenAction => ({
  type: AuthenticationActionTypes.SET_AUTH_TOKEN,
  payload: { token }
});

const setAdminProfile = (profile: ProfileResponse): SetAdminProfileAction => ({
  type: AuthenticationActionTypes.SET_ADMIN_PROFILE,
  payload: profile
});

const logOut = (): LogoutAction => ({
  type: AuthenticationActionTypes.LOGOUT
});

const signIn =
  (data: LoginRequest, formActions: FormikHelpers<typeof data>): ActionType =>
  async dispatch => {
    dispatch(singInLoading());
    try {
      const { affiliateId, tcAccepted } = await api.affiliate.auth.signIn(data);
      dispatch(signInSuccess(affiliateId, tcAccepted));
    } catch (err) {
      dispatch(signInFailure());
      formActions.setSubmitting(false);
    }
  };

const adminSignIn =
  (data: LoginRequest, formActions: FormikHelpers<typeof data>): ActionType =>
  async dispatch => {
    dispatch(singInLoading());
    try {
      const { email, roles } = await api.admin.auth.signIn(data);
      dispatch(adminSignInSuccess(email, roles));
    } catch (err) {
      dispatch(signInFailure());
      formActions.setSubmitting(false);
    }
  };

const signUp =
  (
    referralId: number | null,
    data: RegistartionRequest,
    formActions: FormikHelpers<RegistrationValues>
  ): ActionType =>
  async dispatch => {
    dispatch(signUpLoading());
    try {
      const { affiliateId } = await api.affiliate.auth.signUp(referralId, data);
      dispatch(signUpSuccess(affiliateId));
    } catch (err) {
      dispatch(signUpFailure());
      formActions.setSubmitting(false);
    }
  };

const fetchCloseMonthStatus = (): ActionType => async dispatch => {
  const { ok } = await api.admin.getCloseMonthStatus();
  dispatch(setCloseMonthStatus(ok));
};

const closeMonth = (): ActionsTypeWithSnackbar => async dispatch => {
  const { ok } = await api.admin.closeMonth();
  dispatch(setCloseMonthStatus(ok));
  dispatch(openSnackbar({ type: "success", message: "Month closed." }));
};

const changePassword =
  (
    data: ChangePasswordRequest,
    helpers: FormikHelpers<ChangePasswordValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.affiliate.auth.changePassword(data);
      dispatch(changePasswordIsSuccess());
      dispatch(openSnackbar({ type: "success", message: "Password changed!" }));
      helpers.resetForm();
    } finally {
      helpers.setSubmitting(false);
    }
  };

const fetchAdminProfile = (): ActionType => async dispatch => {
  const profile = await api.admin.getProfile();
  dispatch(setAdminProfile(profile));
};

interface AuthenticationState {
  error: boolean;
  isLoading: boolean;
  loggedIn?: boolean;
  isUserLoggedIn?: boolean;
  isAdminLoggedIn?: boolean;
  token?: string;
  email?: string;
  canCloseMonth: boolean;
  tcAccepted?: boolean;
  isAdmin?: boolean;
  roles: RoleTypes[];
  userId?: number;
}

const initialState: AuthenticationState = {
  error: false,
  isLoading: false,
  canCloseMonth: false,
  roles: []
};

const reducer: Reducer<AuthenticationState, AuthenticationActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthenticationActionTypes.SIGN_IN_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case AuthenticationActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        loggedIn: true,
        isUserLoggedIn: true,
        isAdminLoggedIn: false,
        isAdmin: false,
        tcAccepted: action.payload.tcAccepted,
        userId: action.payload.userId
      };
    case AuthenticationActionTypes.SET_ADMIN_PROFILE:
    case AuthenticationActionTypes.ADMIN_SIGN_IN_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        loggedIn: true,
        isUserLoggedIn: false,
        isAdminLoggedIn: true,
        isAdmin: action.payload.roles.includes("admin"),
        email: action.payload.email,
        roles: action.payload.roles
      };
    case AuthenticationActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        loggedIn: true,
        isUserLoggedIn: true,
        isAdminLoggedIn: false,
        isAdmin: false,
        userId: action.payload.userId
      };
    case AuthenticationActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case AuthenticationActionTypes.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload.token
      };
    case AuthenticationActionTypes.SET_CLOSE_MONTH_STATUS:
      return {
        ...state,
        canCloseMonth: action.payload
      };
    case AuthenticationActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

const getAuthState = (state: AppState) => state.authentication;
const getAdminRoles = (state: AppState) => state.authentication.roles;
const getLoggedInStatus = (state: AppState) => state.authentication.loggedIn;
const getCloseMonthStatus = (state: AppState) =>
  state.authentication.canCloseMonth;

export {
  reducer,
  signIn,
  signUp,
  adminSignIn,
  logOut,
  setAuthToken,
  closeMonth,
  fetchAdminProfile,
  fetchCloseMonthStatus,
  changePassword,
  getAuthState,
  getAdminRoles,
  getLoggedInStatus,
  getCloseMonthStatus
};
