import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import EmptyData from "components/empty-data";
import { BackButton, DownloadCsvButton } from "components/button";
import { TableSkeleton } from "components/table";
import { RangeDatePicker } from "modules/datepicker";

import LinkDetails from "./components/LinkDetails";
import TrafficReport from "./components/TrafficReport";
import LinkInfoSkeleton from "./Skeleton";
import useAffiliateLink from "./useAffiliateLink";

const CSV_HEADERS = [
  { label: "Date", key: "date" },
  { label: "Segment", key: "segment" },
  { label: "Clicks", key: "clicks" },
  { label: "Reg. (NRC)", key: "nrc" },
  { label: "1st Deposit (NDC)", key: "ndc" },
  { label: "Deposits", key: "deposits" },
  { label: "Turnover", key: "turnover" },
  { label: "Gross Revenue", key: "grossRevenue" },
  { label: "Net Revenue", key: "netRevenue" },
  { label: "CPA", key: "cpa" },
  { label: "Commission", key: "commission" },
  { label: "Total", key: "total" }
];

interface Props {
  handleEditDrawerOpen: () => void;
}

const Component: FC<Props> = ({ handleEditDrawerOpen }) => {
  const {
    classes,
    error,
    isLoading,
    totals,
    traffics,
    linkDetails,
    handleBackButton
  } = useAffiliateLink();

  if (error) {
    return <EmptyData />;
  }

  return (
    <Box>
      <BackButton onClick={handleBackButton}>go back</BackButton>
      {!linkDetails ? (
        <LinkInfoSkeleton />
      ) : (
        <LinkDetails details={linkDetails} onEditClick={handleEditDrawerOpen} />
      )}
      <Box className={classes.trafficReport}>
        <Typography variant="h3" className={classes.title}>
          Traffic report
        </Typography>
        <Box>
          <RangeDatePicker />
          <DownloadCsvButton
            className={classes.button}
            headers={CSV_HEADERS}
            data={traffics}
            disabled={isEmpty(traffics)}
          />
        </Box>
      </Box>
      {isLoading ? (
        <TableSkeleton rows={6} cells={12} />
      ) : (
        <TrafficReport totals={totals} traffics={traffics} />
      )}
    </Box>
  );
};

export default Component;
