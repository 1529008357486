import * as yup from "yup";

const validationSchema = yup.object().shape({
  commissionShare: yup
    .number()
    .min(1, "Commission must be greater than 0")
    .max(100, "Commission must be lower than 100")
    .required("Commission is required")
});

export default validationSchema;
