import { CreatePlanRequest, CreatePlanValues } from "@types";

import omit from "lodash/omit";
import flatten from "lodash/flatten";
import formatMoney from "utils/formatMoney";

export default function ({ plan, rules }: CreatePlanValues): CreatePlanRequest {
  return {
    plan: formatMoney.toObject(plan),
    rules: formatMoney.toArray(
      flatten(
        rules.map(rule =>
          rule.countryIds.map(countryId =>
            omit(
              {
                ...rule,
                countryId,
                deposit: rule.deposit ? rule.deposit : 0,
                deposit_cpa: rule.deposit_cpa ? rule.deposit_cpa : 0
              },
              "countryIds"
            )
          )
        )
      )
    )
  };
}
