import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import format from "date-fns/format";
import { pdf } from "@react-pdf/renderer";

import api from "api";
import Invoice from "components/pdf-invoice";
import { openDialog } from "modules/dialog";
import { getDatePickerState } from "modules/datepicker";
import {
  confirmPayment,
  getPaymentStateById,
  markPayment
} from "modules/payments";
import { getAdminRoles } from "modules/authentication";

import { fetchPayment, getPaymentState } from "./duck";
import { useStyles } from "./styles";

interface MatchParams {
  affiliateId: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { affiliateId } = useParams<MatchParams>();

  const roles = useSelector(getAdminRoles);
  const { fullDate, year, month } = useSelector(getDatePickerState);
  const {
    isLoading: isTableLoading,
    error: tableError,
    payments,
    totals
  } = useSelector(getPaymentState);
  const { error, isLoading, payment } =
    useSelector(getPaymentStateById)(affiliateId);

  useEffect(() => {
    dispatch(fetchPayment(Number(affiliateId), year, month));
  }, [dispatch, affiliateId, year, month]);

  const handleConfirmPayment = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "confirm-payment",
        payload: {
          type: "submit",
          title: "Are you sure you want to confirm invoice?",
          subtitle: "This operation cannot be undone",
          onConfirm: () => dispatch(confirmPayment(Number(affiliateId)))
        }
      })
    );
  }, [dispatch, affiliateId]);

  const handleMarkPayment = useCallback(() => {
    if (payment && payment.invoiceId) {
      dispatch(
        openDialog({
          dialog: "mark-payment",
          payload: {
            type: "submit",
            title: "Are you sure you want to mark invoice as paid?",
            subtitle: "This operation cannot be undone",
            onConfirm: () =>
              dispatch(
                markPayment(Number(affiliateId), Number(payment.invoiceId))
              )
          }
        })
      );
    }
  }, [dispatch, affiliateId, payment]);

  const handleDownloadInvoice = useCallback(
    async (template: "invoice" | "receipt" | "draft") => {
      if (affiliateId && payment && payment.invoiceId) {
        const invoice = await api.admin.affiliate.invoices.getInvoice(
          Number(affiliateId),
          payment.invoiceId
        );
        const blob = await pdf(
          <Invoice template={template} invoice={invoice} />
        ).toBlob();
        saveAs(blob, `${invoice.invoiceNumber}.pdf`);
      }
    },
    [affiliateId, payment]
  );

  const handleDownloadDraft = useCallback(async () => {
    const invoice = await api.admin.affiliate.invoices.getDraftInvoice(
      Number(affiliateId)
    );
    const blob = await pdf(
      <Invoice template="draft" invoice={invoice} />
    ).toBlob();
    saveAs(blob, `${invoice.affiliate.name}_draft-invoice.pdf`);
  }, [affiliateId]);

  return {
    classes,
    error,
    tableError,
    isLoading,
    isTableLoading,
    affiliateId,
    roles,
    month: format(fullDate, "MMMM yyyy"),
    details: payment,
    totals,
    payments,
    handleMarkPayment,
    handleConfirmPayment,
    handleDownloadDraft,
    handleDownloadInvoice
  };
}
