import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { TooltipCardSkeleton } from "components/cards";

const InvoiceInformationSkeleton: FC = () => (
  <Box>
    <Skeleton width={128} height={32} style={{ marginLeft: 16 }} />
    <Box display="flex" flexWrap="wrap">
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
    </Box>
    <Box display="flex" flexWrap="wrap">
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
    </Box>
  </Box>
);

export default InvoiceInformationSkeleton;
