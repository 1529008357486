import { AffiliatePlayersRevenue } from "@types";

import React, { FC } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import Box from "@material-ui/core/Box";

import { BRAND_NAMES } from "constants/brands";
import { TooltipCard } from "components/cards";
import getBrandColorClassName from "utils/getBrandColorClassName";

import { useStyles } from "./styles";

type Props = AffiliatePlayersRevenue;

const PlayerInfo: FC<Props> = ({
  playerId,
  countryId,
  registrationDate,
  link,
  clickDate,
  referralId,
  segment,
  brandId,
  deal
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.playerIdContainer}>
        <TooltipCard className={classes.playerId} label="Player ID">
          {String(playerId)}
        </TooltipCard>
      </Box>
      <Box className={classes.playerInfo}>
        <TooltipCard label="country">{countryId}</TooltipCard>
        <TooltipCard label="Registration Date">
          {format(parseISO(registrationDate), "dd MMM yyyy")}
        </TooltipCard>
        <TooltipCard label="Link">{link}</TooltipCard>
        <TooltipCard label="Brand" className={getBrandColorClassName(brandId)}>
          {BRAND_NAMES[brandId]}
        </TooltipCard>
        <TooltipCard label="Deal">{deal}</TooltipCard>
        {segment && <TooltipCard label="Segment">{segment}</TooltipCard>}
        {clickDate && (
          <TooltipCard label="Click Date">
            {format(parseISO(clickDate), "dd MMM yyyy")}
          </TooltipCard>
        )}
        {referralId && (
          <TooltipCard label="Referral ID">{referralId}</TooltipCard>
        )}
      </Box>
    </Box>
  );
};

export default PlayerInfo;
