import { AffiliateLinkClick, AffiliateLinkClicksTotal } from "@types";

import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";

import Table, { Column } from "components/table";
import EmptyData from "components/empty-data";

import { useReportStyles } from "../styles";

interface Props {
  traffics: AffiliateLinkClick[];
  totals?: AffiliateLinkClicksTotal;
}

const TrafficReport: FC<Props> = ({ traffics, totals }) => {
  const classes = useReportStyles();

  if (isEmpty(traffics)) {
    return <EmptyData className={classes.emptyScreen} />;
  }

  return (
    <Table
      displayRows={6}
      initialData={traffics}
      totals={{ title: "Total", totals }}
    >
      <Column
        label="Date"
        name="date"
        align="left"
        type="text"
        className={classes.dateColumn}
      />
      <Column
        label="Segment"
        name="segment"
        align="left"
        type="text"
        className={classes.segmentColumn}
      />
      <Column label="Clicks" name="clicks" type="text" />
      <Column label="NRC" name="nrc" type="text" />
      <Column label="NDC" name="ndc" type="text" />
      <Column label="Deposits" name="deposits" type="currency" />
      <Column label="Turnover" name="turnover" type="currency" />
      <Column label="Gross" name="grossRevenue" type="currency" />
      <Column label="Net" name="netRevenue" type="currency" />
      <Column label="CPA" name="cpa" type="currency" />
      <Column label="Comm." name="commission" type="currency" />
      <Column label="Total" name="total" type="currency" />
    </Table>
  );
};

export default TrafficReport;
