import React, { FC, Children, ReactElement } from "react";
import cn from "classnames";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { SortDirection, ChildProps } from "../types";
import { useTableStyles } from "../styles";

interface Props {
  columns: ReactElement<ChildProps>[];
  sortBy: string;
  sortDirection: SortDirection;
  checkboxProps: {
    checked?: boolean;
    indeterminate?: boolean;
  };
  handleSort: (sortKey: string, sortDirection: SortDirection) => void;
  handleAllRowsCheck?: () => void;
}

const Header: FC<Props> = ({
  columns,
  sortBy,
  sortDirection,
  checkboxProps,
  handleSort,
  handleAllRowsCheck
}) => {
  const classes = useTableStyles();

  return (
    <TableHead component="div">
      {Children.map(columns, (column, index) => {
        const { className, align, name, label, style } = column.props;

        return (
          <TableCell
            key={index}
            style={style}
            component="div"
            align={align || "right"}
            className={cn([className], {
              [classes.checkbox]: name === "checkbox"
            })}
          >
            {name === "checkbox" ? (
              <Checkbox {...checkboxProps} onChange={handleAllRowsCheck} />
            ) : name.includes("button") || name.includes("dropdown") ? null : (
              <TableSortLabel
                active={sortBy === name}
                direction={sortDirection}
                classes={{ icon: classes.sortIcon }}
                onClick={() =>
                  handleSort(name, sortDirection === "asc" ? "desc" : "asc")
                }
              >
                {label}
              </TableSortLabel>
            )}
          </TableCell>
        );
      })}
    </TableHead>
  );
};

export default Header;
