import React from "react";
import ReactDOM from "react-dom";

import { createStore } from "store";
import { initializeInterceptors } from "api";

import * as serviceWorker from "./serviceWorker";
import App from "./App";

const store = createStore();
initializeInterceptors(store);

ReactDOM.render(<App store={store} />, document.getElementById("root"));

serviceWorker.unregister();
