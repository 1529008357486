import React from "react";
import Box from "@material-ui/core/Box";

import { TooltipCardSkeleton } from "components/cards";

const PlayerInfoSkeleton = () => (
  <Box marginBottom="-8px">
    <TooltipCardSkeleton />
    <Box display="flex" flexWrap="wrap">
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
    </Box>
  </Box>
);

export default PlayerInfoSkeleton;
