import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.colors.white,
      borderBottom: `1px solid ${theme.colors.blacke0}`,
      boxShadow: "none",
      height: 48
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: " 0 24px 0 32px"
    },
    container: {
      display: "flex",
      alignItems: "center"
    },
    logo: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      margin: "16px 32px 16px 0"
    },
    navItem: {
      margin: "0 0 0 24px",
      "&:first-child": {
        margin: 0
      }
    },
    button: {
      fontWeight: 500,
      textTransform: "unset"
    },
    username: {
      color: theme.colors.blue
    },
    icon: {
      color: "inherit",
      fontSize: 16
    },
    exitIcon: {
      marginLeft: 8
    }
  })
);

export { useHeaderStyles };
