import React, { FC, Children, ReactElement } from "react";
import cn from "classnames";

import Checkbox from "@material-ui/core/Checkbox";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";

import formatMoney from "utils/formatMoney";

import { ChildProps, ColumnType } from "../types";
import { useTableStyles } from "../styles";

interface Props {
  columns: ReactElement<ChildProps & { type: ColumnType }>[];
  checkboxProps: {
    checked?: boolean;
    indeterminate?: boolean;
  };
  totals: {
    title: string;
    totals: { [key: string]: number };
  };
  handleAllRowsCheck?: () => void;
}

const Subtotal: FC<Props> = ({
  totals: { title, totals },
  columns,
  checkboxProps,
  handleAllRowsCheck
}) => {
  const classes = useTableStyles();

  return (
    <TableFooter component="div">
      {Children.map(columns, (column, index) => {
        const { name, type, align, style, className } = column.props;

        if (name === "checkbox") {
          return (
            <TableCell component="div" className={classes.checkbox}>
              <Checkbox {...checkboxProps} onChange={handleAllRowsCheck} />
            </TableCell>
          );
        } else if (
          (index === 0 && !handleAllRowsCheck) ||
          (index === 1 && handleAllRowsCheck)
        ) {
          return (
            <TableCell
              component="div"
              style={style}
              className={cn([className])}
            >
              {`${title}:`}
            </TableCell>
          );
        } else if (index > 0 && !Object.keys(totals).includes(name)) {
          return (
            <TableCell
              component="div"
              style={style}
              className={cn([className])}
            />
          );
        } else {
          return (
            <TableCell
              key={index}
              component="div"
              style={style}
              align={align || "right"}
              className={cn([className], {
                [classes.negativeValue]: totals[name] < 0
              })}
            >
              {type === "currency"
                ? formatMoney.from(totals[name])
                : totals[name]}
            </TableCell>
          );
        }
      })}
    </TableFooter>
  );
};

export default Subtotal;
