import React, { FC, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { AFFILIATE_TABS } from "constants/variables";
import { getDatePickerState } from "modules/datepicker";
import {
  getAffiliateSubAffiliatesState,
  fetchAffiliateSubAffiliates
} from "modules/affiliate-sub-afiiliates";

import Skeleton from "./Skeleton";
import { useStyles } from "./styles";

interface Params {
  id: string;
  tab: string;
}

interface LinkTabProps {
  to: string;
  label: string;
  className: string;
  value: boolean;
}

const LinkTab: FC<LinkTabProps> = props => (
  <Tab component={Link} {...props} disableRipple />
);

const AffiliatesTabs: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, tab } = useParams<Params>();
  const { year, month } = useSelector(getDatePickerState);
  const { isLoading, data: subAffiliates } = useSelector(
    getAffiliateSubAffiliatesState
  );
  const isEmptySubs = isEmpty(subAffiliates);

  useEffect(() => {
    if (tab !== "subs") {
      dispatch(
        fetchAffiliateSubAffiliates({
          affiliateId: Number(id),
          year: Number(year),
          month: Number(month)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, year, month]);

  if ((isLoading && isEmptySubs) || (isLoading && tab !== "subs")) {
    return <Skeleton />;
  }

  return (
    <Box className={classes.container}>
      <Tabs
        value={true}
        textColor="secondary"
        classes={{ indicator: classes.indicator }}
      >
        {AFFILIATE_TABS.map(({ label, value }) => {
          const isActive = tab === value;
          const url =
            value === "overview" || value === "revenue" || value === "subs"
              ? `/admin/affiliates/${id}/${value}/${year}/${month}`
              : `/admin/affiliates/${id}/${value}`;

          return (
            <LinkTab
              to={url}
              key={value}
              label={label}
              value={isActive}
              className={cn(classes.tab, {
                [classes.hidden]: isEmptySubs && value === "subs"
              })}
            />
          );
        })}
      </Tabs>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default AffiliatesTabs;
