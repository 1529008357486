import { Fee } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum FeesActionTypes {
  FETCH_FEES_LOADING = "@fees/fetch-fees-loading",
  FETCH_FEES_SUCCESS = "@fees/fetch-fees-success",
  FETCH_FEES_FAILURE = "@fees/fetch-fees-failure"
}

interface FetchFeesIsLoadingAction {
  type: FeesActionTypes.FETCH_FEES_LOADING;
}

interface FetchFeesIsSuccessAction {
  type: FeesActionTypes.FETCH_FEES_SUCCESS;
  payload: { fees: Fee[] };
}

interface FetchPlansIsFailureAction {
  type: FeesActionTypes.FETCH_FEES_FAILURE;
}

type PlansActions =
  | FetchFeesIsLoadingAction
  | FetchFeesIsSuccessAction
  | FetchPlansIsFailureAction;

const fetchFeesIsLoading = (): FetchFeesIsLoadingAction => ({
  type: FeesActionTypes.FETCH_FEES_LOADING
});

const fetchFeesIsSuccess = (fees: Fee[]): FetchFeesIsSuccessAction => ({
  type: FeesActionTypes.FETCH_FEES_SUCCESS,
  payload: { fees }
});

const fetchFeesIsFailure = (): FetchPlansIsFailureAction => ({
  type: FeesActionTypes.FETCH_FEES_FAILURE
});

const fetchFees = (): ThunkActionCreator<PlansActions> => async dispatch => {
  dispatch(fetchFeesIsLoading());
  try {
    const { fees } = await api.admin.fees.getFees();
    dispatch(fetchFeesIsSuccess(fees));
  } catch (err) {
    dispatch(fetchFeesIsFailure());
  }
};

interface FeesState {
  error: boolean;
  isLoading: boolean;
  data: Fee[];
}

const initialState: FeesState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<FeesState, PlansActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FeesActionTypes.FETCH_FEES_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case FeesActionTypes.FETCH_FEES_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        data: action.payload.fees
      };
    case FeesActionTypes.FETCH_FEES_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };

    default:
      return state;
  }
};

const getFeesState = (state: AppState) => state.fees;

export { reducer, fetchFees, getFeesState };
