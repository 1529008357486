import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { TableSkeleton } from "components/table";

import { useSkeletonStyles } from "../../styles";

const DraftInvoiceSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box>
      <Skeleton width={300} height={30} />
      <Box className={classes.search}>
        <Skeleton width={400} height={30} />
        <Box className={classes.buttons}>
          <Skeleton className={classes.button} />
          <Skeleton className={classes.button} />
          <Skeleton className={classes.button} />
        </Box>
      </Box>
      <TableSkeleton rows={6} cells={6} />
    </Box>
  );
};

export default DraftInvoiceSkeleton;
