import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";

interface Props {
  disabled: boolean;
  csvProps: DownloadCsvButtonProps;
  handleCreateInvoice?: () => void;
  handleCreatePayment?: () => void;
}

const SearchButtons: FC<Props> = ({
  csvProps,
  disabled,
  handleCreateInvoice,
  handleCreatePayment
}) => (
  <>
    {handleCreateInvoice && (
      <Button
        disabled={disabled}
        color="secondary"
        onClick={handleCreateInvoice}
      >
        confirm invoice
      </Button>
    )}
    {handleCreatePayment && (
      <Button onClick={handleCreatePayment}>add payment</Button>
    )}
    <DownloadCsvButton {...csvProps} />
  </>
);

export default SearchButtons;
