import { CreateFeeValues, SelectOption } from "@types";

import React, { FC, useState } from "react";
import { Form, Formik, FormikProps, FieldArray, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CreateCardIcon from "@material-ui/icons/PlaylistAddOutlined";

import TextField from "components/text-field";
import Input from "components/input";
import SubmitButton from "components/submit-button";
import { TextCard } from "components/cards";

import validationSchema from "./validationSchema";
import { getInitialCountries } from "./helpers";
import { useStyles } from "./styles";
import Card from "./components/Card";

export interface CreateFeeProps {
  title: string;
  initialState?: CreateFeeValues;
  handleSubmit: (
    values: CreateFeeValues,
    helpers: FormikHelpers<CreateFeeValues>
  ) => void;
}

const initialValues: CreateFeeValues = {
  fee: {
    name: "",
    percent: "",
    active: false
  },
  rules: []
};

const CreateFee: FC<CreateFeeProps> = ({
  title,
  initialState,
  handleSubmit
}) => {
  const classes = useStyles();

  const [countries, setCountries] = useState<SelectOption[]>(
    getInitialCountries(initialState ? initialState.rules : initialValues.rules)
  );

  return (
    <Formik
      initialValues={initialState ? initialState : initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue
      }: FormikProps<CreateFeeValues>) => (
        <Form className="drawer-container">
          <Box className="drawer-header">
            <Box className={classes.row}>
              <Typography variant="h4" className="drawer-header_title">
                {title}
              </Typography>
              <Typography className="drawer-header_subtitle">
                changes take effect next month
              </Typography>
            </Box>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <Box display="flex">
              <Input
                label="name"
                name="fee.name"
                className={classes.dealInput}
                error={touched.fee?.name && !!errors.fee?.name}
                helperText={touched.fee?.name && errors.fee?.name}
                value={values.fee.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <TextCard label="active" className={classes.activeBox}>
                <Switch
                  edge="start"
                  name="fee.active"
                  checked={values.fee.active}
                  onChange={handleChange}
                />
              </TextCard>
            </Box>
          </Box>
          <TextField name="fee.percent" label="fee %" numeric />
          <Divider className={classes.divider} />
          <FieldArray
            name="rules"
            render={arrayHelpers => (
              <Box>
                <Box className={classes.createCardContainer}>
                  <ButtonBase
                    className={classes.createCard}
                    onClick={() => {
                      arrayHelpers.insert(0, {});
                    }}
                  >
                    <Typography className={classes.createCardText}>
                      Click to add country restrictions
                    </Typography>
                    <CreateCardIcon className={classes.createCardIcon} />
                  </ButtonBase>
                </Box>
                <Box>
                  {values.rules.map((rule, index) => (
                    <Card
                      id={index}
                      key={index}
                      rule={rule}
                      errors={errors}
                      touched={touched}
                      countries={countries}
                      handleFieldValue={setFieldValue}
                      handleCountries={setCountries}
                      handleRemoveCard={() => {
                        arrayHelpers.remove(index);
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateFee;
