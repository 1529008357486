import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const ROW_COUNT = Array.from(Array(6));

const LogSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        <Skeleton width={120} height={16} />
        <Skeleton className={classes.subtitle} width={80} height={16} />
      </Box>
      <Skeleton height={44} />
    </Box>
  );
};

const LogsSkeleton: FC = () => (
  <>
    {ROW_COUNT.map((_, index) => (
      <LogSkeleton key={index} />
    ))}
  </>
);

export default LogsSkeleton;
