import React, { FC, useState } from "react";
import { saveAs } from "file-saver";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";

import api from "api";

import FileIcon from "./FileIcon";
import getFileType from "./helpers/getFileType";
import { useStyles } from "./styles";

interface Props extends BoxProps {
  fileName: string;
  tooltipPlacement?: TooltipProps["placement"];
}

const Attachment: FC<Props> = ({
  fileName,
  tooltipPlacement,
  className,
  ...rest
}) => {
  const classes = useStyles({ isShared: !!tooltipPlacement });
  const [progress, setProgress] = useState<number>(0);
  const fileExtension = fileName.slice(fileName.lastIndexOf("."));
  const originalName = fileName.substring(fileName.lastIndexOf("/") + 1);

  const handleDownload = async () => {
    const blob = await api.admin.attachments.getAttachment(
      fileName,
      setProgress
    );
    saveAs(blob, originalName);
  };

  return (
    <Tooltip
      title={originalName}
      classes={{ tooltip: classes.noMaxWidth }}
      placement={tooltipPlacement || "bottom-start"}
    >
      <Box
        onClick={handleDownload}
        className={cn(classes.container, className)}
        {...rest}
      >
        <FileIcon
          className={classes.fileIcon}
          type={getFileType(fileExtension)}
        />
        <Box className={classes.filename}>
          {originalName.replace(fileExtension, "")}
        </Box>
        {progress > 0 && progress < 100 && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progress}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default Attachment;
