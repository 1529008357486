import React, { FC } from "react";

import BrandFilter, { BrandFilterProps } from "components/brand-filter";
import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";
import { RangeDatePicker } from "modules/datepicker";

interface Props {
  disabled?: boolean;
  csvProps: DownloadCsvButtonProps;
  filterProps: BrandFilterProps;
}

const SearchButtons: FC<Props> = ({ disabled, csvProps, filterProps }) => {
  return (
    <>
      <BrandFilter {...filterProps} />
      <RangeDatePicker />
      <DownloadCsvButton {...csvProps} disabled={disabled} />
    </>
  );
};

export default SearchButtons;
