import { AffiliateLog } from "@types";

import React, { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { ListChildComponentProps } from "react-window";
import cn from "classnames";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { LOG_SYSTEM_NAME } from "constants/variables";
import Attachment from "components/attachment";
import { getUserById } from "modules/users";

import { useStyles } from "../styles";

interface Props extends ListChildComponentProps {
  data: AffiliateLog[];
  handleRowHeight: (index: number, size: number) => void;
}

const ListItem: FC<Props> = ({ index, style, data, handleRowHeight }) => {
  const classes = useStyles();
  const { createdBy, createdAt, note, attachments } = data[index];
  const rowRef = useRef<HTMLDivElement>(null);
  const { user } = useSelector(getUserById)(createdBy);

  const isSystem = createdBy === 0;
  const lastVisit = formatDistanceToNow(new Date(createdAt), {
    addSuffix: true
  });

  useEffect(() => {
    if (rowRef.current && handleRowHeight) {
      handleRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  return (
    <div style={style}>
      <div
        ref={rowRef}
        className={cn(classes.log, { [classes.systemMessage]: isSystem })}
      >
        <Box className={classes.logHeader}>
          <Typography
            className={cn(classes.logUsername, {
              [classes.systemUser]: isSystem
            })}
          >
            {isSystem || !user ? LOG_SYSTEM_NAME : user.email}
          </Typography>
          <Typography className={classes.logLastVisit}>{lastVisit}</Typography>
        </Box>
        <Box className={classes.logTextContainer}>
          <Typography className={classes.logText}>{note}</Typography>
        </Box>
        {attachments && (
          <Box className={classes.logAttachments}>
            {attachments.map(fileName => (
              <Attachment key={fileName} fileName={fileName} />
            ))}
          </Box>
        )}
      </div>
    </div>
  );
};

export default ListItem;
