import { AffiliateResponse } from "@types";

import React, { FC } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

import EmptyData from "components/empty-data";
import { TooltipCard, TooltipCardSkeleton } from "components/cards";
import { getUsersState } from "modules/users";
import { getAffiliateState } from "modules/affiliate";

import { Container, Divider } from "./styles";

const AffiliateDetailsSkeleton: FC = () => (
  <Box>
    <Container>
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
      <TooltipCardSkeleton />
    </Container>
    <Divider />
  </Box>
);

const AffiliateDetails: FC = () => {
  const { isLoading, error, data } = useSelector(getAffiliateState);
  const { data: users } = useSelector(getUsersState);

  if (isLoading) {
    return <AffiliateDetailsSkeleton />;
  }

  if (error || !data) {
    return <EmptyData />;
  }

  const { contactName, masterId, email, skype, phone, userId } =
    data as AffiliateResponse;
  const accountManager = users.find(user => user.userId === userId)?.email;

  return (
    <Box>
      <Container>
        <TooltipCard label="name">{contactName}</TooltipCard>
        <TooltipCard label="email">{email}</TooltipCard>
        {skype && <TooltipCard label="skype">{skype}</TooltipCard>}
        {phone && <TooltipCard label="phone">{phone}</TooltipCard>}
        {accountManager && (
          <TooltipCard label="account manager">{accountManager}</TooltipCard>
        )}
        {masterId && (
          <TooltipCard label="master account">{masterId}</TooltipCard>
        )}
      </Container>
      <Divider />
    </Box>
  );
};

export { AffiliateDetailsSkeleton };
export default AffiliateDetails;
