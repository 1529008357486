import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import { TableSkeleton } from "components/table";
import { TooltipCardSkeleton } from "components/cards";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const PaymentsTableSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box>
          <Skeleton width={256} height={32} />
          <Skeleton width={152} height={24} />
          <Box display="flex" flexWrap="wrap" marginLeft={-2} maxWidth={312}>
            <TooltipCardSkeleton />
            <TooltipCardSkeleton />
            <TooltipCardSkeleton />
            <TooltipCardSkeleton />
          </Box>
        </Box>
        <Box marginLeft={4}>
          <Skeleton width={128} height={24} />
          <Skeleton width={96} height={16} />
          <Box className={classes.bankDetails}>
            <Skeleton width={128} height={16} />
            <Skeleton width={64} height={16} />
            <Skeleton width={143} height={16} />
            <Skeleton width={128} height={16} />
            <Skeleton width={128} height={16} />
            <Skeleton width={143} height={16} />
            <Skeleton width={64} height={16} />
          </Box>
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.dateRow}>
          <Skeleton width={256} height={32} />
          <Skeleton width={128} height={32} className={classes.button} />
        </Box>
        <Box display="flex" flexWrap="wrap" marginLeft={-2} marginTop={-1}>
          <TooltipCardSkeleton />
          <TooltipCardSkeleton />
        </Box>
        <Box display="flex" flexWrap="wrap" marginLeft={-2}>
          <TooltipCardSkeleton />
          <TooltipCardSkeleton />
          <TooltipCardSkeleton />
          <TooltipCardSkeleton />
        </Box>
        <Box className={classes.buttonsRow}>
          <Skeleton width={144} height={32} className={classes.button} />
          <Skeleton width={144} height={32} className={classes.button} />
        </Box>
        <TableSkeleton showTotal rows={6} cells={3} cellWidth={128} />
      </Box>
    </Box>
  );
};

export default PaymentsTableSkeleton;
