import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";
import BrandFilter, { BrandFilterProps } from "components/brand-filter";

interface Props {
  disabled: boolean;
  csvProps: DownloadCsvButtonProps;
  filterProps: BrandFilterProps;
  handleChangeDeal?: (() => void) | false;
  handleMovePlayers?: (() => void) | false;
}

const SearchButtons: FC<Props> = ({
  disabled,
  csvProps,
  filterProps,
  handleChangeDeal,
  handleMovePlayers
}) => (
  <>
    <BrandFilter {...filterProps} />
    {handleChangeDeal && (
      <Button disabled={disabled} onClick={handleChangeDeal}>
        change deal
      </Button>
    )}
    {handleMovePlayers && (
      <Button disabled={disabled} onClick={handleMovePlayers}>
        move players
      </Button>
    )}
    <DownloadCsvButton {...csvProps} />
  </>
);

export default SearchButtons;
