import {
  AffiliateCallbacks,
  AffiliateCallback,
  CreateAffiliateCallbackRequest
} from "@types";

import { FormikHelpers } from "formik";
import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { openSnackbar, SnackbarActions } from "modules/snackbar";

enum AffiliateCallbacksActionTypes {
  FETCH_AFFILIATE_CALLBACKS_LOADING = "@affiliate/callbacks/fetch-callbacks-loading",
  FETCH_AFFILIATE_CALLBACKS_SUCCESS = "@affiliate/callbacks/fetch-callbacks-success",
  FETCH_AFFILIATE_CALLBACKS_FAILURE = "@affiliate/callbacks/fetch-callbacks-failure",
  CREATE_AFFILIATE_CALLBACK_SUCCESS = "@affiliate/callbacks/create-callback-success",
  UPDATE_AFFILIATE_CALLBACK_SUCCESS = "@affiliate/callbacks/update-callback-success",
  REMOVE_AFFILIATE_CALLBACK_SUCCESS = "@affiliate/callbacks/remove-callback-success"
}

interface FetchAffiliateCallbacksIsLoadingAction {
  type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_LOADING;
}

interface FetchAffiliateCallbacksIsSuccessAction {
  type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_SUCCESS;
  payload: AffiliateCallbacks;
}

interface FetchAffiliateCallbacksIsFailureAction {
  type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_FAILURE;
}

interface CreateAffiliateCallbackIsSuccessAction {
  type: AffiliateCallbacksActionTypes.CREATE_AFFILIATE_CALLBACK_SUCCESS;
  payload: AffiliateCallback;
}

interface UpdateAffiliateCallbackIsSuccessAction {
  type: AffiliateCallbacksActionTypes.UPDATE_AFFILIATE_CALLBACK_SUCCESS;
  payload: AffiliateCallback;
}

interface RemoveAffiliateCallbackIsSuccessAction {
  type: AffiliateCallbacksActionTypes.REMOVE_AFFILIATE_CALLBACK_SUCCESS;
}

type AffiliateCallbacksActions =
  | FetchAffiliateCallbacksIsLoadingAction
  | FetchAffiliateCallbacksIsSuccessAction
  | FetchAffiliateCallbacksIsFailureAction
  | CreateAffiliateCallbackIsSuccessAction
  | UpdateAffiliateCallbackIsSuccessAction
  | RemoveAffiliateCallbackIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<AffiliateCallbacksActions | DrawersActions>
>;

const fetchAffiliateCallbacksIsLoading =
  (): FetchAffiliateCallbacksIsLoadingAction => ({
    type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_LOADING
  });

const fetchAffiliateCallbacksIsSuccess = (
  callbacks: AffiliateCallbacks
): FetchAffiliateCallbacksIsSuccessAction => ({
  type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_SUCCESS,
  payload: callbacks
});

const fetchAffiliateCallbacksIsFailure =
  (): FetchAffiliateCallbacksIsFailureAction => ({
    type: AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_FAILURE
  });

const createAffiliateCallbackIsSuccess = (
  callback: AffiliateCallback
): CreateAffiliateCallbackIsSuccessAction => ({
  type: AffiliateCallbacksActionTypes.CREATE_AFFILIATE_CALLBACK_SUCCESS,
  payload: callback
});

const updateAffiliateCallbackIsSuccess = (
  callback: AffiliateCallback
): UpdateAffiliateCallbackIsSuccessAction => ({
  type: AffiliateCallbacksActionTypes.UPDATE_AFFILIATE_CALLBACK_SUCCESS,
  payload: callback
});

const removeAffiliateCallbackIsSuccess =
  (): RemoveAffiliateCallbackIsSuccessAction => ({
    type: AffiliateCallbacksActionTypes.REMOVE_AFFILIATE_CALLBACK_SUCCESS
  });

const fetchAffiliateCallbacks =
  (affiliateId: number): ThunkActionCreator<AffiliateCallbacksActions> =>
  async dispatch => {
    dispatch(fetchAffiliateCallbacksIsLoading());
    try {
      const response = await api.admin.affiliate.callbacks.getCallbacks(
        affiliateId
      );
      dispatch(fetchAffiliateCallbacksIsSuccess(response));
    } catch (err) {
      dispatch(fetchAffiliateCallbacksIsFailure());
    }
  };

const createAffiliateCallback =
  (
    affiliateId: number,
    data: CreateAffiliateCallbackRequest,
    formikHelpers: FormikHelpers<CreateAffiliateCallbackRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const { callback } = await api.admin.affiliate.callbacks.createCallback(
        affiliateId,
        data
      );
      dispatch(openSnackbar({ type: "success", message: "Callback created!" }));
      dispatch(createAffiliateCallbackIsSuccess(callback));
      dispatch(fetchAffiliateCallbacks(affiliateId));
      formikHelpers.resetForm();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const updateAffiliateCallback =
  (
    affiliateId: number,
    callbackId: number,
    data: CreateAffiliateCallbackRequest,
    formikHelpers: FormikHelpers<CreateAffiliateCallbackRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const { callback } = await api.admin.affiliate.callbacks.updateCallback(
        affiliateId,
        callbackId,
        data
      );
      dispatch(openSnackbar({ type: "success", message: "Callback updated!" }));
      dispatch(updateAffiliateCallbackIsSuccess(callback));
      dispatch(fetchAffiliateCallbacks(affiliateId));
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const removeAffiliateCallback =
  (affiliateId: number, callbackId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.affiliate.callbacks.deleteCallback(affiliateId, callbackId);
    dispatch(openSnackbar({ type: "success", message: "Callback removed!" }));
    dispatch(removeAffiliateCallbackIsSuccess());
    dispatch(fetchAffiliateCallbacks(affiliateId));
  };

interface AffiliateCallbacksState {
  error: boolean;
  isLoading: boolean;
  data: AffiliateCallback[];
}

const initialState: AffiliateCallbacksState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<AffiliateCallbacksState, AffiliateCallbacksActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.callbacks.map(({ name, code, ...rest }) => ({
          name: name ? name : "All",
          code: code ? code : "",
          ...rest
        }))
      };
    case AffiliateCallbacksActionTypes.FETCH_AFFILIATE_CALLBACKS_FAILURE:
      return {
        error: true,
        isLoading: false,
        data: []
      };

    default:
      return state;
  }
};

const getAffiliateCallbacksState = (state: AppState) =>
  state.affiliate.callbacks;

export {
  reducer,
  createAffiliateCallback,
  fetchAffiliateCallbacks,
  updateAffiliateCallback,
  removeAffiliateCallback,
  getAffiliateCallbacksState
};
