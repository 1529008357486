import { AffiliatePayment } from "@types";

import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import Search from "components/search";
import EmptyData from "components/empty-data";
import Table, { Column, HTMLColumn } from "components/table";

import DraftInvoiceTableSkeleton from "./Skeleton";
import SearchButtons from "./SearchButtons";
import { getAffiliateInvoicesDraftState } from "../../duck";
import { useStyles } from "../../styles";

interface Props {
  handleCreateInvoice?: () => void;
  handleCreatePayment?: () => void;
}

const CSV_HEADERS = [
  { label: "ID", key: "paymentId" },
  { label: "Date", key: "transactionDate" },
  { label: "CreatedBy", key: "createdBy" },
  { label: "Type", key: "type" },
  { label: "Description", key: "description" },
  { label: "Amount", key: "amount" }
];

const DraftInvoiceTable: FC<Props> = ({
  handleCreateInvoice,
  handleCreatePayment
}) => {
  const classes = useStyles();
  const { isLoading, payments, canConfirm, totals } = useSelector(
    getAffiliateInvoicesDraftState
  );
  const { query, setQuery, results } = useSearch<AffiliatePayment>(
    ["description", "createdBy", "paymentId", "type"],
    payments
  );

  const disabled = isEmpty(payments);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  if (isLoading) {
    return <DraftInvoiceTableSkeleton />;
  }

  return (
    <Box>
      <Typography className={classes.title} variant="h3">
        Draft Invoice
      </Typography>
      <Search
        value={query}
        onChange={handleSearch}
        placeholder="Search payment"
        disabled={disabled}
        buttons={
          <SearchButtons
            csvProps={{
              headers: CSV_HEADERS,
              data: results,
              disabled: isEmpty(results)
            }}
            disabled={!canConfirm}
            handleCreatePayment={handleCreatePayment}
            handleCreateInvoice={handleCreateInvoice}
          />
        }
      />
      {disabled ? (
        <EmptyData
          margin="16px 0"
          title="no payments"
          text="There is no payments credited for this month yet"
        />
      ) : (
        <Table
          initialData={results}
          displayRows={4}
          totals={{ title: "Subtotal", totals }}
        >
          <Column
            label="ID"
            name="paymentId"
            align="left"
            type="text"
            className={classes.idColumn}
          />
          <Column
            label="Date"
            name="transactionDate"
            align="left"
            type="date"
            className={classes.dateColumn}
          />
          <Column
            label="Created By"
            name="createdBy"
            align="left"
            type="text"
            className={classes.createdByColumn}
          />
          <Column
            label="Type"
            name="type"
            align="left"
            type="text"
            className={classes.typeColumn}
          />
          <HTMLColumn
            label="Description"
            name="description"
            align="left"
            className={classes.descriptionColumn}
          />
          <Column
            label="Amount"
            name="amount"
            type="currency"
            className={classes.amountColumn}
          />
        </Table>
      )}
    </Box>
  );
};

export default DraftInvoiceTable;
