import * as yup from "yup";
import { TRANSACTION_METHODS } from "constants/payments";

const validationSchema = yup.object().shape({
  type: yup.string().nullable().required("Type is required"),
  amount: yup
    .mixed()
    .when("type", {
      is: TRANSACTION_METHODS.manual,
      then: yup
        .number()
        .notOneOf([0], "Amount is must be greater or lower than 0")
        .typeError("Amount must be a valid amount"),
      otherwise: yup.number().positive("Amount is must be greater then 0")
    })
    .required("Amount is required"),
  description: yup.string().required("Description is required")
});

export default validationSchema;
