import { ListAffiliate } from "@types";

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import some from "lodash/some";

import useSearch from "hooks/useSearch";
import { getDatePickerState } from "modules/datepicker";

import {
  addToLastUsageAffiliates,
  clearLastUsageAffiliates,
  fetchAffiliates,
  getAffiliatesState,
  removeFromLastUsageAffiliates
} from "./duck";
import getNeighborhoodIds from "./helpers/getNeighborhoodIds";

interface Params {
  id: string;
  tab: string;
}

export default function () {
  const { id, tab } = useParams<Params>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { year, month } = useSelector(getDatePickerState);

  // const lastUsageAffiliates = useSelector(getLastUsageAffiliates);
  const { isLoading, error, list, lastUsageIds } =
    useSelector(getAffiliatesState);
  const [activeAffiliate, setActiveAffiliate] = useState<number>(Number(id));
  const { query, setQuery, results } = useSearch<ListAffiliate>(
    ["affiliateId", "affiliateName", "affiliateEmail"],
    list
  );

  useEffect(() => {
    dispatch(fetchAffiliates());
  }, [dispatch]);

  useEffect(() => {
    const affiliateId = Number(id);
    const isAffiliate = some(list, { affiliateId });

    if (isAffiliate) {
      handleChangeAffiliate(affiliateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, id]);

  const handleChangeAffiliate = useCallback(
    (id: number) => {
      const isLastUsageAffiliate = lastUsageIds.includes(id);
      if (!isLastUsageAffiliate) {
        dispatch(addToLastUsageAffiliates(id));
      }
      setActiveAffiliate(id);
    },
    [dispatch, lastUsageIds]
  );

  const handleRemoveAffiliate = useCallback(
    (id: number) => {
      const { nextId, previousId } = getNeighborhoodIds(id, lastUsageIds);
      const isActive = activeAffiliate === id;
      const haveNextId = isActive && nextId;
      const havePreviousId = isActive && previousId;
      const isCalendarTab = tab === "overview" || tab === "revenue";

      dispatch(removeFromLastUsageAffiliates(id));

      if (isActive && havePreviousId) {
        setActiveAffiliate(previousId);
        history.push(
          isCalendarTab
            ? `/admin/affiliates/${previousId}/${tab}/${year}/${month}`
            : `/admin/affiliates/${previousId}/${tab}`
        );
      }

      if (isActive && haveNextId) {
        setActiveAffiliate(nextId);
        history.push(
          isCalendarTab
            ? `/admin/affiliates/${nextId}/${tab}/${year}/${month}`
            : `/admin/affiliates/${nextId}/${tab}`
        );
      }

      if (isActive && !haveNextId && !havePreviousId) {
        history.push("/admin/affiliates");
      }
    },
    [activeAffiliate, lastUsageIds, dispatch, history, tab, month, year]
  );

  const handleClearAffiliates = useCallback(() => {
    dispatch(clearLastUsageAffiliates());
    history.push("/admin/affiliates");
  }, [dispatch, history]);

  return {
    isLoading,
    error,
    lastUsageIds,
    affiliate: activeAffiliate,
    affiliates: results,
    searchQuery: query,
    handleSearch: setQuery,
    handleChangeAffiliate,
    handleRemoveAffiliate,
    handleClearAffiliates
  };
}
