import React, { FC } from "react";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { TooltipCard } from "components/cards";
import EmptyData from "components/empty-data";
import SubmitButton from "components/submit-button";
import ChangePassword from "components/change-password";

import Skeleton from "./Skeleton";
import EditPayment from "./components/EditPayment";
import EditProfile from "./components/EditProfile";
import validationSchema from "./validationSchema";
import useAffiliateProfile from "./useAffiliateProfile";

const Component: FC = () => {
  const {
    classes,
    error,
    isLoading,
    profile,
    apiToken,
    handleSubmit,
    handleRefreshToken
  } = useAffiliateProfile();

  if (error) {
    return <EmptyData />;
  }

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <Skeleton />
      ) : !profile ? (
        <EmptyData />
      ) : (
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={profile}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.box}>
              <Typography variant="h3" color="textSecondary">
                Edit profile
              </Typography>
              <EditProfile countryId={values.countryId} />
              <Box className={classes.secondBox}>
                <Typography variant="h3" color="textSecondary">
                  Payment method
                </Typography>
                <EditPayment paymentMethod={values.paymentMethod} />
              </Box>
              <SubmitButton className={classes.button}>
                save changes
              </SubmitButton>
            </form>
          )}
        </Formik>
      )}
      <Box className={classes.box}>
        <ChangePassword email={profile?.email || ""} />
        <Box className={classes.secondBox}>
          <Typography variant="h3" color="textSecondary">
            Tools
          </Typography>
          <Box marginTop={2} margin={-2}>
            <Box margin={2}>
              <Typography variant="subtitle1" component="span">
                API Reference
              </Typography>
              <Typography>
                <Link
                  target="__blank"
                  className={classes.text}
                  href=" https://affmore.com/api/v1/affiliate-swagger/"
                >
                  https://affmore.com/api/v1/affiliate-swagger/
                </Link>
              </Typography>
            </Box>
            {apiToken && (
              <TooltipCard label="API Token">{apiToken}</TooltipCard>
            )}
            <Button
              onClick={handleRefreshToken}
              className={classes.refreshButton}
            >
              Refresh api token
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Component;
