import React, { FC } from "react";
import { NavLink as RouterNavLink, NavLinkProps } from "react-router-dom";
import cn from "classnames";

import { useStyles } from "./styles";

interface Props extends NavLinkProps {
  isDisabled?: boolean;
}

const NavLink: FC<Props> = ({
  isDisabled,
  children,
  className,
  activeClassName,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    } else if (onClick) {
      onClick && onClick(event);
    }
  };

  return (
    <RouterNavLink
      onClick={handleClick}
      className={cn(classes.root, className, {
        [classes.disabled]: isDisabled
      })}
      activeClassName={cn(classes.active, activeClassName)}
      {...rest}
    >
      {children}
    </RouterNavLink>
  );
};

export default NavLink;
