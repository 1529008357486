import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

import { ActionCardSkeleton } from "components/cards";

import { useSkeletonStyles } from "./styles";

const DEALS_COUNT = Array.from(Array(5));

const DealsSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <>
      <Typography component="div" className={classes.dealsLabel}>
        Deals
      </Typography>
      <Box className={classes.row}>
        {DEALS_COUNT.map((_, index) => (
          <ActionCardSkeleton
            key={index}
            width={96}
            className={classes.actionCard}
          >
            <div className={classes.dealsRow}>
              <Skeleton className={classes.text} />
              <Skeleton variant="circle" className={classes.icon} />
            </div>
          </ActionCardSkeleton>
        ))}
      </Box>
      <Divider className={classes.divider} />
    </>
  );
};

export default DealsSkeleton;
