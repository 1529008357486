import {
  AffiliatePlayerActivity,
  AffiliatePlayerActivities,
  AffiliatePlayerActivitiesTotals,
  AffiliatePlayerRequest,
  PlayerRevenueRequestOptions
} from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum AffiliatePlayerActionTypes {
  FETCH_AFFILIATE_PLAYER_ACTIVITIES_LOADING = "@affiliate/player/fetch-activities-loading",
  FETCH_AFFILIATE_PLAYER_ACTIVITIES_SUCCESS = "@affiliate/player/fetch-activities-success",
  FETCH_AFFILIATE_PLAYER_ACTIVITIES_FAILURE = "@affiliate/player/fetch-activities-failure"
}

interface FetchAffiliatePlayerActivitiesIsLoadingAction {
  type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_LOADING;
}

interface FetchAffiliatePlayerActivitiesIsSuccessAction {
  type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_SUCCESS;
  payload: AffiliatePlayerActivities;
}

interface FetchAffiliatePlayerActivitiesIsFailureAction {
  type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_FAILURE;
}

type AffiliatePlayerActivitiesActions =
  | FetchAffiliatePlayerActivitiesIsLoadingAction
  | FetchAffiliatePlayerActivitiesIsSuccessAction
  | FetchAffiliatePlayerActivitiesIsFailureAction;

const fetchAffiliatePlayerActivitiesIsLoading =
  (): FetchAffiliatePlayerActivitiesIsLoadingAction => ({
    type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_LOADING
  });

const fetchAffiliatePlayerActivitiesIsSuccess = (
  activities: AffiliatePlayerActivities
): FetchAffiliatePlayerActivitiesIsSuccessAction => ({
  type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_SUCCESS,
  payload: { ...activities }
});

const fetchAffiliatePlayerActivitiesIsFailure =
  (): FetchAffiliatePlayerActivitiesIsFailureAction => ({
    type: AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_FAILURE
  });

const fetchAffiliatePlayerActivitiesByDate =
  (
    options: PlayerRevenueRequestOptions
  ): ThunkActionCreator<AffiliatePlayerActivitiesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayerActivitiesIsLoading());
    try {
      const details =
        await api.admin.affiliate.players.getPlayerActivitiesByDate(options);
      dispatch(fetchAffiliatePlayerActivitiesIsSuccess(details));
    } catch (err) {
      dispatch(fetchAffiliatePlayerActivitiesIsFailure());
    }
  };

const fetchAffiliatePlayerActivities =
  (
    id: number,
    playerId: number
  ): ThunkActionCreator<AffiliatePlayerActivitiesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayerActivitiesIsLoading());
    try {
      const activities = await api.admin.affiliate.players.getPlayerActivities(
        id,
        playerId
      );
      dispatch(fetchAffiliatePlayerActivitiesIsSuccess(activities));
    } catch (err) {
      dispatch(fetchAffiliatePlayerActivitiesIsFailure());
    }
  };

const fetchAffiliateViewPlayerActivitiesByDate =
  (
    options: AffiliatePlayerRequest
  ): ThunkActionCreator<AffiliatePlayerActivitiesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayerActivitiesIsLoading());
    try {
      const details = await api.affiliate.players.getPlayerByDate(options);
      dispatch(fetchAffiliatePlayerActivitiesIsSuccess(details));
    } catch (err) {
      dispatch(fetchAffiliatePlayerActivitiesIsFailure());
    }
  };

const fetchAffiliateViewPlayerActivities =
  (playerId: number): ThunkActionCreator<AffiliatePlayerActivitiesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayerActivitiesIsLoading());
    try {
      const details = await api.affiliate.players.getPlayer(playerId);
      dispatch(fetchAffiliatePlayerActivitiesIsSuccess(details));
    } catch (err) {
      dispatch(fetchAffiliatePlayerActivitiesIsFailure());
    }
  };

interface AffiliatePlayerActivitiesState {
  error: boolean;
  isLoading: boolean;
  total: number;
  totals?: AffiliatePlayerActivitiesTotals;
  activities: AffiliatePlayerActivity[];
}

const initialState = {
  error: false,
  isLoading: false,
  total: 0,
  totals: undefined,
  activities: []
};

const reducer: Reducer<
  AffiliatePlayerActivitiesState,
  AffiliatePlayerActivitiesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.activities.total,
        totals: action.payload.activities.totals,
        activities: action.payload.activities.items
      };
    case AffiliatePlayerActionTypes.FETCH_AFFILIATE_PLAYER_ACTIVITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliatePlayerActivitiesState = (state: AppState) =>
  state.affiliate.player;

export {
  reducer,
  fetchAffiliatePlayerActivities,
  fetchAffiliateViewPlayerActivities,
  fetchAffiliatePlayerActivitiesByDate,
  fetchAffiliateViewPlayerActivitiesByDate,
  getAffiliatePlayerActivitiesState
};
