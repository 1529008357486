import * as yup from "yup";
import { isBefore } from "date-fns";

const validationSchema = yup.object().shape({
  brandId: yup.string().required("Brand is required"),
  affiliateId: yup.number().required("Affiliate is required"),
  fees: yup
    .array()
    .of(
      yup.object().shape({
        adminFeeId: yup.number().required(),
        periodFrom: yup.date().required(),
        periodTo: yup
          .date()
          .required()
          .when("periodFrom", (periodFrom, schema) => {
            return schema.min(periodFrom, "To date must be after From date");
          })
      })
    )
    .test(
      "no-overlapping-dates",
      "Periods cannot overlap",
      function (periods, context) {
        if (!periods) return true;
        periods.map(
          ({ periodFrom: periodFromA, periodTo: periodToA }, indexA) => {
            if (
              periods.some(
                ({ periodFrom: periodFromB, periodTo: periodToB }, indexB) => {
                  if (periodFromA && periodFromB && periodToA && periodToB)
                    return (
                      indexA !== indexB &&
                      !isBefore(periodToA, periodFromB) &&
                      !isBefore(periodToB, periodFromA)
                    );
                }
              )
            )
              throw context.createError({
                path: `fees[${indexA}].periodTo`,
                message: "Periods cannot overlap"
              });
            return true;
          }
        );
        return true;
      }
    )
});

export default validationSchema;
