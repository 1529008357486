import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

const Logo: FC<SvgIconProps> = ({ ...props }) => (
  <svg
    id="affmore-logo"
    width="84"
    height="16"
    viewBox="0 0 275.3 39.8"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    version="1.1"
    {...props}
  >
    <g>
      <path
        fill="#179DC0"
        d="M19.5,0.1C8.7,0.1,0,8.8,0,19.6s8.7,19.5,19.5,19.5S39,30.4,39,19.6C39.1,8.8,30.3,0.1,19.5,0.1z M24.2,28.8
		l-1.4-4.6h-6.6l-1.5,4.2l-4.1-0.2l6.6-18h4.9l6.4,17.7L24.2,28.8z"
      />
      <path fill="#179DC0" d="M17,21c-0.1,0,4.8,0,4.8,0l-2.4-7L17,21z" />
      <path
        fill="#179DC0"
        d="M60.8,0.1C50,0.1,41.3,8.8,41.3,19.6S50,39.1,60.8,39.1s19.5-8.7,19.5-19.5S71.6,0.1,60.8,0.1z M67,13.7h-7.3
		v4.4h5.6l0.5,3.4h-6.1v7h-4.2c0,0,0-18.1,0.1-18.2H67V13.7z"
      />
      <path
        fill="#179DC0"
        d="M102.5,0.1C91.7,0.1,83,8.8,83,19.6s8.7,19.5,19.5,19.5S122,30.4,122,19.6S113.3,0.1,102.5,0.1z M108.7,13.7
		h-7.3v4.4h5.6l0.5,3.4h-6.1v7h-4.2c0,0,0-18.1,0.1-18.2h11.3L108.7,13.7L108.7,13.7z"
      />
      <polygon
        fill="#3F3F3F"
        points="153.6,0.7 149,20.3 148.5,20.3 143.7,0.7 127.7,0.7 125.5,38.9 138.2,38.9 138.7,20.4 139.4,20.4 
		143.9,38.9 153.3,38.9 157.8,20.3 158.4,20.3 159,38.9 171.7,38.9 169.4,0.7 	"
      />
      <path
        fill="#3F3F3F"
        d="M194.4,0.1c0,0-4.1-0.2-6.9,0c-2.8,0.2-5.1,0.9-7.5,2s-4.1,3.6-5.3,6.4c-1.3,2.8-1.5,8.4-1.6,10.4
		s0.2,6.8,0.5,8.6s1.6,5.7,4.3,8.5s8.3,3.7,9.7,3.8s3.4,0.1,6.1,0s7.5-0.7,11.3-4.7s3.8-10.7,3.8-17.9s-2.5-11.1-4-12.9
		C203.4,2.6,199.2,0.1,194.4,0.1z M196.1,26.3c0,1.1-0.4,3-3,3s-7.1,0-7.1,0s0-14.9,0-16.1s0.8-3,3.3-3c2.6,0,6.7,0,6.7,0
		S196.1,25.2,196.1,26.3z"
      />
      <path
        fill="#3F3F3F"
        d="M244.1,16.9c0-2.9,0-4.6,0-5.4S243,0.7,233,0.7s-20.8,0-20.8,0v38.1h12.2V27.6h2.5l5.1,11.3h13.3l-6.6-12.7
		C238.7,26.1,244.1,23.8,244.1,16.9z M231.3,16.4c0,1.3-1.7,1.6-2.6,1.6c-0.9,0-4.5,0-4.5,0v-7.6l0,0h4.5c1.8,0,2.6,1,2.6,1.8
		C231.3,13.1,231.3,15.2,231.3,16.4z"
      />
      <polygon
        fill="#3F3F3F"
        points="260.4,29.3 260.4,24.5 272.6,24.5 272.6,15.7 260.4,15.7 260.4,10.5 273.4,10.5 275,0.7 248.4,0.7 
		248.4,38.9 275.3,38.9 275.3,29.3 	"
      />
    </g>
  </svg>
);

export default Logo;
