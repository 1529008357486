import React, { FC } from "react";

import Overview from "modules/overview";
import PageContainer from "components/page-container";

const OverviewPage: FC = () => (
  <PageContainer>
    <Overview />
  </PageContainer>
);

export default OverviewPage;
