import { AffiliateCallback, CreateAffiliateCallbackRequest } from "@types";
import omit from "lodash/omit";

export default function (
  callback: AffiliateCallback
): CreateAffiliateCallbackRequest {
  return {
    callback: omit(callback, [
      "code",
      "name",
      "callbackId",
      "createdAt",
      "createdBy",
      "updatedAt"
    ])
  };
}
