import { createStyles, makeStyles } from "@material-ui/core/styles";

const bankInformationStyles = () =>
  createStyles({
    paymentMethod: {
      marginLeft: -16,
      marginBottom: -16
    },
    accountInfo: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      marginLeft: -16
    },
    divider: {
      margin: "16px 0"
    }
  });

const useStyles = makeStyles(theme =>
  createStyles({
    container: {},
    paymentInfo: {
      marginTop: -16,
      marginBottom: 32
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      overflow: "hidden"
    },
    account: {
      display: "flex",
      overflow: "hidden",
      marginBottom: 16,
      marginLeft: -16
    },
    amount: {
      marginLeft: -16
    },
    commPayments: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    idColumn: {
      maxWidth: 96,
      fontWeight: 500
    },
    dateColumn: {
      maxWidth: 96
    },
    createdByColumn: {
      maxWidth: 256,
      paddingRight: 4,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    typeColumn: {
      maxWidth: 128
    },
    descriptionColumn: {
      minWidth: 256
    },
    ...bankInformationStyles()
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      margin: -16
    },
    methodBlock: {
      display: "flex",
      flexWrap: "wrap"
    },
    bankBlock: {
      display: "flex",
      flexWrap: "wrap",
      padding: 6,
      marginLeft: -8
    },
    divider: {
      margin: "10px 16px"
    },
    minPayment: {
      padding: "10px 0"
    },
    commission: {
      margin: "32px 16px 0"
    },
    searchBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "16px"
    },
    buttons: {
      display: "flex"
    },
    button: {
      borderRadius: 17,
      marginLeft: 8,
      width: 130,
      height: 32,
      [theme.breakpoints.down("sm")]: {
        height: 32,
        width: 32
      }
    }
  })
);

export { bankInformationStyles, useStyles, useSkeletonStyles };
