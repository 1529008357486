import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import Search from "components/search";
import EmptyData from "components/empty-data";
import EmptySearch from "components/empty-search";
import Table, {
  Column,
  MultipleColumn,
  DropdownColumn
} from "components/table";

import Skeleton from "./Skeleton";
import useAffiliateCallbacks from "./useAffiliateCallbacks";

const Component: FC = () => {
  const {
    data,
    error,
    classes,
    isLoading,
    isEmpty,
    isEmptySearch,
    searchQuery,
    handleSearch,
    handleRowClick,
    handleRemoveCallback,
    handleCreateCallbackDrawer
  } = useAffiliateCallbacks();

  return (
    <Box>
      <Typography variant="h3">Callbacks</Typography>
      <Search
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Search by link name"
        buttons={
          <Button color="secondary" onClick={handleCreateCallbackDrawer}>
            create new callback
          </Button>
        }
        disabled={isEmpty}
      />
      {isLoading ? (
        <Skeleton />
      ) : isEmpty || error ? (
        <EmptyData className={classes.emptyScreen} />
      ) : (
        <Box>
          {!isEmptySearch ? (
            <Table
              initialData={data}
              estimatedItemSize={81}
              displayRows={6}
              handleRowClick={handleRowClick}
            >
              <Column
                label="Brand"
                name="brandId"
                align="left"
                type="brand"
                className={classes.brandColumn}
              />
              <MultipleColumn
                align="left"
                label="Link"
                name="name"
                urlName="code"
                className={classes.linkColumn}
              />
              <MultipleColumn
                align="left"
                label="Trigger"
                name="trigger"
                secondName="url"
              />
              <Column
                label="Method"
                name="method"
                type="text"
                className={classes.methodColumn}
              />
              <DropdownColumn name="dropdown" className={classes.iconColumn}>
                <MenuItem onClick={handleRemoveCallback}>Remove</MenuItem>
              </DropdownColumn>
            </Table>
          ) : (
            <EmptySearch className={classes.emptyScreen} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Component;
