import { Reducer } from "redux";
import format from "date-fns/format";

import { now, startOfMonth, endOfMonth } from "constants/dates";
import { AppState } from "store";

const getSeparatedDate = (date: Date) => ({
  year: format(date, "yyyy"),
  month: format(date, "MM"),
  day: format(date, "dd")
});

enum DatePickerActionTypes {
  SET_DATE = "@datepicker/set-date",
  SET_DATE_RANGE = "@datepicker/set-date-range"
}

interface SetDate {
  type: DatePickerActionTypes.SET_DATE;
  payload: Date;
}

interface SetDateRange {
  type: DatePickerActionTypes.SET_DATE_RANGE;
  payload: [Date, Date];
}

type DatePickerActions = SetDate | SetDateRange;

const setDate = (date: Date): SetDate => ({
  type: DatePickerActionTypes.SET_DATE,
  payload: date
});

const setDateRange = (dateRange: [Date, Date]): SetDateRange => ({
  type: DatePickerActionTypes.SET_DATE_RANGE,
  payload: dateRange
});

interface DatePickerState {
  fullDate: Date;
  dateRange: [Date, Date];
  year: string;
  month: string;
  day: string;
}

const initialState: DatePickerState = {
  fullDate: now,
  dateRange: [startOfMonth, endOfMonth],
  ...getSeparatedDate(now)
};

const reducer: Reducer<DatePickerState, DatePickerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DatePickerActionTypes.SET_DATE:
      return {
        ...state,
        fullDate: action.payload,
        ...getSeparatedDate(action.payload)
      };
    case DatePickerActionTypes.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload
      };
    default:
      return state;
  }
};

const getDatePickerState = (state: AppState) => state.datepicker;
const getFormattedDate = (state: AppState) =>
  format(state.datepicker.fullDate, "LLL yyyy");

export { reducer, setDate, setDateRange, getDatePickerState, getFormattedDate };
