import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  isAdmin?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme =>
  createStyles({
    container: {
      width: "100%"
    },
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    table: {
      width: "100%",
      height: ({ isAdmin }) =>
        isAdmin ? "calc(100vh - 400px)" : "calc(100vh - 196px)",
      background: theme.colors.white,
      [theme.breakpoints.down("md")]: {
        height: ({ isAdmin }) =>
          isAdmin ? "calc(100vh - 400px)" : "calc(100vh - 176px)",
        maxWidth: "calc(100vw - 32px)",
        overflowX: "auto",
        overflowY: "hidden"
      }
    },
    iconColumn: {
      maxWidth: 46,
      justifyContent: "center"
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: "100%",
      width: "100%"
    },
    title: {
      display: "flex",
      flexWrap: "wrap",
      width: 136,
      height: 32,
      [theme.breakpoints.down("md")]: {
        height: 24
      }
    },
    search: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 64
    },
    button: {
      borderRadius: 17
    },
    text: {
      margin: 0
    },
    tableWrapper: {
      width: "100%",
      marginTop: 2,
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100vw - 32px)",
        overflowX: "auto",
        overflowY: "hidden"
      }
    },
    table: {
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: 960,
        minWidth: 960
      }
    },
    tableHeader: {
      display: "flex",
      alignItems: "center",
      height: 48,
      padding: "0 16px",
      borderBottom: `2px solid ${theme.colors.blacke0}`
    },
    tableDeal: {
      marginLeft: "calc(50% - 107px)"
    },
    tableRow: {
      display: "flex",
      padding: "0 16px",
      height: 88
    },
    linkInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    dealInfo: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: "calc(50% - 207px)"
    },
    tableButton: {
      borderRadius: 16,
      transform: "none"
    },
    divider: {
      height: 1,
      backgroundColor: theme.colors.blacke0
    }
  })
);

export { useStyles, useSkeletonStyles };
