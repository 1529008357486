import React, { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { useTermsConditionsCheck } from "hooks";
import Sidebar from "modules/affiliate-sidebar";
import { getViewModeState } from "modules/view-mode";
import AffiliateInfoDialog from "components/affiliate-info-dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      background: theme.colors.white
    },
    viewModeBox: {
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        bottom: 0
      }
    },
    viewModeBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 16,
      width: "100%",
      fontSize: 12,
      lineHeight: "16px"
    },
    adminViewMode: {
      color: theme.colors.red,
      background: theme.colors.red10
    },
    affiliateViewMode: {
      color: theme.colors.orange,
      background: theme.colors.orange10
    },
    extraPadding: {
      paddingTop: 16
    },
    content: {
      width: "100%",
      height: "100%",
      overflow: "auto",
      padding: "64px 32px 32px 32px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 16px 16px 16px",
        marginTop: 48,
        maxHeight: "calc(100vh - 48px)"
      }
    }
  })
);

interface Props {
  children: ReactElement;
}

const AffiliateViewPageLayout: FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { affiliateId, accountId } = useSelector(getViewModeState);

  useTermsConditionsCheck();

  return (
    <>
      {(affiliateId || accountId) && (
        <Box className={classes.viewModeBox}>
          {affiliateId && (
            <Box className={`${classes.viewModeBar} ${classes.adminViewMode}`}>
              Affiliate view mode
            </Box>
          )}
          {accountId && (
            <Box
              className={`${classes.viewModeBar} ${classes.affiliateViewMode}`}
            >
              Account view mode
            </Box>
          )}
        </Box>
      )}
      <Box
        className={cn(classes.container, {
          [classes.extraPadding]: affiliateId || accountId
        })}
      >
        <Sidebar />
        <Box className={classes.content}>{children}</Box>
      </Box>
      <AffiliateInfoDialog />
    </>
  );
};

export default AffiliateViewPageLayout;
