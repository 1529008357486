import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      overflow: "auto",
      padding: 32,
      background: "linear-gradient(122.01deg, #FFFFFF 0%, #F5F5F5 100%)",
      "& a": {
        textDecoration: "none"
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center"
      }
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto"
      }
    },
    divider: {
      height: 512,
      width: 1,
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "50%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: 64,
        width: "100%",
        height: "auto"
      }
    }
  })
);

export { useStyles };
