import React, { FC } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import EmptyData from "components/empty-data";
import Table, { TableSkeleton, Column, HTMLColumn } from "components/table";

import { getAffiliateInvoiceState } from "../duck";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "0 16px"
    },
    id: {
      maxWidth: 86,
      fontWeight: 500
    },
    date: {
      maxWidth: 86
    },
    createdBy: {
      maxWidth: 256,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    type: {
      maxWidth: 86,
      paddingLeft: 8
    },
    description: {
      minWidth: 152
    },
    amount: {
      fontWeight: 500
    }
  })
);

const PaymentsTable: FC = () => {
  const classes = useStyles();
  const { isLoading, error, data } = useSelector(getAffiliateInvoiceState);

  if (isLoading) {
    return <TableSkeleton showTotal rows={8} cells={6} />;
  }

  if (error || !data) {
    return <EmptyData style={{ margin: 16 }} />;
  }

  const { payments, totals } = data;

  return (
    <Box className={classes.container}>
      <Table
        initialData={payments}
        displayRows={7}
        total={totals.amount}
        totals={{ title: "Subtotal", totals }}
      >
        <Column
          label="ID"
          name="paymentId"
          align="left"
          type="text"
          className={classes.id}
        />
        <Column
          label="Date"
          name="transactionDate"
          align="left"
          type="date"
          className={classes.date}
        />
        <Column
          label="Created By"
          name="createdBy"
          align="left"
          type="text"
          className={classes.createdBy}
        />
        <Column
          label="Type"
          name="type"
          align="left"
          type="text"
          className={classes.type}
        />
        <HTMLColumn
          label="Description"
          name="description"
          align="left"
          className={classes.description}
        />
        <Column
          label="Amount"
          name="amount"
          type="currency"
          className={classes.amount}
        />
      </Table>
    </Box>
  );
};

export default PaymentsTable;
