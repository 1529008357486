import { SubAffiliate } from "@types";

import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

import { TooltipCard } from "components/cards";
import Table, {
  Column,
  MultipleColumn,
  DropdownColumn,
  TableSkeleton
} from "components/table";
import Search from "components/search";
import EmptyData from "components/empty-data";

import SearchButtons from "./components/SearchButtons";
import useSubAffiliates from "./useSubAffiliates";

interface Props {
  handleRowClick: (item: SubAffiliate) => void;
  handleAddDrawerOpen?: () => void;
}

const Component: FC<Props> = ({ handleRowClick, handleAddDrawerOpen }) => {
  const {
    classes,
    error,
    isLoading,
    total,
    totals,
    affiliateId,
    referralLink,
    subAffiliates,
    searchQuery,
    handleSearchQuery,
    handleRemoveAffiliate
  } = useSubAffiliates();

  if (error) {
    return <EmptyData />;
  }

  return (
    <Box>
      <Box className={classes.tooltip}>
        <TooltipCard
          label="Sub-Affiliate Referral Link"
          className={classes.link}
        >
          {referralLink}
        </TooltipCard>
      </Box>
      <Search
        value={searchQuery}
        placeholder="Search by AffiliateID"
        buttons={
          handleAddDrawerOpen && (
            <SearchButtons
              affiliateId={affiliateId}
              handleAddSubAffiliate={handleAddDrawerOpen}
            />
          )
        }
        onChange={handleSearchQuery}
      />
      {isLoading ? (
        <TableSkeleton rows={8} cells={7} rowHeight={64} />
      ) : (
        <Table
          total={total}
          displayRows={10}
          initialData={subAffiliates}
          estimatedItemSize={65}
          totals={{ title: "Subtotal", totals }}
          handleRowClick={handleRowClick}
        >
          <MultipleColumn
            label="Affiliate ID"
            name="affiliateId"
            secondName="affiliateName"
            align="left"
          />
          <Column label="Reg.(NRC)" name="nrc" type="text" />
          <Column label="1st dep.(NDC)" name="ndc" type="text" />
          <Column
            label="Registrations"
            name="registeredCustomers"
            type="text"
          />
          <Column label="Depositors" name="depositingCustomers" type="text" />
          <Column label="Net Revenue" name="netRevenue" type="currency" />
          <Column label="Commission" name="commission" type="currency" />
          {handleRemoveAffiliate && (
            <DropdownColumn name="dropdown" className={classes.iconColumn}>
              <MenuItem onClick={handleRemoveAffiliate}>Remove</MenuItem>
            </DropdownColumn>
          )}
        </Table>
      )}
    </Box>
  );
};

export default Component;
