import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

const DocumentFileIcon: FC<SvgIconProps> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.9987 1.66699C4.08682 1.66699 3.33203 2.42178 3.33203 3.33366V16.667C3.33203 17.5789 4.08682 18.3337 4.9987 18.3337H14.9987C15.9106 18.3337 16.6654 17.5789 16.6654 16.667V6.66699L11.6654 1.66699H4.9987ZM4.9987 3.33366H10.832V7.50033H14.9987V16.667H4.9987V3.33366ZM6.66536 10.0003V11.667H13.332V10.0003H6.66536ZM6.66536 13.3337V15.0003H13.332V13.3337H6.66536Z" />
  </svg>
);

export default DocumentFileIcon;
