import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const ROWS_COUNT = Array.from(Array(8));

const TableRow: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <>
      <Box className={classes.tableRow}>
        <Box className={classes.linkInfo}>
          <Skeleton width={128} height={16} />
          <Skeleton width={128} height={16} />
          <Skeleton width={197} height={20} />
        </Box>
        <Box className={classes.dealInfo}>
          <Skeleton width={220} height={16} />
          <Skeleton width={32} height={32} className={classes.tableButton} />
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </>
  );
};

const LinksSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.tableWrapper}>
      <Box className={classes.table}>
        <Box className={classes.tableHeader}>
          <Skeleton width={97} height={16} className={classes.text} />
          <Skeleton width={26} height={16} className={classes.tableDeal} />
        </Box>
        {ROWS_COUNT.map((_, index) => (
          <TableRow key={index} />
        ))}
      </Box>
    </Box>
  );
};

export { LinksSkeleton as Skeleton };
export default LinksSkeleton;
