import { OverviewAffiliate } from "@types";

import { useEffect, useCallback, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { BRANDS } from "constants/brands";
import { useSearch, useUrlDateCheck } from "hooks";

import {
  fetchOverview,
  setIncludeInternals,
  setOverviewBrandFilter,
  getOverviewState
} from "./duck";

interface Params {
  year: string;
  month: string;
}

export default function () {
  const dispatch = useDispatch();
  const { year, month } = useParams<Params>();
  const { isLoading, error, brand, includeInternals, totals, data } =
    useSelector(getOverviewState);
  const { query, setQuery, results } = useSearch<OverviewAffiliate>(
    ["affiliateId"],
    data
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleBrandFilter = useCallback(
    (brand: BRANDS | null) => {
      dispatch(setOverviewBrandFilter(brand));
    },
    [dispatch]
  );

  const handleIncludeInternals = useCallback(
    (_event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setIncludeInternals());
    },
    [dispatch]
  );

  useUrlDateCheck();

  useEffect(() => {
    dispatch(
      fetchOverview({
        year: Number(year),
        month: Number(month),
        includeInternals,
        brandId: brand
      })
    );
  }, [year, month, includeInternals, brand, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setOverviewBrandFilter(null));
    };
  }, [dispatch]);

  return {
    isEmpty: isEmpty(data),
    isEmptyOverview: isEmpty(results),
    isLoading,
    error,
    year,
    month,
    totals,
    searchQuery: query,
    overview: results,
    includeInternals,
    brand,
    handleSearch,
    handleBrand: handleBrandFilter,
    handleIncludeInternals
  };
}
