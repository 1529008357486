import React, { FC } from "react";
import cn from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import formatMoney from "utils/formatMoney";

import { useSummaryStyles } from "../styles";

interface Props {
  data: {
    grossRevenue: number;
    bonuses: number;
    adjustments: number;
    fees: number;
    tax: number;
    netRevenue: number;
    commission: number;
    cpa: number;
  };
}

const Summary: FC<Props> = ({
  data: {
    grossRevenue,
    bonuses,
    adjustments,
    fees,
    netRevenue,
    commission,
    cpa,
    tax
  }
}) => {
  const classes = useSummaryStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <Typography className={classes.label}>Gross Revenue:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: grossRevenue < 0
          })}
        >
          {formatMoney.from(grossRevenue)}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>Bonuses:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: bonuses < 0
          })}
        >
          {formatMoney.from(bonuses)}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>Adjustments:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: adjustments < 0
          })}
        >
          {formatMoney.from(adjustments)}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>Fees:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: fees < 0
          })}
        >
          {formatMoney.from(fees)}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>Tax:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: tax < 0
          })}
        >
          {formatMoney.from(tax)}
        </Typography>
      </Box>
      <Box className={`${classes.row} ${classes.dividedRow}`}>
        <Box className={classes.row}>
          <Typography variant="h4">Net Revenue:</Typography>
          <Typography
            variant="h4"
            className={cn(classes.revenue, {
              [classes.negativeValue]: netRevenue < 0
            })}
          >
            {formatMoney.from(netRevenue)}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>Commission:</Typography>
        <Typography
          className={cn(classes.value, {
            [classes.negativeValue]: commission < 0
          })}
        >
          {formatMoney.from(commission)}
        </Typography>
      </Box>
      {!!cpa && (
        <Box className={classes.row}>
          <Typography className={classes.label}>CPA:</Typography>
          <Typography
            className={cn(classes.value, {
              [classes.negativeValue]: cpa < 0
            })}
          >
            {formatMoney.from(cpa)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Summary;
