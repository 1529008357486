import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";

interface Props {
  csvProps: DownloadCsvButtonProps;
  handleCreatePlanOpen?: () => void;
}

const SearchButtons: FC<Props> = ({ csvProps, handleCreatePlanOpen }) => (
  <>
    <DownloadCsvButton {...csvProps} />
    {handleCreatePlanOpen && (
      <Button onClick={handleCreatePlanOpen} color="secondary">
        create new plan
      </Button>
    )}
  </>
);

export default SearchButtons;
