/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactElement, useCallback, useRef } from "react";
import { VariableSizeList } from "react-window";
import TableBody from "@material-ui/core/TableBody";
import AutoSizer from "react-virtualized-auto-sizer";
import isEmpty from "lodash/isEmpty";

import Row from "./Row";
import EmptySearch from "./EmptySearch";
import { GeneratedRowProps } from "../types";

const DEFAULT_ROW_HEIGHT = 48;
const BORDER_SIZE = 1;
const ROW_HEIGHT = DEFAULT_ROW_HEIGHT + BORDER_SIZE;

interface Props {
  data: any[];
  columns: ReactElement<GeneratedRowProps>[];
  checkedIds?: number[];
  displayRows?: number;
  estimatedItemSize?: number;
  handleRowClick?: (item: any) => void;
  handleRowCheck?: (itemId: number) => void;
}

const Content: FC<Props> = ({
  data,
  columns,
  checkedIds,
  displayRows,
  estimatedItemSize,
  handleRowClick,
  handleRowCheck
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<{ [key: number]: number }>({});
  const itemSize = estimatedItemSize ? estimatedItemSize : ROW_HEIGHT;

  const getRowHeight = (index: number) => rowHeights.current[index] || itemSize;

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size + BORDER_SIZE };
  };

  const getTableHeight = useCallback(() => {
    if (displayRows) {
      if (data.length < displayRows) {
        return itemSize * data.length;
      } else {
        return itemSize * displayRows;
      }
    }

    return itemSize * data.length;
  }, [data.length, displayRows, itemSize]);

  if (isEmpty(data)) {
    return <EmptySearch />;
  }

  return (
    <TableBody component="div" style={{ height: getTableHeight() }}>
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            ref={listRef}
            height={height}
            width={width}
            itemData={data}
            itemSize={getRowHeight}
            estimatedItemSize={estimatedItemSize}
            itemCount={data.length}
            overscanCount={8}
          >
            {props => (
              <Row
                {...props}
                columns={columns}
                checkedIds={checkedIds}
                handleRowHeight={setRowHeight}
                handleRowCheck={handleRowCheck}
                handleRowClick={handleRowClick}
              />
            )}
          </VariableSizeList>
        )}
      </AutoSizer>
    </TableBody>
  );
};

export default Content;
