import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useRoles } from "hooks";
import ErrorPage from "modules/error";
import { fetchAdminProfile } from "modules/authentication";

const NotFoundPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoggedin } = useRoles();

  useEffect(() => {
    if (!isLoggedin) {
      dispatch(fetchAdminProfile());
    }
  }, [isLoggedin, dispatch]);

  return (
    <ErrorPage
      style={{ width: "100%", height: isLoggedin ? "100%" : "100vh" }}
    />
  );
};

export default NotFoundPage;
