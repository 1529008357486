import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container, { ContainerProps } from "@material-ui/core/Container";

const PageContainer = withStyles(theme => ({
  root: {
    display: "block",
    width: "100%",
    height: "100%",
    maxHeight: "calc(100vh - 48px)",
    overflowY: "auto",
    padding: "0 32px 32px 32px",
    background: theme.colors.white
  }
}))((props: ContainerProps) => <Container maxWidth={false} {...props} />);

export default PageContainer;
