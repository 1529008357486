import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  fetchAffiliateViewPlayersRevenue,
  getAffiliatePlayersRevenueState
} from "modules/affiliate-players-revenue";
import {
  ActivitiesTable,
  fetchAffiliateViewPlayerActivitiesByDate
} from "modules/affiliate-player";
import { BackButton } from "components/button";
import { TableSkeleton } from "components/table";
import PlayerInfo, { PlayerInfoSkeleton } from "components/player-info";
import EmptyData from "components/empty-data";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: "relative",
      marginTop: 28
    },
    backButton: {
      position: "absolute",
      top: -52,
      left: 0,
      [theme.breakpoints.down("md")]: {
        top: -32
      }
    },
    divider: {
      margin: "16px 0px 32px 0px"
    }
  })
);

interface Params {
  year: string;
  month: string;
  playerId: string;
}

const AffiliateViewPlayerRevenuePage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { year, month, playerId } = useParams<Params>();
  const { data: playersRevenue, isLoading } = useSelector(
    getAffiliatePlayersRevenueState
  );

  const details = playersRevenue.find(
    item => item.playerId === Number(playerId)
  );

  const handleBack = () => {
    history.push(`/revenue/${year}/${month}/`);
  };

  useEffect(() => {
    const options = {
      playerId: Number(playerId),
      year: Number(year),
      month: Number(month)
    };
    dispatch(fetchAffiliateViewPlayerActivitiesByDate(options));
  }, [playerId, year, month, dispatch]);

  useEffect(() => {
    if (!details) {
      const options = {
        year: Number(year),
        month: Number(month)
      };
      dispatch(fetchAffiliateViewPlayersRevenue(options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, year, month]);

  return (
    <Box className={classes.container}>
      <BackButton className={classes.backButton} onClick={handleBack}>
        go back
      </BackButton>
      {details ? (
        <>
          <PlayerInfo {...details} />
          <Divider className={classes.divider} />
          <ActivitiesTable />
        </>
      ) : isLoading ? (
        <>
          <PlayerInfoSkeleton />
          <Divider className={classes.divider} />
          <TableSkeleton rows={12} cells={9} />
        </>
      ) : (
        <EmptyData />
      )}
    </Box>
  );
};

export default AffiliateViewPlayerRevenuePage;
