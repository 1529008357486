import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

import { TooltipCardSkeleton } from "components/cards";

import { usePaymentInfoSkeletonStyles } from "../../styles";

const PaymentInfoSkeleton: FC = () => {
  const classes = usePaymentInfoSkeletonStyles();

  return (
    <Box>
      <Box className={classes.header}>
        <Skeleton width={400} height={30} />
        <Box className={classes.buttons}>
          <Skeleton className={classes.button} />
        </Box>
      </Box>
      <Box className={classes.details}>
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
      </Box>
      <Skeleton width={64} height={16} />
      <Divider className={classes.divider} />
    </Box>
  );
};

export default PaymentInfoSkeleton;
