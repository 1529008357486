/* eslint-disable no-useless-escape */

export const isNumber = /^[0-9]*$/;
export const isFloatingNumber = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
export const isNegativeFloatingNumber =
  /^([0-9, -]+\d{0,2}(\.\d{0,2})?|\.?\d{1,2})$/;
export const isNegativeNumber = /^-?[0-9]*$/;
export const isPhone = /^[0-9 ()+-]+$/;
export const isPercent = /^(100|\d{0,2}(\.\d{0,2})?|\.?\d{1,2})$/;
export const isPassword = /^(?=.{8,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*/;
export const isEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isHTML = /<\/?[a-z][\s\S]*>/i;
export const isURL =
  /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//{}=]*)/;
