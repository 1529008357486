import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import DateRoute from "components/date-route";
import Layout from "pages/affiliate-view/Layout";
import Link from "pages/affiliate-view/Link";
import Links from "pages/affiliate-view/Links";
import Overview from "pages/affiliate-view/Overview";
import Payments from "pages/affiliate-view/Payments";
import Profile from "pages/affiliate-view/Profile";
import Players from "pages/affiliate-view/Players";
import Player from "pages/affiliate-view/Player";
import PlayersRevenue from "pages/affiliate-view/PlayersRevenue";
import PlayerRevenue from "pages/affiliate-view/PlayerRevenue";
import Activities from "pages/affiliate-view/Activities";
import SubAffiliates from "pages/affiliate-view/SubAffiliates";
import NotFound from "pages/NotFound";

const AffiliateRoutes: FC = () => {
  return (
    <Layout>
      <Switch>
        <DateRoute exact path="/overview/:year/:month" component={Overview} />
        <Route path="/profile" component={Profile} />
        <Route exact path="/links" component={Links} />
        <Route path="/links/:linkId" component={Link} />
        <Route path="/payments" component={Payments} />
        <Route exact path="/players" component={Players} />
        <Route path="/players/:playerId" component={Player} />
        <DateRoute
          exact
          path="/revenue/:year/:month"
          component={PlayersRevenue}
        />
        <DateRoute
          exact
          path="/revenue/:year/:month/players/:playerId"
          component={PlayerRevenue}
        />
        <Route path="/activities" component={Activities} />
        <DateRoute exact path="/subs/:year/:month" component={SubAffiliates} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default AffiliateRoutes;
