import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    title: {
      fontSize: 24,
      liineHeight: "32px",
      color: theme.colors.black21
    },
    notice: {
      marginTop: 8,
      fontSize: 12,
      liineHeight: "16px",
      color: theme.colors.black75
    },
    form: {
      maxWidth: 348,
      width: 348,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    buttons: {
      marginTop: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    backButton: {
      width: 42,
      height: 42,
      padding: 0,
      marginRight: 8,
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue}`,
      "&:hover": {
        color: theme.colors.white,
        borderColor: theme.colors.blue,
        background: theme.colors.blue
      }
    },
    submitButton: {
      height: 42,
      width: "100%",
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue}`,
      borderRadius: 21,
      "&:hover": {
        background: theme.colors.blue,
        color: theme.colors.white,
        borderColor: theme.colors.blue
      }
    },
    userEmail: {
      fontSize: 12,
      liineHeight: "16px",
      color: theme.colors.blue
    }
  })
);

export { useStyles };
