import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const defaultRowHeight = 49;
const defaultCellHeight = 16;
const defaultCellWidth = 64;

interface Props {
  rows: number;
  cells: number;
  rowHeight?: number;
  cellWidth?: number;
  cellHeight?: number;
  showTotal?: boolean;
}

const TableSkeleton: FC<Props> = ({
  rows,
  cells,
  rowHeight,
  cellWidth,
  cellHeight,
  showTotal
}) => {
  const classes = useSkeletonStyles();

  const cellsArray = Array.from(Array(cells));
  const rowsArray = Array.from(Array(rows));

  return (
    <Box className={classes.tableWrapper}>
      <Box className={classes.table}>
        {rowsArray.map((_, index) => (
          <Box
            key={index}
            className={classes.tableRow}
            height={rowHeight || defaultRowHeight}
          >
            {cellsArray.map((_, index) => (
              <Skeleton
                key={index}
                width={cellWidth || defaultCellWidth}
                height={cellHeight || defaultCellHeight}
              />
            ))}
          </Box>
        ))}
      </Box>
      {showTotal && (
        <Box className={classes.total}>
          <Skeleton width={128} height={32} />
        </Box>
      )}
    </Box>
  );
};

export default TableSkeleton;
