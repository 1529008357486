import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import SearchInput from "components/search-input";
import List from "components/selectable-list";
import PlanInfo from "components/plan-info";
import EmptyData from "components/empty-data";

import Skeleton from "./Skeleton";
import usePlan from "./usePlan";

const Plan: FC = () => {
  const {
    classes,
    error,
    isLoading,
    isAdmin,
    plan,
    rules,
    searchQuery,
    affiliates,
    handleSearch,
    handleResetSearch,
    handleListChange,
    handleChangeButton
  } = usePlan();

  if (isLoading) {
    return <Skeleton isAdmin editable={isAdmin} />;
  }

  if (error || isEmpty(plan)) {
    return <EmptyData className="no-data" />;
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.details}>
        <Box className="drawer-header">
          <Typography variant="h4" className="drawer-header_title">
            Plan Details
          </Typography>
          {isAdmin && (
            <Button color="secondary" onClick={handleChangeButton}>
              edit plan
            </Button>
          )}
        </Box>
        <PlanInfo
          isAdmin
          planLabel="Name"
          plan={plan}
          rules={rules}
          className={classes.plan}
        />
      </Box>
      <Box className={classes.companies}>
        <Box className={classes.companiesHeader}>
          <Typography variant="h4" className={classes.companiesTitle}>
            {`${affiliates.length} companies using`}
          </Typography>
          <SearchInput
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by id, name, email"
          />
        </Box>
        {isEmpty(affiliates) ? (
          <EmptyData style={{ padding: "16px 32px" }} />
        ) : (
          <Box className={classes.list}>
            <List
              options={affiliates.map(({ affiliateId, affiliateName }) => ({
                label: affiliateName,
                value: affiliateId,
                description: affiliateId
              }))}
              handleClear={handleResetSearch}
              handleChange={handleListChange}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Plan;
