import React, { FC, useEffect, useRef, memo } from "react";
import { ListChildComponentProps } from "react-window";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useStyles } from "../styles";

interface Props extends ListChildComponentProps {
  selectedOption?: number;
  handleRowHeight?: (index: number, size: number) => void;
  handleChange: (value: number) => void;
}

const ListItemComponent: FC<Props> = ({
  index,
  data,
  style,
  selectedOption,
  handleChange,
  handleRowHeight
}) => {
  const option = data[index];
  const rowRef = useRef<HTMLDivElement>(null);

  const isSelected = selectedOption === option.value;
  const classes = useStyles({ isSelected });

  useEffect(() => {
    if (rowRef.current && handleRowHeight) {
      handleRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  return (
    <div style={style}>
      <ListItem
        button
        disableRipple
        ref={rowRef}
        selected={isSelected}
        classes={{
          root: classes.root
        }}
        onClick={() => handleChange(option.value)}
      >
        {isSelected && <Box className={classes.indicator} />}
        <ListItemText
          className={classes.text}
          primary={
            <Typography className={classes.title}>{option.label}</Typography>
          }
          secondary={
            option.description && (
              <Typography
                className={classes.description}
              >{`#${option.description}`}</Typography>
            )
          }
        />
      </ListItem>
    </div>
  );
};

export default memo(ListItemComponent);
