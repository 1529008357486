import { ReactElement } from "react";
import { Reducer } from "redux";

import { AppState } from "store";

enum DialogTypes {
  OPEN_DIALOG = "@dialog/open-dialog",
  CLOSE_DIALOG = "@dialog/close-dialog"
}

export interface DialogPayload {
  type: "delete" | "submit";
  title: string;
  subtitle?: string;
  content?: string | ReactElement;
  onCancel?: () => void;
  onConfirm: () => void;
}

interface Payload {
  dialog: string;
  payload: DialogPayload;
}

interface OpenDialogAction {
  type: DialogTypes.OPEN_DIALOG;
  payload: Payload;
}

interface CloseDialogAction {
  type: DialogTypes.CLOSE_DIALOG;
}

export type DialogActionsType = OpenDialogAction | CloseDialogAction;

const openDialog = (payload: Payload): OpenDialogAction => ({
  type: DialogTypes.OPEN_DIALOG,
  payload
});

const closeDialog = (): CloseDialogAction => ({
  type: DialogTypes.CLOSE_DIALOG
});

interface DialogState {
  dialog?: string;
  payload?: DialogPayload;
}

const initialState: DialogState = {
  dialog: undefined,
  payload: undefined
};

const reducer: Reducer<DialogState, DialogActionsType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DialogTypes.OPEN_DIALOG:
      return {
        ...state,
        ...action.payload
      };
    case DialogTypes.CLOSE_DIALOG:
      return initialState;
    default:
      return state;
  }
};

const getDialogState = (state: AppState) => state.dialog;

export { reducer, openDialog, closeDialog, getDialogState };
