import React, { FC, ReactElement } from "react";
import cn from "classnames";
import Box, { BoxProps } from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { useCardStyles, useSkeletonStyles } from "../styles";

interface Props {
  label: string;
  className?: string;
  children: ReactElement | Date | string | number;
}

interface SkeletonProps extends BoxProps {
  width?: number;
  height?: number;
  children?: ReactElement | string;
}

const TextCardSkeleton: FC<SkeletonProps> = ({
  width = 80,
  height = 16,
  children,
  ...boxProps
}) => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container} {...boxProps}>
      <Skeleton className={classes.label} width={width} height={height} />
      {children}
    </Box>
  );
};

const TextCard: FC<Props> = ({ label, className, children }) => {
  const classes = useCardStyles();

  return (
    <Card className={cn(classes.card, className)}>
      <Box className={classes.divider} />
      <CardContent>
        <Typography component="span" variant="subtitle1">
          {label}
        </Typography>
        <Typography component="div" className={classes.text}>
          {children}
        </Typography>
      </CardContent>
    </Card>
  );
};

export { TextCardSkeleton };
export default TextCard;
