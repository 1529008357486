/* eslint-disable @typescript-eslint/no-unused-vars */
import { SelectOption, IsMulti } from "@types";

import React, { FC } from "react";
import {
  GroupBase,
  MenuProps,
  OptionProps,
  NoticeProps,
  ControlProps,
  MultiValueGenericProps,
  DropdownIndicatorProps
} from "react-select";

import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import {
  TextFieldProps,
  BaseTextFieldProps
} from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import EmptyData from "components/empty-data";
import Input from "components/input";

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef">;

declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    classes?: { [key: string]: string };
    size?: "small" | "medium";
    isDisabled: boolean;
    startAdornment?: React.ReactNode;
    error?: boolean;
    helperText?: string;
    TextFieldProps?: TextFieldProps;
  }
}

interface CreateLabelProps {
  label?: string;
  value: string;
}

const InputComponent: FC<InputComponentProps> = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control: FC<ControlProps<SelectOption, IsMulti>> = ({
  children,
  innerProps,
  innerRef,
  selectProps: {
    classes,
    size,
    isDisabled,
    startAdornment,
    error,
    helperText,
    TextFieldProps
  }
}) => (
  <Input
    error={error}
    size={size}
    disabled={isDisabled}
    helperText={helperText}
    InputProps={{
      inputComponent: InputComponent,
      startAdornment: startAdornment,
      inputProps: {
        children,
        ref: innerRef,
        className: classes?.input,
        ...(innerProps as InputComponentProps)
      }
    }}
    {...TextFieldProps}
  />
);

const Menu: FC<MenuProps<SelectOption, IsMulti>> = ({
  selectProps: { classes },
  innerProps: { ref: _, ...rest },
  children
}) => (
  <Paper square className={classes?.paper} {...rest}>
    {children}
  </Paper>
);

const Option: FC<OptionProps<SelectOption, IsMulti>> = ({
  innerRef,
  innerProps,
  selectProps: { classes },
  isFocused,
  isSelected,
  children
}) => (
  <MenuItem
    {...innerProps}
    disableRipple
    ref={innerRef}
    selected={isFocused}
    component="div"
  >
    <Typography
      noWrap
      className={classes?.optionText}
      style={{ fontSize: 14 }}
      variant={isSelected ? "h5" : "body1"}
      color={isSelected ? "primary" : "textPrimary"}
    >
      {children}
    </Typography>
  </MenuItem>
);

const MultiValueLabel: FC<MultiValueGenericProps<SelectOption>> = ({
  data: { value }
}) => {
  return (
    <Typography
      style={{
        padding: "3px 5px",
        fontSize: 16,
        lineHeight: "24px"
      }}
    >
      {value}
    </Typography>
  );
};

const DropdownIndicator: FC<DropdownIndicatorProps<SelectOption, IsMulti>> = ({
  selectProps: { classes }
}) => {
  return <ExpandMoreIcon className={classes?.dropdown} />;
};

const NoOptionsMessage: FC<NoticeProps<SelectOption, IsMulti>> = ({
  selectProps: { classes },
  innerProps
}) => (
  <Box className={classes?.noOptionsMessage} {...innerProps}>
    <EmptyData />
  </Box>
);

const CreateLabel: FC<CreateLabelProps> = ({ label, value }) => (
  <Typography
    noWrap
    component="span"
    style={{ fontSize: 14 }}
    variant="h5"
    color="primary"
  >
    {`${label ? label : "New option:"} ${value}`}
  </Typography>
);

export {
  Control,
  Menu,
  Option,
  DropdownIndicator,
  NoOptionsMessage,
  CreateLabel,
  MultiValueLabel
};
