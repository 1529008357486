import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    details: {
      margin: "0 0 0 -16px"
    },
    name: {
      whiteSpace: "normal"
    },
    creator: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      marginTop: "-16px"
    },
    rule: {
      marginTop: "32px",
      "&:last-child": {
        marginBottom: "8px"
      }
    },
    ruleRow: {
      display: "flex",
      justifyContent: "space-between",
      padding: "4px 0"
    },
    label: {
      fontSize: 14,
      lineHeight: "24px"
    },
    text: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500
    },
    divider: {
      height: "1px",
      margin: "16px -32px"
    }
  })
);

export { useStyles };
