export enum Steps {
  Email = 1,
  Code = 2,
  NewPassword = 3
}

export interface ForgotValues {
  email: string;
  code: string;
  password: string;
  passwordConfirm: string;
}
