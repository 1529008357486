import { ChangePasswordValues } from "@types";

import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";
import { changePassword } from "modules/authentication";

import { validationSchema } from "./validationSchema";

interface Props {
  email: string;
}

const initialValues: ChangePasswordValues = {
  oldPassword: "",
  newPassword: "",
  repeatNewPassword: ""
};

const ChangePassword: FC<Props> = ({ email }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (
      { oldPassword, newPassword }: ChangePasswordValues,
      helpers: FormikHelpers<ChangePasswordValues>
    ) => {
      dispatch(
        changePassword(
          { email, oldPassword: oldPassword, newPassword },
          helpers
        )
      );
    },
    [dispatch, email]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h3" color="textSecondary">
            Change password
          </Typography>
          <Box marginTop={2} marginBottom={2}>
            <TextField
              label="Current Password"
              name="oldPassword"
              type="password"
            />
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
            />
            <TextField
              label="New Password Again"
              name="repeatNewPassword"
              type="password"
            />
          </Box>
          <Button
            type="submit"
            color="secondary"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            save changes
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default ChangePassword;
