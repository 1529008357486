export default function (id: number, array: number[]) {
  const index = array.indexOf(id);
  const previousId = array[index - 1];
  const nextId = array[index + 1];

  return {
    previousId,
    nextId
  };
}
