import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      padding: 16,
      maxWidth: 256,
      boxShadow: "none",
      background: theme.colors.blackf5,
      border: `1px solid ${theme.colors.blacke0}`,
      borderRadius: 8
    },
    balanceAmount: {
      margin: "4px 0",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    minPayment: {
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black9e
    }
  })
);

export { useStyles };
