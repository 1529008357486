import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExpandIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import EmptyData from "components/empty-data";
import DatePicker from "modules/datepicker";

import useAffiliate from "./useAffiliate";
import Skeleton from "./Skeleton";

const Affiliate: FC = () => {
  const {
    classes,
    isLoading,
    isCalendarTab,
    affiliate,
    error,
    datePickerUrl,
    menuAnchor,
    handleMenuOpen,
    handleMenuClose,
    handleViewMode,
    handleEditDrawer,
    handleCloseAccount,
    handleSubaccountsDrawer,
    handleSubAffiliateDrawer,
    handleCreateSubAccountDrawer
  } = useAffiliate();

  if (isLoading) {
    return <Skeleton isOverview={isCalendarTab} />;
  }

  if (error || !affiliate) {
    return <EmptyData />;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        {handleSubaccountsDrawer ? (
          <ButtonBase
            className={classes.masterProfile}
            onClick={handleSubaccountsDrawer}
          >
            <Typography
              align="left"
              component="span"
              variant="subtitle1"
              className={classes.label}
            >
              {affiliate.lastLogin}
            </Typography>
            <Typography component="div" variant="h2" align="left">
              {`${affiliate.affiliateName} #${affiliate.affiliateId}`}
            </Typography>
            <Typography
              align="left"
              component="span"
              variant="subtitle1"
              color="textSecondary"
              className={classes.subtitle}
            >
              Click to show all subaccounts
            </Typography>
          </ButtonBase>
        ) : (
          <Box>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.label}
            >
              {affiliate.lastLogin}
            </Typography>
            <Typography component="div" variant="h2">
              {`${affiliate.affiliateName} #${affiliate.affiliateId}`}
            </Typography>
          </Box>
        )}
        <Box className={classes.buttons}>
          {isCalendarTab && <DatePicker baseDateUrl={datePickerUrl} />}
          <Button
            color="secondary"
            className={classes.actionButton}
            endIcon={<ExpandIcon fontSize="small" />}
            onClick={handleMenuOpen}
          >
            actions
          </Button>
          <Menu
            anchorEl={menuAnchor}
            open={!!menuAnchor}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEditDrawer}>Edit</MenuItem>
            <MenuItem onClick={handleViewMode}>Show affiliate view</MenuItem>
            {!affiliate.masterId && (
              <Box>
                <MenuItem onClick={handleCreateSubAccountDrawer}>
                  Add sub-account
                </MenuItem>
                <MenuItem onClick={handleSubAffiliateDrawer}>
                  Add sub-affiliate
                </MenuItem>
              </Box>
            )}
            <MenuItem onClick={handleCloseAccount}>Close account</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Affiliate;
