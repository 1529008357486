import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Logo from "assets/icons/Logo";
import { getAuthState, fetchAdminProfile } from "modules/authentication";
import { getDatePickerState } from "modules/datepicker";
import { GoogleUser } from "modules/google-auth";
import NavLink from "components/nav-link";

import { useHeaderStyles } from "./styles";

const Header: FC = () => {
  const classes = useHeaderStyles();
  const dispatch = useDispatch();

  const { roles } = useSelector(getAuthState);
  const { year, month } = useSelector(getDatePickerState);
  const isNavLinkDisabled = roles.includes("payer");

  useEffect(() => {
    dispatch(fetchAdminProfile());
  }, [dispatch]);

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar disableGutters className={classes.toolBar}>
        <div className={classes.container}>
          <NavLink
            to="/"
            className={classes.logo}
            isDisabled={isNavLinkDisabled}
          >
            <Logo />
          </NavLink>
          <div className={classes.container}>
            <NavLink
              to="/admin/affiliates"
              className={classes.navItem}
              isActive={(_, location) =>
                location.pathname.includes("/admin/affiliates")
              }
              isDisabled={isNavLinkDisabled}
            >
              Affiliates
            </NavLink>
            <NavLink
              to={`/admin/overview/${year}/${month}`}
              className={classes.navItem}
              isDisabled={isNavLinkDisabled}
            >
              Overview
            </NavLink>
            <NavLink
              to={`/admin/payments/${year}/${month}`}
              className={classes.navItem}
            >
              Payments
            </NavLink>
            <NavLink
              to="/admin/plans"
              className={classes.navItem}
              isDisabled={isNavLinkDisabled}
            >
              Plans
            </NavLink>
            <NavLink
              to="/admin/fees"
              className={classes.navItem}
              isDisabled={isNavLinkDisabled}
            >
              Fees
            </NavLink>
            <NavLink
              to="/admin/landings"
              className={classes.navItem}
              isDisabled={isNavLinkDisabled}
            >
              Landings
            </NavLink>
          </div>
        </div>
        <div className={classes.container}>
          <GoogleUser />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
