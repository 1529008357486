import React, { FC } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import Table, { Column, TableSkeleton } from "components/table";
import { Summary } from "components/table";
import EmptyData from "components/empty-data";

import { getAffiliatePlayerActivitiesState } from "./duck";

const ActivitiesTable: FC = () => {
  const { isLoading, error, activities, totals } = useSelector(
    getAffiliatePlayerActivitiesState
  );

  if (isLoading) {
    return <TableSkeleton showTotal rows={9} cells={9} />;
  }

  if (error || isEmpty(activities) || !totals) {
    return <EmptyData style={{ margin: 16 }} />;
  }

  return (
    <Table
      displayRows={4}
      initialData={activities}
      totals={{ title: "Subtotal", totals }}
      summary={<Summary data={totals} />}
    >
      <Column
        label="Date"
        name="activityDate"
        align="left"
        type="date"
        style={{ minWidth: 96 }}
      />
      <Column label="Deposits" name="deposits" type="currency" />
      <Column label="Turnover" name="turnover" type="currency" />
      <Column label="Gross" name="grossRevenue" type="currency" />
      <Column label="Bonuses" name="bonuses" type="currency" />
      <Column label="Adjustm." name="adjustments" type="currency" />
      <Column label="Fees" name="fees" type="currency" />
      <Column label="Tax" name="tax" type="currency" />
      <Column label="Net" name="netRevenue" type="currency" />
      <Column label="Comm." name="commission" type="currency" />
    </Table>
  );
};

export default ActivitiesTable;
