/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateFeeRuleValue, CreateFeeValues, SelectOption } from "@types";

import React, { FC, useCallback } from "react";
import { getIn, FormikErrors, FormikTouched } from "formik";
import uniqBy from "lodash/uniqBy";
import differenceBy from "lodash/differenceBy";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/CloseOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";

import { isPercent } from "utils/regexes";
import Select from "components/select";

import CardField from "./CardField";
import { initialCountries } from "../helpers";
import { useStyles } from "../styles";

interface Props {
  id: number;
  rule: CreateFeeRuleValue;
  countries: SelectOption[];
  errors: FormikErrors<CreateFeeValues>;
  touched: FormikTouched<CreateFeeValues>;
  handleFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
  handleRemoveCard: () => void;
  handleCountries: (countries: SelectOption[]) => void;
}

const Card: FC<Props> = ({
  id,
  rule,
  countries,
  errors: formikErrors,
  touched: formikTouched,
  handleFieldValue,
  handleCountries,
  handleRemoveCard
}) => {
  const classes = useStyles();
  const errors = getIn(formikErrors, `rules.${id}`);
  const touched = getIn(formikTouched, `rules.${id}`);

  const selectedValues = initialCountries.filter(({ value }) =>
    rule?.countryIds?.some(id => value === id)
  );

  const handleChange = useCallback(
    (options: any) => {
      const values = options?.map(({ value }: SelectOption) => value);
      handleCountries(differenceBy(initialCountries, options));
      handleFieldValue(`rules.${id}.countryIds`, values);
    },
    [handleCountries, handleFieldValue, id]
  );

  const handleRemove = useCallback(() => {
    const nextCountries = [...countries, ...selectedValues];
    handleCountries(uniqBy(nextCountries, "value"));
    handleRemoveCard();
  }, [countries, handleCountries, handleRemoveCard, selectedValues]);

  return (
    <Paper className={classes.planCard}>
      <Box className={classes.planCardHeader}>
        <Select
          isMulti
          name={`rules.${id}.countryIds`}
          className={classes.countrySelector}
          placeholder="Add country restrictions"
          value={selectedValues}
          options={countries}
          onChange={handleChange}
          isDisabled={rule.nextMonthPercent === 0}
        />
        <IconButton className={classes.iconButton} onClick={handleRemove}>
          <RemoveIcon />
        </IconButton>
      </Box>
      <Box className={classes.rules}>
        <Box className={classes.rule}>
          <Typography className={classes.ruleTitle}>Fee Percent:*</Typography>
          <CardField
            name={`rules.${id}.percent`}
            pattern={isPercent}
            className={`${classes.smallInput} ${classes.percentInput}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className={classes.startAdornment}>%</span>
                </InputAdornment>
              )
            }}
          />
        </Box>
        {touched && errors && (
          <Box className={classes.cardError}>
            {errors.countryIds && (
              <Typography className={classes.cardErrorText}>
                {errors.countryIds}
              </Typography>
            )}
            {touched.percent && errors.percent && (
              <Typography className={classes.cardErrorText}>
                {errors.percent}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default Card;
