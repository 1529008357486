import * as yup from "yup";
import { PAYMENT_METHODS } from "constants/payments";
import {
  bankAccountValidationSchema,
  skrillAccountValidationSchema,
  casinoAccountValidationSchema
} from "utils/payments";

const validationSchema = yup.object().shape({
  name: yup.string().required("Company name is required"),
  contactName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  countryId: yup.string().nullable().notRequired(),
  address: yup.string().nullable().notRequired(),
  phone: yup.string().notRequired().nullable(),
  skype: yup.string().notRequired().nullable(),
  vatNumber: yup.string().notRequired().nullable(),
  info: yup.string().notRequired().nullable(),
  allowEmails: yup.boolean(),
  paymentMinAmount: yup.number(),
  paymentMethod: yup.string().required("Payment method is required"),
  paymentMethodDetails: yup
    .object()
    .when("paymentMethod", {
      is: PAYMENT_METHODS.skrill,
      then: skrillAccountValidationSchema
    })
    .when("paymentMethod", {
      is: PAYMENT_METHODS.casino,
      then: casinoAccountValidationSchema
    })
    .when("paymentMethod", {
      is: PAYMENT_METHODS.bank,
      then: bankAccountValidationSchema
    })
});

export default validationSchema;
