import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import NonLoggedin from "components/nonloggedin";
import { GoogleLoginPage } from "modules/google-auth";

const GooglePage: FC<RouteComponentProps> = () => (
  <NonLoggedin>
    <GoogleLoginPage redirectPath="/admin/affiliates" />
  </NonLoggedin>
);

export default GooglePage;
