import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { Store } from "redux";

import { AppState } from "store";

import { setError } from "modules/error";
import { openSnackbar } from "modules/snackbar";
import { logOut, setAuthToken } from "modules/authentication";
import { googleLogout } from "modules/google-auth";
import { stopAdminViewMode, stopAffiliateViewMode } from "modules/view-mode";

import methods from "./methods";

const api = axios.create({
  baseURL: process.env.HTTP_PROXY,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true
});

const initializeInterceptors = (store: Store<AppState>) => {
  api.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = store.getState().authentication.token;
    const viewedAffiliateId = store.getState().viewMode.affiliateId;
    const viewedAccountId = store.getState().viewMode.accountId;

    if (config.headers) {
      if (token) {
        config.headers["x-auth-token"] = token;
      }

      if (viewedAffiliateId) {
        config.headers["affiliate-id"] = String(viewedAffiliateId);
      }

      if (viewedAccountId) {
        config.headers["child-id"] = String(viewedAccountId);
      }
    }

    return config;
  });

  api.interceptors.response.use(
    (response: AxiosResponse<{ data: unknown }>) => {
      if (response.headers["x-auth-token"]) {
        store.dispatch(setAuthToken(response.headers["x-auth-token"]));
      }

      if (response.data.data) {
        return response.data.data;
      } else {
        return response.data;
      }
    },
    (error: AxiosError<{ error: { message: string } }>) => {
      const res = error.response;

      if (typeof res === "undefined") {
        const message =
          "A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.";
        store.dispatch(openSnackbar({ type: "error", message }));
      }

      if (res) {
        const message =
          res.status < 500 || (res.data.error && res.data.error.message)
            ? res.data.error.message
            : res.statusText;

        if (res.status === 401) {
          store.dispatch(logOut());
          store.dispatch(googleLogout());
          store.dispatch(stopAdminViewMode());
          store.dispatch(stopAffiliateViewMode());
        } else {
          store.dispatch(openSnackbar({ type: "error", message }));
          store.dispatch(
            setError({
              status: res.status,
              message
            })
          );
        }
      }
      return Promise.reject(error);
    }
  );
};

export * from "./types";
export { initializeInterceptors };

export default methods(api);
