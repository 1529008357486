import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

import { useSkeletonStyles } from "./styles";

interface Props {
  isAdmin?: boolean;
  editable: boolean;
}

const FeeSkeleton: FC<Props> = ({ isAdmin, editable }) => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.details}>
        <Box className="drawer-header">
          <Skeleton width={114} height={28} className="drawer-header_title" />
          {editable && (
            <Skeleton width={93} height={30} className={classes.button} />
          )}
        </Box>
        <Box className={classes.deal}>
          <Skeleton width={33} height={16} />
          <Skeleton width={287} height={30} />
          <Skeleton width={287} height={30} />
        </Box>
        {isAdmin && (
          <Box className={classes.create}>
            <Box className={classes.created}>
              <Skeleton width={45} height={16} />
              <Skeleton width={70} height={30} />
            </Box>
            <Box className={classes.createdBy}>
              <Skeleton width={62} height={16} />
              <Skeleton width={110} height={30} />
            </Box>
          </Box>
        )}
        <Divider className={classes.divider} />
        <Box className={classes.geo}>
          <Skeleton width={50} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
        </Box>
        <Box className={classes.geo}>
          <Skeleton width={50} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
        </Box>
        <Box className={classes.geo}>
          <Skeleton width={50} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
        </Box>
        <Box className={classes.geo}>
          <Skeleton width={50} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
          <Skeleton width={300} height={24} />
        </Box>
      </Box>
      <Box className={classes.companies}>
        <Box className={classes.companiesHeader}>
          <Skeleton
            width={180}
            height={30}
            className={classes.companiesTitle}
          />
          <Skeleton width={288} height={40} className={classes.search} />
        </Box>
        <Box className={classes.list}>
          <Box className={classes.affiliate}>
            <Skeleton width={100} height={20} />
            <Skeleton width={120} height={16} />
          </Box>
          <Box className={classes.affiliate}>
            <Skeleton width={100} height={20} />
            <Skeleton width={120} height={16} />
          </Box>
          <Box className={classes.affiliate}>
            <Skeleton width={100} height={20} />
            <Skeleton width={120} height={16} />
          </Box>
          <Box className={classes.affiliate}>
            <Skeleton width={100} height={20} />
            <Skeleton width={120} height={16} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FeeSkeleton;
