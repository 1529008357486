import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: "100%"
    },
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    table: {
      width: "100%",
      height: "calc(100vh - 196px)"
    },
    linkColumn: {
      paddingRight: 4
    },
    segmentColumn: {
      paddingRight: 4,
      fontWeight: 500
    },
    brandColumn: {
      maxWidth: 96
    },
    clicksColumn: {
      maxWidth: 32
    },
    regColumn: {
      maxWidth: 52
    },
    depositsColumn: {
      maxWidth: 96
    },
    ndcColumn: {
      maxWidth: 52
    },
    turnoverColumn: {
      maxWidth: 96
    },
    netColumn: {
      maxWidth: 96
    },
    commColumn: {
      maxWidth: 96
    },
    cpaColumn: {
      maxWidth: 96
    }
  })
);

export { useStyles };
