import * as yup from "yup";

const ruleValidationSchema = yup.object().shape(
  {
    countryIds: yup
      .array()
      .of(yup.string().notRequired())
      .required("Countries is required"),
    nrs: yup.number().min(0).max(100).required("NRS is required"),
    cpa: yup.number().required("CPA is required"),
    deposit: yup.mixed().when("deposit_cpa", {
      is: (value: string) => Number(value) > 0,
      then: yup
        .number()
        .notOneOf([0], "Deposit is must be greater than 0")
        .required("Deposit is required"),
      otherwise: yup.number().notRequired()
    }),
    deposit_cpa: yup.mixed().when("deposit", {
      is: (value: string) => Number(value) > 0,
      then: yup
        .number()
        .notOneOf([0], "Cumulative deposit is must be greater than 0")
        .required("Cumulative deposit is required"),
      otherwise: yup.number().notRequired()
    })
  },
  [["deposit", "deposit_cpa"]]
);

const validationSchema = yup.object().shape({
  plan: yup.object().shape({
    name: yup.string().required("Name is required"),
    nrs: yup.number().min(0).max(100).nullable().default(null),
    cpa: yup.number().required("CPA is required")
  }),
  rules: yup.array().of(ruleValidationSchema)
});

export { ruleValidationSchema };
export default validationSchema;
