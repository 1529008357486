import { Plan, PlanRule } from "@types";
import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { BRANDS, BRAND_NAMES } from "constants/brands";
import PlanInfo from "components/plan-info";

interface Props {
  isAdmin?: boolean;
  deal: Plan;
  brand?: BRANDS;
  rules: PlanRule[];
  handleChange?: () => void;
}

const DealDetails: FC<Props> = ({
  isAdmin,
  deal,
  brand,
  rules,
  handleChange
}) => (
  <Container className="drawer-container">
    <Box className="drawer-header">
      <Typography variant="h4" className="drawer-header_title">
        {brand ? BRAND_NAMES[brand] : "Deal details"}
      </Typography>
      {handleChange && (
        <Button color="secondary" onClick={handleChange}>
          change deal
        </Button>
      )}
    </Box>
    <PlanInfo
      isAdmin={isAdmin}
      planLabel="Deal"
      plan={deal}
      rules={rules}
      style={{ padding: "0 16px" }}
    />
  </Container>
);

export default DealDetails;
