import { Fee, FeeRule } from "@types";
import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { BRANDS, BRAND_NAMES } from "constants/brands";
import FeeDetails from "modules/fee/components/FeeDetails";

interface Props {
  isAdmin?: boolean;
  affiliateFee: Fee;
  brand?: BRANDS;
  rules: FeeRule[];
  handleChange?: () => void;
}

const AffiliateFeeDetails: FC<Props> = ({
  affiliateFee,
  brand,
  rules,
  handleChange
}) => (
  <Container className="drawer-container">
    <Box className="drawer-header">
      <Typography variant="h4" className="drawer-header_title">
        {brand ? BRAND_NAMES[brand] : "Fee details"}
      </Typography>
      {handleChange && (
        <Button color="secondary" onClick={handleChange}>
          edit fees
        </Button>
      )}
    </Box>
    <FeeDetails
      feeLabel="Admin Fee"
      fee={affiliateFee}
      rules={rules}
      style={{ padding: "0 16px" }}
    />
  </Container>
);

export default AffiliateFeeDetails;
