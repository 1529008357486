import { CreateSubAffiliateValues } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";
import SubmitButton from "components/submit-button";

import validationSchema from "./validationSchema";

export interface EditCommissionProps {
  initialState: CreateSubAffiliateValues;
  onSubmit: (
    values: CreateSubAffiliateValues,
    helpers: FormikHelpers<CreateSubAffiliateValues>
  ) => void;
}

const EditCommission: FC<EditCommissionProps> = ({
  initialState,
  onSubmit
}) => (
  <Formik
    initialValues={initialState}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {formik => (
      <form className="drawer-container" onSubmit={formik.handleSubmit}>
        <Box className="drawer-header">
          <Typography variant="h4" className="drawer-header_title">
            Edit commission
          </Typography>
          <SubmitButton>save changes</SubmitButton>
        </Box>
        <TextField
          numeric
          name="commissionShare"
          label="Commission Share (%)"
        />
      </form>
    )}
  </Formik>
);

export default EditCommission;
