import React, { FC } from "react";
import ceil from "lodash/ceil";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/ClearRounded";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 8
    },
    filename: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      color: theme.colors.black61,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "calc(100% - 96px)"
    },
    progressBar: {
      display: "flex",
      alignItems: "center"
    },
    fileSize: {
      marginLeft: 4,
      fontSize: 12,
      lineHeight: "16px",
      whiteSpace: "nowrap",
      color: theme.colors.black9e
    },
    cancelIcon: {
      marginLeft: 8,
      color: theme.colors.blackbd,
      "&:hover": {
        cursor: "pointer",
        color: theme.colors.black61
      }
    }
  })
);

interface Props {
  file: File;
  onRemove?: (fileName: string) => void;
}

const AttachmentToUpload: FC<Props> = ({ file, onRemove }) => {
  const classes = useStyles();
  const fileSizeMB = `${ceil(file.size! / 10 ** 6, 2)} Mb`;

  return (
    <Box className={classes.container}>
      <Box className={classes.filename}>{file.name}</Box>
      <Box className={classes.progressBar}>
        <Typography className={classes.fileSize}>{fileSizeMB}</Typography>
        {onRemove && (
          <CancelIcon
            className={classes.cancelIcon}
            onClick={() => onRemove(file.name)}
            fontSize="small"
          />
        )}
      </Box>
    </Box>
  );
};

export default AttachmentToUpload;
