/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import Box from "@material-ui/core/Box";

import { AFFILIATE_PAYMENT_METHODS_OPTIONS } from "constants/payments";
import { getPaymentName } from "utils/payments";
import SelectField from "components/select-field";
import { BankForm, SkrillForm } from "components/account-forms";

interface Props {
  paymentMethod: string;
}

const EditPayment: FC<Props> = ({ paymentMethod }) => (
  <Box marginBottom={2} marginTop={2}>
    <SelectField
      label="Payment Method"
      name="paymentMethod"
      isSearchable={false}
      placeholder="Select Payment Method"
      value={{
        label: getPaymentName(paymentMethod),
        value: paymentMethod
      }}
      options={AFFILIATE_PAYMENT_METHODS_OPTIONS}
    />
    {paymentMethod === "banktransfer" && <BankForm />}
    {paymentMethod === "skrill" && <SkrillForm />}
  </Box>
);

export default EditPayment;
