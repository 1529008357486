import React, { FC, useEffect, useCallback } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";

import Drawer from "components/drawer";
import PageContainer from "components/page-container";
import Plan from "modules/plan";
import Plans, { fetchPlans } from "modules/plans";

type Props = RouteComponentProps;

const PlansPage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  return (
    <PageContainer>
      <Plans />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Route exact path={`${match.path}/:planId`} component={Plan} />
      </Drawer>
    </PageContainer>
  );
};

export default PlansPage;
