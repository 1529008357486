import { AffiliatePlayersRevenue as IPlayersRevenue } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import isSameYear from "date-fns/isSameYear";
import isSameMonth from "date-fns/isSameMonth";

import DatePicker, { getDatePickerState, setDate } from "modules/datepicker";
import AffiliatePlayersRevenue, {
  getAffiliatePlayersRevenueState,
  fetchAffiliateViewPlayersRevenue
} from "modules/affiliate-players-revenue";

interface Params {
  year: string;
  month: string;
}

const AffiliateViewPlayersRevenuePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { year, month } = useParams<Params>();
  const { fullDate } = useSelector(getDatePickerState);
  const { brand } = useSelector(getAffiliatePlayersRevenueState);

  const handleRowClick = useCallback(
    ({ playerId }: IPlayersRevenue) => {
      history.push(`/revenue/${year}/${month}/players/${playerId}`);
    },
    [history, year, month]
  );

  useEffect(() => {
    const paramsDate = new Date(`${year}-${month}`);
    if (
      !isSameYear(paramsDate, fullDate) ||
      !isSameMonth(paramsDate, fullDate)
    ) {
      dispatch(setDate(paramsDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullDate]);

  useEffect(() => {
    const options = {
      year: Number(year),
      month: Number(month),
      brandId: brand
    };
    dispatch(fetchAffiliateViewPlayersRevenue(options));
  }, [dispatch, year, month, brand]);

  return (
    <AffiliatePlayersRevenue
      datepicker={<DatePicker baseDateUrl="/revenue" />}
      handleRowClick={handleRowClick}
    />
  );
};

export default AffiliateViewPlayersRevenuePage;
