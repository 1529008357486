import { CreateFeeValues, Fee } from "@types";

import { useCallback, ChangeEvent, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";
import { formatFeeToRequest, createFee, deleteFee } from "modules/fee";

import { getFeesState } from "./duck";
import { useStyles } from "./styles";
import { getDatePickerState } from "../datepicker";

export default function () {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { year, month } = useSelector(getDatePickerState);

  const { isLoading, error, data } = useSelector(getFeesState);

  const { query, setQuery, results } = useSearch<Fee>(["name"], data);

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleRowClick = useCallback(
    ({ adminFeeId }: Fee) => {
      history.push(`/admin/fees/${adminFeeId}`);
    },
    [history]
  );

  const handleCreateFee = useCallback(
    (values: CreateFeeValues, helpers: FormikHelpers<CreateFeeValues>) => {
      const data = formatFeeToRequest(values);
      dispatch(createFee(data, helpers));
    },
    [dispatch]
  );

  const handleDeleteFee = useCallback(
    (event: MouseEvent<HTMLElement>) =>
      ({ name, adminFeeId }: Fee) => {
        event.stopPropagation();
        dispatch(
          openDialog({
            dialog: "delete-fee",
            payload: {
              type: "delete",
              title: `Delete ${name}?`,
              subtitle: "This operation cannot be undone",
              content:
                "Affiliate schedules using this fee will also be deleted, or cut short if currently running.",
              onConfirm: () => dispatch(deleteFee(adminFeeId))
            }
          })
        );
      },
    [dispatch]
  );

  const handleCreateDrawer = useCallback(() => {
    dispatch(
      openDrawer("create-fee", {
        title: "Create Fee",
        handleSubmit: handleCreateFee
      })
    );
  }, [dispatch, handleCreateFee]);

  return {
    classes,
    error,
    isLoading,
    year,
    month,
    isEmpty: isEmpty(data),
    isEmptySearch: isEmpty(results),
    fees: results,
    searchQuery: query,
    handleSearchQuery,
    handleRowClick,
    handleDeleteFee,
    handleCreateDrawer
  };
}
