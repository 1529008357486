import React from "react";
import { Link } from "react-router-dom";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import cn from "classnames";

import { LinkColumnProps } from "../../types";

const styles = (theme: Theme) =>
  createStyles({
    link: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      textDecoration: "none",
      color: theme.colors.blue,
      "&:hover": {
        textDecoration: "underline"
      }
    }
  });

const LinkColumn = withStyles(styles)(
  ({
    value: displayValue,
    name,
    linkName,
    linkValue,
    className,
    classes,
    href,
    ...rest
  }: LinkColumnProps) => {
    const match = linkName ? linkName : name;
    const value = linkValue ? linkValue : displayValue;
    const url = href.replace(new RegExp("{" + match + "}", "g"), String(value));

    return (
      <Link
        to={url}
        className={cn(classes.link, className)}
        onClick={(event: React.MouseEvent) => event.stopPropagation()}
        {...rest}
      >
        {`#${displayValue}`}
      </Link>
    );
  }
);

export default LinkColumn;
