import { useSelector } from "react-redux";

import { useRoles } from "hooks";
import { getDatePickerState } from "modules/datepicker";

import { useStyles } from "./styles";
import { getErrorState } from "./duck";

export default function () {
  const classes = useStyles();

  const { isLoggedin, isAdmin } = useRoles();
  const { status } = useSelector(getErrorState);
  const { year, month } = useSelector(getDatePickerState);

  const getLinkUrl = () => {
    if (isAdmin) {
      return "/admin/affiliates";
    }

    if (isLoggedin && !isAdmin) {
      return `/overview/${year}/${month}`;
    }

    return `/`;
  };

  return {
    classes,
    status,
    linkUrl: getLinkUrl()
  };
}
