import { createStyles, makeStyles } from "@material-ui/core/styles";

const useCardStyles = makeStyles(theme =>
  createStyles({
    card: {
      background: "transparent",
      boxShadow: "none",
      display: "flex",
      marginLeft: -1
    },
    mobileCard: {
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%"
      }
    },
    cardAreaVisible: {
      background: theme.colors.blackf5,
      "& svg": {
        color: theme.colors.blue
      },
      "& $divider": {
        background: "none"
      }
    },
    hoverEffect: {
      "&:hover svg": {
        color: theme.colors.blue
      },
      "&:hover $divider": {
        background: "none"
      }
    },
    label: {
      textTransform: "capitalize",
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black75
    },
    text: {
      fontSize: 14,
      lineHeight: "24px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    divider: {
      width: 1,
      height: "calc(100% - 32px)",
      alignSelf: "center",
      background: theme.colors.blacke0
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: 16,
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%"
      }
    },
    label: {
      margin: 0
    },
    text: {
      margin: 0,
      marginTop: 2
    }
  })
);

export { useCardStyles, useSkeletonStyles };
