import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required("Company name is required"),
  contactName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  countryId: yup.string().nullable().notRequired(),
  address: yup.string().nullable().notRequired(),
  phone: yup.string().notRequired().nullable(),
  skype: yup.string().notRequired().nullable(),
  vatNumber: yup.string().notRequired().nullable(),
  info: yup.string().notRequired().nullable(),
  allowEmails: yup.boolean(),
  isInternal: yup.boolean(),
  allowNegativeFee: yup.boolean(),
  floorBrandCommission: yup.boolean(),
  allowPayments: yup.boolean(),
  userId: yup.number().nullable().notRequired()
});

export default validationSchema;
