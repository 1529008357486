import { Reducer } from "redux";
import { FormikHelpers } from "formik";

import api from "api";
import {
  CreateFeeRequest,
  CreateFeeValues,
  Fee,
  FeeResponse,
  FeeRule,
  FeeAffiliate
} from "@types";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";
import { fetchFees } from "modules/fees";
import { closeDrawer, DrawersActions } from "drawers";

enum FeeActionTypes {
  CLEAR_STATE = "@fee/clear-state",
  FEE_LOADING = "@fee/fee-loading",
  FETCH_FEE_SUCCESS = "@fee/fetch-fee-success",
  FETCH_FEE_FAILURE = "@fee/fetch-fee-failure",
  UPDATE_FEE_SUCCESS = "@fee/update-fee-success",
  CREATE_FEE_SUCCESS = "@fee/create-fee-success",
  DELETE_FEE_SUCCESS = "@fee/delete-fee-success",
  SET_FEE_RUNNING_AFFILIATE_FILTER = "@fee/set-fee-running-affiliates-filter"
}

interface FeeIsLoadingAction {
  type: FeeActionTypes.FEE_LOADING;
}

interface FetchFeeIsSuccessAction {
  type: FeeActionTypes.FETCH_FEE_SUCCESS;
  payload: FeeResponse;
}

interface FetchFeeIsFailureAction {
  type: FeeActionTypes.FETCH_FEE_FAILURE;
}

interface UpdateFeeIsSuccessAction {
  type: FeeActionTypes.UPDATE_FEE_SUCCESS;
  payload: FeeResponse;
}

interface CreateFeeIsSuccessAction {
  type: FeeActionTypes.CREATE_FEE_SUCCESS;
}

interface DeleteFeeIsSuccessAction {
  type: FeeActionTypes.DELETE_FEE_SUCCESS;
}

interface ClearStateAction {
  type: FeeActionTypes.CLEAR_STATE;
}

interface SetFeeRunningAffiliateFilterAction {
  type: FeeActionTypes.SET_FEE_RUNNING_AFFILIATE_FILTER;
  payload: boolean;
}

type FeeActions =
  | ClearStateAction
  | FeeIsLoadingAction
  | FetchFeeIsSuccessAction
  | DeleteFeeIsSuccessAction
  | CreateFeeIsSuccessAction
  | UpdateFeeIsSuccessAction
  | FetchFeeIsFailureAction
  | SetFeeRunningAffiliateFilterAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<FeeActions | DrawersActions>
>;

const feeIsLoading = (): FeeIsLoadingAction => ({
  type: FeeActionTypes.FEE_LOADING
});

const fetchFeeIsSuccess = (fee: FeeResponse): FetchFeeIsSuccessAction => ({
  type: FeeActionTypes.FETCH_FEE_SUCCESS,
  payload: fee
});

const fetchFeeIsFailure = (): FetchFeeIsFailureAction => ({
  type: FeeActionTypes.FETCH_FEE_FAILURE
});

const updateFeeIsSuccess = (fee: FeeResponse): UpdateFeeIsSuccessAction => ({
  type: FeeActionTypes.UPDATE_FEE_SUCCESS,
  payload: fee
});

const createFeeIsSuccess = (): CreateFeeIsSuccessAction => ({
  type: FeeActionTypes.CREATE_FEE_SUCCESS
});

const deleteFeeIsSuccess = (): DeleteFeeIsSuccessAction => ({
  type: FeeActionTypes.DELETE_FEE_SUCCESS
});

const clearFeeState = (): ClearStateAction => ({
  type: FeeActionTypes.CLEAR_STATE
});

const setFeeRunningAffiliateFilter = (
  filterRunningAffiliates: boolean
): SetFeeRunningAffiliateFilterAction => ({
  type: FeeActionTypes.SET_FEE_RUNNING_AFFILIATE_FILTER,
  payload: filterRunningAffiliates
});

const fetchFee =
  (id: number): ThunkActionCreator<FeeActions> =>
  async dispatch => {
    dispatch(feeIsLoading());
    try {
      const fee = await api.admin.fees.getFee(id);
      dispatch(fetchFeeIsSuccess(fee));
    } catch (err) {
      dispatch(fetchFeeIsFailure());
    }
  };

const updateFee =
  (
    adminFeeId: number,
    data: CreateFeeRequest,
    formikHelpers: FormikHelpers<CreateFeeValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      const fee = await api.admin.fees.updateFee(adminFeeId, data);
      dispatch(openSnackbar({ type: "success", message: "Fee updated!" }));
      dispatch(updateFeeIsSuccess(fee));
      dispatch(fetchFees());
      dispatch(fetchFee(adminFeeId));
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const createFee =
  (
    data: CreateFeeRequest,
    formikHelpers: FormikHelpers<CreateFeeValues>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.fees.createFee(data);
      dispatch(openSnackbar({ type: "success", message: "Fee created!" }));
      dispatch(createFeeIsSuccess());
      dispatch(fetchFees());
      formikHelpers.resetForm();
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const deleteFee =
  (id: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.fees.deleteFee(id);
    dispatch(openSnackbar({ type: "success", message: "Fee removed!" }));
    dispatch(deleteFeeIsSuccess());
    dispatch(fetchFees());
  };

interface FeeState {
  error: boolean;
  isLoading: boolean;
  details?: Fee;
  rules: FeeRule[];
  affiliates: FeeAffiliate[];
  filterRunningAffiliates: boolean;
}

const initialState: FeeState = {
  error: false,
  isLoading: false,
  details: undefined,
  rules: [],
  affiliates: [],
  filterRunningAffiliates: false
};

const reducer: Reducer<FeeState, FeeActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FeeActionTypes.FEE_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case FeeActionTypes.FETCH_FEE_SUCCESS:
    case FeeActionTypes.UPDATE_FEE_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        details: action.payload.fee,
        rules: action.payload.rules,
        affiliates: action.payload.affiliates
          ? action.payload.affiliates
          : state.affiliates
      };
    case FeeActionTypes.FETCH_FEE_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case FeeActionTypes.SET_FEE_RUNNING_AFFILIATE_FILTER:
      return {
        ...state,
        filterRunningAffiliates: action.payload
      };
    case FeeActionTypes.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

const getFeeState = (state: AppState) => ({
  ...state.fee,
  details: state.fee.details! as Fee,
  rules: state.fee.rules as FeeRule[]
});

export {
  reducer,
  fetchFee,
  updateFee,
  createFee,
  deleteFee,
  getFeeState,
  clearFeeState,
  setFeeRunningAffiliateFilter
};
