import { CreateFeeRuleValue } from "@types";

import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import { countriesOptions } from "constants/countries";

const initialCountries = countriesOptions;

const getInitialCountries = (rules: CreateFeeRuleValue[]) => {
  if (!isEmpty(rules)) {
    const initialIds = flatten(rules.map(rule => rule.countryIds));
    const filteredCountries = initialCountries.filter(
      ({ value }) => !initialIds.some(id => id === value)
    );
    return filteredCountries;
  }

  return initialCountries;
};

export { initialCountries, getInitialCountries };
