import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import formatMoney from "utils/formatMoney";
import { TextCard } from "components/cards";
import EmptyData from "components/empty-data";

import useAffiliateOverview from "./useAffiliateOverview";
import Skeleton from "./Skeleton";
import { Divider } from "./styles";

const AffiliateOverview: FC = () => {
  const { classes, isLoading, error, statistics } = useAffiliateOverview();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error || !statistics) {
    return <EmptyData className={classes.emptyData} />;
  }

  const {
    nrc,
    ndc,
    monthlyCommission,
    cpa,
    commission,
    activePlayers,
    depositingCustomers
  } = statistics;

  return (
    <>
      <Box className={classes.row}>
        <TextCard label="Revenue Share">
          <Typography className={classes.cardContent} variant="h2">
            {formatMoney.from(commission)}
          </Typography>
        </TextCard>
        {!!cpa && (
          <TextCard label="CPA">
            <Typography className={classes.cardContent} variant="h2">
              {formatMoney.from(cpa)}
            </Typography>
          </TextCard>
        )}
        <TextCard label="Total Commission">
          <Typography className={classes.cardContent} variant="h2">
            {formatMoney.from(monthlyCommission.current)}
          </Typography>
        </TextCard>
      </Box>
      <Divider />
      <Box className={classes.row}>
        <TextCard label="New Registered Customers (NRC)">
          <Typography className={classes.cardContent} variant="h2">
            <Box>{nrc.current}</Box>
          </Typography>
        </TextCard>
        <TextCard label="New Depositing Customers (NDC)">
          <Typography className={classes.cardContent} variant="h2">
            <Box>{ndc.current}</Box>
          </Typography>
        </TextCard>
        <TextCard label="Current month">
          <Box className={classes.multilineContent}>
            <Box className={classes.lineContent}>
              <Box>{activePlayers}</Box>
              <Box>Active Players</Box>
            </Box>
            <Box className={classes.lineContent}>
              <Box>{depositingCustomers}</Box>
              <Box>Depositing Players</Box>
            </Box>
          </Box>
        </TextCard>
      </Box>
      <Divider />
    </>
  );
};

export default AffiliateOverview;
