/* eslint-disable @typescript-eslint/no-explicit-any */
import mapValues from "lodash/mapValues";
import { MONEY_KEYS } from "constants/variables";

const locale = "en-US";
const options = {
  style: "currency",
  currency: "EUR"
};

interface FormatMoneyOptions {
  decimals?: number;
}

const moneyFormatter = new Intl.NumberFormat(locale, options);
const integerMoneyFormatter = new Intl.NumberFormat(locale, {
  ...options,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const convertToNumber = (value: string | null) =>
  typeof value === "string"
    ? Number(value.replace(/[^0-9.-]+/g, ""))
    : Number(value);

const convertToCurrency = (amount: number) =>
  Math.round(parseFloat(String(Number(amount)))) / 100;

const convertToCents = (amount: number) =>
  Math.round(parseFloat(String(Number(amount))) * 100);

const fromCentsToCurrency = (
  value: number | string,
  options?: FormatMoneyOptions
): string => {
  const convertedValue =
    typeof value === "number"
      ? convertToCurrency(value)
      : convertToCurrency(convertToNumber(value));

  if (options?.decimals === 0) {
    return integerMoneyFormatter.format(convertedValue);
  }

  return moneyFormatter.format(convertedValue);
};

const fromObjectCentsToCurrency = (object: { [key: string]: any }) =>
  mapValues(object, (value, key) =>
    MONEY_KEYS.includes(key) ? convertToCurrency(Number(value)) : value
  );

const fromArrayCentsToCurrency = (array: any[]): typeof array =>
  array.map(value => fromObjectCentsToCurrency(value));

const toCentsFromCurrency = (value: number | string): number => {
  if (typeof value === "number") {
    return convertToCents(value);
  }

  return convertToCents(convertToNumber(value));
};

const toObjectCentsFromCurrency = (object: any): typeof object =>
  mapValues(object, (value, key) =>
    MONEY_KEYS.includes(key) ? convertToCents(value) : value
  );

const toArrayCentsFromCurrency = (array: any[]): typeof array =>
  array.map(value => toObjectCentsFromCurrency(value));

const formatMoney = {
  convertToNumber,
  to: toCentsFromCurrency,
  toObject: toObjectCentsFromCurrency,
  toArray: toArrayCentsFromCurrency,
  from: fromCentsToCurrency,
  fromObject: fromObjectCentsToCurrency,
  fromArray: fromArrayCentsToCurrency
};

export default formatMoney;
