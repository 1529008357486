import thunk from "redux-thunk";
import { parseISO } from "date-fns";
import { Store, createStore as createReduxStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import {
  getPersistedState,
  setPersistedState,
  clearLocalStorage,
  REDUX_STATE
} from "./localStorage";
import { rootReducer } from "./rootReducer";
import { AppState } from ".";

const tempStore = createReduxStore(rootReducer);
const initialState = tempStore.getState();
const loadedState = getPersistedState();

const createStore = (): Store<AppState> => {
  const localStorageState = localStorage.getItem(REDUX_STATE);
  clearLocalStorage();

  localStorage.setItem(REDUX_STATE, localStorageState ? localStorageState : "");

  const store = createReduxStore(
    rootReducer,
    {
      ...initialState,
      ...loadedState,
      datepicker: {
        ...initialState.datepicker,
        ...loadedState.datepicker,
        fullDate: loadedState.datepicker
          ? parseISO(loadedState.datepicker.fullDate)
          : initialState.datepicker.fullDate
      },
      affiliates: {
        ...initialState.affiliates,
        lastUsageIds: loadedState.affiliates
          ? loadedState.affiliates.lastUsageIds
          : initialState.affiliates.lastUsageIds
      }
    },
    composeWithDevTools(applyMiddleware(thunk))
  );

  store.subscribe(() => {
    const state = store.getState();

    setPersistedState({
      authentication: state.authentication,
      datepicker: {
        fullDate: state.datepicker.fullDate,
        year: state.datepicker.year,
        month: state.datepicker.month,
        day: state.datepicker.day
      },
      viewMode: state.viewMode,
      affiliate: {
        profile: {
          infoDialogShown: state.affiliate.profile.infoDialogShown
        }
      },
      affiliates: {
        lastUsageIds: state.affiliates.lastUsageIds
      }
    });
  });

  return store;
};

export { createStore };
