/* eslint-disable @typescript-eslint/ban-types */
import React, { FC } from "react";
import { useSelector } from "react-redux";

import EmptyData from "components/empty-data";
import { DealDetails, getAffiliateDealState } from "modules/affiliate-deal";

export type UserDealDetailsProps = {};

const UserDealDetails: FC<UserDealDetailsProps> = () => {
  const { error, data } = useSelector(getAffiliateDealState);

  if (!data || error) {
    return <EmptyData className="no-data" />;
  }

  return <DealDetails brand={data.brandId} deal={data} rules={data.rules} />;
};

export default UserDealDetails;
