import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      "& a": {
        textDecoration: "none"
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        flexDirection: "column-reverse"
      }
    },
    leftContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%"
    },
    wrapper: {
      width: 286,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
        width: "100%"
      }
    },
    rightContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        zoom: 0.8
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 32
      }
    },
    oops: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 500,
      color: theme.colors.black21
    },
    tip: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black75,
      marginTop: 8
    },
    homeLink: {
      textDecoration: "none"
    },
    homeButton: {
      marginTop: 16,
      borderColor: theme.colors.blue,
      background: theme.colors.blue,
      color: theme.colors.white,
      "&:hover": {
        color: theme.colors.blue,
        background: theme.colors.white
      }
    },
    divider: {
      height: 512,
      width: 1,
      background: theme.colors.blacke0,
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    }
  })
);

export { useStyles };
