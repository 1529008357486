import React, { FC } from "react";

import { Formik } from "formik";

import EmailForm from "./components/EmailForm";
import CodeForm from "./components/CodeForm";
import NewPasswordForm from "./components/NewPasswordForm";

import { Steps, ForgotValues } from "./types";
import useForgotPassword from "./useForgotPassword";

const initialValues: ForgotValues = {
  email: "",
  code: "",
  password: "",
  passwordConfirm: ""
};

const Forgot: FC = () => {
  const { step, validationSchema, handleStep, handleSubmit } =
    useForgotPassword();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, isValid, setErrors, handleSubmit: onSubmit }) => (
        <form onSubmit={onSubmit}>
          {step === Steps.Email && <EmailForm disabled={!dirty || !isValid} />}
          {step === Steps.Code && (
            <CodeForm
              disabled={!dirty || !isValid}
              email={values.email}
              handleBack={() => {
                setErrors({});
                handleStep(Steps.Email);
              }}
            />
          )}
          {step === Steps.NewPassword && (
            <NewPasswordForm
              disabled={!dirty || !isValid}
              handleBack={() => {
                setErrors({});
                handleStep(Steps.Code);
              }}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default Forgot;
