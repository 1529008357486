import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      padding: 8,
      background: theme.colors.blackf5,
      borderRadius: 4,
      color: theme.colors.black61,
      "&:hover": {
        cursor: "pointer",
        color: theme.colors.black21,
        background: theme.colors.blackee,
        textDecoration: "none"
      },
      "&:hover svg": {
        fill: theme.colors.black21
      }
    },
    fileIcon: {
      flexShrink: 0,
      color: theme.colors.black75,
      fill: theme.colors.black75
    },
    filename: {
      marginLeft: 8,
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      textOverflow: "ellipsis",
      maxWidth: 196,
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    noMaxWidth: {
      maxWidth: "none"
    },
    progress: {
      height: 2,
      borderRadius: "0px 0px 4px 4px",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    }
  })
);

export { useStyles };
