import { AffiliatePlayersRevenue } from "@types";

import { useCallback, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { openDrawer } from "drawers";
import { BRANDS } from "constants/brands";

import {
  getAffiliatePlayersState,
  checkAffiliatePlayer,
  uncheckAffiliatePlayer,
  setAffiliateCheckedPlayers,
  fetchAffiliatePlayers,
  fetchAffiliateViewPlayers,
  setAffiliatePlayersBrandFilter,
  updateAffiliatePlayers
} from "./duck";
import { useStyles } from "./styles";

interface Params {
  id: string;
}

export default function () {
  const classes = useStyles();
  const { id: affiliateId } = useParams<Params>();
  const dispatch = useDispatch();
  const { isLoading, error, brand, checkedIds, data, totals } = useSelector(
    getAffiliatePlayersState
  );
  const { query, setQuery, results } = useSearch<AffiliatePlayersRevenue>(
    ["playerId"],
    data
  );

  const handleBrandFilter = useCallback(
    (brand: BRANDS | null) => {
      dispatch(setAffiliatePlayersBrandFilter(brand));
    },
    [dispatch]
  );

  const addToCheckedIds = useCallback(
    (id: number) => {
      dispatch(checkAffiliatePlayer(id));
    },
    [dispatch]
  );

  const removeFromCheckedIds = useCallback(
    (removedId: number) => {
      dispatch(uncheckAffiliatePlayer(removedId));
    },
    [dispatch]
  );

  const handleCheckedPlayers = useCallback(
    (ids: number[]) => {
      dispatch(setAffiliateCheckedPlayers(ids));
    },
    [dispatch]
  );

  const handleRowCheck = useCallback(
    (id: number) =>
      !checkedIds.includes(id) ? addToCheckedIds(id) : removeFromCheckedIds(id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedIds]
  );

  const handleAllRowsCheck = useCallback(() => {
    const playersIds = results.map(({ playerId }) => playerId);
    if (checkedIds.length < playersIds.length) {
      handleCheckedPlayers(playersIds);
    } else {
      handleCheckedPlayers([]);
    }
  }, [checkedIds, results, handleCheckedPlayers]);

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleChangeDealDrawer = useCallback(() => {
    dispatch(
      openDrawer("change-deal", {
        handleSubmit: (planId: number) => {
          dispatch(
            updateAffiliatePlayers(Number(affiliateId), brand, checkedIds, {
              planId
            })
          );
        }
      })
    );
  }, [dispatch, affiliateId, brand, checkedIds]);

  const handleMovePlayersDrawer = useCallback(() => {
    dispatch(
      openDrawer("move-players", {
        handleSubmit: (newAffiliateId: number) => {
          const data = {
            affiliateId: newAffiliateId
          };
          dispatch(
            updateAffiliatePlayers(Number(affiliateId), brand, checkedIds, data)
          );
        }
      })
    );
  }, [dispatch, affiliateId, brand, checkedIds]);

  useEffect(() => {
    dispatch(setAffiliateCheckedPlayers([]));
    if (affiliateId) {
      dispatch(fetchAffiliatePlayers(Number(affiliateId), brand));
    } else {
      dispatch(fetchAffiliateViewPlayers(brand));
    }
  }, [dispatch, affiliateId, brand]);

  useEffect(() => {
    return () => {
      dispatch(setAffiliatePlayersBrandFilter(null));
    };
  }, [dispatch]);

  return {
    classes,
    error,
    totals,
    isLoading,
    players: results,
    searchQuery: query,
    isEmpty: isEmpty(data),
    isAdmin: !!affiliateId,
    isEmptySearch: isEmpty(results),
    isEmptyChecked: isEmpty(checkedIds),
    brandFilterProps: {
      brand: brand,
      onChange: handleBrandFilter
    },
    checkedTableProps: !!affiliateId && {
      checkedIds,
      handleRowCheck,
      handleAllRowsCheck
    },
    handleSearchQuery,
    handleChangeDealDrawer,
    handleMovePlayersDrawer
  };
}
