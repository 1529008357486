import { SelectOption } from "@types";

import React, { FC, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import isEmpty from "lodash/isEmpty";

import EmptyResult from "components/empty-search";

import SelectListSkeleton from "./Skeleton";
import ListItem from "./ListItem";

const ITEM_SIZE = 48;

interface Option extends SelectOption {
  description?: string | number;
}

interface Props {
  isLoading?: boolean;
  options: Option[];
  selectedOption?: number;
  handleClear?: () => void;
  handleChange: (value: number) => void;
}

const List: FC<Props> = ({
  isLoading,
  options,
  selectedOption,
  handleClear,
  handleChange
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<{ [key: number]: number }>({});

  const getRowHeight = (index: number) =>
    rowHeights.current[index] || ITEM_SIZE;

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  if (isLoading) {
    return <SelectListSkeleton />;
  }

  if (handleClear && isEmpty(options)) {
    return <EmptyResult onClear={handleClear} />;
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeList
          ref={listRef}
          height={height}
          width={width}
          itemSize={getRowHeight}
          itemData={options}
          itemCount={options.length}
          overscanCount={4}
        >
          {props => (
            <ListItem
              {...props}
              selectedOption={selectedOption}
              handleRowHeight={setRowHeight}
              handleChange={handleChange}
            />
          )}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
};

export default List;
