import { SubAffiliate } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { openDrawer } from "drawers";
import DatePicker from "modules/datepicker";
import AffiliateSubAffiliates, {
  fetchAffiliateViewSubAffiliates
} from "modules/affiliate-sub-afiiliates";

const useStyles = makeStyles(theme =>
  createStyles({
    picker: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        "& .MuiTypography-h3": {
          fontSize: 16,
          lineHeight: "24px"
        }
      }
    }
  })
);

interface Params {
  year: string;
  month: string;
}

const AffiliateViewSubAffiliatesPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { year, month } = useParams<Params>();

  useEffect(() => {
    dispatch(fetchAffiliateViewSubAffiliates(Number(year), Number(month)));
  }, [dispatch, year, month]);

  const handleRowClick = useCallback(
    (affiliate: SubAffiliate) => {
      dispatch(
        openDrawer("affiliate-details", {
          affiliate
        })
      );
    },
    [dispatch]
  );

  return (
    <Box>
      <Box className={classes.picker}>
        <Typography variant="h3">Sub affiliates</Typography>
        <DatePicker baseDateUrl="/subs" />
      </Box>
      <AffiliateSubAffiliates handleRowClick={handleRowClick} />
    </Box>
  );
};

export default AffiliateViewSubAffiliatesPage;
