import React, { FC } from "react";
import { useField } from "formik";
import { TextFieldProps } from "@material-ui/core/TextField";

import { isNumber } from "utils/regexes";
import Input from "components/input";

type CardFieldProps = Omit<TextFieldProps, "name"> & {
  name: string;
  pattern?: RegExp;
};

const CardField: FC<CardFieldProps> = ({ pattern, ...rest }) => {
  const [field, meta] = useField(rest.name);

  return (
    <Input
      {...field}
      {...rest}
      size="small"
      variant="outlined"
      value={field.value?.toString()}
      error={meta.touched && !!meta.error}
      onChange={event => {
        (pattern || isNumber).test(event.target.value) && field.onChange(event);
      }}
    />
  );
};

export default CardField;
