import React, { FC, useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { openDrawer, closeDrawer, getDrawersState } from "drawers";
import { clearPlanState, fetchPlan, getPlanState } from "modules/plan";
import EmptyData from "components/empty-data";

import DealDetails from "./DealDetails";
import Skeleton from "./../Skeleton";

interface MatchParams {
  id?: string;
  year?: string;
  month?: string;
  playerId?: string;
  dealId?: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  onSubmit: (planId: number, playerId: number, pathname: string) => void;
}

const PlayerDeal: FC<Props> = ({ match, onSubmit }) => {
  const dispatch = useDispatch();
  const { playerId, dealId } = match.params;

  const { isOpen } = useSelector(getDrawersState);
  const { isLoading, error, rules, details } = useSelector(getPlanState);

  useEffect(() => {
    dispatch(fetchPlan(Number(dealId)));
  }, [dispatch, dealId]);

  useEffect(() => {
    return () => {
      dispatch(clearPlanState());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      isOpen && dispatch(closeDrawer());
    };
  }, [dispatch, isOpen]);

  const handleSubmit = useCallback(
    (planId: number) => {
      onSubmit(planId, Number(playerId), match.path);
    },
    [playerId, match.path, onSubmit]
  );

  const handleChangeDealOpen = useCallback(() => {
    dispatch(
      openDrawer("change-deal", {
        handleSubmit,
        initialPlanId: Number(dealId)
      })
    );
  }, [dispatch, dealId, handleSubmit]);

  if (isLoading) {
    return <Skeleton />;
  }

  if (isEmpty(details) || error) {
    return <EmptyData className="no-data" />;
  }

  return (
    <DealDetails
      isAdmin
      deal={details}
      rules={rules}
      handleChange={handleChangeDealOpen}
    />
  );
};

export default PlayerDeal;
