import { ListAffiliate } from "@types";

import React, { FC, useMemo } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import EmptySearch from "components/empty-search";

import getListItemUrl from "../helpers/getListItemUrl";
import getFormattedList from "../helpers/getFormattedList";
import getLastUsageAffiliates from "../helpers/getLastUsageAffiliates";
import ListItem from "./ListItem";
import { ListContainer, useListTitleStyles } from "../styles";

interface Props {
  affiliates: ListAffiliate[];
  lastUsageIds: number[];
  activeAffiliate: number;
  onClearSearch: () => void;
  onRemove: (id: number) => void;
  onClear: () => void;
  onChange: (id: number) => void;
}

type RowProps = Pick<
  Props,
  "onRemove" | "onClear" | "onChange" | "activeAffiliate"
> &
  ListChildComponentProps;

const Row: FC<RowProps> = ({
  index,
  style,
  data,
  activeAffiliate,
  onChange,
  onClear,
  onRemove
}) => {
  const classes = useListTitleStyles();
  const { affiliateId, affiliateName, lastUsage } = data[index];
  const url = getListItemUrl(affiliateId);

  if (affiliateId === "title") {
    return (
      <Box className={classes.container} style={style}>
        <Typography className={classes.title} variant="h4">
          {affiliateName}
        </Typography>
        {affiliateName === "Latest" && (
          <Typography
            className={classes.clearBtn}
            variant="subtitle1"
            onClick={() => onClear()}
          >
            Clear all
          </Typography>
        )}
      </Box>
    );
  }

  if (lastUsage) {
    return (
      <ListItem
        style={style}
        key={index}
        id={affiliateId}
        url={url}
        title={affiliateName}
        onChange={onChange}
        onRemove={onRemove}
        selectedAffiliate={activeAffiliate}
      />
    );
  }

  return (
    <ListItem
      style={style}
      key={index}
      id={affiliateId}
      url={url}
      title={affiliateName}
      onChange={onChange}
    />
  );
};

const List: FC<Props> = ({
  affiliates,
  lastUsageIds,
  onClearSearch,
  ...rest
}) => {
  const lastUsageAffiliates = useMemo(
    () => getLastUsageAffiliates(affiliates, lastUsageIds),
    [affiliates, lastUsageIds]
  );
  const displayAffiliates = useMemo(
    () => getFormattedList(lastUsageAffiliates, affiliates),
    [affiliates, lastUsageAffiliates]
  );

  return (
    <ListContainer>
      {isEmpty(affiliates) ? (
        <EmptySearch onClear={onClearSearch} />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={56}
              itemData={displayAffiliates}
              itemCount={displayAffiliates.length}
            >
              {props => <Row {...rest} {...props} />}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </ListContainer>
  );
};

export default List;
