import React, { FC } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import Box, { BoxProps } from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import formatMoney from "utils/formatMoney";
import { getAffiliateState } from "modules/affiliate";

import { useStyles } from "./styles";

const UserBalance: FC<BoxProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { data, isLoading } = useSelector(getAffiliateState);

  return (
    <Box className={cn(classes.card, className)} {...rest}>
      <Typography component="span" variant="subtitle1">
        Pending Payments
      </Typography>
      {isLoading || !data ? (
        <Box margin="4px 0">
          <Skeleton width={106} height={32} animation={false} />
          <Skeleton width={128} height={16} animation={false} />
        </Box>
      ) : (
        <>
          <Typography
            component="div"
            variant="h3"
            className={classes.balanceAmount}
          >
            {formatMoney.from(Number(data.accountBalance))}
          </Typography>
          <Typography component="div" className={classes.minPayment}>
            {`Min payment ${formatMoney.from(data.paymentMinAmount, {
              decimals: 0
            })}`}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default UserBalance;
