import React from "react";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVertRounded";
import { withStyles } from "@material-ui/core/styles";

import { useAnchor } from "hooks";

import { DropdownColumnProps } from "../../types";

const DropdownColumn = withStyles(theme => ({
  root: {
    color: theme.colors.blackbd,
    padding: 8,
    margin: -16,
    "&:hover": {
      color: theme.colors.blue
    }
  }
}))(({ children, ...rest }: DropdownColumnProps) => {
  const { anchorEl, handleClosePopup, handleOpenPopup } = useAnchor();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleOpenPopup(event);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    handleClosePopup();
  };

  return (
    <IconButton onClick={handleMenuOpen} {...rest}>
      <MoreIcon />
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            onClick: (event: React.MouseEvent<HTMLLIElement>) => {
              child.props.onClick && child.props.onClick(event);
              handleClosePopup();
            }
          })
        )}
      </Menu>
    </IconButton>
  );
});

export default DropdownColumn;
