import {
  format,
  endOfMonth as getEndOfMonth,
  startOfMonth as getStartOfMonth
} from "date-fns";

export const now = new Date();
export const day = format(now, "dd");
export const month = format(now, "MM");
export const year = format(now, "yyyy");
export const endOfMonth = getEndOfMonth(now);
export const startOfMonth = getStartOfMonth(now);
export const minDate = new Date("2000-01-01");
export const maxDate = new Date("2030-01-01");
