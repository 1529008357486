import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    emptyScreen: {
      padding: 16
    },
    idColumn: {
      maxWidth: 108
    },
    brandColumn: {
      maxWidth: 108
    },
    linkColumn: {
      minWidth: 392,
      paddingRight: 16
    },
    methodColumn: {
      maxWidth: 64
    },
    iconColumn: {
      maxWidth: 64
    }
  })
);

const skeletonStyles = {
  container: {
    width: "100%"
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 80,
    padding: "0 16px"
  },
  smallCell: {
    width: 64,
    height: 18
  },
  mediumCell: {
    width: 72,
    height: 18
  },
  largeCell: {
    width: 128,
    height: 18
  },
  headerLink: {
    width: 128
  },
  button: {
    width: 24,
    height: 24,
    borderRadius: "50%"
  }
};

export { useStyles, skeletonStyles };
