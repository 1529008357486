import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    picker: {
      display: "none"
    },
    button: {
      marginLeft: 8
    },
    icon: {
      width: 16,
      height: 16
    },
    day: {
      color: theme.colors.black21,
      margin: 0,
      padding: 0,
      borderRadius: 0,
      width: 36,
      height: 36,
      fontSize: 12,
      fontWeight: 500
    },
    currentDay: {
      color: theme.colors.blue,
      "& *": {
        fontWeight: 500
      }
    },
    beginCap: {
      color: theme.colors.white,
      background: `${theme.colors.blue} !important`,
      borderRadius: "8% 0 0 8%",
      "&:hover": {
        color: theme.colors.white
      }
    },
    endCap: {
      color: theme.colors.white,
      background: `${theme.colors.blue} !important`,
      borderRadius: "0 8% 8% 0",
      "&:hover": {
        color: theme.colors.white
      }
    },
    isSelected: {
      background: theme.colors.blue50
    },
    isDisabled: {
      pointerEvents: "none",
      textDecoration: "line-through",
      color: theme.colors.blackbd
    },
    notCurrentMonth: {
      color: theme.colors.blackbd
    }
  })
);

export { useStyles };
