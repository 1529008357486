import { Payment, TableFilterValues } from "@types";

import { useCallback, ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { useSearch } from "hooks";
import { closeDrawer, openDrawer } from "drawers";
import filterArray from "utils/filterArrayByMultipleKeys";
import { openDialog } from "modules/dialog";
import { getDatePickerState } from "modules/datepicker";
import { closeMonth, getAuthState } from "modules/authentication";

import { confirmPayment, markPayment, getPaymentsState } from "./duck";
import { useStyles } from "./styles";

const initialFilterState: TableFilterValues = {
  status: "all",
  taxRate: "all",
  paymentMethod: "all"
};

export default function () {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { canCloseMonth, roles } = useSelector(getAuthState);
  const [filterState, setFilterState] =
    useState<TableFilterValues>(initialFilterState);
  const { year, month } = useSelector(getDatePickerState);
  const { isLoading, error, data } = useSelector(getPaymentsState);
  const { query, setQuery, results } = useSearch<Payment>(
    ["affiliateId", "contactName", "name"],
    data
  );

  const handleFilterChange = useCallback(
    (values: TableFilterValues) => {
      setFilterState(values);
      dispatch(closeDrawer());
    },
    [dispatch]
  );

  const handleClearFilter = useCallback(() => {
    setFilterState(initialFilterState);
  }, []);

  const handleFilterOpen = useCallback(() => {
    dispatch(
      openDrawer("table-filter", {
        initialState: filterState,
        handleSubmit: handleFilterChange
      })
    );
  }, [dispatch, filterState, handleFilterChange]);

  const handleConfirmPayment = useCallback(
    ({ affiliateId }: Payment) => {
      dispatch(
        openDialog({
          dialog: "confirm-payment",
          payload: {
            type: "submit",
            title: "Are you sure you want to confirm invoice?",
            subtitle: "This operation cannot be undone",
            onConfirm: () => dispatch(confirmPayment(affiliateId))
          }
        })
      );
    },
    [dispatch]
  );

  const handleMarkPayment = useCallback(
    ({ affiliateId, invoiceId }: Payment) => {
      dispatch(
        openDialog({
          dialog: "mark-payment",
          payload: {
            type: "submit",
            title: "Are you sure you want to mark invoice as paid?",
            subtitle: "This operation cannot be undone",
            onConfirm: () =>
              dispatch(markPayment(affiliateId, Number(invoiceId)))
          }
        })
      );
    },
    [dispatch]
  );

  const handleRowClick = useCallback(
    ({ affiliateId }: Payment) => {
      history.push(`/admin/payments/${year}/${month}/${affiliateId}`);
    },
    [history, year, month]
  );

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleCloseMonth = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "close-month",
        payload: {
          type: "submit",
          title: "Are you sure you want to close month?",
          subtitle: "This operation cannot be undone",
          onConfirm: () => dispatch(closeMonth())
        }
      })
    );
  }, [dispatch]);

  return {
    classes,
    error,
    isEmpty: isEmpty(data),
    isEmptyPayments: isEmpty(results),
    isFilterOn: !isEqual(initialFilterState, filterState),
    isLoading,
    year,
    month,
    roles,
    canCloseMonth,
    payments: filterArray<Payment, TableFilterValues>(results, filterState),
    searchQuery: query,
    handleRowClick,
    handleFilterOpen,
    handleClearFilter,
    handleSearchQuery,
    handleConfirmPayment,
    handleMarkPayment,
    handleCloseMonth
  };
}
