import { createStyles, makeStyles } from "@material-ui/core/styles";

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      padding: 0,
      minWidth: 700
    },
    button: {
      borderRadius: 17
    },
    details: {
      flex: 1,
      zIndex: 1,
      padding: 16,
      maxWidth: 380,
      boxShadow:
        "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)"
    },
    deal: {
      margin: 16
    },
    create: {
      display: "flex",
      paddingBottom: 32
    },
    created: {
      borderRight: `1px solid ${theme.colors.blacke0}`,
      padding: "0 16px"
    },
    createdBy: {
      paddingLeft: 16
    },
    divider: {
      marginLeft: -16,
      marginRight: -16
    },
    geo: {
      marginLeft: 16,
      marginTop: 32
    }
  })
);

export { useSkeletonStyles };
