import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px"
      }
    },
    table: {
      width: "100%",
      height: "calc(100vh - 196px)"
    },
    playerColumn: {
      maxWidth: 84
    },
    countryColumn: {
      maxWidth: 72
    },
    brandColumn: {
      minWidth: 84,
      maxWidth: 128
    },
    netRevenueColumn: {
      [theme.breakpoints.down("lg")]: {
        maxWidth: "73px"
      }
    },
    emptyData: {
      marginTop: 16
    }
  })
);

export { useStyles };
