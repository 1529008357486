import {
  AffiliatePayment,
  AffiliatePayments,
  AffiliatePaymentTotal
} from "@types";

import { Reducer } from "redux";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum PaymentsActionTypes {
  FETCH_PAYMENTS_LOADING = "@affiliate-payments/fetch-payments-loading",
  FETCH_PAYMENTS_SUCCESS = "@affiliate-payments/fetch-payments-success",
  FETCH_PAYMENTS_FAILURE = "@affiliate-payments/fetch-payments-failure"
}

interface FetchPaymentsIsLoadingAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_LOADING;
}

interface FetchPaymentsIsSuccessAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS;
  payload: AffiliatePayments;
}

interface FetchPaymentsIsFailureAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_FAILURE;
}

type AffiliatePaymentsActions =
  | FetchPaymentsIsLoadingAction
  | FetchPaymentsIsSuccessAction
  | FetchPaymentsIsFailureAction;

const fetchAffiliatePaymentsIsLoading = (): FetchPaymentsIsLoadingAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_LOADING
});

const fetchAffiliatePaymentsIsSuccess = (
  payments: AffiliatePayments
): FetchPaymentsIsSuccessAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS,
  payload: { ...payments }
});

const fetchAffiliatePaymentsIsFailure = (): FetchPaymentsIsFailureAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_FAILURE
});

const fetchAffiliateViewPayments =
  (): ThunkActionCreator<AffiliatePaymentsActions> => async dispatch => {
    dispatch(fetchAffiliatePaymentsIsLoading());
    try {
      const payments = await api.affiliate.payments.getPayments();
      dispatch(fetchAffiliatePaymentsIsSuccess(payments));
    } catch (err) {
      dispatch(fetchAffiliatePaymentsIsFailure());
    }
  };

interface AffiliatePaymentsState {
  error: boolean;
  isLoading: boolean;
  data: AffiliatePayment[];
  totals?: AffiliatePaymentTotal;
  total: number;
}

const initialState: AffiliatePaymentsState = {
  error: false,
  isLoading: false,
  data: [],
  total: 0,
  totals: undefined
};

const reducer: Reducer<AffiliatePaymentsState, AffiliatePaymentsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PaymentsActionTypes.FETCH_PAYMENTS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.payments.total,
        totals: action.payload.payments.totals,
        data: action.payload.payments.items.map(
          ({ transactionDate, ...rest }) => ({
            transactionDate: format(parseISO(transactionDate), "dd MMM yyyy"),
            ...rest
          })
        )
      };
    case PaymentsActionTypes.FETCH_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliatePaymentsState = (state: AppState) => state.affiliate.payments;

export { reducer, fetchAffiliateViewPayments, getAffiliatePaymentsState };
