import * as React from "react";

import "./styles/google-user.style.css";
import { getGoogleAccountFromCookie } from "../utils";

const GoogleUser: React.FC = () => {
  const user = getGoogleAccountFromCookie();

  if (user) {
    return (
      <div className="google-user">
        <div className="google-user__email">{user.email}</div>
        <img
          src={user.picture}
          alt="avatar-image"
          className="google-user__avatar"
        />
      </div>
    );
  }

  return null;
};

export { GoogleUser };
