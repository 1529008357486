export enum PAYMENT_STATUSES {
  paid = "Paid",
  confirmed = "Confirmed",
  blocked = "Nothing to confirm",
  unconfirmed = "Unconfirmed"
}

export const PAYMENT_STATUSES_OPTIONS = [
  { label: PAYMENT_STATUSES.confirmed, value: PAYMENT_STATUSES.confirmed },
  { label: PAYMENT_STATUSES.unconfirmed, value: PAYMENT_STATUSES.unconfirmed },
  { label: PAYMENT_STATUSES.blocked, value: PAYMENT_STATUSES.blocked },
  { label: PAYMENT_STATUSES.paid, value: PAYMENT_STATUSES.paid }
];

export enum PAYMENT_METHODS {
  bank = "banktransfer",
  skrill = "skrill",
  casino = "casinoaccount"
}

export enum PAYMENT_METHODS_NAMES {
  bank = "Bank Transfer",
  skrill = "Skrill",
  casino = "Casino Account"
}

export const PAYMENT_METHODS_OPTIONS = [
  { label: PAYMENT_METHODS_NAMES.bank, value: PAYMENT_METHODS.bank },
  { label: PAYMENT_METHODS_NAMES.skrill, value: PAYMENT_METHODS.skrill },
  { label: PAYMENT_METHODS_NAMES.casino, value: PAYMENT_METHODS.casino }
];

export const AFFILIATE_PAYMENT_METHODS_OPTIONS = [
  { label: PAYMENT_METHODS_NAMES.bank, value: PAYMENT_METHODS.bank },
  { label: PAYMENT_METHODS_NAMES.skrill, value: PAYMENT_METHODS.skrill }
];

export enum TRANSACTION_METHODS {
  commission = "Commission",
  cpa = "CPA",
  manual = "Manual",
  payment = "Payment",
  tax = "Tax"
}

export const TRANSACTION_METHODS_OPTIONS = [
  {
    label: TRANSACTION_METHODS.commission,
    value: TRANSACTION_METHODS.commission
  },
  { label: TRANSACTION_METHODS.cpa, value: TRANSACTION_METHODS.cpa },
  { label: TRANSACTION_METHODS.manual, value: TRANSACTION_METHODS.manual },
  { label: TRANSACTION_METHODS.payment, value: TRANSACTION_METHODS.payment }
];
