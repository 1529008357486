import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";

import { getDatePickerState, setDate } from "modules/datepicker";

interface Params {
  year: string;
  month: string;
}

export default function useUrlDateCheck() {
  const dispatch = useDispatch();
  const { year, month } = useParams<Params>();
  const { fullDate } = useSelector(getDatePickerState);

  useEffect(() => {
    const newDate = new Date(`${year}-${month}`);
    if (!isSameYear(newDate, fullDate) || !isSameMonth(newDate, fullDate)) {
      dispatch(setDate(newDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fullDate]);
}
