/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import cn from "classnames";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";

import { TableProps } from "./types";
import useTable from "./useTable";
import Total from "./components/Total";
import Header from "./components/Header";
import Content from "./components/Content";
import Subtotal from "./components/Subtotal";

const Component: FC<TableProps> = props => {
  const {
    data,
    total,
    totals,
    sortBy,
    sortDirection,
    classes,
    className,
    columns,
    summary,
    displayRows,
    checkedIds,
    checkboxProps,
    estimatedItemSize,
    handleSort,
    handleRowClick,
    handleRowCheck,
    handleAllRowsCheck
  } = useTable(props);

  return (
    <Box className={classes.tableWrapper}>
      <Table component="div" className={cn(classes.table, className)}>
        <Header
          sortBy={sortBy}
          columns={columns}
          handleSort={handleSort}
          sortDirection={sortDirection}
          checkboxProps={checkboxProps}
          handleAllRowsCheck={handleAllRowsCheck}
        />
        <Content
          data={data}
          columns={columns}
          checkedIds={checkedIds}
          displayRows={displayRows}
          estimatedItemSize={estimatedItemSize}
          handleRowClick={handleRowClick}
          handleRowCheck={handleRowCheck}
        />
        {data.length !== 0 && (
          <>
            {totals && (
              <Subtotal
                totals={totals}
                columns={columns}
                checkboxProps={checkboxProps}
                handleAllRowsCheck={handleAllRowsCheck}
              />
            )}
            <Total summary={summary} total={total} />
          </>
        )}
      </Table>
    </Box>
  );
};

export default Component;
