/* eslint-disable @typescript-eslint/no-explicit-any */
export default function <T, U>(
  array: T[],
  filters: { [P in keyof U]: U[P] }
): T[] {
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    return filterKeys.every(key => {
      if ((filters as any)[key] === "all") return true;
      return (item as any)[key] === (filters as any)[key];
    });
  });
}
