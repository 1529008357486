import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "24px",
      color: theme.colors.black21
    },
    link: {
      color: theme.colors.blue,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  })
);

const TermsContent: FC = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.text}>
        We have amended our T&amp;C's to add a few new terms including - high
        roller features and what is deemed to be a high roller, dormant account
        time frames.
        <br />
        We have also amended our date of payments for our affiliates, and deals
        including active account eligibility.
      </Typography>
      <a
        className={`${classes.text} ${classes.link}`}
        target="_blank"
        rel="noopener noreferrer"
        href="https://affmore.com/terms-and-conditions/"
      >
        Read full T&amp;C
      </a>
    </Box>
  );
};

export default TermsContent;
