import { ListAffiliate } from "@types";

import React, { FC, useState, ChangeEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useSearch } from "hooks";
import SelectedList from "components/selectable-list";
import { getAffiliatesState } from "modules/affiliates-sidebar";

export interface MovePlayersProps {
  handleSubmit: (affiliateId: number) => void;
}

const MovePlayers: FC<MovePlayersProps> = ({ handleSubmit }) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [selectedAffiliate, setSelectedAffiliate] = useState<number>();

  const { list } = useSelector(getAffiliatesState);

  const { query, setQuery, results } = useSearch<ListAffiliate>(
    ["affiliateName", "affiliateId"],
    list
  );

  const options = results.map(({ affiliateId, affiliateName }) => ({
    label: affiliateName,
    value: affiliateId,
    description: affiliateId
  }));

  const handleSearchReset = useCallback(() => setQuery(""), [setQuery]);
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value),
    [setQuery]
  );
  const handleChangeAffiliate = useCallback(
    (value: number) => {
      if (value !== selectedAffiliate) {
        setDisabled(false);
      }
      setSelectedAffiliate(value);
    },
    [selectedAffiliate]
  );

  return (
    <Box width="100%" height="100%" maxWidth={380} padding={2}>
      <Box className="drawer-header" marginBottom={2}>
        <Typography
          variant="h4"
          color="secondary"
          className="drawer-header_title"
        >
          move players
        </Typography>
        <Button
          color="secondary"
          disabled={disabled}
          onClick={() => handleSubmit(selectedAffiliate!)}
        >
          save changes
        </Button>
      </Box>
      <Input
        value={query}
        placeholder="Search affiliate by name or ID"
        onChange={handleSearch}
      />
      <Box
        margin={-2}
        marginTop={2}
        height="100%"
        maxHeight="calc(100% - 144px)"
      >
        <SelectedList
          options={options}
          selectedOption={selectedAffiliate}
          handleClear={handleSearchReset}
          handleChange={handleChangeAffiliate}
        />
      </Box>
    </Box>
  );
};

export default MovePlayers;
