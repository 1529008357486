import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

import { TableSkeleton } from "components/table";
import { TooltipCardSkeleton } from "components/cards";

import { useSkeletonStyles } from "./styles";

const AccountSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <>
      <TooltipCardSkeleton />
      <Box className={classes.methodBlock}>
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
      </Box>
      <Divider className={classes.divider} />
      <TooltipCardSkeleton />
      <Box className={classes.bankBlock}>
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
        <TooltipCardSkeleton />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.minPayment}>
        <TooltipCardSkeleton />
      </Box>
    </>
  );
};

const PaymentsSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <AccountSkeleton />
      <Divider className={classes.divider} />
      <Skeleton className={classes.commission} width={300} height={30} />
      <Box className={classes.searchBar}>
        <Skeleton width={400} height={30} />
        <Box className={classes.buttons}>
          <Skeleton className={classes.button} />
        </Box>
      </Box>
      <TableSkeleton rows={6} cells={6} />
    </Box>
  );
};

export { AccountSkeleton };
export default PaymentsSkeleton;
