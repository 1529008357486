import * as yup from "yup";

import { isPassword } from "utils/regexes";

const matchText =
  "Password must contain 8 characters, one uppercase, one lowercase, one number letter";

const validationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .matches(isPassword, matchText)
    .required("Password is required"),
  newPassword: yup
    .string()
    .matches(isPassword, matchText)
    .notOneOf(
      [yup.ref("oldPassword")],
      "Old password and new password are same"
    )
    .required("Password is required"),
  repeatNewPassword: yup
    .string()
    .matches(isPassword, matchText)
    .oneOf([yup.ref("newPassword")], "Passwords doesn't match")
    .required("Confirm password is required")
});

export { validationSchema };
