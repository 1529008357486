/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOption } from "@types";

import React, { FC } from "react";
import { useField } from "formik";

import Select, { CreatableSelect } from "components/select";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  isCreatable?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  rows?: number;
  value: any;
  options: SelectOption[];
  type?: string;
  className?: string;
}

const SelectField: FC<Props> = props => {
  const [field, meta, helpers] = useField(props);

  if (props.isCreatable) {
    return (
      <CreatableSelect
        {...field}
        {...props}
        inputId={`creatable-select-input_${field.name}`}
        createOptionPosition="first"
        TextFieldProps={{
          label: props.label,
          margin: "normal",
          InputLabelProps: {
            htmlFor: `creatable-select-input_${field.name}`,
            shrink: true
          }
        }}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error ? meta.error : undefined}
        onBlur={() => helpers.setTouched(true)}
        onChange={(option: any) =>
          helpers.setValue(option ? option.value : null)
        }
      />
    );
  }

  return (
    <Select
      {...field}
      {...props}
      inputId={`select-input_${field.name}`}
      TextFieldProps={{
        label: props.label,
        margin: "normal",
        InputLabelProps: {
          htmlFor: `select-input_${field.name}`,
          shrink: true
        }
      }}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : undefined}
      onBlur={() => helpers.setTouched(true)}
      onChange={(option: any) => helpers.setValue(option ? option.value : null)}
    />
  );
};

export default SelectField;
