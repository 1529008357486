import { Reducer } from "redux";
import {
  AffiliateActivities,
  AffiliateActivitiesTotals,
  AffiliateActivity
} from "@types";

import api from "api";
import { BRANDS } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";

enum AffiliateActivitiesActionTypes {
  FETCH_AFFILIATE_ACTIVITIES_LOADING = "@affiliate/activites/fetch-activities-loading",
  FETCH_AFFILIATE_ACTIVITIES_SUCCESS = "@affiliate/activities/fetch-activities-success",
  FETCH_AFFILIATE_ACTIVITIES_FAILURE = "@affiliate/activities/fetch-activities-failure",
  SET_AFFILIATE_ACTIVITIES_BRAND_FILTER = "@affiliate/activities/set-brand-filter"
}

interface FetchAffiliateActivitiesIsLoadingAction {
  type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_LOADING;
}

interface FetchAffiliateActivitiesIsSuccessAction {
  type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_SUCCESS;
  payload: AffiliateActivities;
}

interface FetchAffiliateActivitiesIsFailureAction {
  type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_FAILURE;
}

interface SetAffiliateActivitiesBrandFilterAction {
  type: AffiliateActivitiesActionTypes.SET_AFFILIATE_ACTIVITIES_BRAND_FILTER;
  payload: BRANDS | null;
}

type AffiliateActivitiesActions =
  | FetchAffiliateActivitiesIsLoadingAction
  | FetchAffiliateActivitiesIsSuccessAction
  | FetchAffiliateActivitiesIsFailureAction
  | SetAffiliateActivitiesBrandFilterAction;

type ActionsType = ThunkActionCreator<AffiliateActivitiesActions>;

const fetchAffiliateActivitiesIsLoading =
  (): FetchAffiliateActivitiesIsLoadingAction => ({
    type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_LOADING
  });

const fetchAffiliateActivitiesIsSuccess = (
  data: AffiliateActivities
): FetchAffiliateActivitiesIsSuccessAction => ({
  type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_SUCCESS,
  payload: data
});

const fetchAffiliateActivitiesIsFailure =
  (): FetchAffiliateActivitiesIsFailureAction => ({
    type: AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_FAILURE
  });

const fetchAffiliateActivities =
  (id: number, from: string, to: string, brandId: BRANDS | null): ActionsType =>
  async dispatch => {
    dispatch(fetchAffiliateActivitiesIsLoading());
    try {
      const { activities } = await api.admin.affiliate.getActivity(
        id,
        from,
        to,
        brandId
      );
      dispatch(fetchAffiliateActivitiesIsSuccess(activities));
    } catch (err) {
      dispatch(fetchAffiliateActivitiesIsFailure());
    }
  };

const fetchAffiliateViewActivities =
  (from: string, to: string, brandId: BRANDS | null): ActionsType =>
  async dispatch => {
    dispatch(fetchAffiliateActivitiesIsLoading());
    try {
      const { activities } = await api.affiliate.getActivity(from, to, brandId);
      dispatch(fetchAffiliateActivitiesIsSuccess(activities));
    } catch (err) {
      dispatch(fetchAffiliateActivitiesIsFailure());
    }
  };

const setAffiliateActivitiesBrandFilter = (
  brand: BRANDS | null
): SetAffiliateActivitiesBrandFilterAction => ({
  type: AffiliateActivitiesActionTypes.SET_AFFILIATE_ACTIVITIES_BRAND_FILTER,
  payload: brand
});

interface AffiliateActivitiesState {
  error: boolean;
  isLoading: boolean;
  brand: BRANDS | null;
  total: number;
  totals?: AffiliateActivitiesTotals;
  data: AffiliateActivity[];
}

const initialState: AffiliateActivitiesState = {
  error: false,
  isLoading: false,
  brand: null,
  total: 0,
  totals: undefined,
  data: []
};

const reducer: Reducer<AffiliateActivitiesState, AffiliateActivitiesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        total: action.payload.total,
        totals: action.payload.totals,
        data: action.payload.items
      };
    case AffiliateActivitiesActionTypes.FETCH_AFFILIATE_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false
      };
    case AffiliateActivitiesActionTypes.SET_AFFILIATE_ACTIVITIES_BRAND_FILTER:
      return {
        ...state,
        brand: action.payload
      };
    default:
      return state;
  }
};

const getAffiliateActivitiesState = (state: AppState) =>
  state.affiliate.activities;

export {
  reducer,
  fetchAffiliateViewActivities,
  fetchAffiliateActivities,
  setAffiliateActivitiesBrandFilter,
  getAffiliateActivitiesState
};
