import { CreateAffiliateLogRequest } from "@types";

import { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import flatten from "lodash/flatten";

import { fetchUsers } from "modules/users";

import { getAffiliateLogState, createAffiliateLogNote } from "./duck";
import { useStyles } from "./styles";

interface Params {
  id: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const [progress, setProgress] = useState<number>(0);
  const { isLoading, error, data } = useSelector(getAffiliateLogState);
  const sharedFiles = flatten(data.map(item => item.attachments));

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleCreateLog = useCallback(
    (
      values: CreateAffiliateLogRequest,
      helpers: FormikHelpers<CreateAffiliateLogRequest>
    ) => {
      dispatch(
        createAffiliateLogNote(Number(id), values, helpers, setProgress)
      );
    },
    [dispatch, id]
  );

  return {
    classes,
    error,
    isLoading,
    logs: data,
    files: sharedFiles,
    uploadProgress: progress,
    handleCreateLog
  };
}
