import React, { FC } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Drawer from "components/drawer";

import { closeDrawer, getDrawersState } from "./duck";
import AddSubAffiliate, { AddSubAffiliateProps } from "./add-subaffiliate";
import EditCommission, { EditCommissionProps } from "./edit-commission";
import AffiliateDetails, { AffiliateDetailsProps } from "./affiliate-details";
import ChangeDeal, { ChangeDealProps } from "./change-deal";
import MovePlayers, { MovePlayersProps } from "./move-players";
import CreateLanding, { CreateLandingProps } from "./create-landing";
import CreateLink, { CreateLinkProps } from "./create-link";
import CreatePlan, { CreatePlanProps } from "./create-plan";
import CreatePayment, { CreatePaymentProps } from "./create-payment";
import CreateUserLink, { CreateUserLinkProps } from "./create-user-link";
import EditAffiliate, { EditAffiliateProps } from "./edit-affiliate";
import EditPayment, { EditPaymentProps } from "./edit-payment";
import UserDealDetails, { UserDealDetailsProps } from "./user-deal-details";
import UserAffiliateFeeDetails, {
  UserAffiliateFeeDetailsProps
} from "./user-affiliate-fee-details";
import CreateSubAccount, { CreateSubAccountProps } from "./create-subaccount";
import Subaccounts, { SubaccountsProps } from "./subaccounts";
import TableFilter, { TableFilterProps } from "./table-filter";
import CreateCallback, { CreateCallbackProps } from "./create-callback";
import CreateFee, { CreateFeeProps } from "./create-fee";
import ChangeAffiliateFee, {
  ChangeAffiliateFeeProps
} from "./change-affiliate-fee";

const DrawerContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isOpen, drawer, payload } = useSelector(getDrawersState);

  const handleClose = () => {
    if (payload?.backURL) {
      history.push(payload.backURL);
    }
    dispatch(closeDrawer());
  };

  return (
    <Drawer open={isOpen} onOpen={() => null} onClose={handleClose}>
      {drawer === "edit-affiliate" && (
        <EditAffiliate {...(payload as EditAffiliateProps)} />
      )}
      {drawer === "change-deal" && (
        <ChangeDeal {...(payload as ChangeDealProps)} />
      )}
      {drawer === "move-players" && (
        <MovePlayers {...(payload as MovePlayersProps)} />
      )}
      {drawer === "user-deal-details" && (
        <UserDealDetails {...(payload as UserDealDetailsProps)} />
      )}
      {(drawer === "edit-link" || drawer === "create-link") && (
        <CreateLink {...(payload as CreateLinkProps)} />
      )}
      {(drawer === "edit-user-link" || drawer === "create-user-link") && (
        <CreateUserLink {...(payload as CreateUserLinkProps)} />
      )}
      {drawer === "create-payment" && (
        <CreatePayment {...(payload as CreatePaymentProps)} />
      )}
      {drawer === "edit-payment-information" && (
        <EditPayment {...(payload as EditPaymentProps)} />
      )}
      {(drawer === "create-landing" || drawer === "edit-landing") && (
        <CreateLanding {...(payload as CreateLandingProps)} />
      )}
      {(drawer === "create-plan" || drawer === "edit-plan") && (
        <CreatePlan {...(payload as CreatePlanProps)} />
      )}
      {drawer === "affiliate-details" && (
        <AffiliateDetails {...(payload as AffiliateDetailsProps)} />
      )}
      {drawer === "add-subaffiliate" && (
        <AddSubAffiliate {...(payload as AddSubAffiliateProps)} />
      )}
      {drawer === "edit-commission" && (
        <EditCommission {...(payload as EditCommissionProps)} />
      )}
      {drawer === "create-subaccount" && (
        <CreateSubAccount {...(payload as CreateSubAccountProps)} />
      )}
      {drawer === "subaccounts" && (
        <Subaccounts {...(payload as SubaccountsProps)} />
      )}
      {drawer === "table-filter" && (
        <TableFilter {...(payload as TableFilterProps)} />
      )}
      {(drawer === "create-callback" || drawer === "edit-callback") && (
        <CreateCallback {...(payload as CreateCallbackProps)} />
      )}
      {(drawer === "create-fee" || drawer === "edit-fee") && (
        <CreateFee {...(payload as CreateFeeProps)} />
      )}
      {drawer === "change-affiliate-fee" && (
        <ChangeAffiliateFee {...(payload as ChangeAffiliateFeeProps)} />
      )}
      {drawer === "user-affiliate-fee-details" && (
        <UserAffiliateFeeDetails
          {...(payload as UserAffiliateFeeDetailsProps)}
        />
      )}
    </Drawer>
  );
};

export default DrawerContainer;
