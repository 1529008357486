import { AffiliateOverview, DateRequest, RequestOptionsByDate } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum OverviewActionTypes {
  FETCH_OVERVIEW_LOADING = "@affiliate-overview/fetch-overview-loading",
  FETCH_OVERVIEW_SUCCESS = "@affiliate-overview/fetch-overview-success",
  FETCH_OVERVIEW_FAILURE = "@affiliate-overview/fetch-overview-failure"
}

interface FetchOverviewIsLoadingAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_LOADING;
}

interface FetchOverviewIsSuccessAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_SUCCESS;
  payload: AffiliateOverview;
}

interface FetchOverviewIsFailureAction {
  type: OverviewActionTypes.FETCH_OVERVIEW_FAILURE;
}

type AffiliateOverviewActions =
  | FetchOverviewIsLoadingAction
  | FetchOverviewIsSuccessAction
  | FetchOverviewIsFailureAction;

const fetchAffiliateOverviewIsLoading = (): FetchOverviewIsLoadingAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_LOADING
});

const fetchAffiliateOverviewIsSuccess = (
  overview: AffiliateOverview
): FetchOverviewIsSuccessAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_SUCCESS,
  payload: overview
});

const fetchAffiliateOverviewIsFailure = (): FetchOverviewIsFailureAction => ({
  type: OverviewActionTypes.FETCH_OVERVIEW_FAILURE
});

const fetchAffiliateOverview =
  (
    options: RequestOptionsByDate
  ): ThunkActionCreator<AffiliateOverviewActions> =>
  async dispatch => {
    dispatch(fetchAffiliateOverviewIsLoading());
    try {
      const overview = await api.admin.affiliate.getOverview(options);
      dispatch(fetchAffiliateOverviewIsSuccess(overview));
    } catch (err) {
      dispatch(fetchAffiliateOverviewIsFailure());
    }
  };

const fetchUserOverview =
  (options: DateRequest): ThunkActionCreator<AffiliateOverviewActions> =>
  async dispatch => {
    dispatch(fetchAffiliateOverviewIsLoading());
    try {
      const overview = await api.affiliate.getOverview(options);
      dispatch(fetchAffiliateOverviewIsSuccess(overview));
    } catch (err) {
      dispatch(fetchAffiliateOverviewIsFailure());
    }
  };

interface AffiliateOverviewState {
  error: boolean;
  isLoading: boolean;
  data?: AffiliateOverview;
}

const initialState: AffiliateOverviewState = {
  error: false,
  isLoading: false,
  data: undefined
};

const reducer: Reducer<AffiliateOverviewState, AffiliateOverviewActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OverviewActionTypes.FETCH_OVERVIEW_LOADING:
      return {
        ...state,
        error: false,
        isLoading: true
      };
    case OverviewActionTypes.FETCH_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case OverviewActionTypes.FETCH_OVERVIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    default:
      return state;
  }
};

const getAffiliateOverviewState = (state: AppState) => state.affiliate.overview;

export {
  reducer,
  fetchAffiliateOverview,
  fetchUserOverview,
  getAffiliateOverviewState
};
