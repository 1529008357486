import { AffiliatePlayersRevenue } from "@types";

import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  generatePath,
  RouteComponentProps
} from "react-router-dom";

import { PlayerDetails } from "modules/affiliate-player";
import { PlayerDeal, updateAffiliatePlayerDeal } from "modules/affiliate-deal";
import AffiliatePlayers, {
  fetchAffiliatePlayers,
  getAffiliatePlayersState
} from "modules/affiliate-players";
import Drawer from "components/drawer";

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

const AffiliatePlayersPage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { id } = match.params;

  const { brand } = useSelector(getAffiliatePlayersState);

  const handlePlayerClick = useCallback(
    ({ playerId }: AffiliatePlayersRevenue) => {
      history.push(`${match.url}/${playerId}`);
    },
    [history, match]
  );

  const handleChangeDealSubmit = useCallback(
    async (planId: number, playerId: number, pathname: string) => {
      const path = generatePath(pathname, {
        id,
        playerId,
        dealId: planId
      });

      await dispatch(updateAffiliatePlayerDeal(Number(id), playerId, planId));
      dispatch(fetchAffiliatePlayers(Number(id), brand));
      history.replace(path);
    },
    [dispatch, history, brand, id]
  );

  return (
    <>
      <AffiliatePlayers handleRowClick={handlePlayerClick} />
      <Drawer
        open={!match.isExact}
        onOpen={() => null}
        onClose={() => history.push(match.url)}
      >
        <Switch>
          <Route
            exact
            path={`${match.path}/:playerId`}
            component={PlayerDetails}
          />
          <Route
            exact
            path={`${match.path}/:playerId/deals/:dealId`}
            render={props => (
              <PlayerDeal onSubmit={handleChangeDealSubmit} {...props} />
            )}
          />
        </Switch>
      </Drawer>
    </>
  );
};

export default AffiliatePlayersPage;
