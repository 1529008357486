import { User } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

enum UsersActionTypes {
  FETCH_USERS_LOADING = "@users/fetch-users-loading",
  FETCH_USERS_SUCCESS = "@users/fetch-users-success",
  FETCH_USERS_FAILURE = "@users/fetch-users-failure"
}

interface FetchUsersIsLoadingAction {
  type: UsersActionTypes.FETCH_USERS_LOADING;
}

interface FetchUsersIsSuccessAction {
  type: UsersActionTypes.FETCH_USERS_SUCCESS;
  payload: { users: User[] };
}

interface FetchUsersIsFailureAction {
  type: UsersActionTypes.FETCH_USERS_FAILURE;
}

type UsersActions =
  | FetchUsersIsLoadingAction
  | FetchUsersIsSuccessAction
  | FetchUsersIsFailureAction;

const fetchUsersIsLoading = (): FetchUsersIsLoadingAction => ({
  type: UsersActionTypes.FETCH_USERS_LOADING
});

const fetchUsersIsSuccess = (users: User[]): FetchUsersIsSuccessAction => ({
  type: UsersActionTypes.FETCH_USERS_SUCCESS,
  payload: { users }
});

const fetchUsersIsFailure = (): FetchUsersIsFailureAction => ({
  type: UsersActionTypes.FETCH_USERS_FAILURE
});

const fetchUsers = (): ThunkActionCreator<UsersActions> => async dispatch => {
  dispatch(fetchUsersIsLoading());
  try {
    const { users } = await api.admin.users.getUsers();
    dispatch(fetchUsersIsSuccess(users));
  } catch (err) {
    dispatch(fetchUsersIsFailure());
  }
};

interface UsersState {
  error: boolean;
  isLoading: boolean;
  data: User[];
}

const initialState: UsersState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<UsersState, UsersActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: false
      };
    case UsersActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.users,
        error: false
      };
    case UsersActionTypes.FETCH_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };

    default:
      return state;
  }
};

const getUsersState = (state: AppState) => state.users;
const getUsersForSelect = (state: AppState) =>
  state.users.data.map(({ userId, email }) => ({
    label: email,
    value: userId
  }));
const getUserById = (state: AppState) => (id: number) => ({
  isLoading: state.users.isLoading,
  user: state.users.data.find(user => user.userId === id)
});

export { reducer, fetchUsers, getUsersState, getUserById, getUsersForSelect };
