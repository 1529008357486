import { createStyles, makeStyles } from "@material-ui/core/styles";

const useProfileStyles = makeStyles(theme =>
  createStyles({
    profile: {
      display: "flex",
      flexDirection: "column",
      marginTop: 32,
      marginBottom: 24
    },
    masterProfile: {
      display: "flex",
      flexDirection: "column",
      margin: "16px -16px 8px -16px",
      alignItems: "flex-start",
      borderRadius: 8,
      padding: 16,
      transition: "background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        background: theme.colors.blackf5
      }
    },
    profileId: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black75
    },
    subtitle: {
      color: theme.colors.black9e,
      textTransform: "none",
      marginTop: 2
    },
    profileName: {
      fontWeight: 500
    }
  })
);

const useMenuStyles = makeStyles(theme =>
  createStyles({
    menu: {
      display: "flex",
      flexDirection: "column"
    },
    link: {
      marginTop: 8,
      cursor: "pointer",
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black75,
      textDecoration: "none"
    },
    activeLink: {
      fontWeight: 500,
      color: theme.colors.blue
    },
    logoutLink: {
      cursor: "pointer",
      marginTop: 16,
      color: theme.colors.blackbd,
      "&:hover": {
        color: theme.colors.blue
      }
    },
    logoutLinkSubmit: {
      cursor: "pointer",
      marginTop: 16,
      fontWeight: 500,
      color: theme.colors.red,
      "&:hover": {
        color: theme.colors.black21
      }
    },
    logoutLinkDisabled: {
      pointerEvents: "none",
      color: theme.colors.blackbd
    }
  })
);

const useStyles = makeStyles(theme =>
  createStyles({
    sidebar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: 320,
      height: "100%",
      padding: "16px 32px"
    },
    header: {},
    footer: {},
    submitLogout: {
      cursor: "pointer",
      marginTop: 16,
      fontWeight: 500,
      color: theme.colors.red,
      "&:hover": {
        color: theme.colors.black21
      }
    },
    stopViewModeBtn: {
      margin: "16px 0",
      background: theme.colors.black21,
      color: theme.colors.white,
      "&:hover": {
        color: theme.colors.black21,
        background: theme.colors.white,
        borderColor: theme.colors.black21
      }
    },
    userBalance: {
      marginTop: 16,
      maxWidth: "100%"
    },
    disabled: {
      pointerEvents: "none",
      color: theme.colors.blackbd
    }
  })
);

const useMobileStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: theme.colors.white,
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.16)",
      height: 48
    },
    iconButton: {
      padding: 8,
      color: theme.colors.black21
    },
    drawerPaper: {
      display: "flex",
      justifyContent: "space-between",
      minWidth: 320,
      background: theme.colors.blackfa
    },
    drawerContent: {
      padding: "0px 16px"
    },
    drawerContentHeader: {
      display: "flex",
      alignItems: "center",
      padding: 4
    },
    drawerContentFooter: {
      padding: 16
    },
    logo: {
      display: "flex",
      marginLeft: 8
    },
    stopViewModeBtn: {
      margin: "16px 0",
      background: theme.colors.black21,
      color: theme.colors.white,
      "&:hover": {
        color: theme.colors.black21,
        background: theme.colors.white,
        borderColor: theme.colors.black21
      }
    },
    userBalance: {
      maxWidth: "100%"
    }
  })
);

export { useStyles, useMobileStyles, useProfileStyles, useMenuStyles };
