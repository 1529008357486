/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreatePlanRuleValue, CreatePlanValues, SelectOption } from "@types";

import React, { FC, useCallback } from "react";
import { getIn, FormikErrors, FormikTouched } from "formik";
import uniqBy from "lodash/uniqBy";
import differenceBy from "lodash/differenceBy";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/CloseOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbolRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import { isPercent } from "utils/regexes";
import {
  allCountriesOption,
  countriesValues,
  isAllCountriesSelected
} from "constants/countries";
import useAnchor from "hooks/useAnchor";
import Select from "components/select";

import CardField from "./CardField";
import { initialCountries, ruleNrsOptions } from "../helpers";
import { useStyles } from "../styles";

interface Props {
  id: number;
  rule: CreatePlanRuleValue;
  countries: SelectOption[];
  errors: FormikErrors<CreatePlanValues>;
  touched: FormikTouched<CreatePlanValues>;
  handleFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void;
  handleRemoveCard: () => void;
  handleCountries: (countries: SelectOption[]) => void;
}

const Card: FC<Props> = ({
  id,
  rule,
  countries,
  errors: formikErrors,
  touched: formikTouched,
  handleFieldValue,
  handleCountries,
  handleRemoveCard
}) => {
  const classes = useStyles();
  const errors = getIn(formikErrors, `rules.${id}`);
  const touched = getIn(formikTouched, `rules.${id}`);

  const { anchorEl, handleOpenPopup, handleClosePopup } = useAnchor();
  const selectedValues = initialCountries.filter(({ value }) =>
    rule?.countryIds?.some(id => value === id)
  );

  const handleChange = useCallback(
    (options: any) => {
      const values = options?.map(({ value }: SelectOption) => value);

      if (options?.includes(allCountriesOption)) {
        const newRule = {
          ...rule,
          countryIds: countriesValues
        };
        handleCountries([]);
        handleFieldValue("rules", [newRule]);
      } else {
        handleCountries(differenceBy(initialCountries, options));
        handleFieldValue(`rules.${id}.countryIds`, values);
      }
    },
    [handleCountries, handleFieldValue, id, rule]
  );

  const handleRemove = useCallback(() => {
    const nextCountries = selectedValues?.includes(allCountriesOption)
      ? initialCountries
      : [allCountriesOption, ...countries, ...selectedValues];
    handleCountries(uniqBy(nextCountries, "value"));
    handleRemoveCard();
  }, [countries, handleCountries, handleRemoveCard, selectedValues]);

  return (
    <Paper className={classes.planCard}>
      <Box className={classes.planCardHeader}>
        <Select
          isMulti
          name={`rules.${id}.countryIds`}
          className={classes.countrySelector}
          placeholder="Add country restrictions"
          value={
            isAllCountriesSelected(rule?.countryIds)
              ? [allCountriesOption]
              : selectedValues
          }
          options={countries}
          onChange={handleChange}
        />
        <IconButton className={classes.iconButton} onClick={handleRemove}>
          <RemoveIcon />
        </IconButton>
      </Box>
      <Box className={classes.rules}>
        <Box className={classes.rule}>
          <Typography className={classes.ruleTitle}>
            Net Revenue Share:*
          </Typography>
          <CardField
            name={`rules.${id}.nrs`}
            pattern={isPercent}
            className={classes.select}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className={classes.startAdornment}>%</span>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.endAdornment}
                >
                  <IconButton
                    className={classes.nrsDropdown}
                    onClick={event => handleOpenPopup(event)}
                  >
                    <ExpandMoreRoundedIcon className={classes.dropdownIcon} />
                  </IconButton>
                  <Menu
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleClosePopup}
                  >
                    {ruleNrsOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleFieldValue(`rules.${id}.nrs`, option.value);
                          handleClosePopup();
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box className={classes.rule}>
          <Typography className={classes.ruleTitle}>
            Cost per Aquistion:*
          </Typography>
          <CardField
            name={`rules.${id}.cpa`}
            className={`${classes.smallInput} ${classes.cpaInput}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EuroSymbolIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box className={classes.rule}>
          <Box className={classes.deposit}>
            <CardField
              name={`rules.${id}.deposit`}
              className={classes.smallInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroSymbolIcon />
                  </InputAdornment>
                )
              }}
            />
            <Typography className={classes.ruleTitle}>
              {`Cumulative Deposit:`}
            </Typography>
          </Box>
          <CardField
            name={`rules.${id}.deposit_cpa`}
            className={classes.smallInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EuroSymbolIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {touched && errors && (
          <Box className={classes.cardError}>
            {errors.countryIds && (
              <Typography className={classes.cardErrorText}>
                {errors.countryIds}
              </Typography>
            )}
            {touched.nrs && errors.nrs && (
              <Typography className={classes.cardErrorText}>
                {errors.nrs}
              </Typography>
            )}
            {touched.cpa && errors.cpa && (
              <Typography className={classes.cardErrorText}>
                {errors.cpa}
              </Typography>
            )}
            {touched.deposit && errors.deposit && (
              <Typography className={classes.cardErrorText}>
                {errors.deposit}
              </Typography>
            )}
            {touched.deposit_cpa && errors.deposit_cpa && (
              <Typography className={classes.cardErrorText}>
                {errors.deposit_cpa}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default Card;
