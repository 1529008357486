import { AffiliateInvoice } from "@types";

import React, { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import Search from "components/search";
import { DownloadCsvButton } from "components/button";
import EmptyData from "components/empty-data";
import Table, { Column } from "components/table";
import { getAffiliateInvoicesState } from "modules/affiliate-invoices";

import InvoicesTableSkeleton from "./Skeleton";
import { useStyles } from "../../styles";

const CSV_HEADERS = [
  { label: "ID", key: "invoiceId" },
  { label: "Date", key: "invoiceDate" },
  { label: "Invoice Number", key: "invoiceNumber" },
  { label: "Credited", key: "creditedAmount" },
  { label: "Paid", key: "paidAmount" },
  { label: "Status", key: "status" }
];

interface Props {
  handleRowClick?: (invoice: AffiliateInvoice) => void;
}

const InvoicesTable: FC<Props> = ({ handleRowClick }) => {
  const classes = useStyles();
  const {
    isLoading,
    invoices: { items }
  } = useSelector(getAffiliateInvoicesState);

  const { query, setQuery, results } = useSearch<AffiliateInvoice>(
    ["invoiceId", "invoiceNumber"],
    items
  );

  const disabled = isEmpty(items);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  if (isLoading) {
    return <InvoicesTableSkeleton />;
  }

  return (
    <Box marginTop={4}>
      <Typography className={classes.title} variant="h3">
        Invoices
      </Typography>
      <Search
        value={query}
        onChange={handleSearch}
        placeholder="Search invoice"
        disabled={disabled}
        buttons={
          <DownloadCsvButton
            headers={CSV_HEADERS}
            data={results}
            disabled={isEmpty(results)}
          />
        }
      />
      {disabled ? (
        <EmptyData margin="16px 0" />
      ) : (
        <Table
          initialData={results}
          displayRows={4}
          handleRowClick={handleRowClick}
        >
          <Column
            label="ID"
            name="invoiceId"
            align="left"
            type="text"
            className={classes.idColumn}
          />
          <Column
            label="Date"
            name="invoiceDate"
            align="left"
            type="date"
            className={classes.dateColumn}
          />
          <Column
            label="Invoice Number"
            name="invoiceNumber"
            align="left"
            type="text"
          />
          <Column
            label="Credited"
            name="creditedAmount"
            align="right"
            type="currency"
            className={classes.amountColumn}
          />
          <Column
            label="Paid"
            name="paidAmount"
            comparedName="creditedAmount"
            align="left"
            type="currency"
            className={classes.paidColumn}
          />
          <Column
            label="Status"
            name="status"
            type="status"
            className={classes.statusColumn}
          />
        </Table>
      )}
    </Box>
  );
};

export default InvoicesTable;
