import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

import Table, { Column, DropdownColumn } from "components/table";
import Search from "components/search";
import EmptyData from "components/empty-data";

import SearchButtons from "./components/SearchButtons";
import Skeleton from "./Skeleton";
import useFees from "./useFees";

// TODO 456: Maybe we can show column with countries fee has rules for, or '*' if it has no specific rules
const CSV_HEADERS = [
  { label: "Name", key: "name" },
  { label: "Percent", key: "percent" }
];

const Component: FC = () => {
  const {
    classes,
    error,
    isLoading,
    isEmpty,
    isEmptySearch,
    fees,
    searchQuery,
    handleSearchQuery,
    handleRowClick,
    handleDeleteFee,
    handleCreateDrawer
  } = useFees();

  if (error || !fees) {
    return <EmptyData margin={2} />;
  }

  return (
    <>
      <Search
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search fee by Name"
        disabled={isEmpty}
        buttons={
          <SearchButtons
            csvProps={{
              headers: CSV_HEADERS,
              data: fees,
              disabled: isEmptySearch
            }}
            handleCreateFee={handleCreateDrawer}
          />
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Table
            initialData={fees}
            estimatedItemSize={49}
            handleRowClick={handleRowClick}
          >
            <Column
              label="Name"
              name="name"
              align="left"
              variant="h6"
              type="text"
              className={classes.nameColumn}
            />
            <Column
              label="Running"
              name="isRunning"
              type="boolean"
              className={classes.boldColumn}
            />
            <Column
              label="Fee"
              name="percent"
              type="feePercent"
              className={classes.boldColumn}
            />
            <Column label="Active" name="active" type="boolean" />
            <Column
              label="Next month fee"
              name="nextMonthPercent"
              type="feePercent"
              className={classes.boldColumn}
            />
            <Column
              label="Next month active"
              name="nextMonthActive"
              type="boolean"
            />
            <DropdownColumn name="dropdown" className={classes.iconColumn}>
              <MenuItem onClick={handleDeleteFee}>Remove</MenuItem>
            </DropdownColumn>
          </Table>
        )}
      </Box>
    </>
  );
};

export default Component;
