import { DisplayAffiliate, ListAffiliate } from "@types";

import { intersection, isEmpty } from "lodash";

const getFormattedList = (
  lastUsageAffiliates: ListAffiliate[],
  filteredAffiliates: ListAffiliate[]
): DisplayAffiliate[] => {
  const affiliatesIntersection = intersection(
    lastUsageAffiliates,
    filteredAffiliates
  );

  if (isEmpty(filteredAffiliates)) {
    return filteredAffiliates;
  }

  const affiliatesWithTitle = [
    { affiliateId: "title", affiliateName: "Affiliates" },
    ...filteredAffiliates
  ];

  if (!isEmpty(affiliatesIntersection)) {
    const lastUsageAffiliatesWithTitle = [
      { affiliateId: "title", affiliateName: "Latest" },
      ...affiliatesIntersection.map(({ affiliateId, affiliateName }) => ({
        affiliateId,
        affiliateName,
        lastUsage: true
      }))
    ];

    return [...lastUsageAffiliatesWithTitle, ...affiliatesWithTitle];
  }

  return [...affiliatesWithTitle];
};

export default getFormattedList;
