import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { useRoles } from "hooks";
import PublicRoute from "components/public-route";
import { getDatePickerState } from "modules/datepicker";

import ForgotPassword from "pages/affiliate-view/ForgotPassword";
import AffiliateLogin from "pages/affiliate-view/Login";
import NotFound from "pages/NotFound";
import GooglePage from "pages/admin/GoogleLogin";

import AdminRoutes from "./admin.routes";
import AffiliateRoutes from "./affiliate-view.routes";

const Routes = () => {
  const { year, month } = useSelector(getDatePickerState);
  const { isLoggedin, isAdminLoggedIn, isUserLoggedIn } = useRoles();

  return (
    <Switch>
      {!isLoggedin && (
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route exact path="/login" component={AffiliateLogin} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Redirect exact from="/admin" to="/admin/login" />
          <Route exact path="/admin/login" component={GooglePage} />
          <Route path="*" component={NotFound} />
        </Switch>
      )}
      {isAdminLoggedIn && (
        <>
          <PublicRoute
            exact
            path={["/", "/admin", "/admin/login"]}
            redirectPath="/admin/affiliates"
            component={GooglePage}
          />
          <AdminRoutes />
        </>
      )}
      {isUserLoggedIn && (
        <>
          <PublicRoute
            exact
            path={["/", "/login"]}
            redirectPath={`/overview/${year}/${month}`}
            component={AffiliateLogin}
          />
          <PublicRoute
            exact
            path="/forgot"
            redirectPath={`/overview/${year}/${month}`}
            component={ForgotPassword}
          />
          <AffiliateRoutes />
        </>
      )}
    </Switch>
  );
};

export default Routes;
