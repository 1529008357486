import { Landing } from "@types";

import React, { FC } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import { BRANDS, BRAND_NAMES } from "constants/brands";
import Table, { Column, DropdownColumn } from "components/table";
import getBrandColorClassName from "utils/getBrandColorClassName";

interface Props {
  brandId: BRANDS;
  landings: Landing[];
  isExpanded: boolean;
  handleExpand: (brandId: BRANDS) => void;
  handleRemoveLanding: (
    event: React.MouseEvent<HTMLElement>
  ) => (landing: Landing) => void;
  handleRowClick: (landing: Landing) => void;
}

const ExpandedTable: FC<Props> = ({
  brandId,
  landings,
  isExpanded,
  handleExpand,
  handleRowClick,
  handleRemoveLanding
}) => (
  <Accordion expanded={isExpanded} onChange={() => handleExpand(brandId)}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h3" className={getBrandColorClassName(brandId)}>
        {BRAND_NAMES[brandId]}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Table
        initialData={landings}
        displayRows={7}
        estimatedItemSize={49}
        handleRowClick={handleRowClick}
      >
        <Column
          label="Landing"
          name="landingPage"
          align="left"
          type="text"
          style={{ fontWeight: 500 }}
        />
        <Column label="Usages" name="usages" type="text" />
        <DropdownColumn name="dropdown" style={{ maxWidth: 64 }}>
          <MenuItem onClick={handleRemoveLanding}>Remove</MenuItem>
        </DropdownColumn>
      </Table>
    </AccordionDetails>
  </Accordion>
);

export default ExpandedTable;
