import React, { FC } from "react";
import Box from "@material-ui/core/Box";

import TextField from "components/text-field";

const BankForm: FC = () => (
  <Box>
    <TextField
      label="Account Holder Name"
      name="paymentMethodDetails.bankAccountHolder"
    />
    <TextField label="IBAN" name="paymentMethodDetails.bankIban" />
    <TextField label="BIC/SWIFT" name="paymentMethodDetails.bankBic" />
    <TextField label="Bank Name" name="paymentMethodDetails.bankName" />
    <TextField
      label="Bank Clearing Number"
      name="paymentMethodDetails.bankClearingNumber"
    />
    <TextField label="Bank Address" name="paymentMethodDetails.bankAddress" />
    <TextField
      label="Bank Post Code"
      name="paymentMethodDetails.bankPostCode"
    />
    <TextField label="Bank Country" name="paymentMethodDetails.bankCountry" />
  </Box>
);

const SkrillForm: FC = () => (
  <TextField label="Skrill Account" name="paymentMethodDetails.skrillAccount" />
);

const CasinoForm: FC = () => (
  <TextField
    label="Casino Account Email"
    name="paymentMethodDetails.casinoAccountEmail"
  />
);

export { BankForm, SkrillForm, CasinoForm };
