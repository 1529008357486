import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import omit from "lodash/omit";

import { openDrawer, closeDrawer, getDrawersState } from "drawers";
import { BRANDS } from "constants/brands";
import { getAffiliateDealStateById } from "modules/affiliate-deals";
import { updateAffiliateDeal } from "modules/affiliate-deal";

interface MatchParams {
  id: string;
  year: string;
  month: string;
  brandId: BRANDS;
}

export default function () {
  const dispatch = useDispatch();
  const { id, brandId } = useParams<MatchParams>();

  const { isLoading, error, deal } = useSelector(getAffiliateDealStateById)(
    brandId
  );
  const { isOpen } = useSelector(getDrawersState);

  useEffect(() => {
    return () => {
      isOpen && dispatch(closeDrawer());
    };
  }, [dispatch, isOpen]);

  const handleChangeDealSubmit = useCallback(
    (planId: number) => {
      const data = {
        planId,
        brandId,
        affiliateId: Number(id)
      };
      dispatch(updateAffiliateDeal(data));
    },
    [dispatch, id, brandId]
  );

  const handleChangeDealOpen = useCallback(() => {
    if (deal) {
      dispatch(
        openDrawer("change-deal", {
          initialPlanId: deal.planId,
          handleSubmit: handleChangeDealSubmit
        })
      );
    }
  }, [dispatch, deal, handleChangeDealSubmit]);

  return {
    error,
    deal: omit(deal, ["rules", "brandId"]),
    rules: deal ? deal.rules : [],
    isLoading,
    brand: brandId as BRANDS,
    handleChangeDealOpen
  };
}
