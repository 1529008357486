import React, { FC } from "react";

import Sidebar from "./components/Sidebar";
import MobileSidebar from "./components/MobileSidebar";
import useAffiliateSidebar from "./useAffiliateSidebar";

const UserSidebar: FC = () => {
  const {
    isEmpty,
    isViewMode,
    handleLogout,
    handleStopAdminViewMode,
    handleStopAffiliateViewMode
  } = useAffiliateSidebar();

  return (
    <>
      <Sidebar
        isViewMode={isViewMode}
        showSubs={!isEmpty}
        handleLogout={handleLogout}
        handleStopAdminViewMode={handleStopAdminViewMode}
        handleStopAffiliateViewMode={handleStopAffiliateViewMode}
      />
      <MobileSidebar
        isViewMode={isViewMode}
        showSubs={!isEmpty}
        handleLogout={handleLogout}
        handleStopAffiliateViewMode={handleStopAffiliateViewMode}
      />
    </>
  );
};

export default UserSidebar;
