import { CreateAffiliateCallbackRequest } from "@types";

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {
  CALLBACK_METHODS,
  CALLBACK_TRIGGERS,
  CALLBACK_METHODS_OPTIONS,
  CALLBACK_TRIGGERS_OPTIONS
} from "constants/callbacks";
import { BRANDS, BRAND_SELECT_OPTIONS } from "constants/brands";
import SubmitButton from "components/submit-button";
import TextField from "components/text-field";
import SelectField from "components/select-field";
import CheckboxField from "components/checkbox-field";
import { TextCard } from "components/cards";
import {
  fetchAffiliateLinks,
  getAffiliateLinksSelectOptions
} from "modules/affiliate-links";

import Hints from "./components/Hints";
import validationSchema from "./validationSchema";

export interface CreateCallbackProps {
  title: string;
  affiliateId: number;
  initialState?: CreateAffiliateCallbackRequest;
  handleSubmit: (
    values: CreateAffiliateCallbackRequest,
    helpers: FormikHelpers<CreateAffiliateCallbackRequest>
  ) => void;
}

const initialValues = {
  callback: {
    affiliateId: null,
    linkId: null,
    brandId: BRANDS.LD,
    method: CALLBACK_METHODS.GET,
    trigger: CALLBACK_TRIGGERS.NRC,
    url: "",
    enabled: true
  }
};

const CreateCallback: FC<CreateCallbackProps> = ({
  title,
  affiliateId,
  initialState,
  handleSubmit
}) => {
  const dispatch = useDispatch();
  const linksOptions = useSelector(getAffiliateLinksSelectOptions);

  useEffect(() => {
    dispatch(fetchAffiliateLinks(affiliateId, null));
  }, [dispatch, affiliateId]);

  const getBrandValue = (value: string) =>
    BRAND_SELECT_OPTIONS.find(brand => brand.value === value);

  const getLinkValue = (value?: number | null) =>
    value ? linksOptions.find(link => link.value === value) : null;

  const getMethodValue = (value: string) =>
    CALLBACK_METHODS_OPTIONS.find(method => method.value === value);

  const getTriggerValue = (value: string) =>
    CALLBACK_TRIGGERS_OPTIONS.find(trigger => trigger.value === value);

  return (
    <Formik
      initialValues={
        initialState
          ? initialState
          : { callback: { ...initialValues.callback, affiliateId } }
      }
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit }) => (
        <form className="drawer-container" onSubmit={handleSubmit}>
          <Box className="drawer-header" margin="0 -8px" paddingBottom={0}>
            <Typography variant="h4" className="drawer-header_title">
              {title}
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <TextCard label="Affiliate ID">
                <Typography component="div" variant="h4">
                  {affiliateId}
                </Typography>
              </TextCard>
              <CheckboxField
                label="Active"
                labelPlacement="start"
                name="callback.enabled"
              />
            </Box>
            <SelectField
              name="callback.brandId"
              label="Brand"
              placeholder="Select brand"
              isSearchable={false}
              options={BRAND_SELECT_OPTIONS}
              value={getBrandValue(values.callback.brandId)}
            />
            <SelectField
              isSearchable
              isClearable
              name="callback.linkId"
              label="Link"
              placeholder="Select link"
              options={linksOptions}
              value={getLinkValue(values.callback.linkId)}
            />
            <SelectField
              name="callback.method"
              label="Method"
              placeholder="Select method"
              isSearchable={false}
              options={CALLBACK_METHODS_OPTIONS}
              value={getMethodValue(values.callback.method)}
            />
            <SelectField
              name="callback.trigger"
              label="Trigger"
              placeholder="Select trigger"
              isSearchable={false}
              options={CALLBACK_TRIGGERS_OPTIONS}
              value={getTriggerValue(values.callback.trigger)}
            />
            <TextField multiline name="callback.url" label="URL" />
          </Box>
          <Hints />
        </form>
      )}
    </Formik>
  );
};

export default CreateCallback;
