import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import Logo from "assets/icons/Logo";
import UserBalance from "components/user-balance";
import { getDatePickerState } from "modules/datepicker";

import Menu from "./Menu";
import Profile from "./Profile";
import { useStyles } from "../styles";

interface Props {
  isViewMode: boolean;
  showSubs: boolean;
  handleLogout: () => void;
  handleStopAdminViewMode?: () => void;
  handleStopAffiliateViewMode?: () => void;
}

const UserSidebar: FC<Props> = ({
  isViewMode,
  showSubs,
  handleLogout,
  handleStopAdminViewMode,
  handleStopAffiliateViewMode
}) => {
  const classes = useStyles();

  const { year, month } = useSelector(getDatePickerState);

  return (
    <Hidden mdDown implementation="css">
      <Box className={classes.sidebar}>
        <Box className={classes.header}>
          <Link to={`/overview/${year}/${month}`}>
            <Logo />
          </Link>
          <Profile />
          <Menu
            isViewMode={isViewMode}
            showSubs={showSubs}
            handleLogoutSubmit={handleLogout}
          />
        </Box>
        <Box className={classes.footer}>
          {handleStopAdminViewMode && (
            <Button
              onClick={handleStopAdminViewMode}
              className={classes.stopViewModeBtn}
            >
              Back to admin panel
            </Button>
          )}
          {handleStopAffiliateViewMode && (
            <Button
              onClick={handleStopAffiliateViewMode}
              className={classes.stopViewModeBtn}
            >
              Back to master account
            </Button>
          )}
          <UserBalance className={classes.userBalance} />
        </Box>
      </Box>
    </Hidden>
  );
};

export default UserSidebar;
