import { createTheme } from "@material-ui/core/styles";
import { brandColors, colors } from "./colors";
import { datepickerOverrides } from "./datepicker";

const theme = createTheme({
  props: {
    MuiButton: {
      variant: "outlined"
    },
    MuiMenu: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    },
    MuiTextField: {
      fullWidth: true,
      variant: "outlined"
    },
    MuiCheckbox: {
      disableRipple: true
    },
    MuiSwipeableDrawer: {
      elevation: 0,
      anchor: "right"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 321,
      md: 569,
      lg: 969,
      xl: 1281
    }
  },
  palette: {
    secondary: {
      main: colors.blue
    },
    error: {
      main: colors.red
    },
    success: {
      main: colors.green
    },
    info: {
      main: colors.blue
    },
    warning: {
      main: colors.orange
    },
    action: {
      active: colors.blue,
      selected: colors.blue50,
      hover: colors.blue50
    },
    text: {
      primary: colors.black21,
      secondary: colors.black61,
      disabled: colors.black75,
      hint: colors.blackf5
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".MuiSkeleton-root": {
          backgroundColor: `${colors.blue50} !important`
        },
        ".LD": {
          color: `${brandColors.LD} !important`
        },
        ".OS": {
          color: `${brandColors.OS} !important`
        },
        ".CJ": {
          color: `${brandColors.CJ} !important`
        },
        ".KK": {
          color: `${brandColors.KK} !important`
        },
        ".FK": {
          color: `${brandColors.FK} !important`
        },
        ".SN": {
          color: `${brandColors.SN} !important`
        },
        ".VB": {
          color: `${brandColors.VB} !important`
        },
        ".SB": {
          color: `${brandColors.SB} !important`
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.blacke0
      }
    },
    MuiIcon: {
      fontSizeSmall: {
        fontSize: 16,
        color: "inherit"
      }
    },
    MuiTooltip: {
      tooltip: {
        whiteSpace: "nowrap",
        minWidth: "64px",
        fontSize: "12px"
      },
      tooltipPlacementTop: {
        marginBottom: "8px !important"
      },
      tooltipPlacementBottom: {
        marginTop: "8px !important"
      },
      tooltipPlacementRight: {
        marginLeft: "8px !important"
      },
      tooltipPlacementLeft: {
        marginRight: "8px !important"
      }
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: "16px"
        }
      }
    },
    MuiTable: {
      root: {
        display: "flex",
        flexDirection: "column"
      }
    },
    MuiTableBody: {
      root: {
        display: "flex",
        width: "100%",
        height: "100%"
      }
    },
    MuiTableHead: {
      root: {
        display: "flex",
        borderBottom: `2px solid ${colors.blacke0}`,
        transition: " all 0.4s ease-out",
        minHeight: "50px",
        "& .MuiTableCell-root": {
          fontSize: 12,
          lineHeight: "16px",
          overflow: "visible",
          color: colors.black9e
        },
        "&:hover": {
          borderRadius: "8px 8px 0 0",
          background: colors.blackf5
        }
      }
    },
    MuiTableRow: {
      root: {
        display: "flex",
        transition: " all 0.4s ease-out",
        borderBottom: `1px solid ${colors.blacke0}`,
        "&:hover": {
          background: colors.blackf5
        }
      }
    },
    MuiTableCell: {
      root: {
        display: "flex",
        flex: "1 1 auto",
        alignItems: "center",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 12,
        borderBottom: "none",
        "&:first-child": {
          paddingLeft: 16
        },
        "&:last-child": {
          paddingRight: 16
        }
      }
    },
    MuiTableFooter: {
      root: {
        display: "flex",
        transition: " all 0.4s ease-out",
        borderTop: `1px solid ${colors.blacke0}`,
        "& .MuiTableCell-root": {
          fontSize: 12,
          lineHeight: "16px",
          fontWeight: 500,
          overflow: "visible",
          color: colors.black21
        },
        "&:hover": {
          borderRadius: "0 0 8px 8px",
          background: colors.blackf5
        }
      }
    },
    MuiAccordion: {
      root: {
        margin: 0,
        boxShadow: "none",
        "&::before": {
          background: "none"
        },
        "&$expanded": {
          margin: 0
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        "&$expanded": {
          margin: 0,
          minHeight: "auto"
        }
      },
      content: {
        margin: "16px 8px",
        "&$expanded": {
          margin: "16px 0"
        }
      },
      expandIcon: {
        color: colors.blackbd,
        marginRight: 0,
        "&$expanded": {
          color: colors.blue
        }
      },
      expanded: {}
    },
    MuiAccordionDetails: {
      root: {
        padding: 0
      }
    },
    MuiMenu: {
      paper: {
        marginTop: 8,
        borderRadius: 8,
        minWidth: "108px"
      }
    },
    MuiMenuItem: {
      root: {
        textTransform: "none",
        fontSize: 14,
        lineHeight: "24px",
        color: colors.black21,
        "&:hover": {
          color: colors.blue,
          "& svg": {
            fill: colors.blue
          },
          "& .MuiSvgIcon-root": {
            color: colors.blue
          }
        }
      }
    },
    MuiPopover: {
      paper: {
        boxShadow:
          "0px 1px 3px #00000033, 0px 2px 2px #0000001f, 0px 0px 2px #00000024"
      }
    },
    MuiSkeleton: {
      text: {
        transform: "scale(1, 0.9)"
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 10
      },
      colorPrimary: {
        backgroundColor: colors.blackee
      },
      barColorPrimary: {
        backgroundColor: colors.teal
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 16,
        color: "inherit"
      }
    },
    MuiIconButton: {
      root: {
        color: colors.black61,
        borderColor: colors.blackc4,
        "&:hover": {
          color: colors.blue,
          borderColor: colors.blue100
        }
      },
      colorSecondary: {
        color: colors.blue,
        borderColor: colors.blue100
      }
    },
    MuiButton: {
      root: {
        border: `1px solid ${colors.blackc4}`,
        "&:hover": {
          color: colors.blue,
          backgroundColor: colors.blue50,
          borderColor: colors.blue100
        },
        "&$disabled": {
          border: `1px solid ${colors.blacke0}`,
          "& $startIcon": {
            color: colors.blacke0
          },
          "& $endIcon": {
            color: colors.blacke0
          }
        },
        "& a": {
          textDecoration: "none",
          color: "inherit"
        }
      },
      outlined: {
        fontSize: 12,
        lineHeight: "16px",
        color: colors.black21,
        whiteSpace: "nowrap",
        height: 32,
        borderRadius: 17,
        "&:hover $startIcon": {
          color: colors.blue
        },
        "&:hover $endIcon": {
          color: colors.blue
        }
      },
      outlinedSecondary: {
        color: colors.blue,
        borderColor: colors.blue100,
        "&$disabled": {
          border: `1px solid ${colors.blacke0}`
        },
        "&:hover": {
          backgroundColor: colors.blue50,
          borderColor: colors.blue100
        },
        "& $startIcon": {
          color: colors.blue
        },
        "& $endIcon": {
          color: colors.blue
        }
      },
      startIcon: {
        color: colors.black61
      },
      endIcon: {
        color: colors.black61
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.blackda,
        "& .MuiSvgIcon-root": {
          width: 18,
          height: 18
        },
        "&:hover": {
          color: colors.blue,
          backgroundColor: colors.blue50
        }
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 16,
        marginBottom: 16
      }
    },
    MuiFormLabel: {
      root: {
        textTransform: "capitalize"
      }
    },
    MuiTextField: {
      root: {
        "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
          {
            WebkitAppearance: "none",
            margin: 0
          }
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        "&$disabled": {
          color: colors.blackbd
        },
        "&$error": {
          color: colors.red
        }
      }
    },
    MuiInputAdornment: {
      root: {
        color: colors.blackbd,
        "& .MuiSvgIcon-root": {
          width: 16,
          height: 16
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        fontSize: 14,
        lineHeight: "21px",
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: colors.blue
        },
        "&$notchedOutline": {
          borderColor: colors.blacke0
        },
        "&$disabled": {
          color: colors.blackbd,
          borderColor: colors.blacke0
        },
        "&$focused": {
          borderColor: colors.blue,
          "&:not($disabled):not($error) .MuiInputAdornment-positionStart": {
            color: colors.blue
          }
        },
        "&$error": {
          color: colors.red,
          borderColor: colors.red,
          "& .MuiInputAdornment-positionStart": {
            color: colors.red
          },
          "& .MuiSvgIcon-root": {
            color: colors.red
          }
        },
        "& fieldset": {
          borderColor: colors.blacke0
        }
      },
      multiline: {
        padding: "16.32px 14px"
      }
    },
    MuiTypography: {
      colorTextSecondary: {
        color: colors.black9e
      }
    },
    ...datepickerOverrides
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  },
  typography: {
    fontFamily: '"Rubik", sans-serif',
    fontSize: 14,
    h2: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 500
    },
    h3: {
      fontSize: 24,
      lineHeight: "32px"
    },
    h4: {
      fontSize: 20,
      lineHeight: "28px",
      textTransform: "capitalize",
      fontWeight: 500
    },
    h5: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 500,
      color: colors.black21
    },
    h6: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      color: colors.black21
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: "normal",
      textTransform: "capitalize",
      color: colors.black61
    }
  },
  colors: {
    ...colors,
    ...brandColors
  }
});

export { colors };
export default theme;
