import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useEmptySearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "16px 32px"
    },
    title: {
      fontWeight: 400,
      textTransform: "capitalize",
      color: theme.colors.black75
    },
    text: {
      fontSize: 12,
      padding: "8px 0 16px 0",
      color: theme.colors.black75
    },
    button: {
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue100}`
    }
  })
);

export { useEmptySearchStyles };
