import { DraftInvoiceResponse, PaymentInvoiceResponse } from "@types";

import React, { FC } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import formatMoney from "utils/formatMoney";
import LogoImage from "assets/images/logo.png";
import WatermarkImage from "assets/images/watermark.png";

import registerFonts from "./registerDocumentFonts";
import styles from "./styles";

interface Props {
  template: "invoice" | "receipt" | "draft";
  invoice: PaymentInvoiceResponse | DraftInvoiceResponse;
}

registerFonts();

const Invoice: FC<Props> = ({ template, invoice }) => {
  const { affiliate, payments, company, totals, note } = invoice;
  const invoiceDate = "invoiceDate" in invoice ? invoice.invoiceDate : null;
  const invoiceNumber =
    "invoiceNumber" in invoice ? invoice.invoiceNumber : null;

  return (
    <Document>
      <Page style={styles.body}>
        {template === "draft" && (
          <Image fixed src={WatermarkImage} style={styles.watermark} />
        )}
        <View style={styles.logoWrapper}>
          <Image src={LogoImage} style={styles.logo} fixed />
        </View>
        {(template === "invoice" || template === "draft") && (
          <Text style={styles.title}>Invoice</Text>
        )}
        {template === "receipt" && <Text style={styles.title}>Receipt</Text>}
        <View
          style={{
            ...styles.info,
            flexDirection: template === "receipt" ? "column-reverse" : "column"
          }}
        >
          <View
            style={{
              ...styles.infoBox,
              alignItems: template === "receipt" ? "flex-start" : "flex-end"
            }}
          >
            <Text style={styles.affiliateName}>{affiliate.name}</Text>
            {affiliate.address && (
              <Text style={styles.affiliateAddress}>{affiliate.address}</Text>
            )}
            {affiliate.vatNumber && (
              <View style={styles.affiliateVatNumber}>
                <Text style={styles.affiliateVatNumberLabel}>VAT Number:</Text>
                <Text style={styles.affiliateVatNumberValue}>
                  {affiliate.vatNumber}
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              ...styles.infoBox,
              alignItems: template === "receipt" ? "flex-end" : "flex-start"
            }}
          >
            <Text style={styles.companyName}>{company.name}</Text>
            <Text style={styles.companyAddress1}>{company.address1}</Text>
            <Text style={styles.companyAddress2}>{company.address2}</Text>
            <View style={styles.companyVatNumber}>
              <Text style={styles.companyVatNumberLabel}>VAT Number:</Text>
              <Text style={styles.companyVatNumberValue}>
                {company.vatNumber}
              </Text>
            </View>
          </View>
        </View>
        {invoiceDate && invoiceNumber && (
          <View style={styles.invoice}>
            <View style={styles.invoiceDate}>
              <Text style={styles.invoiceDateLabel}>Date:</Text>
              <Text style={styles.invoiceDateValue}>
                {format(parseISO(invoiceDate), "dd MMMM yyyy")}
              </Text>
            </View>
            <View style={styles.invoiceNumber}>
              <Text style={styles.invoiceNumberLabel}>Invoice Number:</Text>
              <Text style={styles.invoiceNumberValue}>{invoiceNumber}</Text>
            </View>
          </View>
        )}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={{ ...styles.descriptionColumn, fontWeight: 500 }}>
              Description
            </Text>
            <Text style={{ ...styles.amountColumn, fontWeight: 500 }}>
              Amount(EUR)
            </Text>
          </View>
          <View style={styles.tableContent}>
            {payments.map((payment, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.firstSubRow}>
                  <Text style={styles.descriptionColumn}>
                    {payment.description}
                  </Text>
                  <Text style={styles.amountColumn}>
                    {formatMoney.from(payment.amount)}
                  </Text>
                </View>
                <View style={styles.secondSubRow}>
                  <Text
                    style={styles.descriptionColumn}
                  >{`Vat at - ${payment.taxRate}%`}</Text>
                  <Text style={styles.amountColumn}>
                    {formatMoney.from(payment.tax)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.tableFooter}>
            <Text style={{ ...styles.descriptionColumn, fontWeight: 500 }}>
              Total
            </Text>
            <Text style={{ ...styles.amountColumn, fontWeight: 500 }}>
              {formatMoney.from(totals.total)}
            </Text>
          </View>
        </View>
        <View style={styles.paymentMethod}>
          <Text style={styles.paymentMethodTitle}>
            Beneficiary bank details:
          </Text>
          <View style={styles.paymentMethodDetails}>
            {affiliate.paymentMethodDetails.skrillAccount && (
              <View style={styles.paymentMethodDetailsText}>
                <Text style={styles.paymentMethodDetailsLabel}>
                  Skrill account:
                </Text>
                <Text style={styles.paymentMethodDetailsValue}>
                  {affiliate.paymentMethodDetails.skrillAccount}
                </Text>
              </View>
            )}
            {affiliate.paymentMethodDetails.casinoAccountEmail && (
              <View style={styles.paymentMethodDetailsText}>
                <Text style={styles.paymentMethodDetailsLabel}>
                  Casino account:
                </Text>
                <Text style={styles.paymentMethodDetailsValue}>
                  {affiliate.paymentMethodDetails.casinoAccountEmail}
                </Text>
              </View>
            )}
            {affiliate.paymentMethodDetails.bankName && (
              <View style={styles.paymentMethodDetailsText}>
                <Text style={styles.paymentMethodDetailsLabel}>Bank:</Text>
                <Text style={styles.paymentMethodDetailsValue}>
                  {affiliate.paymentMethodDetails.bankName}
                </Text>
              </View>
            )}
            {affiliate.paymentMethodDetails.bankIban && (
              <View style={styles.paymentMethodDetailsText}>
                <Text style={styles.paymentMethodDetailsLabel}>IBAN:</Text>
                <Text style={styles.paymentMethodDetailsValue}>
                  {affiliate.paymentMethodDetails.bankIban}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.note}>
          <Text style={styles.noteText}>{note}</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Invoice;
