/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { getLoggedInStatus } from "modules/authentication";

interface Props extends RouteProps {
  redirectPath: string;
}

const PublicRoute: FC<Props> = ({ redirectPath, component, ...rest }) => {
  const Component: any = component;
  const loggedIn = useSelector(getLoggedInStatus);

  return (
    <Route
      {...rest}
      render={props =>
        !loggedIn ? <Component {...props} /> : <Redirect to={redirectPath} />
      }
    />
  );
};

export default PublicRoute;
