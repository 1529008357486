import { ListAffiliate, CreatePlanValues } from "@types";

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormikHelpers } from "formik";

import { useSearch } from "hooks";
import formatMoney from "utils/formatMoney";
import getGroupedPlanRules from "utils/getGroupedPlanRules";
import { getAuthState } from "modules/authentication";
import { getDatePickerState } from "modules/datepicker";
import { openDrawer, closeDrawer, getDrawersState } from "drawers";

import { getPlanState, updatePlan, fetchPlan, clearPlanState } from "./duck";
import formatPlanToRequest from "./helpers/formatToRequest";
import { useStyles } from "./styles";

interface MatchParams {
  planId: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { planId } = useParams<MatchParams>();
  const history = useHistory();

  const { roles } = useSelector(getAuthState);
  const { isOpen } = useSelector(getDrawersState);
  const { error, isLoading, details, rules, affiliates } =
    useSelector(getPlanState);

  const { year, month } = useSelector(getDatePickerState);
  const { query, setQuery, results } = useSearch<ListAffiliate>(
    ["affiliateName", "affiliateId", "affiliateEmail"],
    affiliates
  );

  const handleResetSearch = useCallback(() => setQuery(""), [setQuery]);

  const handleListChange = useCallback(
    (id: number) =>
      history.push(`/admin/affiliates/${id}/overview/${year}/${month}`),
    [history, year, month]
  );

  const handleEditPlan = useCallback(
    (values: CreatePlanValues, helpers: FormikHelpers<CreatePlanValues>) => {
      const data = formatPlanToRequest(values);
      dispatch(updatePlan(Number(planId), data, helpers));
    },
    [dispatch, planId]
  );

  const handleChangeButton = useCallback(() => {
    dispatch(
      openDrawer("edit-plan", {
        initialState: {
          plan: formatMoney.fromObject({
            name: details.name,
            nrs: details.nrs ? details.nrs : null,
            cpa: details.cpa
          }),
          rules: formatMoney.fromArray(getGroupedPlanRules(rules))
        },
        handleSubmit: handleEditPlan
      })
    );
  }, [details, dispatch, handleEditPlan, rules]);

  useEffect(() => {
    dispatch(fetchPlan(Number(planId)));

    return () => {
      dispatch(clearPlanState());
    };
  }, [dispatch, planId]);

  useEffect(() => {
    return () => {
      if (isOpen) dispatch(closeDrawer());
    };
  }, [dispatch, isOpen]);

  return {
    classes,
    error,
    isLoading,
    isAdmin: roles.includes("admin"),
    plan: details,
    rules,
    searchQuery: query,
    affiliates: results,
    handleSearch: setQuery,
    handleResetSearch,
    handleListChange,
    handleChangeButton
  };
}
