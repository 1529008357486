import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

import { TextCardSkeleton } from "components/cards";

import { useSkeletonStyles, Divider } from "./styles";

const OverviewSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <>
      <Box className={classes.row}>
        {Array.from(Array(3)).map((_, index) => (
          <TextCardSkeleton key={index}>
            <Box>
              <Skeleton className={classes.box} />
            </Box>
          </TextCardSkeleton>
        ))}
      </Box>
      <Divider />
      <Box className={classes.row}>
        <TextCardSkeleton width={96}>
          <>
            <Skeleton className={classes.smallBox} />
            <Skeleton className={classes.box} />
          </>
        </TextCardSkeleton>
        <TextCardSkeleton width={96}>
          <>
            <Skeleton className={classes.smallBox} />
            <Skeleton className={classes.box} />
          </>
        </TextCardSkeleton>
        <TextCardSkeleton width={96}>
          <>
            <Skeleton className={classes.mediumBox} />
            <Skeleton className={classes.mediumBox} />
          </>
        </TextCardSkeleton>
      </Box>
      <Divider />
    </>
  );
};

export default OverviewSkeleton;
