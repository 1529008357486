/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactElement, CSSProperties } from "react";
import { CSVLink } from "react-csv";
import Button, { ButtonProps } from "@material-ui/core/Button";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ArrowIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import { withStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import formatMoney from "utils/formatMoney";

export interface DownloadCsvButtonProps {
  data: any;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  headers: {
    key: string;
    label: string;
  }[];
  children?: ReactElement | string;
  enclosingCharacter?: string;
  separator?: string;
  uFEFF?: boolean;
  asyncOnClick?: boolean;
}

const BackButton = withStyles(theme => ({
  root: {
    border: 0,
    padding: 0,
    fontSize: 12,
    lineHeight: "16px",
    color: theme.colors.black9e,
    "&:hover": {
      background: "none",
      color: theme.colors.blue
    },
    "& svg": {
      marginRight: 16
    }
  }
}))(({ children, ...props }: ButtonProps) => (
  <Button disableRipple disableFocusRipple {...props}>
    <ArrowIcon />
    {children}
  </Button>
));

const CircleIconButton = withStyles(theme => ({
  root: {
    width: 32,
    height: 32,
    padding: 0,
    borderWidth: 1,
    borderStyle: "solid",
    "&:hover": {
      color: theme.colors.blue
    }
  }
}))(({ children, ...rest }: IconButtonProps) => (
  <IconButton {...rest}>{children}</IconButton>
));

const DownloadCsvButton: FC<DownloadCsvButtonProps> = ({
  disabled,
  className,
  style,
  data,
  ...rest
}) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  return (
    <CSVLink
      target="_blank"
      filename="report.csv"
      style={{
        textDecoration: "none",
        borderRadius: 17,
        pointerEvents: disabled ? "none" : "visible",
        ...style
      }}
      data={formatMoney.fromArray(data)}
      {...rest}
    >
      {!isMobile ? (
        <Button disabled={disabled} className={className}>
          download csv
        </Button>
      ) : (
        <CircleIconButton disabled={disabled} className={className}>
          <DownloadIcon />
        </CircleIconButton>
      )}
    </CSVLink>
  );
};

export { BackButton, CircleIconButton, DownloadCsvButton };
