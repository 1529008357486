import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FilterIcon from "@material-ui/icons/FilterListRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import DatePicker from "modules/datepicker";
import Search from "components/search";
import { DownloadCsvButton, CircleIconButton } from "components/button";
import EmptyData from "components/empty-data";
import Table, {
  Column,
  LinkColumn,
  ButtonColumn,
  MultipleColumn
} from "components/table";

import Skeleton from "./Skeleton";
import usePayments from "./usePayments";

const CSV_HEADERS = [
  { label: "ID", key: "affiliateId" },
  { label: "Name", key: "contactName" },
  { label: "Payment method", key: "paymentMethod" },
  { label: "Opening balance", key: "openingBalance" },
  { label: "Credited", key: "creditedAmount" },
  { label: "Paid", key: "paidAmount" },
  { label: "Closing balance", key: "closingBalance" },
  { label: "Tax rate", key: "taxRate" },
  { label: "Status", key: "status" },
  { label: "Manager", key: "manager" }
];

const Component: FC = () => {
  const {
    classes,
    error,
    isEmpty,
    isFilterOn,
    isEmptyPayments,
    isLoading,
    year,
    month,
    roles,
    payments,
    searchQuery,
    canCloseMonth,
    handleRowClick,
    handleFilterOpen,
    handleClearFilter,
    handleCloseMonth,
    handleSearchQuery,
    handleMarkPayment,
    handleConfirmPayment
  } = usePayments();

  return (
    <>
      <Search
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder="Search by ID or name"
        disabled={isEmpty}
        buttons={
          <>
            {isFilterOn && (
              <CircleIconButton color="secondary" onClick={handleClearFilter}>
                <CloseRoundedIcon />
              </CircleIconButton>
            )}
            <Button
              disabled={isEmpty}
              color={isFilterOn ? "secondary" : "default"}
              onClick={handleFilterOpen}
              endIcon={<FilterIcon fontSize="small" />}
            >
              Filter
            </Button>
            <DownloadCsvButton
              disabled={isEmptyPayments}
              headers={CSV_HEADERS}
              data={payments}
            />
            <Button
              disabled={isLoading || error || !canCloseMonth}
              onClick={handleCloseMonth}
            >
              close month
            </Button>
            <DatePicker baseDateUrl="/admin/payments" />
          </>
        }
      />
      <Box className={classes.table}>
        {isLoading ? (
          <Skeleton />
        ) : isEmpty || error ? (
          <EmptyData />
        ) : (
          <Table
            initialData={payments}
            estimatedItemSize={65}
            handleRowClick={handleRowClick}
          >
            {roles.includes("payer") ? (
              <Column
                label="ID"
                name="affiliateId"
                align="left"
                type="text"
                className={classes.idColumn}
              />
            ) : (
              <LinkColumn
                label="ID"
                name="affiliateId"
                href={`/admin/affiliates/{affiliateId}/overview/${year}/${month}`}
                align="left"
                className={classes.idColumn}
              />
            )}
            <MultipleColumn
              label="Name"
              align="left"
              name="contactName"
              secondName="name"
              className={classes.nameColumn}
            />
            <Column
              label="Payment"
              align="left"
              name="paymentMethod"
              type="text"
              className={classes.paymentColumn}
            />
            <Column
              label="Opening"
              align="right"
              name="openingBalance"
              type="currency"
              className={classes.openingBalanceColumn}
            />
            <Column
              label="Credited"
              align="right"
              name="creditedAmount"
              type="currency"
              className={classes.creditedAmountColumn}
            />
            <Column
              label="Paid"
              align="right"
              name="paidAmount"
              type="currency"
              className={classes.paidAmountColumn}
            />
            <Column
              label="Closing"
              align="right"
              name="closingBalance"
              type="currency"
              className={classes.closingBalanceColumn}
            />
            <Column
              label="Tax Rate"
              align="right"
              name="taxRate"
              type="percent"
              className={classes.taxRateColumn}
            />
            <Column
              label="Status"
              align="left"
              name="status"
              type="status"
              className={classes.statusColumn}
            />
            <Column
              label="Manager"
              align="right"
              name="manager"
              type="text"
              className={classes.managerColumn}
            />
            <ButtonColumn
              align="right"
              name="payment-button"
              color="secondary"
              className={classes.buttonColumn}
              handleMarkPayment={handleMarkPayment}
              handleConfirmPayment={handleConfirmPayment}
            >
              Confirm invoice
            </ButtonColumn>
          </Table>
        )}
      </Box>
    </>
  );
};

export default Component;
