import { PaymentItem, PaymentItemTotal } from "@types";

import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";

interface PaymentPayload {
  items: PaymentItem[];
  totals: PaymentItemTotal;
}

enum PaymentActiontypes {
  FETCH_PAYMENT_LOADING = "@payment/fetch-payment-loading",
  FETCH_PAYMENT_SUCCESS = "@payment/fetch-payment-success",
  FETCH_PAYMENT_FAILURE = "@payments/fetch-payment-failure"
}

interface FetchPaymentIsLoadingAction {
  type: PaymentActiontypes.FETCH_PAYMENT_LOADING;
}

interface FetchPaymentIsFailureAction {
  type: PaymentActiontypes.FETCH_PAYMENT_FAILURE;
}

interface FetchPaymentIsSuccessAction {
  type: PaymentActiontypes.FETCH_PAYMENT_SUCCESS;
  payload: {
    items: PaymentItem[];
    totals: PaymentItemTotal;
  };
}

type PaymentActions =
  | FetchPaymentIsLoadingAction
  | FetchPaymentIsFailureAction
  | FetchPaymentIsSuccessAction;

const fetchPaymentIsLoading = (): FetchPaymentIsLoadingAction => ({
  type: PaymentActiontypes.FETCH_PAYMENT_LOADING
});

const fetchPaymentIsFailure = (): FetchPaymentIsFailureAction => ({
  type: PaymentActiontypes.FETCH_PAYMENT_FAILURE
});

const fetchPaymentIsSuccess = (
  payment: PaymentPayload
): FetchPaymentIsSuccessAction => ({
  type: PaymentActiontypes.FETCH_PAYMENT_SUCCESS,
  payload: payment
});

const fetchPayment =
  (
    affiliateId: number,
    year: string,
    month: string
  ): ThunkActionCreator<PaymentActions> =>
  async dispatch => {
    dispatch(fetchPaymentIsLoading());
    try {
      const { payments } = await api.admin.payments.getPayment(
        affiliateId,
        year,
        month
      );
      dispatch(fetchPaymentIsSuccess(payments));
    } catch (err) {
      dispatch(fetchPaymentIsFailure());
    }
  };

interface PaymentState {
  error: boolean;
  isLoading: boolean;
  totals: PaymentItemTotal;
  payments: PaymentItem[];
}

const initialState: PaymentState = {
  error: false,
  isLoading: false,
  totals: {
    tax: 0,
    total: 0,
    amount: 0,
    debitAmount: 0,
    creditAmount: 0
  },
  payments: []
};

const reducer: Reducer<PaymentState, PaymentActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PaymentActiontypes.FETCH_PAYMENT_LOADING:
      return {
        ...initialState,
        isLoading: true
      };
    case PaymentActiontypes.FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        totals: action.payload.totals,
        payments: action.payload.items
      };
    case PaymentActiontypes.FETCH_PAYMENT_FAILURE:
      return {
        ...initialState,
        error: true
      };
    default:
      return state;
  }
};

const getPaymentState = (state: AppState) => state.payment;

export { reducer, fetchPayment, getPaymentState };
