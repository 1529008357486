import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      padding: 0,
      minWidth: 700
    },
    details: {
      flex: 1,
      zIndex: 1,
      padding: 16,
      maxWidth: 380,
      boxShadow:
        "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)"
    },
    companies: {
      width: "100%",
      maxWidth: 320,
      background: theme.colors.blackf5
    },
    companiesHeader: {
      padding: 16
    },
    companiesTitle: {
      padding: "16px 0",
      fontWeight: 400,
      textTransform: "lowercase",
      color: theme.colors.black75
    },
    search: {
      fontSize: 14,
      lineHeight: "24px",
      maxHeight: 42,
      width: "100%",
      backgroundColor: theme.colors.white
    },
    plan: {
      padding: "0 16px"
    },
    list: {
      height: "100%",
      maxHeight: "calc(100vh - 142px)"
    }
  })
);

const useSkeletonStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      padding: 0,
      minWidth: 700
    },
    button: {
      borderRadius: 15
    },
    details: {
      flex: 1,
      zIndex: 1,
      padding: 16,
      maxWidth: 380,
      boxShadow:
        "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)"
    },
    deal: {
      margin: 16
    },
    create: {
      display: "flex",
      paddingBottom: 32
    },
    created: {
      borderRight: `1px solid ${theme.colors.blacke0}`,
      padding: "0 16px"
    },
    createdBy: {
      paddingLeft: 16
    },
    divider: {
      marginLeft: -16,
      marginRight: -16
    },
    geo: {
      marginLeft: 16,
      marginTop: 32
    },
    companies: {
      position: "fixed",
      top: 4,
      right: 4,
      flex: 1,
      width: "100%",
      height: "calc(100% - 8px)",
      maxWidth: 320,
      background: theme.colors.blackf5,
      borderRadius: "8px",
      padding: 16
    },
    companiesHeader: {
      padding: 16
    },
    companiesTitle: {
      padding: 16
    },
    search: {
      margin: "16px -16px 0 -16px"
    },
    list: {
      height: "100%",
      maxHeight: "calc(100vh - 142px)"
    },
    affiliate: {
      marginLeft: 16,
      marginBottom: 16
    }
  })
);

export { useStyles, useSkeletonStyles };
