import { BRANDS } from "constants/brands";

const colors = {
  white: "#ffffff",
  blue: "#4054B2",
  blue50: "#E8EAF6",
  blue100: "#C5CAE9",
  teal: "#009688",
  teal50: "#E0F2F1",
  red: "#F2453D",
  red10: "#fef1f0",
  orange: "#FF9800",
  orange10: "#fff5e6",
  green: "#009688",
  black21: "#212121",
  black61: "#616161",
  black75: "#757575",
  black9e: "#9E9E9E",
  blackda: "#dadada",
  blackc4: "#c4c4c4",
  blacke0: "#E0E0E0",
  blackf5: "#F5F5F5",
  blackfa: "#FAFAFA",
  blackbd: "#BDBDBD",
  blackee: "#eee"
};

const brandColors = {
  [BRANDS.LD]: "#00BCD4",
  [BRANDS.CJ]: "#8BC34A",
  [BRANDS.KK]: "#2C98F0",
  [BRANDS.OS]: "#C62828",
  [BRANDS.FK]: "#171721",
  [BRANDS.SN]: "#FF521D",
  [BRANDS.VB]: "#00B75B",
  [BRANDS.SB]: "#5e082b"
};

export type Colors = typeof colors & typeof brandColors;
export { colors, brandColors };
