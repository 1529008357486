import React, { FC, ReactElement } from "react";
import Box from "@material-ui/core/Box";

import Header from "components/header";

interface Props {
  children: ReactElement;
}

const AdminPageLayout: FC<Props> = ({ children }) => (
  <Box width="100%" height="100vh" overflow="auto" bgcolor="white">
    <Header />
    <Box height="100%" width="100%" paddingTop="48px">
      {children}
    </Box>
  </Box>
);

export default AdminPageLayout;
