import { CreateFeeRequest, CreateFeeValues } from "@types";

import omit from "lodash/omit";
import flatten from "lodash/flatten";

export default function ({ fee, rules }: CreateFeeValues): CreateFeeRequest {
  return {
    fee,
    rules: flatten(
      rules.map(rule =>
        rule.countryIds.map(countryId =>
          omit({ ...rule, countryId }, "countryIds")
        )
      )
    )
  };
}
