import React from "react";
import Container, { ContainerProps } from "@material-ui/core/Container";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";

const SidebarContainer = withStyles(theme => ({
  root: {
    maxWidth: 320,
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
    background: theme.colors.blackfa,
    borderRight: `1px solid ${theme.colors.blacke0}`,
    padding: 0
  }
}))((props: ContainerProps) => <Container {...props} />);

const ListContainer = withStyles({
  root: {
    maxWidth: 320,
    width: "100%",
    height: "100%",
    maxHeight: "calc(100% - 75px)",
    padding: 0
  }
})((props: ContainerProps) => <Container maxWidth={false} {...props} />);

const useListTitleStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: 28,
      width: "auto",
      fontWeight: 400,
      padding: "0 32px",
      color: theme.colors.black75
    },
    title: {
      width: "auto",
      fontWeight: 400
    },
    clearBtn: {
      fontSize: 14,
      paddingTop: 4,
      cursor: "pointer",
      "&:hover": {
        color: theme.colors.blue
      }
    }
  })
);

const useListItemStyles = makeStyles(theme =>
  createStyles({
    container: {
      "&:hover $iconButton": {
        visibility: "visible"
      }
    },
    root: {
      height: 56,
      paddingLeft: "32px !important"
    },
    indicator: {
      position: "absolute",
      left: 0,
      width: 4,
      height: 56,
      background: theme.colors.blue,
      borderRadius: "0px 4px 4px 0px"
    },
    title: {
      color: theme.colors.black21,
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: 228
    },
    subtitle: {
      fontSize: 14,
      color: theme.colors.black75
    },
    iconButton: {
      visibility: "hidden",
      "&:hover $removeIcon": {
        color: theme.colors.blue
      }
    },
    iconButtonVisible: {
      visibility: "inherit"
    },
    removeIcon: {
      fontSize: 18,
      color: theme.colors.black9e
    }
  })
);

const useListItemSkeletonStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 56,
      margin: "0 32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    title: {
      margin: 0
    },
    subtitle: {
      marginTop: 2
    }
  })
);

const useSidebarSkeletonStyles = makeStyles(theme =>
  createStyles({
    search: {
      maxWidth: 320,
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.colors.blacke0}`,
      padding: 16
    },
    label: {
      margin: "16px 32px !important"
    },
    listContainer: {
      width: "100%",
      maxWidth: 320,
      height: "100%",
      position: "fixed"
    }
  })
);

export {
  SidebarContainer,
  ListContainer,
  useListTitleStyles,
  useListItemStyles,
  useListItemSkeletonStyles,
  useSidebarSkeletonStyles
};
