import { CreateSubAccountRequest } from "@types";

import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikHelpers } from "formik";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import Box, { BoxProps } from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { openDrawer } from "drawers";
import {
  createAffiliateViewSubAccount,
  getAffiliateState
} from "modules/affiliate";
import { startAffiliateViewMode } from "modules/view-mode";

import { useProfileStyles } from "../styles";

const ProfileSkeleton: FC<BoxProps> = ({ className, ...rest }) => (
  <Box className={cn(className)} {...rest}>
    <Skeleton width={96} height={24} />
    <Skeleton width={152} height={32} />
  </Box>
);

const Profile: FC = () => {
  const classes = useProfileStyles();
  const dispatch = useDispatch();
  const { isLoading, data, subaccounts } = useSelector(getAffiliateState);

  const handleListChange = useCallback(
    (id: number) => {
      dispatch(startAffiliateViewMode(id));
      window.location.reload();
    },
    [dispatch]
  );

  const handleCreateSubAccount = useCallback(
    (
      values: CreateSubAccountRequest,
      helpers: FormikHelpers<CreateSubAccountRequest>
    ) => {
      dispatch(createAffiliateViewSubAccount(values, helpers));
    },
    [dispatch]
  );

  const handleCreateSubAccountDrawer = useCallback(() => {
    dispatch(
      openDrawer("create-subaccount", {
        handleSubmit: handleCreateSubAccount
      })
    );
  }, [dispatch, handleCreateSubAccount]);

  const handleChangeAccount = useCallback(() => {
    dispatch(
      openDrawer("subaccounts", {
        handleListChange,
        handleCreateDrawer: handleCreateSubAccountDrawer
      })
    );
  }, [dispatch, handleListChange, handleCreateSubAccountDrawer]);

  if (isLoading || !data) {
    return <ProfileSkeleton className={classes.profile} />;
  }

  if (!isEmpty(subaccounts)) {
    return (
      <ButtonBase
        className={classes.masterProfile}
        onClick={handleChangeAccount}
      >
        <Typography component="span" className={classes.profileId}>
          {`#${data.affiliateId}`}
        </Typography>
        <Typography
          component="span"
          variant="h3"
          className={classes.profileName}
        >
          {data.affiliateName}
        </Typography>
        <Typography
          component="span"
          variant="subtitle1"
          className={classes.subtitle}
        >
          Click to select subaccount
        </Typography>
      </ButtonBase>
    );
  }

  return (
    <Box className={classes.profile}>
      <Typography component="span" className={classes.profileId}>
        {`#${data.affiliateId}`}
      </Typography>
      <Typography component="span" variant="h3" className={classes.profileName}>
        {data.affiliateName}
      </Typography>
    </Box>
  );
};

export default Profile;
