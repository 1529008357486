/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Ref, forwardRef } from "react";
import cn from "classnames";
import { TransitionProps } from "@material-ui/core/transitions";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

import useDialog from "./useDialog";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component: FC = () => {
  const {
    classes,
    isDelete,
    isSubmit,
    dialog,
    state,
    handleClose,
    handleConfirm
  } = useDialog();

  return (
    <Dialog
      keepMounted
      open={!!dialog}
      classes={{ root: classes.root }}
      PaperProps={{ className: classes.paper }}
      TransitionComponent={Transition}
      onClose={(event, reason) => {
        if (
          dialog === "accept-tc" &&
          (reason === "backdropClick" || reason === "escapeKeyDown")
        )
          return;
        handleClose();
      }}
    >
      <Typography variant="h3" className={classes.title}>
        {state?.title}
      </Typography>
      {state?.subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {state.subtitle}
        </Typography>
      )}
      {typeof state?.content === "string" ? (
        <Typography className={classes.text}>{state.content}</Typography>
      ) : (
        <Box className={classes.content}>{state?.content}</Box>
      )}
      <DialogActions className={classes.actions}>
        {isDelete && <Button onClick={handleClose}>cancel</Button>}
        <Button
          onClick={handleConfirm}
          className={cn({
            [classes.deleteBtn]: isDelete,
            [classes.submitBtn]: isSubmit
          })}
        >
          {isDelete && "Delete"}
          {isSubmit && "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
