import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: "100%",
      padding: "16px 0 32px 0"
    },
    tab: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      minWidth: 48,
      color: theme.colors.black21
    },
    hidden: {
      display: "none"
    },
    indicator: {
      zIndex: 1,
      height: 1,
      transition: "all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    divider: {
      width: "100%",
      height: 1,
      position: "relative",
      top: -1,
      zIndex: 0
    }
  })
);

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      padding: "16px 0 32px 0"
    },
    tabs: {
      display: "flex"
    },
    tab: {
      margin: "12px 8px",
      padding: "8px 12px"
    },
    divider: {
      width: "100%",
      height: 1,
      position: "relative",
      top: -1,
      zIndex: 0
    }
  })
);

export { useStyles, useSkeletonStyles };
