import { AffiliateLink } from "@types";

import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { LINK_URL } from "constants/variables";
import { TooltipCard } from "components/cards";

import { useDetailsStyles } from "../styles";

interface Props {
  details: AffiliateLink;
  onEditClick: () => void;
}

const LinkDetails: FC<Props> = ({
  details: { name, landingPage, code, deal },
  onEditClick
}) => {
  const classes = useDetailsStyles();

  return (
    <>
      <Box className={classes.title}>
        <Typography variant="h3" className={classes.name}>
          {name}
        </Typography>
        <Button onClick={onEditClick}>edit link</Button>
      </Box>
      <Box className={classes.info}>
        <TooltipCard label="landing">{landingPage}</TooltipCard>
        {deal && <TooltipCard label="deal">{deal}</TooltipCard>}
        <TooltipCard label="link" className={classes.link}>
          {`${LINK_URL}${code}`}
        </TooltipCard>
        <TooltipCard label="code">{code}</TooltipCard>
      </Box>
    </>
  );
};

export default LinkDetails;
