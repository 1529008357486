import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

const AffiliateFeeSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.details}>
      <Box className="drawer-header">
        <Skeleton width={114} height={28} className="drawer-header_title" />
        <Skeleton width={93} height={32} className={classes.button} />
      </Box>
      <Box className={classes.deal}>
        <Skeleton width={33} height={16} />
        <Skeleton width={287} height={30} />
        <Skeleton width={287} height={30} />
      </Box>

      <Box className={classes.create}>
        <Box className={classes.created}>
          <Skeleton width={45} height={16} />
          <Skeleton width={70} height={30} />
        </Box>
        <Box className={classes.createdBy}>
          <Skeleton width={62} height={16} />
          <Skeleton width={110} height={30} />
        </Box>
      </Box>

      <Divider className={classes.divider} />
      <Box className={classes.geo}>
        <Skeleton width={50} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </Box>
      <Box className={classes.geo}>
        <Skeleton width={50} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </Box>
      <Box className={classes.geo}>
        <Skeleton width={50} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </Box>
      <Box className={classes.geo}>
        <Skeleton width={50} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
        <Skeleton width={300} height={24} />
      </Box>
    </Box>
  );
};

export default AffiliateFeeSkeleton;
