import { CreateAffiliateLinkValues, AffiliateLink } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import isEmpty from "lodash/isEmpty";

import api from "api";
import { openDrawer } from "drawers";
import { getAffiliateLinksState } from "modules/affiliate-links";
import LinkDetails, {
  getAffiliateLinkState,
  receiveAffiliateLinkDetails,
  updateAffiliateViewLink
} from "modules/affiliate-link";

interface Params {
  linkId: string;
}

const AffiliateViewLinkPage: FC = () => {
  const dispatch = useDispatch();
  const { linkId } = useParams<Params>();

  const { data: linksData } = useSelector(getAffiliateLinksState);
  const { details } = useSelector(getAffiliateLinkState);

  const handleSubmit = useCallback(
    (
      values: CreateAffiliateLinkValues,
      helpers: FormikHelpers<CreateAffiliateLinkValues>
    ) => {
      const data = {
        name: values.name,
        brandId: values.brandId,
        landingPage: values.landingPage
      };
      dispatch(updateAffiliateViewLink(Number(linkId), data, helpers));
    },
    [dispatch, linkId]
  );

  const handleEditDrawerOpen = useCallback(() => {
    dispatch(
      openDrawer("edit-user-link", {
        title: "edit link",
        initialState: {
          name: details!.name,
          brandId: details!.brandId,
          landingPage: details!.landingPage
        },
        onSubmit: handleSubmit
      })
    );
  }, [details, dispatch, handleSubmit]);

  useEffect(() => {
    const setDetails = (data: AffiliateLink[]) => {
      const linkDetails = data.find(item => item.linkId === Number(linkId));
      if (linkDetails) {
        dispatch(receiveAffiliateLinkDetails(linkDetails));
      }
    };
    const setInitialData = async () => {
      const { links } = await api.affiliate.links.getLinks(null);
      setDetails(links);
    };

    if (isEmpty(linksData)) {
      setInitialData();
    } else {
      setDetails(linksData);
    }
  }, [dispatch, linksData, linkId]);

  return <LinkDetails handleEditDrawerOpen={handleEditDrawerOpen} />;
};

export default AffiliateViewLinkPage;
