import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

import { useSkeletonStyles } from "./styles";

const AffiliatesTabSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.tabs}>
        <Skeleton className={classes.tab} width={64} height={24} />
        <Skeleton className={classes.tab} width={60} height={24} />
        <Skeleton className={classes.tab} width={46} height={24} />
        <Skeleton className={classes.tab} width={64} height={24} />
        <Skeleton className={classes.tab} width={72} height={24} />
        <Skeleton className={classes.tab} width={32} height={24} />
        <Skeleton className={classes.tab} width={60} height={24} />
        <Skeleton className={classes.tab} width={64} height={24} />
        <Skeleton className={classes.tab} width={64} height={24} />
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default AffiliatesTabSkeleton;
