import React, { FC } from "react";
import Button from "@material-ui/core/Button";

import { DownloadCsvButton, DownloadCsvButtonProps } from "components/button";

interface Props {
  csvProps: DownloadCsvButtonProps;
  handleCreateFee?: () => void;
}

const SearchButtons: FC<Props> = ({ csvProps, handleCreateFee }) => (
  <>
    <DownloadCsvButton {...csvProps} />
    {handleCreateFee && (
      <Button onClick={handleCreateFee} color="secondary">
        create new fee
      </Button>
    )}
  </>
);

export default SearchButtons;
