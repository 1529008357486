import React, { useCallback } from "react";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleLoginProps
} from "@react-oauth/google";
import { useDispatch } from "react-redux";

import { redirect } from "../utils";
import { googleSignInFailure, googleSignInSuccess } from "../duck";

type Props = Omit<GoogleLoginProps, "onSuccess" | "onError"> & {
  redirectPath?: string;
};

const LoginButton: React.FC<Props> = ({ redirectPath, ...rest }) => {
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    (response: CredentialResponse) => {
      dispatch(googleSignInSuccess(response));
      redirectPath && redirect(redirectPath);
    },
    [dispatch, redirectPath]
  );

  const handleError = useCallback(() => {
    dispatch(googleSignInFailure("Google login failed"));
  }, [dispatch]);

  return (
    <GoogleLogin {...rest} onSuccess={handleSuccess} onError={handleError} />
  );
};

export { LoginButton };
