/* eslint-disable @typescript-eslint/no-explicit-any */
import getTime from "date-fns/getTime";

export default function (array: any[], key: string, direction: "asc" | "desc") {
  return array.sort((item1, item2) => {
    let firstItem = item1[key];
    let secondItem = item2[key];

    if (key.toLowerCase().includes("date")) {
      firstItem = getTime(new Date(firstItem));
      secondItem = getTime(new Date(secondItem));
    }

    if (firstItem < secondItem) return direction === "asc" ? -1 : 1;
    if (secondItem < firstItem) return direction === "asc" ? 1 : -1;
    return 0;
  });
}
