import React, { FC, useEffect, useCallback } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";

import Drawer from "components/drawer";
import PageContainer from "components/page-container";
import Fees, { fetchFees } from "modules/fees";
import Fee from "modules/fee";

type Props = RouteComponentProps;

const FeesPage: FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  useEffect(() => {
    dispatch(fetchFees());
  }, [dispatch]);

  return (
    <PageContainer>
      <Fees />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Route exact path={`${match.path}/:adminFeeId`} component={Fee} />
      </Drawer>
    </PageContainer>
  );
};

export default FeesPage;
