import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "hidden",
      marginTop: -16
    },
    playerInfo: {
      display: "flex",
      flexWrap: "wrap"
    },
    playerIdContainer: {
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        width: "auto"
      }
    },
    playerId: {
      fontSize: 24,
      lineHeight: "32px"
    },
    dealInfo: {
      display: "flex"
    }
  })
);

export { useStyles };
