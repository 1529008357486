import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core";

const ServerIcon: FC<SvgIconProps> = props => (
  <svg
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="#212121"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.6668 12.333C17.8527 12.333 12.3335 17.8522 12.3335 24.6663V123.333C12.3335 130.147 17.8527 135.666 24.6668 135.666H67.8335V95.583C67.8335 92.3948 68.2444 89.3053 68.9536 86.333H24.6668V24.6663H74.0002V74.3249C77.1513 69.3175 81.357 65.0534 86.3335 61.8591V24.6663C86.3335 17.8522 80.8143 12.333 74.0002 12.333H24.6668ZM37.0002 36.9997V49.333H61.6668V36.9997H37.0002ZM37.0002 61.6663V73.9997H61.6668V61.6663H37.0002ZM107.917 67.833C92.5927 67.833 80.1668 80.2588 80.1668 95.583V135.666H135.667V95.583C135.667 80.2588 123.241 67.833 107.917 67.833ZM98.6668 86.333C102.071 86.333 104.834 89.0957 104.834 92.4997C104.834 95.9037 102.071 98.6663 98.6668 98.6663C95.2628 98.6663 92.5002 95.9037 92.5002 92.4997C92.5002 89.0957 95.2628 86.333 98.6668 86.333ZM117.167 86.333C120.571 86.333 123.334 89.0957 123.334 92.4997C123.334 95.9037 120.571 98.6663 117.167 98.6663C113.763 98.6663 111 95.9037 111 92.4997C111 89.0957 113.763 86.333 117.167 86.333ZM49.3335 103.291C53.5885 103.291 57.0418 106.745 57.0418 111C57.0418 115.255 53.5885 118.708 49.3335 118.708C45.0785 118.708 41.6252 115.255 41.6252 111C41.6252 106.745 45.0785 103.291 49.3335 103.291ZM107.917 104.833C116.433 104.833 123.334 107.596 123.334 111C123.334 114.404 116.433 123.333 107.917 123.333C99.4007 123.333 92.5002 114.404 92.5002 111C92.5002 107.596 99.4007 104.833 107.917 104.833Z" />
  </svg>
);

export default ServerIcon;
