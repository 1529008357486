import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "../styles";

const ARRAY_LIST_COUNT = Array.from(Array(16));

const SelectListSkeleton: FC = () => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.root}>
      {ARRAY_LIST_COUNT.map((_, index) => (
        <Box key={index} className={classes.container}>
          <Skeleton className={classes.title} width={316} />
        </Box>
      ))}
    </Box>
  );
};

export default SelectListSkeleton;
