import { useSelector } from "react-redux";

import { getAffiliateDealsState } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const { isLoading, error, data } = useSelector(getAffiliateDealsState);

  return {
    classes,
    isLoading,
    error,
    deals: data
  };
}
