import {
  AffiliatePlayersRevenue,
  AffiliatePlayersRevenues,
  AffiliatePlayersRevenueTotals,
  DateRequest,
  RequestOptionsByBrand
} from "@types";

import { Reducer } from "redux";

import api from "api";
import { BRANDS } from "constants/brands";
import { AppState, ThunkActionCreator } from "store";

enum AffiliatePlayersRevenueActionTypes {
  SET_AFFILIATE_PLAYERS_REVENUE_BRAND_FILTER = "@affiliate/players-revenue/set-brand-filter",
  FETCH_AFFILIATE_PLAYERS_REVENUE_LOADING = "@affiliate/players-revenue/fetch-players-loading",
  FETCH_AFFILIATE_PLAYERS_REVENUE_SUCCESS = "@affiliate/players-revenue/fetch-players-success",
  FETCH_AFFILIATE_PLAYERS_REVENUE_FAILURE = "@affiliate/players-revenue/fetch-players-failure"
}

interface FetchAffiliatePlayersRevenueIsLoadingAction {
  type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_LOADING;
}

interface FetchAffiliatePlayersRevenueIsSuccessAction {
  type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_SUCCESS;
  payload: AffiliatePlayersRevenues;
}

interface FetchAffiliatePlayersRevenueIsFailureAction {
  type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_FAILURE;
}

interface SetAffiliatePlayersRevenueBrandFilterAction {
  type: AffiliatePlayersRevenueActionTypes.SET_AFFILIATE_PLAYERS_REVENUE_BRAND_FILTER;
  payload: BRANDS | null;
}

type AffiliateRevenuesActions =
  | FetchAffiliatePlayersRevenueIsLoadingAction
  | FetchAffiliatePlayersRevenueIsSuccessAction
  | FetchAffiliatePlayersRevenueIsFailureAction
  | SetAffiliatePlayersRevenueBrandFilterAction;

const fetchAffiliatePlayersRevenueIsLoading =
  (): FetchAffiliatePlayersRevenueIsLoadingAction => ({
    type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_LOADING
  });

const fetchAffiliatePlayersRevenueIsSuccess = (
  revenues: AffiliatePlayersRevenues
): FetchAffiliatePlayersRevenueIsSuccessAction => ({
  type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_SUCCESS,
  payload: { ...revenues }
});

const fetchAffiliatePlayersRevenueIsFailure =
  (): FetchAffiliatePlayersRevenueIsFailureAction => ({
    type: AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_FAILURE
  });

const fetchAffiliatePlayersRevenue =
  (
    options: RequestOptionsByBrand
  ): ThunkActionCreator<AffiliateRevenuesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayersRevenueIsLoading());
    try {
      const revenues = await api.admin.affiliate.players.getPlayersRevenue(
        options
      );
      dispatch(fetchAffiliatePlayersRevenueIsSuccess(revenues));
    } catch (err) {
      dispatch(fetchAffiliatePlayersRevenueIsFailure());
    }
  };

const fetchAffiliateViewPlayersRevenue =
  (
    options: DateRequest & { brandId?: BRANDS | null }
  ): ThunkActionCreator<AffiliateRevenuesActions> =>
  async dispatch => {
    dispatch(fetchAffiliatePlayersRevenueIsLoading());
    try {
      const revenues = await api.affiliate.players.getPlayersRevenue(options);
      dispatch(fetchAffiliatePlayersRevenueIsSuccess(revenues));
    } catch (err) {
      dispatch(fetchAffiliatePlayersRevenueIsFailure());
    }
  };

const setAffiliatePlayersRevenueBrandFilter = (
  brand: BRANDS | null
): SetAffiliatePlayersRevenueBrandFilterAction => ({
  type: AffiliatePlayersRevenueActionTypes.SET_AFFILIATE_PLAYERS_REVENUE_BRAND_FILTER,
  payload: brand
});

interface AffiliatePlayersRevenueState {
  error: boolean;
  isLoading: boolean;
  total: number;
  brand: BRANDS | null;
  totals?: AffiliatePlayersRevenueTotals;
  data: AffiliatePlayersRevenue[];
}

const initialState = {
  error: false,
  isLoading: false,
  total: 0,
  brand: null,
  totals: undefined,
  data: []
};

const reducer: Reducer<
  AffiliatePlayersRevenueState,
  AffiliateRevenuesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        total: action.payload.revenues.total,
        totals: action.payload.revenues.totals,
        data: action.payload.revenues.items
      };
    case AffiliatePlayersRevenueActionTypes.FETCH_AFFILIATE_PLAYERS_REVENUE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case AffiliatePlayersRevenueActionTypes.SET_AFFILIATE_PLAYERS_REVENUE_BRAND_FILTER:
      return {
        ...state,
        brand: action.payload
      };
    default:
      return state;
  }
};

const getAffiliatePlayersRevenueState = (state: AppState) =>
  state.affiliate.playersRevenue;
const getAffiliatePlayerRevenueStateById =
  (state: AppState) => (id: string) => ({
    error: state.affiliate.playersRevenue.error,
    isLoading: state.affiliate.playersRevenue.isLoading,
    player: state.affiliate.playersRevenue.data.find(
      ({ playerId }) => playerId === Number(id)
    )
  });

export {
  reducer,
  fetchAffiliatePlayersRevenue,
  fetchAffiliateViewPlayersRevenue,
  getAffiliatePlayersRevenueState,
  getAffiliatePlayerRevenueStateById,
  setAffiliatePlayersRevenueBrandFilter
};
