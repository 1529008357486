import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import format from "date-fns/format";

import { getDatePickerState } from "modules/datepicker";

import {
  getAffiliateLinkState,
  fetchAffiliateLink,
  fetchAffiliateViewLink
} from "./duck";
import { useReportStyles } from "./styles";

interface Params {
  id: string;
  linkId: string;
}

export default function () {
  const classes = useReportStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, linkId } = useParams<Params>();

  const { dateRange } = useSelector(getDatePickerState);
  const { details, data, error, isLoading, totals } = useSelector(
    getAffiliateLinkState
  );

  useEffect(() => {
    const from = format(dateRange[0], "yyyy-MM-dd");
    const to = format(dateRange[1], "yyyy-MM-dd");
    if (id) {
      dispatch(fetchAffiliateLink(Number(id), Number(linkId), from, to));
    } else {
      dispatch(fetchAffiliateViewLink(Number(linkId), from, to));
    }
  }, [dateRange, dispatch, id, linkId]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const trafficWithTotals = data.map(report => ({
    ...report,
    total: report.cpa + report.commission
  }));

  return {
    classes,
    error,
    isLoading,
    totals,
    traffics: trafficWithTotals,
    linkDetails: details,
    handleBackButton
  };
}
