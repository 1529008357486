import { Payment, Payments, ConfirmPaymentResponse } from "@types";
import { Reducer } from "redux";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { openSnackbar, SnackbarActions } from "modules/snackbar";

import { PAYMENT_STATUSES } from "constants/payments";

enum PaymentsActionTypes {
  FETCH_PAYMENTS_LOADING = "@payments/fetch-payments-loading",
  FETCH_PAYMENTS_SUCCESS = "@payments/fetch-payments-success",
  FETCH_PAYMENTS_FAILURE = "@payments/fetch-payments-failure",
  CONFIRM_PAYMENT = "@payments/confirm-payment-success",
  MARK_PAYMENT = "@payments/mark-payment-success"
}

interface ConfirmPaymentPayload extends ConfirmPaymentResponse {
  affiliateId: number;
}

interface FetchPaymentsIsLoadingAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_LOADING;
}

interface FetchPaymentsIsSuccessAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS;
  payload: Payments;
}

interface FetchPaymentsIsFailureAction {
  type: PaymentsActionTypes.FETCH_PAYMENTS_FAILURE;
}

interface ConfirmPaymentIsSuccessAction {
  type: PaymentsActionTypes.CONFIRM_PAYMENT;
  payload: ConfirmPaymentPayload;
}

interface MarkPaymentIsSuccessAction {
  type: PaymentsActionTypes.MARK_PAYMENT;
  payload: { affiliateId: number };
}

type PaymentsActions =
  | FetchPaymentsIsLoadingAction
  | FetchPaymentsIsSuccessAction
  | FetchPaymentsIsFailureAction
  | ConfirmPaymentIsSuccessAction
  | MarkPaymentIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<PaymentsActions>
>;

const fetchPaymentsIsLoading = (): FetchPaymentsIsLoadingAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_LOADING
});

const fetchPaymentsIsSuccess = (
  payments: Payments
): FetchPaymentsIsSuccessAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS,
  payload: { ...payments }
});

const fetchPaymentsIsFailure = (): FetchPaymentsIsFailureAction => ({
  type: PaymentsActionTypes.FETCH_PAYMENTS_FAILURE
});

const fetchPayments =
  (year: string, month: string): ThunkActionCreator<PaymentsActions> =>
  async dispatch => {
    dispatch(fetchPaymentsIsLoading());
    try {
      const payments = await api.admin.payments.getPayments(year, month);
      dispatch(fetchPaymentsIsSuccess(payments));
    } catch (err) {
      dispatch(fetchPaymentsIsFailure());
    }
  };

const confirmPaymentIsSuccess = (
  data: ConfirmPaymentPayload
): ConfirmPaymentIsSuccessAction => ({
  type: PaymentsActionTypes.CONFIRM_PAYMENT,
  payload: data
});

const markPaymentIsSuccess = (
  affiliateId: number
): MarkPaymentIsSuccessAction => ({
  type: PaymentsActionTypes.MARK_PAYMENT,
  payload: { affiliateId }
});

const confirmPayment =
  (affiliateId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    const response = await api.admin.affiliate.invoices.confirmInvoice(
      affiliateId
    );
    if (response) {
      dispatch(confirmPaymentIsSuccess({ affiliateId, ...response }));
      dispatch(
        openSnackbar({
          type: "success",
          message: "Payment successful confirmed"
        })
      );
    }
  };

const markPayment =
  (affiliateId: number, invoiceId: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    const response = await api.admin.affiliate.invoices.markInvoiceAsPaid(
      affiliateId,
      invoiceId
    );
    if (response.ok) {
      dispatch(markPaymentIsSuccess(affiliateId));
      dispatch(
        openSnackbar({ type: "success", message: "Payment successful marked" })
      );
    }
  };

interface PaymentsState {
  error: boolean;
  isLoading: boolean;
  data: Payment[];
  totals: {
    closingBalance: number;
  };
  total: number;
}

const initialState: PaymentsState = {
  error: false,
  isLoading: false,
  data: [],
  total: 0,
  totals: {
    closingBalance: 0
  }
};

const reducer: Reducer<PaymentsState, PaymentsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PaymentsActionTypes.FETCH_PAYMENTS_LOADING:
      return {
        ...initialState,
        isLoading: true
      };
    case PaymentsActionTypes.FETCH_PAYMENTS_SUCCESS:
      return {
        error: false,
        isLoading: false,
        total: action.payload.affiliates.total,
        totals: action.payload.affiliates.totals,
        data: action.payload.affiliates.items.map(({ manager, ...rest }) => ({
          ...rest,
          manager: manager ? manager : "No manager"
        }))
      };
    case PaymentsActionTypes.FETCH_PAYMENTS_FAILURE:
      return {
        ...initialState,
        error: true
      };
    case PaymentsActionTypes.CONFIRM_PAYMENT:
      return {
        ...state,
        data: state.data.map(
          ({
            affiliateId,
            status,
            invoiceId,
            canConfirm,
            canMarkAsPaid,
            ...rest
          }) => {
            const isActive = affiliateId === action.payload.affiliateId;
            return {
              affiliateId,
              ...rest,
              canConfirm: isActive ? action.payload.canConfirm : canConfirm,
              canMarkAsPaid: isActive
                ? action.payload.canMarkAsPaid
                : canMarkAsPaid,
              status: isActive ? PAYMENT_STATUSES.confirmed : status,
              invoiceId: isActive ? action.payload.invoiceId : invoiceId
            };
          }
        )
      };
    case PaymentsActionTypes.MARK_PAYMENT:
      return {
        ...state,
        data: state.data.map(
          ({ affiliateId, status, canConfirm, canMarkAsPaid, ...rest }) => {
            const isActive = affiliateId === action.payload.affiliateId;
            return {
              ...rest,
              affiliateId,
              canConfirm: isActive ? false : canConfirm,
              canMarkAsPaid: isActive ? false : canMarkAsPaid,
              status: isActive ? PAYMENT_STATUSES.paid : status
            };
          }
        )
      };
    default:
      return state;
  }
};

const getPaymentsState = (state: AppState) => state.payments;
const getPaymentStateById = (state: AppState) => (id: string) => ({
  error: state.payments.error,
  isLoading: state.payments.isLoading,
  payment: state.payments.data.find(
    ({ affiliateId }) => affiliateId === Number(id)
  )
});

export {
  reducer,
  fetchPayments,
  markPayment,
  confirmPayment,
  getPaymentsState,
  getPaymentStateById
};
