import { Plan, PlanRule } from "@types";

import React, { FC, useEffect, useMemo } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useSelector, useDispatch } from "react-redux";
import Box, { BoxProps } from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import { TextCard, TextCardSkeleton } from "components/cards";
import formatMoney from "utils/formatMoney";
import getGroupedPlanRules from "utils/getGroupedPlanRules";
import { isAllCountriesSelected } from "constants/countries";
import { fetchUsers, getUserById } from "modules/users";

import { useStyles } from "./styles";

interface Props extends BoxProps {
  isAdmin?: boolean;
  planLabel: string;
  plan: Plan;
  rules: PlanRule[];
}

const PlanInfo: FC<Props> = ({
  isAdmin,
  planLabel,
  plan: { name, nrs, cpa: globalCPA, isLadder, createdBy, createdAt },
  rules,
  ...boxProps
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupedRules = useMemo(() => getGroupedPlanRules(rules), []);

  const { isLoading, user } = useSelector(getUserById)(createdBy);

  useEffect(() => {
    isAdmin && dispatch(fetchUsers());
  }, [dispatch, isAdmin]);

  return (
    <Box {...boxProps}>
      <Box className={classes.details}>
        <TextCard label={planLabel}>
          <Typography component="div" variant="h3" className={classes.name}>
            {name}
          </Typography>
        </TextCard>
        {isAdmin && (
          <Box className={classes.creator}>
            <TextCard label="Added">
              {format(parseISO(createdAt), "yyyy.MM.dd")}
            </TextCard>
            {isLoading ? (
              <TextCardSkeleton>
                <Skeleton width={128} height={24} />
              </TextCardSkeleton>
            ) : (
              user && <TextCard label="Added By">{user.email}</TextCard>
            )}
          </Box>
        )}
      </Box>
      <Divider className={classes.divider} />
      <Box>
        <Typography variant="h5">Global</Typography>
        <Box className={classes.ruleRow}>
          <Typography className={classes.label}>Net Revenue Share</Typography>
          <Typography className={classes.text}>
            {isLadder ? "Ladder" : `${nrs}%`}
          </Typography>
        </Box>
        {!!globalCPA && (
          <Box className={classes.ruleRow}>
            <Typography className={classes.label}>
              Cost per Acquisition
            </Typography>
            <Typography className={classes.text}>
              {formatMoney.from(globalCPA, { decimals: 0 })}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider className={classes.divider} />
      {groupedRules.map(
        ({ countryIds, nrs, cpa, deposit, deposit_cpa: depositCPA }, index) => (
          <Box className={classes.rule} key={index}>
            <Typography variant="h5">
              {isAllCountriesSelected(countryIds as string[])
                ? "ALL COUNTRIES"
                : countryIds.join(", ")}
            </Typography>
            <Box className={classes.ruleRow}>
              <Typography className={classes.label}>
                Net Revenue Share
              </Typography>
              <Typography className={classes.text}>{nrs}%</Typography>
            </Box>
            {(!!cpa || !!globalCPA) && (
              <Box className={classes.ruleRow}>
                <Typography className={classes.label}>
                  Cost per Acquisition
                </Typography>
                <Typography className={classes.text}>
                  {formatMoney.from(cpa, { decimals: 0 })}
                </Typography>
              </Box>
            )}
            {!!deposit && !!depositCPA && !!(depositCPA || deposit) && (
              <Box className={classes.ruleRow}>
                <Typography className={classes.label}>
                  {`${formatMoney.from(deposit, {
                    decimals: 0
                  })} Cumulative Deposit`}
                </Typography>
                <Typography className={classes.text}>
                  {formatMoney.from(depositCPA, { decimals: 0 })}
                </Typography>
              </Box>
            )}
          </Box>
        )
      )}
    </Box>
  );
};

export default PlanInfo;
