import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import AffiliatePayments, {
  fetchAffiliateViewPayments
} from "modules/affiliate-payments";

const AffiliateViewPaymentsPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAffiliateViewPayments());
  }, [dispatch]);

  return <AffiliatePayments />;
};

export default AffiliateViewPaymentsPage;
