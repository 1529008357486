import { Reducer } from "redux";

import { AppState } from "store";

export enum SnackBarLayoutTypes {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info"
}

enum SnackBarTypes {
  OPEN_SNACKBAR = "@snackbar/open-snackbar",
  CLOSE_SNACKBAR = "@snackbar/close-snackbar"
}

interface SnackBarPayload {
  type?: "success" | "error" | "warning" | "info";
  message: string;
}

interface OpenSnackbarAction {
  type: SnackBarTypes.OPEN_SNACKBAR;
  payload: SnackBarPayload;
}

interface CloseSnackbarAction {
  type: SnackBarTypes.CLOSE_SNACKBAR;
}

type SnackbarActionsType = OpenSnackbarAction | CloseSnackbarAction;
export type SnackbarActions<T> = T | SnackbarActionsType;

const openSnackbar = (data: SnackBarPayload): OpenSnackbarAction => ({
  type: SnackBarTypes.OPEN_SNACKBAR,
  payload: data
});

const closeSnackbar = (): CloseSnackbarAction => ({
  type: SnackBarTypes.CLOSE_SNACKBAR
});

interface SnackBarState extends SnackBarPayload {
  isOpen: boolean;
}

const initialState: SnackBarState = {
  isOpen: false,
  type: undefined,
  message: ""
};

const reducer: Reducer<SnackBarState, SnackbarActionsType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SnackBarTypes.OPEN_SNACKBAR:
      return {
        ...state,
        ...action.payload,
        isOpen: true
      };
    case SnackBarTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};

const getSnackbarState = (state: AppState) => state.snackbar;

export { reducer, openSnackbar, closeSnackbar, getSnackbarState };
