import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useStyles } from "./styles";
import {
  getInfoDialogStatus,
  setInformationDialogStatus
} from "modules/affiliate";
import { useTheme, useMediaQuery } from "@material-ui/core";

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isOpen = useSelector(getInfoDialogStatus);
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(!isOpen);

  const handleClose = useCallback(() => {
    setInfoDialogOpen(false);
    dispatch(setInformationDialogStatus(true));
  }, [dispatch]);

  return {
    isOpen: infoDialogOpen,
    fullScreen,
    classes,
    handleClose
  };
}
