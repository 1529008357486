import { Reducer } from "redux";
import { GoogleAccount, GoogleLoginResponse } from "./types";
import {
  decodeGoogleToken,
  removeGoogleCookie,
  setGoogleCookie
} from "./utils";

const GOOGLE_SIGN_IN_REQUEST = "google-auth/GOOGLE_SIGN_IN_REQUEST";
const GOOGLE_SIGN_IN_SUCCESS = "google-auth/GOOGLE_SIGN_IN_SUCCESS";
const GOOGLE_SIGN_IN_FAILURE = "google-auth/GOOGLE_SIGN_IN_FAILURE";
const GOOGLE_LOGOUT = "google-auth/GOOGLE_LOGOUT";

interface GoogleSignInRequestAction {
  type: typeof GOOGLE_SIGN_IN_REQUEST;
}

interface GoogleSignInSuccessAction {
  type: typeof GOOGLE_SIGN_IN_SUCCESS;
  payload: GoogleLoginResponse;
}

interface GoogleSignInFailureAction {
  type: typeof GOOGLE_SIGN_IN_FAILURE;
  payload: {
    error: string;
  };
}

interface GoogleLogout {
  type: typeof GOOGLE_LOGOUT;
}

export interface GoogleAuthState {
  user: GoogleAccount | null;
  loading: boolean;
  error: string | null;
}

type GoogleSignInActions =
  | GoogleSignInRequestAction
  | GoogleSignInSuccessAction
  | GoogleSignInFailureAction
  | GoogleLogout;

const initialState: GoogleAuthState = {
  user: null,
  loading: false,
  error: null
};

const reducer: Reducer<GoogleAuthState, GoogleSignInActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GOOGLE_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GOOGLE_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: decodeGoogleToken(action.payload.credential),
        loading: false,
        error: null
      };
    case GOOGLE_SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case GOOGLE_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

const googleSignInRequest = (): GoogleSignInRequestAction => ({
  type: GOOGLE_SIGN_IN_REQUEST
});

const googleSignInSuccess = (
  response: GoogleLoginResponse
): GoogleSignInSuccessAction => {
  if (response.credential) {
    setGoogleCookie(response.credential);
  }

  return {
    type: GOOGLE_SIGN_IN_SUCCESS,
    payload: response
  };
};

const googleSignInFailure = (error: string): GoogleSignInFailureAction => ({
  type: GOOGLE_SIGN_IN_FAILURE,
  payload: {
    error
  }
});

const googleLogout = (): GoogleLogout => {
  removeGoogleCookie();

  return {
    type: GOOGLE_LOGOUT
  };
};

const getGoogleUser = (state: unknown & { googleAuth: GoogleAuthState }) =>
  state.googleAuth.user;

export {
  reducer,
  googleSignInRequest,
  googleSignInSuccess,
  googleSignInFailure,
  getGoogleUser,
  googleLogout
};
