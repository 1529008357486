export const ROUTE_BASENAME = "/backoffice";
export const BASE_API_URL = "/api/v1";
export const BASE_REFERRAL_URL = "https://affmore.com/backoffice/register?ref=";
export const LINK_URL = "https://affmore.com/clk/";
export const LOG_SYSTEM_NAME = "system@affmore";

export const AFFILIATE_TABS = [
  {
    label: "Overview",
    value: "overview"
  },
  {
    label: "Revenue",
    value: "revenue"
  },
  {
    label: "Links",
    value: "links"
  },
  {
    label: "Players",
    value: "players"
  },
  {
    label: "Invoices",
    value: "invoices"
  },
  {
    label: "Sub-Affiliates",
    value: "subs"
  },
  {
    label: "Log",
    value: "log"
  },
  {
    label: "Activity",
    value: "activities"
  },
  {
    label: "Callbacks",
    value: "callbacks"
  }
];

export const MONEY_KEYS = [
  "amount",
  "balance",
  "cpa",
  "deposit",
  "deposit_cpa",
  "deposits",
  "turnover",
  "grossRevenue",
  "bonuses",
  "adjustments",
  "fees",
  "netRevenue",
  "commission",
  "tax",
  "total",
  "openingBalance",
  "creditedAmount",
  "creditAmount",
  "debitAmount",
  "closingBalance",
  "paidAmount",
  "paymentMinAmount"
];
