import React, { FC } from "react";
import SwipeableDrawer, {
  SwipeableDrawerProps
} from "@material-ui/core/SwipeableDrawer";
import Hidden from "@material-ui/core/Hidden";
import CloseIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";

import { useStyles } from "./styles";

type Props = Omit<
  SwipeableDrawerProps,
  "anchor" | "elevation" | "classes" | "className"
>;

const Drawer: FC<Props> = ({ children, onClose, ...rest }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      {...rest}
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
      className={classes.drawer}
      onClose={onClose}
    >
      <Hidden mdUp implementation="css">
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </Hidden>
      {children}
    </SwipeableDrawer>
  );
};

export default Drawer;
