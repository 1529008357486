import {
  AffiliateInvoice,
  CreatePaymentRequest,
  UpdateAffiliatePaymentInformation
} from "@types";
import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteComponentProps } from "react-router-dom";
import { FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";

import { openDrawer } from "drawers";
import formatMoney from "utils/formatMoney";
import Drawer from "components/drawer";
import {
  getAffiliateState,
  getAffiliatePaymentInformationState,
  updateAffiliatePaymentInformation
} from "modules/affiliate";
import Invoice from "modules/affiliate-invoice";
import {
  DraftInvoiceTable,
  InvoicesTable,
  PaymentInfo,
  confirmAffiliateDraftPayments,
  createAffiliatePayment,
  fetchAffiliateInvoices
} from "modules/affiliate-invoices";
import { openDialog } from "modules/dialog";

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

const AffiliateInvoicesPage: FC<Props> = ({ match, history }) => {
  const dispatch = useDispatch();
  const { id } = match.params;

  const { data: profile } = useSelector(getAffiliateState);
  const paymentInformation = useSelector(getAffiliatePaymentInformationState);

  useEffect(() => {
    dispatch(fetchAffiliateInvoices(Number(id)));
  }, [dispatch, id]);

  const handleOpenDrawer = useCallback(() => null, []);

  const handleCloseDrawer = useCallback(() => {
    history.push(match.url);
  }, [match, history]);

  const handleEditPaymentInfo = useCallback(
    (
      values: UpdateAffiliatePaymentInformation,
      helpers: FormikHelpers<UpdateAffiliatePaymentInformation>
    ) => {
      const data = {
        ...values,
        paymentMinAmount: formatMoney.to(values.paymentMinAmount)
      };

      dispatch(updateAffiliatePaymentInformation(Number(id), data, helpers));
    },
    [dispatch, id]
  );

  const handleEditPaymentInfoOpen = useCallback(() => {
    dispatch(
      openDrawer("edit-payment-information", {
        initialState: formatMoney.fromObject(paymentInformation),
        onSubmit: handleEditPaymentInfo
      })
    );
  }, [dispatch, paymentInformation, handleEditPaymentInfo]);

  const handleCreatePayment = useCallback(
    (
      values: CreatePaymentRequest,
      helpers: FormikHelpers<CreatePaymentRequest>
    ) => {
      const data = {
        ...values,
        amount: formatMoney.to(values.amount)
      };
      dispatch(createAffiliatePayment(Number(id), data, helpers));
    },
    [dispatch, id]
  );

  const handleCreatePaymentOpen = useCallback(() => {
    dispatch(
      openDrawer("create-payment", {
        onSubmit: handleCreatePayment
      })
    );
  }, [dispatch, handleCreatePayment]);

  const handleCreateInvoice = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "confirm-draft-payments",
        payload: {
          type: "submit",
          title: "Are you sure you want to confirm invoice?",
          subtitle: "This operation cannot be undone",
          onConfirm: () => dispatch(confirmAffiliateDraftPayments(Number(id)))
        }
      })
    );
  }, [dispatch, id]);

  const handleInvoiceClick = useCallback(
    ({ invoiceId }: AffiliateInvoice) => {
      history.push(`${match.url}/${invoiceId}`);
    },
    [history, match]
  );

  return (
    <Box>
      {!profile?.masterId && (
        <PaymentInfo handleEditPayment={handleEditPaymentInfoOpen} />
      )}
      <DraftInvoiceTable
        handleCreateInvoice={handleCreateInvoice}
        handleCreatePayment={handleCreatePaymentOpen}
      />
      <InvoicesTable handleRowClick={handleInvoiceClick} />
      <Drawer
        open={!match.isExact}
        onOpen={handleOpenDrawer}
        onClose={handleCloseDrawer}
      >
        <Route exact path={`${match.path}/:invoiceId`} component={Invoice} />
      </Drawer>
    </Box>
  );
};

export default AffiliateInvoicesPage;
