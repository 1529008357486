import React, { FC } from "react";

import ForgotPassword from "modules/forgot-password";
import NonLoggedin from "components/nonloggedin";

const AffiliateViewForgotPasswordPage: FC = () => (
  <NonLoggedin>
    <ForgotPassword />
  </NonLoggedin>
);

export default AffiliateViewForgotPasswordPage;
