import { CreateLandingRequest } from "@types";

import React, { FC } from "react";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { BRAND_SELECT_OPTIONS, BRANDS } from "constants/brands";
import SubmitButton from "components/submit-button";
import SelectField from "components/select-field";
import TextField from "components/text-field";

import validationSchema from "./validationSchema";

export interface CreateLandingProps {
  title: string;
  initialState?: CreateLandingRequest;
  onSubmit: (
    value: CreateLandingRequest,
    helpers: FormikHelpers<CreateLandingRequest>
  ) => void;
}

const initialValues: CreateLandingRequest = {
  brandId: BRANDS.LD,
  landingPage: ""
};

const CreateLanding: FC<CreateLandingProps> = ({
  title,
  initialState,
  onSubmit
}) => {
  const getBrandValue = (value: string) =>
    BRAND_SELECT_OPTIONS.find(brand => brand.value === String(value));

  return (
    <Formik
      initialValues={initialState ? initialState : initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form className="drawer-container" onSubmit={handleSubmit}>
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              {title}
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <SelectField
            name="brandId"
            label="Brand"
            isSearchable={false}
            placeholder="Select brand"
            options={BRAND_SELECT_OPTIONS}
            value={getBrandValue(values.brandId)}
          />
          <TextField label="landingPage" name="landingPage" />
        </form>
      )}
    </Formik>
  );
};

export default CreateLanding;
