/* eslint-disable no-useless-escape */
import * as yup from "yup";
import { isURL } from "utils/regexes";

const validationSchema = yup.object().shape({
  callback: yup.object().shape({
    linkId: yup.number().nullable().notRequired(),
    brandId: yup.string().required("Brand is required"),
    method: yup.string().required("Method is required"),
    trigger: yup.string().required("Trigger is required"),
    url: yup
      .string()
      .matches(isURL, "Value must be a valid URL")
      .required("URL is required"),
    enabled: yup.boolean()
  })
});

export default validationSchema;
