import { Font } from "@react-pdf/renderer";
import Black from "assets/fonts/rubik-black.ttf";
import Bold from "assets/fonts/rubik-bold.ttf";
import Medium from "assets/fonts/rubik-medium.ttf";
import Regular from "assets/fonts/rubik-regular.ttf";

const registerDocumentFonts = () => {
  Font.register({
    family: "Rubik",
    fonts: [
      { src: Regular },
      { src: Medium, fontWeight: 500 },
      { src: Bold, fontWeight: 700 },
      { src: Black, fontWeight: 900 }
    ]
  });
};

export default registerDocumentFonts;
