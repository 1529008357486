import { CreateAffiliateLinkValues } from "@types";

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import TextField from "components/text-field";
import SelectField from "components/select-field";
import SubmitButton from "components/submit-button";
import { fetchPlans, getPlansSelect } from "modules/plans";
import { BRANDS, BRAND_SELECT_OPTIONS } from "constants/brands";
import { fetchLandings, getLandingsSelect } from "modules/landings";

import validationSchema from "./validationSchema";

export interface CreateLinkProps {
  title: string;
  initialState?: CreateAffiliateLinkValues;
  onSubmit: (
    values: CreateAffiliateLinkValues,
    helpers: FormikHelpers<CreateAffiliateLinkValues>
  ) => void;
}

const initialValues: CreateAffiliateLinkValues = {
  name: "",
  landingPage: "",
  brandId: null,
  planId: null
};

const CreateLink: FC<CreateLinkProps> = ({ title, initialState, onSubmit }) => {
  const dispatch = useDispatch();

  const landings = useSelector(getLandingsSelect);
  const plans = useSelector(getPlansSelect);

  const getBrandValue = (value: BRANDS | null) =>
    value ? BRAND_SELECT_OPTIONS.find(brand => brand.value === value) : null;

  const getDealValue = (value?: number | null) =>
    value ? plans.find(plan => plan.value === value.toString()) : null;

  const getLandingValue = (value?: string) =>
    value ? { value, label: value } : null;

  useEffect(() => {
    Promise.all([dispatch(fetchPlans()), dispatch(fetchLandings())]);
  }, [dispatch]);

  return (
    <Formik
      initialValues={initialState ? initialState : initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => (
        <form className="drawer-container" onSubmit={handleSubmit}>
          <Box className="drawer-header">
            <Typography variant="h4" className="drawer-header_title">
              {title}
            </Typography>
            <SubmitButton>save changes</SubmitButton>
          </Box>
          <Box>
            <TextField name="name" label="name" />
            <SelectField
              name="brandId"
              label="Brand"
              placeholder="Select brand"
              isSearchable={false}
              isDisabled={initialState ? !!initialState.brandId : false}
              options={BRAND_SELECT_OPTIONS}
              value={getBrandValue(values.brandId)}
            />
            <SelectField
              isCreatable
              isClearable
              name="landingPage"
              label="Landing"
              placeholder="Select or create landing page"
              options={values.brandId ? landings[values.brandId] : []}
              value={getLandingValue(values.landingPage)}
            />
            <SelectField
              isClearable
              name="planId"
              label="Deal"
              placeholder="Search deal by name"
              options={plans}
              value={getDealValue(values.planId)}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateLink;
