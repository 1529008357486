import { PAYMENT_METHODS, PAYMENT_METHODS_NAMES } from "constants/payments";
import * as yup from "yup";

const getPaymentName = (method: string) => {
  switch (method) {
    case PAYMENT_METHODS.bank:
      return PAYMENT_METHODS_NAMES.bank;
    case PAYMENT_METHODS.skrill:
      return PAYMENT_METHODS_NAMES.skrill;
    case PAYMENT_METHODS.casino:
      return PAYMENT_METHODS_NAMES.casino;
    default:
      return PAYMENT_METHODS_NAMES.bank;
  }
};

const skrillAccountValidationSchema = yup.object().shape({
  skrillAccount: yup
    .string()
    .email("Skrill account must be a valid email")
    .required("Skrill account is required")
});
const casinoAccountValidationSchema = yup.object().shape({
  casinoAccountEmail: yup
    .string()
    .email("Casino account must be a valid email")
    .required("Casino account is required")
});
const bankAccountValidationSchema = yup.object().shape({
  bankAccountHolder: yup.string().required("Bank account holder is required"),
  bankIban: yup.string().required("Account number (IBAN) is required"),
  bankBic: yup.string().required("BIC/SWIFT is required"),
  bankName: yup.string().required("Bank name is required"),
  bankClearingNumber: yup.string().notRequired(),
  bankAddress: yup.string().notRequired(),
  bankPostCode: yup.string().notRequired(),
  bankCountry: yup.string().notRequired()
});

export {
  getPaymentName,
  skrillAccountValidationSchema,
  casinoAccountValidationSchema,
  bankAccountValidationSchema
};
