import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

import { useSmallInputStyles } from "./styles";

const Input = ({ value, size, ...rest }: TextFieldProps) => {
  const classes = useSmallInputStyles();

  return (
    <TextField
      {...rest}
      classes={size === "small" ? classes : {}}
      value={value ? value : ""}
    />
  );
};

export default Input;
