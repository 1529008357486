import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  isSelected: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme =>
  createStyles({
    root: {
      paddingLeft: 32
    },
    indicator: {
      position: "absolute",
      left: 0,
      width: 4,
      height: "100%",
      background: theme.colors.blue,
      borderRadius: "0px 4px 4px 0px"
    },
    text: ({ isSelected }) => ({
      color: isSelected ? theme.colors.blue : theme.colors.black21
    }),
    title: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500
    },
    description: {
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black75,
      maxWidth: 316
    }
  })
);

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 380,
      width: "100%",
      height: "100%",
      maxHeight: "calc(100% - 154px)",
      padding: 0
    },
    container: {
      height: 48,
      margin: "0 32px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    title: {
      margin: 0
    }
  })
);

export { useStyles, useSkeletonStyles };
