/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { getDatePickerState } from "modules/datepicker";

import isActualDate from "./helpers/isActualDate";

const DateRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const Component: any = component;
  const { year: prevYear, month: prevMonth } = useSelector(getDatePickerState);

  return (
    <Route
      {...rest}
      render={routeProps => {
        const {
          path,
          params: { id, playerId, year, month }
        } = routeProps.match;

        const replacements = {
          ":id": id,
          ":playerId": playerId,
          ":year": prevYear,
          ":month": prevMonth
        };

        const redirectPath = path.replace(
          /:id|:playerId|:year|:month/gi,
          match => replacements[match as never]
        );

        if (year && month && !isActualDate(year, month)) {
          return <Redirect to={redirectPath} />;
        }

        return <Component {...routeProps} />;
      }}
    />
  );
};

export default DateRoute;
