import { SubAffiliate, CreateSubAffiliateValues } from "@types";

import React, { FC, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import Box from "@material-ui/core/Box";

import { useUrlDateCheck } from "hooks";
import { openDrawer } from "drawers";
import SubAffiliates, {
  fetchAffiliateSubAffiliates,
  addAffiliateSubAffiliate,
  updateAffiliateSubAffiliate
} from "modules/affiliate-sub-afiiliates";

interface Params {
  id: string;
  year: string;
  month: string;
}

const AffiliateSubAffiliatesPage: FC = () => {
  const dispatch = useDispatch();
  const { id, year, month } = useParams<Params>();

  const handleAddSubAffiliate = useCallback(
    (
      { subId, commissionShare }: CreateSubAffiliateValues,
      helpers: FormikHelpers<CreateSubAffiliateValues>
    ) => {
      dispatch(
        addAffiliateSubAffiliate(
          Number(subId),
          { commissionShare: Number(commissionShare) },
          { affiliateId: Number(id), year: Number(year), month: Number(month) },
          helpers
        )
      );
    },
    [dispatch, id, year, month]
  );

  const handleEditSubAffiliate = useCallback(
    (
      { subId, commissionShare }: CreateSubAffiliateValues,
      helpers: FormikHelpers<CreateSubAffiliateValues>
    ) => {
      dispatch(
        updateAffiliateSubAffiliate(
          Number(subId),
          { commissionShare: Number(commissionShare) },
          { affiliateId: Number(id), year: Number(year), month: Number(month) },
          helpers
        )
      );
    },
    [dispatch, id, year, month]
  );

  const handleAddDrawerOpen = useCallback(() => {
    dispatch(
      openDrawer("add-subaffiliate", {
        affiliateId: id,
        onSubmit: handleAddSubAffiliate
      })
    );
  }, [dispatch, id, handleAddSubAffiliate]);

  const handleEditCommission = useCallback(
    ({ affiliateId, commissionShare }: SubAffiliate) => {
      dispatch(
        openDrawer("edit-commission", {
          initialState: {
            subId: affiliateId,
            commissionShare: commissionShare.toString()
          },
          onSubmit: handleEditSubAffiliate
        })
      );
    },
    [dispatch, handleEditSubAffiliate]
  );

  const handleRowClick = useCallback(
    (affiliate: SubAffiliate) => {
      dispatch(
        openDrawer("affiliate-details", {
          affiliate,
          handleEditCommission
        })
      );
    },
    [dispatch, handleEditCommission]
  );

  useUrlDateCheck();

  useEffect(() => {
    dispatch(
      fetchAffiliateSubAffiliates({
        affiliateId: Number(id),
        year: Number(year),
        month: Number(month)
      })
    );
  }, [dispatch, id, year, month]);

  return (
    <Box display="flex" flexDirection="column">
      <SubAffiliates
        handleRowClick={handleRowClick}
        handleAddDrawerOpen={handleAddDrawerOpen}
      />
    </Box>
  );
};

export default AffiliateSubAffiliatesPage;
