import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

import { useSkeletonStyles } from "./styles";

interface SkeletonProps {
  isOverview?: boolean;
}

const AffiliateSkeleton: FC<SkeletonProps> = ({ isOverview }) => {
  const classes = useSkeletonStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Skeleton className={classes.loginDate} width={256} />
        <Skeleton className={classes.name} width={360} height={32} />
      </Box>
      <Box className={classes.buttons}>
        {isOverview && (
          <Skeleton className={classes.button} width={108} height={32} />
        )}
        <Skeleton
          width={108}
          className={classes.button}
          height={32}
          style={{ marginLeft: 8 }}
        />
      </Box>
    </Box>
  );
};

export default AffiliateSkeleton;
