import * as yup from "yup";
import { isURL } from "utils/regexes";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  landingPage: yup
    .string()
    .matches(isURL, "Landing must be a valid URL")
    .required("Landing is required")
});

export default validationSchema;
