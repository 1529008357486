import { AffiliateInvoice } from "@types";

import React, { FC } from "react";
import cn from "classnames";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { PAYMENT_STATUSES } from "constants/payments";
import formatMoney from "utils/formatMoney";
import { TooltipCard } from "components/cards";

const useStyles = makeStyles(theme =>
  createStyles({
    date: {
      marginLeft: 16,
      fontWeight: 500
    },
    id: {
      fontSize: 24,
      lineHeight: "32px"
    },
    info: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden"
    },
    paid: {
      color: theme.colors.teal
    },
    confirmed: {
      color: theme.colors.orange
    },
    divider: {
      margin: "16px 0 32px 0"
    }
  })
);

type Props = AffiliateInvoice;

const InvoiceInformation: FC<Props> = ({
  invoiceId,
  invoiceNumber,
  invoiceDate,
  creditedAmount,
  paidAmount,
  status
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h3" className={classes.date}>
        {format(parseISO(invoiceDate), "MMMM yyyy")}
      </Typography>
      <Box className={classes.info}>
        <TooltipCard label="Invoice ID">{invoiceId}</TooltipCard>
        <TooltipCard label="Invoice Number">{invoiceNumber}</TooltipCard>
        <TooltipCard
          label="Invoice Status"
          className={cn({
            [classes.paid]: status === PAYMENT_STATUSES.paid,
            [classes.confirmed]: status === PAYMENT_STATUSES.confirmed
          })}
        >
          {status}
        </TooltipCard>
      </Box>
      <Box className={classes.info}>
        <TooltipCard label="Credited Amount">
          {formatMoney.from(Number(creditedAmount))}
        </TooltipCard>
        <TooltipCard label="Paid Amount">
          {formatMoney.from(Number(paidAmount))}
        </TooltipCard>
      </Box>
    </Box>
  );
};

export default InvoiceInformation;
