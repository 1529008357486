import { OverviewAffiliate } from "@types";

import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Search from "components/search";
import EmptyData from "components/empty-data";
import Table, { Column, LinkColumn, TableSkeleton } from "components/table";

import SearchButtons from "./components/SearchButtons";
import useOverview from "./useOverview";

export interface DisplayAffiliate
  extends Omit<OverviewAffiliate, "affiliateId"> {
  affiliateId: string;
}

const CSV_HEADERS = [
  { label: "ID", key: "affiliateId" },
  { label: "NRC", key: "newRegisteredPlayers" },
  { label: "NDC", key: "newDepositingPlayers" },
  { label: "Total Registered", key: "registeredPlayers" },
  { label: "Total Depositing", key: "depositingPlayers" },
  { label: "Conversion Rate", key: "conversionRate" },
  { label: "Deposits", key: "deposits" },
  { label: "Net Revenue", key: "netRevenue" },
  { label: "CPA", key: "cpa" },
  { label: "Commission", key: "commission" },
  { label: "Balance", key: "balance" }
];

const Component: FC = () => {
  const {
    isEmpty,
    isEmptyOverview,
    isLoading,
    error,
    year,
    month,
    searchQuery,
    totals,
    overview,
    includeInternals,
    brand,
    handleBrand,
    handleSearch,
    handleIncludeInternals
  } = useOverview();

  return (
    <Box>
      <Search
        value={searchQuery}
        onChange={handleSearch}
        disabled={isEmpty}
        placeholder="Search by ID"
        buttons={
          <SearchButtons
            disabled={isEmptyOverview}
            csvProps={{ data: overview, headers: CSV_HEADERS }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filterProps={{ brand, onChange: handleBrand }}
          />
        }
      />
      <FormControlLabel
        style={{ marginBottom: 16 }}
        label={
          <Typography variant="body2">
            Include internal tracking accounts
          </Typography>
        }
        control={
          <Checkbox
            checked={includeInternals}
            onChange={handleIncludeInternals}
          />
        }
      />
      <Box height="calc(100vh - 198px)">
        {isLoading ? (
          <TableSkeleton rows={14} cells={11} />
        ) : isEmpty || error ? (
          <EmptyData />
        ) : (
          <Table
            initialData={overview}
            estimatedItemSize={49}
            totals={{ title: "Subtotal", totals: totals }}
          >
            <LinkColumn
              label="ID"
              name="affiliateId"
              align="left"
              href={`/admin/affiliates/{affiliateId}/overview/${year}/${month}`}
              style={{
                maxWidth: 96,
                fontWeight: 500
              }}
            />
            <Column label="NRC" name="newRegisteredPlayers" type="text" />
            <Column label="NDC" name="newDepositingPlayers" type="text" />
            <Column label="Registered" name="registeredPlayers" type="text" />
            <Column label="Depositing" name="depositingPlayers" type="text" />
            <Column label="Conversion" name="conversionRate" type="percent" />
            <Column label="Deposits" name="deposits" type="currency" />
            <Column label="Net Revenue" name="netRevenue" type="currency" />
            <Column label="CPA" name="cpa" type="currency" />
            <Column label="Commission" name="commission" type="currency" />
            <Column label="Balance" name="balance" type="currency" />
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default Component;
