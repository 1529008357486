import { Fee, FeeRule } from "@types";

import React, { FC, useMemo } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { TextCard } from "components/cards";
import getGroupedFeeRules from "utils/getGroupedFeeRules";
import { isAllCountriesSelected } from "constants/countries";
import { DEFAULT_FEE } from "constants/fee";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: "0 16px"
    },
    details: {
      margin: "0 -16px 0 -16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    name: {
      whiteSpace: "normal"
    },
    creator: {
      display: "flex",
      flexWrap: "wrap",
      overflow: "hidden",
      marginTop: "-16px"
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      padding: "4px 0"
    },
    rule: {
      marginTop: "32px",
      "&:last-child": {
        marginBottom: "8px"
      }
    },
    ruleRow: {
      display: "flex",
      justifyContent: "space-between",
      padding: "4px 0"
    },
    label: {
      fontSize: 14,
      lineHeight: "24px"
    },
    text: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 500
    },
    subtext: {
      fontSize: 14,
      color: theme.colors.black9e,
      fontStyle: "italic"
    },
    divider: {
      height: "1px",
      margin: "16px -32px"
    },
    toggle: {
      "& .MuiTypography-root": {
        overflow: "visible !important"
      }
    }
  })
);

interface Props extends BoxProps {
  feeLabel?: string;
  fee: Fee;
  rules: FeeRule[];
}

const FeeDetails: FC<Props> = ({
  feeLabel = "Name",
  fee: { name, percent, nextMonthPercent, draftRemovedAt },
  rules
}) => {
  const classes = useStyles();
  const groupedRules = useMemo(
    () =>
      getGroupedFeeRules(rules).filter(
        ({ percent, nextMonthPercent }) =>
          percent !== null || nextMonthPercent !== null
      ),
    [rules]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.details}>
        <TextCard label={feeLabel}>
          <Typography component="div" variant="h3" className={classes.name}>
            {name || "Default"}
          </Typography>
        </TextCard>
      </Box>
      <Divider className={classes.divider} />
      <Box>
        <Typography variant="h5">Global</Typography>
        <Box className={classes.row}>
          <Typography className={classes.label}>Fee</Typography>
          <Typography className={classes.text}>{`${
            percent || DEFAULT_FEE
          }%`}</Typography>
        </Box>
        {typeof nextMonthPercent !== "undefined" &&
          nextMonthPercent !== percent &&
          !draftRemovedAt && (
            <Box className={classes.row}>
              <Typography className={classes.subtext}>
                Next Month Fee
              </Typography>
              <Typography className={classes.subtext} variant="subtitle2">
                {nextMonthPercent !== null ? `${nextMonthPercent}%` : "DEFAULT"}
              </Typography>
            </Box>
          )}
      </Box>
      <Divider className={classes.divider} />
      {groupedRules.map(
        (
          {
            countryIds,
            percent: rulePercent,
            nextMonthPercent: ruleNextMonthPercent
          },
          index
        ) => (
          <Box className={classes.rule} key={index}>
            <Typography variant="h5">
              {isAllCountriesSelected(countryIds as string[])
                ? "ALL COUNTRIES"
                : countryIds.join(", ")}
            </Typography>
            <Box className={classes.ruleRow}>
              <Typography className={classes.label}>Fee</Typography>
              <Typography className={classes.text}>
                {rulePercent || DEFAULT_FEE}%
              </Typography>
            </Box>
            {typeof ruleNextMonthPercent !== "undefined" &&
              ruleNextMonthPercent !== rulePercent &&
              !draftRemovedAt && (
                <Box className={classes.row}>
                  <Typography className={classes.subtext}>
                    Next Month Fee
                  </Typography>
                  <Typography className={classes.subtext} variant="subtitle2">
                    {ruleNextMonthPercent !== null
                      ? `${ruleNextMonthPercent}%`
                      : nextMonthPercent
                      ? `${nextMonthPercent}%`
                      : `${percent}`}
                  </Typography>
                </Box>
              )}
          </Box>
        )
      )}
    </Box>
  );
};

export default FeeDetails;
