import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoRounded";

import EmptyData from "components/empty-data";
import { ActionCard } from "components/cards";
import { BRANDS, BRAND_NAMES } from "constants/brands";
import getBrandColorClassName from "utils/getBrandColorClassName";

import Skeleton from "./Skeleton";
import useAffiliateDeals from "./useAffiliateDeals";

interface Props {
  handleDealClick: (brandId: BRANDS) => void;
}
const Component: FC<Props> = ({ handleDealClick }) => {
  const { classes, isLoading, error, deals } = useAffiliateDeals();

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <EmptyData />;
  }

  return (
    <>
      <Typography component="div" className={classes.title}>
        Deals
      </Typography>
      {isEmpty(deals) ? (
        <EmptyData />
      ) : (
        <Box className={classes.row}>
          {deals.map(({ brandId, name }) => (
            <ActionCard
              key={brandId}
              label={BRAND_NAMES[brandId]}
              labelClassName={getBrandColorClassName(brandId)}
              onClick={() => handleDealClick(brandId)}
            >
              <Box className={classes.flexCenter}>
                <Typography
                  noWrap
                  component="span"
                  className={classes.brandText}
                >
                  {name}
                </Typography>
                <InfoIcon className={classes.icon} />
              </Box>
            </ActionCard>
          ))}
        </Box>
      )}
      <Divider className={classes.divider} />
    </>
  );
};

export default Component;
