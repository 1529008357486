import React from "react";
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { Divider as MaterialDivider } from "@material-ui/core";
import { DividerProps } from "@material-ui/core/Divider";

const Divider = withStyles(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.colors.blacke0,
    margin: "32px 0"
  }
}))((props: DividerProps) => <MaterialDivider {...props} />);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%"
    },
    emptyData: {
      marginBottom: 32
    },
    flexCenter: {
      display: "flex",
      alignItems: "center"
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      margin: -16,
      overflow: "hidden",
      "& .MuiCardContent-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
      "& .MuiTypography-subtitle1": {
        maxWidth: 120
      }
    },
    brandText: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      maxWidth: "256px"
    },
    cardContent: {},
    multilineContent: {
      display: "flex",
      flexDirection: "column"
    },
    lineContent: {
      display: "flex",
      "&>:first-child": {
        fontWeight: 500
      },
      "&>:not(:first-child)": {
        marginLeft: 4
      }
    },
    divider: {
      height: 1,
      backgroundColor: theme.colors.blacke0,
      margin: "32px 0"
    },
    icon: {
      fontSize: 16,
      marginLeft: 8,
      color: theme.colors.blacke0
    },
    dealsLabel: {
      fontSize: 14,
      lineHeight: "24px",
      marginBottom: 16
    },
    result: {
      height: 30,
      width: "fit-content",
      fontSize: 12,
      lineHeight: "16px",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "0 8px"
    },
    positive: {
      background: theme.colors.teal50,
      color: theme.colors.green
    },
    negative: {
      background: theme.colors.red10,
      color: theme.colors.red
    },
    neutral: {
      background: theme.colors.orange10,
      color: theme.colors.orange
    },
    brandsMenu: {
      minWidth: "130px"
    }
  })
);

const useSkeletonStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
      flexWrap: "wrap",
      margin: -16,
      overflow: "hidden"
    },
    box: {
      width: 110,
      height: 30,
      margin: 0,
      marginTop: 2
    },
    smallBox: {
      margin: 0,
      marginTop: 2,
      width: 100,
      height: 14
    },
    mediumBox: {
      margin: 0,
      marginTop: 4,
      width: 110,
      height: 20
    }
  })
);

export { useStyles, useSkeletonStyles, Divider };
