import * as yup from "yup";

const ruleValidationSchema = yup.object().shape({
  countryIds: yup
    .array()
    .of(yup.string().notRequired())
    .required("Countries is required"),
  percent: yup.number().min(0).max(100).required("Fee % is required")
});

const validationSchema = yup.object().shape({
  fee: yup.object().shape({
    name: yup.string().required("Name is required"),
    percent: yup
      .number()
      .min(0)
      .max(100, "Fee must be less than or equal to 100")
      .required("Fee is required"),
    active: yup.boolean()
  }),
  rules: yup.array().of(ruleValidationSchema)
});

export { ruleValidationSchema };
export default validationSchema;
