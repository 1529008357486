import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 500,
      color: theme.colors.black21
    },
    text: {
      fontSize: 14,
      lineHeight: "24px",
      color: theme.colors.black75,
      marginTop: 8
    }
  })
);

export { useStyles };
