import { Landing, CreateLandingRequest } from "@types";

import { FormikHelpers } from "formik";
import { Reducer } from "redux";
import groupBy from "lodash/groupBy";

import api from "api";
import { AppState, ThunkActionCreator } from "store";
import { closeDrawer, DrawersActions } from "drawers";
import { openSnackbar, SnackbarActions } from "modules/snackbar";

enum LandingsActionTypes {
  FETCH_LANDINGS_LOADING = "@landings/fetch-landings-loading",
  FETCH_LANDINGS_SUCCESS = "@landings/fetch-landings-success",
  FETCH_LANDINGS_FAILURE = "@landings/fetch-landings-failure",
  CREATE_LANDING_SUCCESS = "@landings/create-landing-success",
  UPDATE_LANDING_SUCCESS = "@landings/update-landing-success",
  REMOVE_LANDING_SUCCESS = "@landings/remove-landing-success"
}

interface FetchLandingsIsLoadingAction {
  type: LandingsActionTypes.FETCH_LANDINGS_LOADING;
}

interface FetchLandingsIsSuccessAction {
  type: LandingsActionTypes.FETCH_LANDINGS_SUCCESS;
  payload: { landings: Landing[] };
}

interface FetchLandingsIsFailureAction {
  type: LandingsActionTypes.FETCH_LANDINGS_FAILURE;
}

interface CreateLandingIsSuccessAction {
  type: LandingsActionTypes.CREATE_LANDING_SUCCESS;
}

interface UpdateLandingIsSuccessAction {
  type: LandingsActionTypes.UPDATE_LANDING_SUCCESS;
}

interface RemoveLandingIsSuccessAction {
  type: LandingsActionTypes.REMOVE_LANDING_SUCCESS;
}

type LandingsActions =
  | FetchLandingsIsLoadingAction
  | FetchLandingsIsSuccessAction
  | FetchLandingsIsFailureAction
  | CreateLandingIsSuccessAction
  | UpdateLandingIsSuccessAction
  | RemoveLandingIsSuccessAction;

type ActionsTypeWithSnackbar = ThunkActionCreator<
  SnackbarActions<LandingsActions | DrawersActions>
>;

const fetchLandingsIsLoading = (): FetchLandingsIsLoadingAction => ({
  type: LandingsActionTypes.FETCH_LANDINGS_LOADING
});

const fetchLandingsIsSuccess = (
  landings: Landing[]
): FetchLandingsIsSuccessAction => ({
  type: LandingsActionTypes.FETCH_LANDINGS_SUCCESS,
  payload: { landings }
});

const fetchLandingsIsFailure = (): FetchLandingsIsFailureAction => ({
  type: LandingsActionTypes.FETCH_LANDINGS_FAILURE
});

const createLandingIsSuccess = (): CreateLandingIsSuccessAction => ({
  type: LandingsActionTypes.CREATE_LANDING_SUCCESS
});

const updateLandingIsSuccess = (): UpdateLandingIsSuccessAction => ({
  type: LandingsActionTypes.UPDATE_LANDING_SUCCESS
});

const removeLandingIsSuccess = (): RemoveLandingIsSuccessAction => ({
  type: LandingsActionTypes.REMOVE_LANDING_SUCCESS
});

const fetchLandings =
  (): ThunkActionCreator<LandingsActions> => async dispatch => {
    dispatch(fetchLandingsIsLoading());
    try {
      const { landings } = await api.admin.landings.getLandings();
      dispatch(fetchLandingsIsSuccess(landings));
    } catch (err) {
      dispatch(fetchLandingsIsFailure());
    }
  };

const createLanding =
  (
    data: CreateLandingRequest,
    formikHelpers: FormikHelpers<typeof data>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.landings.createLanding(data);
      formikHelpers.resetForm();
      dispatch(openSnackbar({ type: "success", message: "Landing created!" }));
      dispatch(createLandingIsSuccess());
      dispatch(fetchLandings());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const updateLanding =
  (
    id: number,
    data: CreateLandingRequest,
    formikHelpers: FormikHelpers<CreateLandingRequest>
  ): ActionsTypeWithSnackbar =>
  async dispatch => {
    try {
      await api.admin.landings.updateLanding(id, data);
      dispatch(openSnackbar({ type: "success", message: "Landing updated!" }));
      dispatch(updateLandingIsSuccess());
      dispatch(fetchLandings());
      dispatch(closeDrawer());
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

const removeLanding =
  (id: number): ActionsTypeWithSnackbar =>
  async dispatch => {
    await api.admin.landings.deleteLanding(id);
    dispatch(openSnackbar({ type: "success", message: "Landing removed!" }));
    dispatch(removeLandingIsSuccess());
    dispatch(fetchLandings());
  };

interface LandingsState {
  error: boolean;
  isLoading: boolean;
  data: Landing[];
}

const initialState: LandingsState = {
  error: false,
  isLoading: false,
  data: []
};

const reducer: Reducer<LandingsState, LandingsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case LandingsActionTypes.FETCH_LANDINGS_LOADING:
      return {
        error: false,
        isLoading: true,
        data: []
      };
    case LandingsActionTypes.FETCH_LANDINGS_SUCCESS:
      return {
        error: false,
        isLoading: false,
        data: action.payload.landings
      };
    case LandingsActionTypes.FETCH_LANDINGS_FAILURE:
      return {
        error: true,
        isLoading: false,
        data: []
      };

    default:
      return state;
  }
};

const getLandingsState = (state: AppState) => state.landings;
const getLandingsSelect = (state: AppState) =>
  groupBy(
    state.landings.data.map(({ brandId, landingPage }) => ({
      brandId,
      label: landingPage,
      value: landingPage
    })),
    "brandId"
  );

export {
  reducer,
  createLanding,
  fetchLandings,
  updateLanding,
  removeLanding,
  getLandingsSelect,
  getLandingsState
};
