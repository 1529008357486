import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "api";
import { openDialog } from "modules/dialog";
import { getAuthState } from "modules/authentication";
import TermsContent from "components/terms-content";

export default function useTermsContionsCheck() {
  const dispatch = useDispatch();
  const { tcAccepted } = useSelector(getAuthState);

  useEffect(() => {
    if (tcAccepted === false) {
      dispatch(
        openDialog({
          dialog: "accept-tc",
          payload: {
            type: "submit",
            title: "Accept New Terms & Conditions",
            content: <TermsContent />,
            onConfirm: () => api.affiliate.auth.acceptTc()
          }
        })
      );
    }
  }, [dispatch, tcAccepted]);
}
