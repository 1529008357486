export default function (extension: string) {
  switch (extension) {
    case ".xml":
    case ".xls":
    case ".xlsx":
    case ".doc":
    case ".docx":
      return "document";
    case ".svg":
    case ".png":
    case ".jpg":
    case ".jpeg":
      return "image";
    case ".pdf":
      return "pdf";
    default:
      return "document";
  }
}
