import { useSelector } from "react-redux";

import { getAffiliateFeesState } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const { isLoading, error, data } = useSelector(getAffiliateFeesState);

  return {
    classes,
    isLoading,
    error,
    fees: data
  };
}
