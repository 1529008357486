import { combineReducers } from "redux";

import { reducer as error } from "modules/error";
import { reducer as authentication } from "modules/authentication";
import { reducer as users } from "modules/users";
import { reducer as viewMode } from "modules/view-mode";
import { reducer as datepicker } from "modules/datepicker";
import { reducer as snackbar } from "modules/snackbar";
import { reducer as dialog } from "modules/dialog";
import { reducer as drawers } from "drawers";
import { reducer as affiliates } from "modules/affiliates-sidebar";
import { reducer as affiliate } from "modules/affiliate";
import { reducer as affiliateOverview } from "modules/affiliate-overview";
import { reducer as affiliateActivities } from "modules/affiliate-activities";
import { reducer as affiliateLandings } from "modules/affiliate-landings";
import { reducer as overview } from "modules/overview";
import { reducer as player } from "modules/affiliate-player";
import { reducer as players } from "modules/affiliate-players";
import { reducer as playersRevenue } from "modules/affiliate-players-revenue";
import { reducer as link } from "modules/affiliate-link";
import { reducer as links } from "modules/affiliate-links";
import { reducer as plan } from "modules/plan";
import { reducer as plans } from "modules/plans";
import { reducer as deal } from "modules/affiliate-deal";
import { reducer as deals } from "modules/affiliate-deals";
import { reducer as affiliateFees } from "modules/affiliate-fees";
import { reducer as affiliateFee } from "modules/affiliate-fee";
import { reducer as affiliatePayments } from "modules/affiliate-payments";
import { reducer as payment } from "modules/payment";
import { reducer as payments } from "modules/payments";
import { reducer as landings } from "modules/landings";
import { reducer as subaffiliates } from "modules/affiliate-sub-afiiliates";
import { reducer as log } from "modules/affiliate-log";
import { reducer as callbacks } from "modules/affiliate-callbacks";
import { reducer as invoice } from "modules/affiliate-invoice";
import { reducer as fees } from "modules/fees";
import { reducer as fee } from "modules/fee";
import { reducer as invoices } from "modules/affiliate-invoices";
import { reducer as googleAuth } from "modules/google-auth";

const rootReducer = combineReducers({
  error,
  authentication,
  googleAuth,
  affiliates,
  affiliate: combineReducers({
    profile: affiliate,
    overview: affiliateOverview,
    payments: affiliatePayments,
    activities: affiliateActivities,
    landings: affiliateLandings,
    fees: affiliateFees,
    fee: affiliateFee,
    invoice,
    invoices,
    player,
    players,
    playersRevenue,
    link,
    links,
    deal,
    deals,
    subaffiliates,
    callbacks,
    log
  }),
  users,
  viewMode,
  datepicker,
  snackbar,
  dialog,
  drawers,
  overview,
  payment,
  payments,
  plan,
  plans,
  fee,
  fees,
  landings
});

export { rootReducer };
