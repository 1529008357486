import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

import { skeletonStyles } from "./styles";

const ROWS_COUNT = Array.from(Array(17));

interface TableRowProps {
  header?: boolean;
}

const TableRow: FC<TableRowProps> = ({ header }) => (
  <>
    <Box style={skeletonStyles.tableRow}>
      <Skeleton width={64} height={18} />
      <Skeleton width={128} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      <Skeleton width={96} height={18} />
      {!header ? (
        <Skeleton width={144} height={32} style={skeletonStyles.tableButton} />
      ) : (
        <Box width={144} height={32} />
      )}
    </Box>
    <Divider />
  </>
);

const PaymentsSkeleton: FC = () => (
  <Box style={skeletonStyles.container}>
    <TableRow header />
    <Divider />
    {ROWS_COUNT.map((_, index) => (
      <TableRow key={index} />
    ))}
  </Box>
);

export default PaymentsSkeleton;
