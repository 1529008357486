import React, { useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import AffiliateLog, { fetchAffiliateLog } from "modules/affiliate-log";

interface Params {
  id: string;
}

const AffiliateLogPage: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();

  useEffect(() => {
    dispatch(fetchAffiliateLog(Number(id)));
  }, [dispatch, id]);

  return <AffiliateLog />;
};

export default AffiliateLogPage;
