import { SubAffiliate } from "@types";

import { useCallback, ChangeEvent, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useRoles, useSearch } from "hooks";
import { BASE_REFERRAL_URL } from "constants/variables";
import { openDialog } from "modules/dialog";
import { getDatePickerState } from "modules/datepicker";

import {
  deleteAffiliateSubAffiliate,
  getAffiliateSubAffiliatesState
} from "./duck";
import { useStyles } from "./styles";

interface Params {
  id: string;
  year: string;
  month: string;
}

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams<Params>();
  const { userId, isAdmin } = useRoles();
  const { year, month } = useSelector(getDatePickerState);
  const { isLoading, error, data, total, totals } = useSelector(
    getAffiliateSubAffiliatesState
  );
  const { query, setQuery, results } = useSearch<SubAffiliate>(
    ["affiliateId"],
    data
  );

  const actualId = id ? id : userId;
  const referralLink = `${BASE_REFERRAL_URL}${actualId}`;

  const handleSearchQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleRemoveAffiliate = useCallback(
    (event: MouseEvent<HTMLElement>) =>
      ({ affiliateId, affiliateName }: SubAffiliate) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "delete-subaffiliate",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              content: `${affiliateName} (${affiliateId})`,
              onConfirm: () =>
                dispatch(
                  deleteAffiliateSubAffiliate(affiliateId, {
                    affiliateId: Number(actualId),
                    year: Number(year),
                    month: Number(month)
                  })
                )
            }
          })
        );
      },
    [dispatch, actualId, year, month]
  );

  return {
    classes,
    error,
    isLoading,
    total,
    totals,
    referralLink,
    affiliateId: String(actualId),
    searchQuery: query,
    subAffiliates: results,
    handleSearchQuery,
    handleRemoveAffiliate: isAdmin ? handleRemoveAffiliate : undefined
  };
}
