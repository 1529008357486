import { AffiliateLink } from "@types";
import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import Table, {
  Column,
  MultipleColumn,
  DropdownColumn
} from "components/table";
import Search from "components/search";
import EmptyData from "components/empty-data";
import BrandFilter from "components/brand-filter";

import Skeleton from "./Skeleton";
import useAffiliateLinks from "./useAffiliateLinks";

interface Props {
  handleLinkClick: (link: AffiliateLink) => void;
  handleDeleteLink: (
    event: React.MouseEvent<HTMLElement>
  ) => (link: AffiliateLink) => void;
  handleCreateDrawerOpen: () => void;
}

const Component: FC<Props> = ({
  handleLinkClick,
  handleDeleteLink,
  handleCreateDrawerOpen
}) => {
  const {
    classes,
    error,
    isLoading,
    isEmpty,
    links,
    searchQuery,
    brandFilterProps,
    handleSearch
  } = useAffiliateLinks();

  return (
    <Box className={classes.container}>
      <Box>
        <Typography variant="h3" className={classes.title}>
          Active Links
        </Typography>
        <Search
          value={searchQuery}
          disabled={isEmpty}
          onChange={handleSearch}
          placeholder="Search link"
          buttons={
            <>
              <BrandFilter {...brandFilterProps} />
              <Button color="secondary" onClick={handleCreateDrawerOpen}>
                create new link
              </Button>
            </>
          }
        />
      </Box>
      {isLoading ? (
        <Skeleton />
      ) : isEmpty || error ? (
        <EmptyData />
      ) : (
        <Box className={classes.table}>
          <Table
            initialData={links}
            estimatedItemSize={89}
            handleRowClick={handleLinkClick}
          >
            <MultipleColumn
              align="left"
              label="Link information"
              name="name"
              urlName="code"
              secondName="landingPage"
            />
            <Column label="Deal" name="deal" type="text" align="left" />
            <DropdownColumn name="dropdown" className={classes.iconColumn}>
              <MenuItem onClick={handleDeleteLink}>Remove</MenuItem>
            </DropdownColumn>
          </Table>
        </Box>
      )}
    </Box>
  );
};
export default Component;
