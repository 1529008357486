import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { LINK_URL } from "constants/variables";

import { MultipleColumnProps } from "../../types";

const styles = (theme: Theme) =>
  createStyles({
    value: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
      color: theme.colors.black21
    },
    secondValue: {
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.black75
    },
    urlValue: {
      display: "flex",
      marginTop: 4,
      padding: "2px 3px 2px 4px",
      borderRadius: 2,
      background: theme.colors.teal50
    },
    urlValueText: {
      fontSize: 12,
      lineHeight: "16px",
      color: theme.colors.teal
    }
  });

const MultipleColumn = withStyles(styles)(
  ({
    value,
    urlValue,
    urlName: _urlName,
    secondName: _secondName,
    secondValue,
    classes,
    ...rest
  }: MultipleColumnProps) => {
    return (
      <Box {...rest}>
        <Typography className={classes.value}>{value}</Typography>
        {!!secondValue && (
          <Typography className={classes.secondValue}>{secondValue}</Typography>
        )}
        {!!urlValue && (
          <Box className={classes.urlValue}>
            <Typography className={classes.urlValueText} noWrap>
              {`${LINK_URL}${urlValue}`}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);

export default MultipleColumn;
