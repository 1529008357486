import { UpdateAffiliateFeesRequest, CreateAffiliateFeesValues } from "@types";

export default function ({
  affiliateId,
  brandId,
  fees
}: CreateAffiliateFeesValues): UpdateAffiliateFeesRequest {
  return {
    affiliateId,
    brandId,
    fees: fees.map(({ periodFrom, periodTo, ...fee }) => ({
      ...fee,
      brandId,
      periodFrom: periodFrom.toISOString(),
      periodTo: periodTo.toISOString()
    }))
  };
}
