import React, { FC } from "react";

import PageContainer from "components/page-container";
import Landings from "modules/landings";

const LandingsPage: FC = () => (
  <PageContainer>
    <Landings />
  </PageContainer>
);

export default LandingsPage;
