import { UpdateAffiliateViewProfileRequest } from "@types";

import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormikHelpers } from "formik";

import { BASE_REFERRAL_URL } from "constants/variables";
import {
  getAffiliateState,
  getAffiliateViewProfileState,
  updateAffiliateViewProfile,
  refreshAffiliateApiToken
} from "modules/affiliate";
import { openDialog } from "modules/dialog";
import { getViewModeState } from "modules/view-mode";
import { getAuthState } from "modules/authentication";

import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, error, apiToken } = useSelector(getAffiliateState);
  const profile = useSelector(getAffiliateViewProfileState);
  const { userId } = useSelector(getAuthState);
  const { affiliateId } = useSelector(getViewModeState);

  const referralLink = `${BASE_REFERRAL_URL}${
    affiliateId ? affiliateId : userId
  }`;

  const handleRefreshToken = useCallback(() => {
    dispatch(
      openDialog({
        dialog: "refresh-api-token",
        payload: {
          type: "submit",
          title: "Are you sure you want to refresh API Token?",
          subtitle: "This operation cannot be undone",
          content: apiToken,
          onConfirm: () => dispatch(refreshAffiliateApiToken())
        }
      })
    );
  }, [dispatch, apiToken]);

  const handleSubmit = useCallback(
    (
      values: UpdateAffiliateViewProfileRequest,
      helpers: FormikHelpers<UpdateAffiliateViewProfileRequest>
    ) => {
      dispatch(updateAffiliateViewProfile(values, helpers));
    },
    [dispatch]
  );

  return {
    classes,
    error,
    isLoading,
    profile,
    apiToken,
    referralLink,
    handleSubmit,
    handleRefreshToken
  };
}
