import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      zIndex: "1301 !important"
    },
    paper: {
      maxWidth: 768,
      padding: 16,
      borderRadius: 16
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    title: {
      color: theme.colors.blue,
      fontWeight: 500
    },
    subtitle: {
      textTransform: "lowercase"
    },
    divider: {},
    content: {
      marginTop: 16,
      color: theme.colors.black21
    },
    text: {
      marginTop: 8,
      fontSize: 16,
      lineHeight: "32px",
      fontWeight: 400,
      // wordWrap: "break-word",
      color: theme.colors.black21
    },
    actions: {
      padding: "8px 24px",
      justifyContent: "space-between"
    },
    actionLinkText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "24px",
      color: theme.colors.black21
    },
    link: {
      color: theme.colors.blue,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  })
);

export { useStyles };
