import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      cursor: "pointer",
      color: theme.colors.black61,
      textDecoration: "none",
      "&:hover:not($disabled)": {
        color: theme.colors.blue
      }
    },
    active: {
      color: theme.colors.blue
    },
    disabled: {
      pointerEvents: "none",
      color: theme.colors.blackbd
    }
  })
);

export { useStyles };
