import { AffiliatePayment } from "@types";

import { ChangeEvent, useCallback } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { useSearch } from "hooks";
import { getAffiliateState } from "modules/affiliate";

import { getAffiliatePaymentsState } from "./duck";
import { useStyles } from "./styles";

export default function () {
  const classes = useStyles();
  const {
    data,
    isLoading: isTableLoading,
    totals
  } = useSelector(getAffiliatePaymentsState);
  const { isLoading: isAccountLoading, data: account } =
    useSelector(getAffiliateState);
  const { query, setQuery, results } = useSearch<AffiliatePayment>(
    ["description"],
    data
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  return {
    classes,
    isEmpty: isEmpty(data),
    disabled: isEmpty(results),
    isLoading: isAccountLoading || isTableLoading,
    account,
    totals,
    payments: results,
    searchValue: query,
    handleSearch
  };
}
