import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowIcon from "@material-ui/icons/KeyboardBackspaceRounded";

import TextField from "components/text-field";

import { useStyles } from "../styles";

interface Props {
  disabled?: boolean;
  handleBack: (event: React.SyntheticEvent) => void;
}

const ThirdStep: FC<Props> = ({ disabled, handleBack }) => {
  const classes = useStyles();

  return (
    <Box className={classes.form}>
      <Typography className={classes.title}>Change New Password</Typography>
      <TextField label="New Password" name="password" type="password" />
      <TextField
        label="Confirm New Password"
        name="passwordConfirm"
        type="password"
      />
      <Box className={classes.buttons}>
        <IconButton className={classes.backButton} onClick={handleBack}>
          <ArrowIcon />
        </IconButton>
        <Button
          type="submit"
          disabled={disabled}
          className={classes.submitButton}
        >
          change password
        </Button>
      </Box>
    </Box>
  );
};

export default ThirdStep;
