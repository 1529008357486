import { CreateFeeRuleValue, FeeRule } from "@types";

import uniqWith from "lodash/uniqWith";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";

export default function (rules: FeeRule[]) {
  const omitedRules = rules.map(rule => omit(rule, ["countryId", "ruleId"]));
  const equalRulesValues = uniqWith(omitedRules, isEqual);

  const groupedRules: CreateFeeRuleValue[] = equalRulesValues.map(values => {
    const matchedNames = rules
      .filter(rule => isEqual(omit(rule, ["countryId", "ruleId"]), values))
      .map(({ countryId }) => String(countryId));

    return {
      countryIds: matchedNames,
      ...values
    };
  });

  return groupedRules;
}
