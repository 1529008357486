import React, { FC, ReactElement } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Logo from "assets/icons/Logo";

import { useStyles } from "./styles";

interface Props {
  children: ReactElement;
}

const NonLoggedin: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.logo}>
        <Logo width={212} height={40} />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default NonLoggedin;
