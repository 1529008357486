import { Landing, CreateLandingRequest } from "@types";

import {
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  ChangeEvent
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikHelpers } from "formik";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

import { BRANDS } from "constants/brands";
import { useSearch } from "hooks";
import { openDrawer } from "drawers";
import { openDialog } from "modules/dialog";

import {
  fetchLandings,
  createLanding,
  updateLanding,
  removeLanding,
  getLandingsState
} from "./duck";

const initialExpandedState = mapValues(BRANDS, () => false);

export default function () {
  const dispatch = useDispatch();

  const { data, isLoading, error } = useSelector(getLandingsState);

  const [isExpanded, setExpanded] = useState(initialExpandedState);
  const { query, setQuery, results } = useSearch<Landing>(
    ["landingPage"],
    data
  );

  useEffect(() => {
    dispatch(fetchLandings());
  }, [dispatch]);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  const handleTableExpand = useCallback(
    (brandId: BRANDS) => {
      setExpanded({ ...isExpanded, [brandId]: !isExpanded[brandId] });
    },
    [isExpanded]
  );

  const handleCreateLanding = useCallback(
    (
      values: CreateLandingRequest,
      helpers: FormikHelpers<CreateLandingRequest>
    ) => {
      dispatch(createLanding(values, helpers));
    },
    [dispatch]
  );

  const handleUpdateLanding = useCallback(
    (id: number) =>
      (
        values: CreateLandingRequest,
        helpers: FormikHelpers<CreateLandingRequest>
      ) => {
        dispatch(updateLanding(id, values, helpers));
      },
    [dispatch]
  );

  const handleRemoveLanding = useCallback(
    (event: MouseEvent<HTMLElement>) =>
      ({ landingId, landingPage }: Landing) => {
        event.stopPropagation();

        dispatch(
          openDialog({
            dialog: "remove-landing",
            payload: {
              type: "delete",
              title: "Are you sure you want to delete?",
              subtitle: "This operation cannot be undone",
              content: landingPage,
              onConfirm: () => dispatch(removeLanding(landingId))
            }
          })
        );
      },
    [dispatch]
  );

  const handleEditDrawerOpen = useCallback(
    (landing: Landing) => {
      const { brandId, landingId, landingPage } = landing;

      dispatch(
        openDrawer("edit-landing", {
          title: "Edit Landing",
          initialState: {
            brandId,
            landingPage
          },
          onSubmit: handleUpdateLanding(landingId)
        })
      );
    },
    [dispatch, handleUpdateLanding]
  );

  const handleCreateDrawerOpen = useCallback(() => {
    dispatch(
      openDrawer("create-landing", {
        title: "Create New Landing",
        onSubmit: handleCreateLanding
      })
    );
  }, [dispatch, handleCreateLanding]);

  return {
    error,
    isLoading,
    isExpanded,
    isEmpty: isEmpty(data),
    isEmptyLandings: isEmpty(results),
    landings: groupBy(results, "brandId"),
    searchQuery: query,
    handleSearch,
    handleTableExpand,
    handleRemoveLanding,
    handleEditDrawerOpen,
    handleCreateDrawerOpen
  };
}
